import React, {useEffect, useState} from "react"

import {
  getRecArt,
} from '../../queries/recommendationQueries'

import {
  createPlaylist2,
  removePlaylist,
} from '../../queries/playListQueries'

import {dexdb} from '../../App'

import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'

import SnackAlert from '../Dialogs/SnackAlert'

export default function PlayListButton({
    id: art_id,
    playlist,
    playlistId,
    size='medium'
}){

    const [ playListActive, setPlayListActive] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const updatePlaylist = async () =>{ 

      if(playListActive){
        try {
          await removePlaylist({
            playlistId,
            art_id,
          })
          setPlayListActive(false)
        } catch(error){
          showSnack('error', 'Error removing from playlist.')
        }
       
      } else {

        try {       
          const art = await dexdb.articles.where('id').equals(parseInt(art_id)).first()
          const rec_id = await getRecArt({art_id})
          await createPlaylist2({
            art_id,
            rec_id,
            playlistId, 
            art_date: art.date,
            title: art.title,
            lang: art.lang,
          })
          setPlayListActive(true)
          
        } catch(error){
          showSnack('error', 'Error adding to playlist.')
        }
      }
    }

    useEffect(() => {
      setPlayListActive(playlist)
    },[playlist])

    return(<>
        <Button   
            size={size}
            onClick={updatePlaylist} 
            startIcon={playListActive ? <CheckIcon /> : <AddIcon />}
            variant="contained" 
            color={playListActive ? "error" : "disabled"} >
            Playlist
        </Button> 
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          />
        </>
    )
}

