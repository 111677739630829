import React from "react";

import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from "@mui/material/Typography";
import DateRange from '@mui/icons-material/DateRange'

import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import ArtLinkItem from '../ListItems/ArtLinkItem'

export default function ClusterRecsCard({ 
  clusterRecs,
  lang
 }){
    return <Card sx={{
              padding:3, minHeight: 500, height: '100%'}}>
              <Grid container 
                    justifyContent='space-between' 
                    alignItems='center'
              >
                <Grid item>
                  <Typography variant="h6">
                    Current Recommendations
                  </Typography>
                </Grid>
                <Grid item>
                  <BasicLinkIconButton 
                    size='small'
                    title='History'
                    to='/user/history'
                    icon={<DateRange />}
                  />
                </Grid>
              </Grid>
              <Divider sx={{
                  marginTop: 1,
                  marginBottom: 1,
                }} />
              <Grid container 
                justifyContent='space-between' 
                alignItems='center'
                spacing={2}
              >
              {clusterRecs && clusterRecs.map((r,i) => 
                <ArtLinkItem lang={lang} key={i} {...r}/>
              )}
              </Grid>
          </Card> 
}