import React, {useState, useEffect} from 'react'
import { useLiveQuery } from "dexie-react-hooks"
import {dexdb} from '../App'

import {
    getGoals, 
    updateGoals, 
    createGoal, 
    deleteGoal,
} from '../queries/goalQueries'

import GoalsPage from '../components/Pages/GoalsPage'

export default function Goals(){
 
    const member = useLiveQuery(() => dexdb.member.toArray(), [])

    const goals = useLiveQuery(() => dexdb.goals.toArray(), [])

    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ read_goal, setReadGoal ] = useState(0)
    const [ quiz_goal, setQuizGoal ] = useState(0)
    const [ listen_goal, setListenGoal ] = useState(0)
    const [ lang, setLang ] = useState('')

    const handleReadGoal = event => setReadGoal(event.target.value)

    const handleQuizGoal = event => setQuizGoal(event.target.value)

    const handleListenGoal = event => setListenGoal(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const addGoals = async () => {
        setLoading(true)
        const newGoal = {
            read_goal,
            quiz_goal, 
            listen_goal,
            lang,
        }

        try {
            await createGoal(newGoal)
            showSnack('success', 'Goal added!')
            setReadGoal(0)
            setQuizGoal(0)
            setListenGoal(0)
        } catch(error){
            showSnack('error', 'Error adding goals!')
        } finally{
            setLoading(false)
        }
       
    }

    const deleteGoal1 = async (id) => {
        const act = goals.filter(g => g.id===id)[0]
        if(!act.active){
            try {
                await deleteGoal({id, goals})
            } catch(error){
                showSnack('error', 'Error activating goal!')
            }
        } else {
            showSnack('error', 'Deselect before deleting.')
        }
    }

    const activateGoal = async (id) => {
        const act = goals.filter(g => g.id===id)[0]

        if(!act.active){
            const newact = {...act, active: true}

            const notact = goals.filter(g => g.id!==id).map(p => ({...p, active: false}))
            const newgoals = [...notact, newact]

            try {
                await updateGoals(newgoals)
            } catch(error){
                showSnack('error', 'Error activating goal!')
            }
        } 
    }

    useEffect(()=> {
    
        const getGoals1 = async () => {
     
            if(member && member.length>0){
                const lang = member[0].langs[0]
                setLang(lang)

                if(goals && goals!==null){
                    if(goals.length===0){
                        await getGoals() 
                    }
                }
            }
        }

        getGoals1()

    },[member, goals])

    return(
    <GoalsPage 
        lang={lang}
        handleReadGoal={handleReadGoal}
        read_goal={read_goal}
        handleListenGoal={handleListenGoal}
        listen_goal={listen_goal}
        handleQuizGoal={handleQuizGoal}
        quiz_goal={quiz_goal}
        loading={loading}
        addGoals={addGoals}
        deleteGoal1={deleteGoal1}
        activateGoal={activateGoal}
        goals={goals}
        snack={snack}
        handleClose={handleClose}
        msgType={msgType}
        alertMsg={alertMsg}
    />

  )
}


