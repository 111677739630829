import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch'
import green from '@mui/material/colors/green'
// import grey from '@mui/material/colors/grey'
import red from '@mui/material/colors/red'

const NotificationSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      color: red['A400'],
      '&.Mui-checked': {
        color: green['A400'],
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
  }));

export default NotificationSwitch
  
//   const LangaPurpleSwitch = styled(Switch)(({ theme }) => ({
//     '& .MuiSwitch-switchBase': {
//       color: grey[300],
//       '&.Mui-checked': {
//         color: '#6415ff',
//         '& + .MuiSwitch-track': {
//           opacity: 1,
//           backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//         },
//       },
//     },
//   }));
  