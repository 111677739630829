import React from "react";

import Button from '@mui/material/Button'

const IconButton = ({
    onClick,
    title,
    icon,
    size='medium'
}) =>

    <Button 
        variant="contained" 
        size={size}
        color="primary" 
        startIcon={icon}
        onClick={onClick}
        style={{
            backgroundColor:'#6415ff',
            '&:hover': {
              backgroundColor: '#5618d0',
            },
            color:'white'
          }}
        >
        {title}
    </Button>

export default IconButton