import React from "react";

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/Description'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'
import Container from '@mui/material/Container'

import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import ClusterLinksCard from '../Cards/ClusterLinksCard'
import ClusterRecsCard from '../Cards/ClusterRecsCard'
import PurpleSwitch from '../Switches/LangaPurpleSwitch1'
import BasicLinkIconButton from "../Buttons/BasicLinkIconButton"
import FlagRound from '../ImageElements/FlagRound'
import ConfirmDelete from '../Dialogs/ConfirmDelete1'
import ClusterInput from '../Inputs/ClusterInput'
import ConfirmParsedArt from '../Dialogs/ConfirmParsedArt'
import AdSidebar from "../Ads/AdSidebar"

export default function ClusterPage({ 
    clusterName, 
    lang, 
    count,
    url,
    loading,
    linkLoading,
    clusterLinks,
    clusterRecs,
    handleLink,
    deleteCluster,
    confirmDelete,
    closeConfirm,
    confirm,
    deleteConfirm,
    clusterActive,
    handleActivate,
    snack,
    msgType,
    alertMsg,
    handleClose,
    cluster_id,
    linkError,
    linkerrorMsg,
    parsedArtTitle,
    parsedArtText,
    addParsedArt,
    parseArt,
    disagree,
    agree,
    toggleUrl,
    addUrl,
 }){

    const { 
      deletecluster,
    } = getTranslations()

    const deleteMsg = "If you don't wish to receive article recommendations from the cluster at this time, you can simply deactivate the cluster."
    const flagSize = 'small'

    const linkCount = count && count>0 ? count : 0

    const link = `/user/cluster_add_text/${cluster_id}/${clusterName}/${lang}/${count}/${clusterActive}`
    
    const submitAnswer = event => {
      if (event.key === 'Enter') {
        parseArt()
      }
    }

    return( 
              <Grid container 
                    spacing={1} 
                    justifyContent='center' 
              >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                     
                      {!loading &&    
                        <Card sx={{padding: 2, mb: 1, mt: 1}}>
                          <Grid container 
                                justifyContent="space-between" 
                                alignItems="center" 
                                spacing={2} 
                          >
                              <Grid item>
                                <Grid container spacing={2}  alignItems="center" >
                                  <Grid item >
                                    <FlagRound 
                                      flagSize={flagSize}
                                      lang={lang}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h5">
                                      {clusterName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container spacing={2} alignItems="center" >
                                  <Grid item>
                                    <Button 
                                      size='small'
                                      variant="contained" 
                                      onClick={toggleUrl} 
                                      startIcon={<LinkIcon />}
                                    >
                                      Add Url
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button 
                                      size='small'
                                      variant="contained" 
                                      color="error" 
                                      onClick={confirmDelete} 
                                      startIcon={<DeleteIcon />}
                                    >
                                        {deletecluster} 
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    {count>0 &&
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          Receive Recommendations
                                        </Grid>
                                        <Grid item>
                                          <PurpleSwitch
                                            checked={clusterActive}
                                            onChange={handleActivate}
                                            name="clusteractive"
                                          />
                                        </Grid>
                                      </Grid>  
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                          </Grid>
                        </Card> 
                      }
                      {addUrl && 
                        <Card sx={{p: 2, mb:1}}> 
                        <Grid container 
                              justifyContent="space-between" 
                              alignItems="center" 
                              spacing={2} 
                        >
                          <Grid item xs={12} md={7}> 
                            <FormControl sx={{width: '100%'}}>
                              <ClusterInput 
                                error={linkError}
                                errorMsg={linkerrorMsg}
                                value={url}
                                label='Url for article in your native language.'
                                handleFunc={handleLink} 
                                submitFunc={submitAnswer}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item >
                            <LoadingIconButton
                              title='Add Url'
                              onClick={parseArt}
                              loading={linkLoading}
                              icon={<LinkIcon />}
                            />
                          </Grid>
                          <Grid item>
                            <BasicLinkIconButton 
                              to={link} 
                              title='Add Article Text'
                              icon={<TextSnippetIcon />}
                            />
                          </Grid>
                        </Grid>
                        </Card> 
                      }  
                      {!loading &&
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <ClusterLinksCard 
                            clusterLinks={clusterLinks} 
                            count={linkCount} 
                            clusterName={clusterName}
                            cluster_id={cluster_id}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <ClusterRecsCard clusterRecs={clusterRecs} />
                        </Grid>
                      </Grid> 
                      }

                      <div style={{height: 100}} />
                        <ConfirmDelete 
                          deleteAction={deleteCluster}
                          confirm={deleteConfirm}
                          closeConfirm={confirmDelete}
                          title='Delete cluster?'
                          question='Are you sure you want to delete this cluster?'
                          message={deleteMsg}
                          disagree='Dismiss'
                          agree='Delete Cluster'
                        />
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                  </Container>
                </Grid>
                <Grid item>
                  <AdSidebar />
                </Grid>
                <ConfirmParsedArt 
                  title={parsedArtTitle}
                  artText={parsedArtText}
                  addParsedArt={addParsedArt}
                  closeConfirm={closeConfirm}
                  confirm={confirm}
                  disagree={disagree}
                  agree={agree}
                />
              </Grid>

      
    )
}