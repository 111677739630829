import { Grid } from "@mui/material";
import React from "react";

import {
    Banner2503001, 
    Banner2503002, 
    Banner2503003, 
    Banner250300,
} from './Banner250300'

export default function AdQuizBar(){
    return  <Grid container spacing={2}>
                <Grid item>
                    <Banner250300 source='web_quiz' />
                </Grid>
                <Grid item>
                    <Banner2503001 source='web_quiz' />
                </Grid>
                <Grid item>
                    <Banner2503002 source='web_quiz' />
                </Grid>
                <Grid item>
                    <Banner2503003 source='web_quiz' />
                </Grid>
            </Grid>
}

 