import trans from './translations'
import {supabase} from './supabase'
import moment from 'moment'

import {getQuizzes} from './queries/quizQueries'

export const appStoreImg = "http://cdn.mcauto-images-production.sendgrid.net/d379a1f4ce3c8ac6/d3fa9301-cb3f-49cf-bf0c-a7c53c339b3f/223x72.png" 
export const playStoreImg = "http://cdn.mcauto-images-production.sendgrid.net/d379a1f4ce3c8ac6/65c2ea9c-478f-4770-8c7d-939b9b25532f/222x73.png"

export const getLinkCount = (array) => {
  if(array.length>0){
      let countstr1 = 0
      array.forEach(c => {
          const cnt = c.translated_arts.length;
          countstr1+=cnt
      })
      return countstr1
  } else {
      return 0
  }
}

export const toMin = (seconds) => {
  return Math.round(seconds/60)
}


export const getProgresDays = (progress) => {
  const now = new Date()
  const month = now.getMonth() + 1
  const year = now.getFullYear()
  const curDay = now.getDate()

  const daysBeforeToday =  progress.length>0 ? progress.filter(d => d.day<=curDay) : []
  const nowDays = daysBeforeToday.map(d => ({day: `${month}-${d.day.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
    })}-${year}`,
    arts_count: d.arts_count,
    quiz_count: d.quiz_count,
    listen_count: d.listen_count,
  }))

  const quizcnts2 = progress.map(a => a.quiz_count)
  const anscnts2 = progress.map(a => a.arts_count)
  const listencnts2 = progress.map(a => a.listen_count)

  const initval = 0

  const answerCount =  quizcnts2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initval
  );

  const artCount = anscnts2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initval
  );

  const listenCount = listencnts2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initval
  );
  
  return {
    nowDays,
    answerCount,
    artCount,
    listenCount,
  }
}

export const getQuizType = (quizType) => {
  switch (quizType) {
    case 'random':
      return 'Random'
    case 'lastanswered':
      return 'Last Answered'
    case 'spaced':
      return 'Spaced Repetition'
    case 'recentadd':
      return 'Recently Added'
    case 'unanswered':
      return 'Unanswered Words'
    default:
      return 'Random'
  }
}

export const findLongestVoiceName = (names) => {
  const lengths = names.map(el => el.length)
  return Math.max(...lengths)
}

export const noParanthesis = (text) => { 
  return text.replace(/ *\([^)]*\) */g, "").replace(')','')
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

const  getInterval = (answerCount, interval, easiness) => {
  if (answerCount <= 1) {
    return 1;
  } else if (answerCount === 2) {
    return 6;
  } else {
    return Math.round(interval * easiness);
  }
}

const getQuality = (percentCorrect) => {
  if (percentCorrect <= .2) {
    return 1;
  }
  if (percentCorrect <= .4 && percentCorrect > .2) {
    return 2;
  }
  if (percentCorrect <= .4 && percentCorrect > .6) {
    return 3;
  }
  if (percentCorrect <= .6 && percentCorrect > .8) {
    return 4;
  }
  if (percentCorrect >= .8) {
    return 5;
  }
  return 1;
}

const getEasiness = (
  quality,
  easiness,
)=>{
  // 1.3 - 2.5
  const newEasiness =
      easiness + 0.1 - (5.0 - quality) * (0.08 + (5.0 - quality) * 0.02);
  return newEasiness > 1.3 ? newEasiness : 1.3;
}

export const getSpacedRepititionNextDate = (
  answerCount,
  prevEasiness,
  percentCorrect,
 ) =>  {
  const interval = 1; // days;
  const quality = getQuality(percentCorrect); // 1 - 5
  const easiness = getEasiness(quality, prevEasiness); // 1.3 - 2.5
  const newinterval = getInterval(answerCount, interval, easiness);
  const now = moment()
  const newReviewDate = now.add(newinterval, 'days').toISOString().toLocaleString()
  return {
    newReviewDate,
    easiness, 
  };
}

export const getCurrentDayStartEnd = () => {
  const now = new Date()
  const day = now.getDate() 
  const month = now.getMonth()
  const year = now.getFullYear()
  const hours = 0
  const minutes = 0
  const endhours = 23
  const endmins = 59
  const start = new Date(year, month, day, hours, minutes).toISOString()
  const end = new Date(year, month, day, endhours, endmins, 59).toISOString()
  return { 
    start, 
    end,
   }
}

export const formatClusterLinks = (cluster_links,  clusterName) => cluster_links.map(c =>({
  id: c.id,
  title: c.translated_arts.title,
  translated_arts_id: c.translated_arts.id,
  targetLang: c.translated_arts.lang,
  cluster_id: c.cluster_id,
  count: cluster_links.length,
  clusterName
}))

export function getMillli(date){
  return new Date(date)
}

export const formatClusterLinks1 = (
  cluster_links, 
  clusterName,
  lang,
  cluster_id,
  ) => cluster_links.map(c =>({
    id: c.id,
    title: c.title,
    url: c.url,
    translated_arts_id: c.id,
    targetLang: lang,
    cluster_id,
    count: cluster_links.length,
    clusterName
}))

export const incrementStorage = (pageName, page, amount) => localStorage.setItem(pageName, (page+amount).toString())
  
export const getStoredPage = pageName =>  parseInt(localStorage.getItem(pageName))

export const formatRecLinks = (
  recommendations,
) => recommendations
.map(c =>({
  art_date: c.articles.date,
  date: c.rec_date,
  title: c.articles.title,
  art_id: c.articles.id,
  lang: c.lang,
  id: c.id,
  aid: c.aid,
  cluster_id: c.cluster_id,
  timestamp: getMillli(c.articles.date),
}))

export const formatRecLinks1 = (
  recommendations,
) => recommendations
.map(c =>({
  art_date: c.articles.date,
  title: c.articles.title,
  art_id: c.articles.id,
  lang: c.articles.lang,
  id: c.id,
  aid: c.aid,
  playlist_position: c.playlist_position,
  timestamp: getMillli(c.articles.date),
}))

export const formatPlaylistLinks1 = (
  recommendations,
) => recommendations
.map(c =>({
  art_date: c.articles.date,
  title: c.articles.title,
  art_id: c.articles.id,
  lang: c.articles.lang,
  id: c.id,
  aid: c.aid,
  played_date: c.played_date,
}))

export const sortDates = (recs)=> recs.sort(({timestamp: a}, {timestamp: b}) => b-a);

export const getClusterRecs = (recs, cluster_id) => formatRecLinks(recs.filter(r => r.cluster_id === cluster_id))

export const getClusterLinks = (links, cluster_id, clusterName) => formatClusterLinks(links.filter(l => l.cluster_id === cluster_id), clusterName)

export const  clusterSelects = ({cluster_id, clusterName, lang}) => {
  const language = langSwitch(lang)
  return `<option value="${cluster_id}-${lang}">${clusterName} - ${language}</option>`
}

export const getTitle = (url) => {  
  return fetch(url)
    .then((response) => response.text())
    .then((html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      const title = doc.querySelectorAll('title')[0];
      return title.innerText;
    });
};

export const getFutureDate = () => {
  const futureDate = moment().add(2, "minutes").format();
  return futureDate.date.slice(0, 16);
};

export const getCurDate = () => {
  const curDate = moment().format();
  return curDate.date.slice(0, 16);
};

export const postData = async (url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export function toHoursAndMinutes(totalSeconds) {
	let totalMinutes = Math.floor(totalSeconds / 60);
	let extraSeconds = totalSeconds % 60;
	totalMinutes = totalMinutes < 10 ? "0" + totalMinutes : totalMinutes;
	extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
	// const seconds = totalSeconds % 60;
	let hours = Math.floor(totalMinutes / 60);
	hours = hours < 10 ? "0" + hours :hours;
	// const minutes = totalMinutes % 60;
  
	// return { h: hours, m: minutes, s: seconds };
	return `${hours}:${totalMinutes}:${extraSeconds}`
}

export const getSessionToken = async () =>{
  const response = await supabase.auth.getSession()
  return response.data.session.access_token
}

export const postAuth = async (url = '', data = {}) => {
  // Default options are marked with *
  const token = await getSessionToken();
  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    if(!response.ok){
      throw await response.json()
    }
    return await response.json(); // parses JSON response into native JavaScript objects
  } catch(error) {
    throw error
  }
}

export const getAuth = async (url = '') => {
  // Default options are marked with *
  const token = await getSessionToken();

  try {
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if(!response.ok){
      throw await response.json()
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } catch(error) {
    throw error
  }
}

export const getNowIso = () => {
  const d = new Date()
  var now = new Date(d.getTime() - d.getTimezoneOffset()*60000);
  return now.toISOString()
}

export const getLocalTime = pgdate => {
  const localOffset = new Date().getTimezoneOffset()
  const localOffsetMillis = 60 * 1000 * localOffset;
  const asdf = new Date(pgdate)
  const ere = asdf.getTime();
  const oi=ere +localOffsetMillis
  return moment(oi)
}

// outputs date with chose sepator - 03/11/2021
export const formatDate1 = (date, separator) => {
  var dd = date.getDate();
  var mm = date.getMonth()+1; 
  var yyyy = date.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  var yearstr = yyyy.toString().substring(2)
  return mm+separator+dd+separator+yearstr;
}

const groupDate = (progdates) => {
  return progdates.reduce((group, product) => {
    const { date } = product;
    group[date] = group[date] ?? [];
    group[date].push(product);
    return group;
  }, {});
}

const getDayDate = (array, field) => array.map(p =>({'date':  p[field].slice(0, 10)}))

export const getGroupProgressDay = (pl, reads, year, month) => {
  const pldates = getDayDate(pl, 'playlist_date')
  const readsdates = getDayDate(reads, 'created_at')

  const pldatesgrouped = groupDate(pldates)

  const readsgrouped =  groupDate(readsdates)

  const monthyr = `${year}-${month}-`

  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

  const dates1 = days.map(d => monthyr+d)

  return dates1.map(d => ({
    date: d.slice(8, 10), 
    plcount: readsgrouped[d] ? pldatesgrouped[d].length : 0,
    readcount: readsgrouped[d] ? readsgrouped[d].length : 0,
  }))

}

const remove = (arr, removeIndex) => [...arr.slice(0, removeIndex), ...arr.slice(removeIndex+1)]

const insert = (arr, insertIndex, ...newItems) => [...arr.slice(0, insertIndex), ...newItems, ...arr.slice(insertIndex)]

export const reorderList = (arr, currIndex, newIndex) => insert(remove(arr, currIndex), newIndex, arr[currIndex])

export const getRecDocId = uid => `${formatDate1(new Date(),'-')}-${uid}` // 03-11-2021-weifjdiwkwidjf

export const getSingleLangRecs = (lang, recommendations) => recommendations.filter(a => a.lang===lang)

export const langs = [
  {lang:'en',language:'English'},
  {lang:'fr',language:'French'},
  {lang:'de',language:'German'},
  {lang:'es',language:'Spanish'},
  {lang:'it',language:'Italian'},
  {lang:'pt',language:'Portguese'}
]

export function sortDate(array){
    return array.sort(function(a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a>b ? -1 : a<b ? 1 : 0;
    })
  } 

export const checkQuizCount = async () => {

    const now = new Date()
    const curHour = now.getHours()
    const checkHour = localStorage.getItem('checkHour')
    const curHrStr = curHour.toString()
    if(checkHour!==curHrStr){
      // check and store quiz 
      await getQuizzes()
      // set 
      localStorage.setItem('checkHour', curHrStr)
    }
  }

export const groupBy = (array, attr)=>{
  const groups = array.reduce((acc, value) => {
  // Group initialization
  var item = value[attr]

  if (!acc[item]) {
      acc[item] = [];
  }
  
  // Grouping
  acc[item].push(value);
  
  return acc;
  }, {});
  return groups
}

export function langSwitch(lang){

    switch (lang) {
      case 'fr':
        return "French";
      case 'de':
        return "German";
      case 'es':
        return "Spanish";
      case 'en':
        return "English";
      case 'it':
        return "Italian";
      case 'pt':
        return "Portuguese";
      case 'gr':
        return "Greek";
      case 'ru':
          return "Russian";
      default:
        return "English";
     }
  }

  export function langVoiceSwitch(voices,lang){
    const voiceLang = `${lang}-${lang.toUpperCase()}`
    const voice = voices.filter(v => v.lang===voiceLang)
    if (voice.length>0){
      return voice
    } else {
      const voiceLang = `${lang}_${lang.toUpperCase()}`
      const voice = voices.filter(v => v.lang===voiceLang)
      return voice
    }
  }

  export const oneLangVocab = (vocab, selectedLang) => JSON.stringify((vocab.length>0  && vocab.filter(v => v.orig_lang === selectedLang))) || ''
  
  export const oneLangVocabSB = (vocab, selectedLang) => JSON.stringify((vocab.length>0  && vocab.filter(v => v.source_lang === selectedLang))) || ''
  
  export const langLister = (vocab,lang) => {
    return {
      lang, 
      language: langSwitch(lang),
      vocabstring: (vocab.length>0  && oneLangVocab(vocab,lang)) || ''
    }
  }

  export const langListerSb = (vocab, lang) => {
    return {
      lang, 
      language: langSwitch(lang),
      vocabstring: (vocab.length>0  && oneLangVocabSB(vocab,lang)) || ''
    }
  }

  export function langSwitchQuiz(native_lang){
    const curTrans =  getTranslations()
    const {en, es, fr, de, it} = curTrans
    switch (native_lang) {
      case 'en':
        return {language: en, flag_lang: 'gb'}
      case 'de':
        return {language: de, flag_lang: native_lang}
      case 'es':
        return {language: es, flag_lang: native_lang}
      case 'fr':
        return {language: fr, flag_lang: native_lang}
      case 'it':
        return {language: it, flag_lang: native_lang}
      // case 'gr':
      //   return {language:'Greek',flag_lang:native_lang}
      // case 'ru':
      //   return {language:'Russian',flag_lang:native_lang}
      default:
        return {language:'English',flag_lang:'gb'};
    }
  }
  
  export function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export function langers1(lang){
    const curTrans =  getTranslations()
    switch (lang) {
      case 'en':
        return {lang, language: curTrans['en']}
      case 'fr':
        return {lang, language: curTrans['fr']}
      case 'de':
        return {lang, language: curTrans['de']}
      case 'es':
        return {lang, language: curTrans['es']}
      case 'pt':
        return {lang, language: curTrans['pt']}
      case 'it':
        return {lang, language: curTrans['it']}
      default:
        return {lang, language: curTrans['en']};
    }
  }

export const getLangs = langs =>  langs.map(l => langers1(l))

export function getLangs1(lang){
  const curTrans =  getTranslations()
  if (lang === 'es') return {language:curTrans['es'],lang:'es'}
  if (lang === 'en') return {language:curTrans['en'],lang:'en'}
  if (lang === 'fr') return {language:curTrans['fr'],lang:'fr'}
  if (lang === 'de') return {language:curTrans['de'],lang:'de'} 
  if (lang === 'it') return {language:curTrans['it'],lang:'it'}
  if (lang === 'pt') return {language:curTrans['pt'],lang:'pt'}
  // if (lang === 'gr') return {language:curTrans['gr'],lang:'gr'}
  // if (lang === 'ru') return {language:curTrans['ru'],lang:'ru'} 
}

export const splitSentence1 = text => {
  return text
  .split("||||")
  .map(sentence => sentence.trim());
}

export function formatDate(date, separator){
  var dd = date.getDate();

  var mm = date.getMonth()+1; 
  var yyyy = date.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 

    var yearstr = yyyy.toString()
    var yy = yearstr.slice(2,4)
    return mm+separator+dd+separator+yy;
}

export const getUser = () => JSON.parse(localStorage.getItem('user'))

export const getTranslations = () => {
  const user = getUser()
  const storedLang = localStorage.getItem('pageLang')
  const landingLang = storedLang && storedLang.length > 0 ? storedLang : 'en'
  const nativeLang = user ? user.native_lang : landingLang
  // const nativeLang = 'de'
  return trans[nativeLang] ? trans[nativeLang] : 'en'
}

export const getClusters = () => JSON.parse(localStorage.getItem('clusters'))


export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const checkAnswer1 = (guess, targetWord) => {
  if(targetWord.includes('|')){
    const answers = targetWord.split(' | ')
    return answers.includes(guess)
  } if (targetWord.includes(',')) {
    const answers = targetWord.split(', ')
    return answers.includes(guess)
  } else {
    return guess.trim() === targetWord
  } 
}

export function getTime(time){
  switch (time) {
    case 6:
      return '6 AM'
    case 7:
      return '7 AM'
    case 8:
      return '8 AM'
    case 9:
      return '9 AM'
    case 10:
      return '10 AM'
    case 11:
      return '11 AM'
    case 12:
      return '12 PM'
    case 13:
      return '1 PM'
    case 14:
      return '2 PM'
    case 15:
      return '3 PM'
    case 16:
      return '4 PM'
    case 17:
      return '5 PM'
    case 18:
      return '6 PM'
    case 19:
      return '7 PM'
    case 20:
      return '8 PM'
    case 21:
      return '9 PM'
    case 22:
      return '10 PM'
    case 23:
      return '11 PM'
    default:
      return ''
  }
}

export function getOffset(tz){
  const begin = tz.slice(4)
  var n = begin.lastIndexOf(')');
  return begin.substring(0,n)
}

export const times = [
  { tm:6,time:'6 AM' },  
  { tm:7,time:'7 AM' },  
  { tm:8,time:'8 AM' },  
  { tm:9,time:'9 AM' },  
  { tm:10,time:'10 AM' }, 
  { tm:11,time:'11 AM' },  
  { tm:12,time:'12 PM' },  
  { tm:13,time:'1 PM' },  
  { tm:14,time:'2 PM' },  
  { tm:15,time:'3 PM' }, 
  { tm:16,time:'4 PM' },  
  { tm:17,time:'5 PM' },  
  { tm:18,time:'6 PM' },
  { tm:19,time:'7 PM' },  
  { tm:20,time:'8 PM' },  
  { tm:21,time:'9 PM' }, 
  { tm:22,time:'10 PM' },  
  { tm:23,time:'11 PM' }
]

export const minimalTimezoneSet = [
  '(GMT-11:00) Pago Pago', 
  '(GMT-10:00) Hawaii Time',
  '(GMT-10:00) Tahiti', 
  '(GMT-09:00) Alaska Time', 
  '(GMT-08:00) Pacific Time',
  '(GMT-07:00) Mountain Time',
  '(GMT-06:00) Central Time',
  '(GMT-05:00) Eastern Time', 
  '(GMT-04:00) Atlantic Time - Halifax', 
  '(GMT-03:00) Buenos Aires', 
  '(GMT-02:00) Sao Paulo',
  '(GMT-01:00) Azores', 
  '(GMT+00:00) London', 
  '(GMT+01:00) Berlin', 
  '(GMT+02:00) Helsinki', 
  '(GMT+03:00) Istanbul', 
  '(GMT+04:00) Dubai', 
  '(GMT+04:30) Kabul', 
  '(GMT+05:00) India Standard Time', 
  '(GMT+06:00) Dhaka', 
  '(GMT+07:00) Bangkok', 
  '(GMT+08:00) Hong Kong', 
  '(GMT+09:00) Tokyo', 
  '(GMT+10:00) Eastern Time - Brisbane', 
  '(GMT+11:00) Eastern Time - Melbourne, Sydney', 
  '(GMT+12:00) Nauru', 
  '(GMT+13:00) Auckland', 
  '(GMT+14:00) Kiritimati', 
];

export const times1 = [
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];
