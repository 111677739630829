import React from 'react'

import {
    findLongestVoiceName,
    noParanthesis
} from '../../utils'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

const PlayerDropDown = ({
    voices,
    voiceIdx,
    handleVoice,
    labelName,
}) => {
    const voiceNames =  voices.length>0 ? voices.map(v => noParanthesis(v.name)) : ['']

    const longest = findLongestVoiceName(voiceNames)

    const minw = longest*12

    return <FormControl 
                sx={{ m: 1, minWidth: minw }}
                size='small'
            >
                <InputLabel  >
                    {labelName}
                </InputLabel>
                <Select
                    autoWidth={true}
                    label={labelName}
                    value={voiceIdx}
                    onChange={handleVoice}
                >
                    {voiceNames.map((l, i) => 
                        <MenuItem key={i} value={i}>
                            {l}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
}

export default PlayerDropDown