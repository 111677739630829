import React , {useState} from 'react'

import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function AudioBasicCard({
    vocab_audio_library,
  }){
    
    const {url} = vocab_audio_library
  
    const [ playing, setPlaying ] = useState(false)
  
    var audio = new Audio(url);
  
    const playSound = async () => {
      setPlaying(true)
      audio.play().then(d => {
        setPlaying(false)
      });
    }
  
    const pauseSound = () =>{ 
      setPlaying(false)
      audio.pause()
    }
  
    return  <Grid container alignItems='center'>
              <Grid item>
                <Tooltip title="Play">
                  <IconButton aria-label="delete" onClick={playing ? pauseSound : playSound} >
                    <PlayArrowIcon color='success'  />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid> 
  }
  