import React, {useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'

import CloseIcon from '@mui/icons-material/Close'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Button, Link } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import BasicIconButton from '../Buttons/BasicIconButton'
import VocabArt from '../VocabQuiz/VocabArt'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import RedButton from '../Buttons/RedButton'
import SnackAlert from '../Dialogs/SnackAlert'
import Container from '@mui/material/Container'

import WordInput from '../Inputs/WordInput'
import FlagRound from '../ImageElements/FlagRound'
import AdSidebar from "../Ads/AdSidebar"

import { LinkBasic } from '../ListItems/BasicLinks'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'

import ImgList from '../Media/Images/ImgList'
import AudioBasicClips from '../Media/Audio/AudioBasicClips'

import { 
  getTranslations, 
  langSwitch, 
  getLocalTime,
 } from '../../utils'

 const EditSent = ({
  sourceSent,
  handleSourceSent,
  handleUpdate,
  targetSent,
  handleTargetSent,
  toggleEdit,
  editLoading,
  handleDelete,
 }) => {
  return (     
  <Grid container alignItems="center" spacing={2}> 
    <Grid item xs={12}>
      <Divider sx={{mb:2}} />
    </Grid>
    <Grid item xs={12} >
      <FormControl sx={{width: '100%'}}>
        <WordInput 
          name="orig_sent"
          id="orig_sent" 
          label={`Source Sentence`}
          value={sourceSent} 
          onChange={handleSourceSent} 
          submitFunc={handleUpdate}
        />
      </FormControl>
      </Grid>
      <Grid item xs={12} >
        <FormControl sx={{width: '100%'}}>
          <WordInput 
            name="orig_sent"
            id="orig_sent" 
            label={`Target Sentence`}
            value={targetSent} 
            onChange={handleTargetSent} 
            submitFunc={handleUpdate}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={toggleEdit} size="small" startIcon={<CloseIcon />} > 
          Close
        </Button>
      </Grid>
      <Grid item >
        <LoadingButton 
          startIcon={<EditIcon />}
          loading={editLoading} 
          onClick={handleUpdate} 
          size="small"  
          color='success'
        > 
          Update
        </LoadingButton>
    </Grid>
    <Grid item >
      <LoadingButton 
        startIcon={<DeleteIcon />}
        loading={editLoading} 
        onClick={handleDelete}  
        size="small" 
        color='error'
      > 
        Delete
      </LoadingButton>
    </Grid>
    </Grid>
  )
 }

 const AddSentForm = ({
  newSourceSent,
  newTargetSent,
  handleSourceSent,
  handleTargetSent,
  origLanguage,
  transLanguage,
  sentLoading,
  addTransSent,
 }) => {
  return <Grid container
               alignItems="center" 
               spacing={1}
          >
          <Grid item xs={12} >
            <FormControl sx={{width: '100%'}}>
              <WordInput 
                name="orig_sent"
                id="orig_sent" 
                label={`${origLanguage} Sentence`}
                value={newSourceSent} 
                onChange={handleSourceSent} 
                submitFunc={addTransSent}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl sx={{width: '100%'}}>
              <WordInput 
                  name="trans_sent"
                  id="trans_sent" 
                  label={`${transLanguage} Sentence`}
                  value={newTargetSent} 
                  onChange={handleTargetSent} 
                  submitFunc={addTransSent}
                />
            </FormControl>
          </Grid>
          <Grid item>
            <LoadingIconButton 
              title='Add'
              icon={<AddIcon />}
              loading={sentLoading}
              size='small'
              onClick={addTransSent}
            />
          </Grid>
        </Grid>
 }

 const VocabSent = ({
  id,
  source_sent,
  target_sent,
  articles,
  lang,
  title,
  url,
  updatedVocabSent,
  deleteVocabSent,
  editLoading,
}) => {

  const [ sourceSent, setSourceSent ] = useState('')
  const [ targetSent, setTargetSent ] = useState('')
  const [ editopen , setEditOpen ] = useState(false)

  const handleSourceSent = event => setSourceSent(event.target.value)
  const handleTargetSent = event => setTargetSent(event.target.value)

  const toggleEdit = () => setEditOpen(!editopen)

  const handleUpdate = () => updatedVocabSent({
    sourceSent,
    targetSent, 
    sent_id: id,
  })

  const handleDelete = () => deleteVocabSent(id)

  useEffect(() => {
    setSourceSent(source_sent)
    setTargetSent(target_sent)

  }, [source_sent, target_sent])
  const encodedUrl = encodeURIComponent(url)
  const sentUrl = `/user/gutenberg/${lang}/${encodedUrl}`

  return  <Grid item xs={12} >
            {editopen ? 
              <EditSent
                sourceSent={sourceSent}
                handleSourceSent={handleSourceSent}
                handleUpdate={handleUpdate}
                targetSent={targetSent}
                handleTargetSent={handleTargetSent}
                toggleEdit={toggleEdit}
                editLoading={editLoading}
                handleDelete={handleDelete}
              />
              :
              <Grid container 
                    alignItems="center" 
                    justifyContent='space-between'
                    spacing={2}
              >
                 <Grid item xs={12}>
                  <Divider sx={{mb:2}} />
                </Grid>
                <Grid item>
                  <Grid container 
                      alignItems="center" 
                      justifyContent='space-between'
                      spacing={2}
                  >
                    {source_sent && source_sent.length>0 &&
                      <Grid item xs={12} >
                        <Typography >
                          {source_sent} 
                        </Typography>
                      </Grid>
                    }
                    {target_sent && target_sent.length>0 &&
                      <Grid item xs={12} >
  
                          <Typography >
                            {target_sent} 
                          </Typography>
                    
                      </Grid>
                    }
                    {title && title.length>0 && url.length>0 &&
                      <Grid item xs={12} >
                        <Link href={sentUrl}  variant='body1'>
                          {title}
                        </Link>
                      </Grid>
                    }
                   </Grid>
                    {articles &&
                      <VocabArt 
                        artId={articles.id}
                        origLang={lang}
                        artTitle={articles.title}
                      />
                    }
                </Grid>
                <Grid item xs={12} >
                  <Button 
                      sx={{mt:2}} 
                      onClick={toggleEdit} 
                      startIcon={<EditIcon />} 
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            }  
          </Grid>
}

export default function TranslationPage({
  wordDeleted,
  origWord,
  transWord,
  origLang,
  transLang,
  answerCount,
  correctCount,
  lastAnswered,
  handleOrigWord,
  handleTransWord,
  deleteTranslation,
  editTranslation,
  showEdit,
  handleEdit,
  handleClose,
  snack,
  msgType,
  alertMsg,
  buttonLoading,
  loading,
  vocabSents,
  handleSourceSent,
  handleTargetSent,
  newSourceSent,
  newTargetSent,
  addSent,
  sentLoading,
  toggleSent,
  addTransSent,
  updatedVocabSent,
  deleteVocabSent,
  editLoading,
  id,
  vocabImages,
  vocabAudio,
}){
  const trans = getTranslations()
  const flagSize = 'small'
  const percent = answerCount > 0 ? Math.round(correctCount/answerCount* 100) : 0
  const link = `/user/translations/${origLang}`

  const elapsetime = lastAnswered ? getLocalTime(lastAnswered).fromNow() : 'No Answers'

  const origLanguage = langSwitch(origLang)
  const transLanguage = langSwitch(transLang)

  vocabSents.sort((a, b) => b.id - a.id);

  return <Grid container spacing={1} justifyContent='center' >
            <Grid item xs={12} lg={9}>
              <Container component="main" maxWidth="xl" >
                  <Grid container 
                    spacing={2} 
                  >
                    <Grid item xs={12}>
                      <Card >
                        <CardContent> 
                          <Grid container 
                            spacing={2} 
                            justifyContent='space-between'
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <Grid container 
                                spacing={2} 
                                alignItems="center"
                                justifyContent='space-between'
                              >
                                <Grid item  >
                                  <LinkBasic
                                    variant="h5" 
                                    to={link} 
                                    title={`${langSwitch(origLang)} Vocabulary`}
                                  />
                                </Grid>
                                {!wordDeleted &&
                                  <Grid item  >
                                    <BasicIconButton
                                      size='small'
                                      title={showEdit ? 'Close' : 'Edit'}
                                      actionFunc={handleEdit}
                                      icon={showEdit ? <CloseIcon /> : <EditIcon />}
                                    />
                                  </Grid>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent> 
                        <CardContent sx={{minHeight: 100}}> 
                            {showEdit ?
                              <Grid container 
                                  spacing={2} 
                                  alignItems="center"
                                  justifyContent='space-between'
                              >
                                <Grid item md={6} sm={12} >
                                  <WordInput 
                                    name="oringal_word"
                                    label={trans.origwrd}
                                    id="oringal_word" 
                                    onChange={handleOrigWord}
                                    value={origWord}
                                    submitFunc={editTranslation}
                                  />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                  <WordInput 
                                    name="trans_word"
                                    label={trans.transwrd}
                                    id="translated_word" 
                                    onChange={handleTransWord}
                                    value={transWord}
                                    submitFunc={editTranslation}
                                  />
                                </Grid>
                                <Grid item>
                                  <RedButton 
                                    title='Delete Word'
                                    onClick={deleteTranslation}
                                  />
                                </Grid>
                                <Grid item >
                                  <LoadingIconButton 
                                    loading={buttonLoading}
                                    title="Submit"
                                    onClick={editTranslation}
                                    icon={<EditIcon />}
                                  />
                                </Grid>
                              </Grid> 
                              :
                              <Grid container 
                                alignItems='flex-start'
                                spacing={3}
                              >
                                <Grid item >
                                  <Grid container 
                                      spacing={1} 
                                      alignItems='center'
                                      justifyContent='space-between'
                                  >
                                    <Grid item xs={12}>
                                      <FlagRound 
                                        flagSize={flagSize}
                                        lang={origLang}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography 
                                        component="h5" 
                                        variant="h5" 
                                        color='primary'
                                      >
                                        {origWord} 
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item >
                                  <Grid container 
                                      spacing={1} 
                                      alignItems='center'
                                      justifyContent='space-between'
                                  >
                                  <Grid item xs={12}>
                                    <FlagRound 
                                        flagSize={flagSize}
                                        lang={transLang}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography 
                                        component="h5" 
                                        variant="h5" 
                                        color='primary'
                                      >
                                        {transWord} 
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                            </CardContent> 
                            <Divider />
                                <Grid container 
                                  spacing={2} 
                                  justifyContent='space-between'
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <Grid container 
                                          alignItems="center"
                                          justifyContent='space-between'
                                    >
                                      <Grid item xs={12} >
                                        <CardContent>
                                          <Grid container 
                                                alignItems='center'
                                                spacing={1}
                                            >
                                            <Grid item  >
                                              <Grid container 
                                                  alignItems='center'
                                                  spacing={1}
                                              >
                                                <Grid item  >
                                                  <Typography 
                                                    variant="h6" 
                                                    color='primary'
                                                  >
                                                    Performance:
                                                  </Typography>
                                                </Grid>
                                                <Grid item sx={{minWidth: 150}}>
                                                  {loading ?
                                                    <CircularProgress size={20} />
                                                    : 
                                                    <Typography variant="h6" >
                                                      {`${correctCount} / ${answerCount} (${percent}%)`} 
                                                    </Typography>
                                                  }
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item >
                                              <Box sx={{width:30}} />
                                            </Grid>
                                            <Grid item   >
                                              <Grid container 
                                                alignItems='center'
                                                spacing={1}
                                              >
                                              <Grid item>
                                                <Typography 
                                                  variant="h6" 
                                                  color='primary'
                                                >
                                                  Last Answer:
                                                </Typography>
                                              </Grid>
                                              <Grid item   >
                                              {loading ?
                                                 <CircularProgress size={20} /> 
                                                 :
                                                <Typography variant="h6" >
                                                  {elapsetime}
                                                </Typography>
                                               
                                               }
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          </Grid>
                                        </CardContent>
                                  </Grid>
                                </Grid>
           
                            </Grid>
                          </Grid> 
                        <Divider />
                        <CardContent> 
                          <Grid container 
                            spacing={2} 
                            alignItems="center"
                            justifyContent='space-between'
                          >
                            <CardContent> 
                              <Grid container spacing={2}>
                                <Grid item xs={12} >
                                  <Grid container 
                                        spacing={2} 
                                        alignItems='center'
                                  >
                                    <Grid item>
                                      <Typography variant='h5'>
                                        Translated Sentences and Articles
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Button 
                                        onClick={toggleSent} 
                                        variant='contained'
                                        size='small'
                                        startIcon={addSent ? <CloseIcon /> : <AddIcon />}
                                      >
                                        {addSent ? 'Close' : 'Add Sentence'}  
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              {addSent && 
                               <Grid item xs={12}>
                                <AddSentForm 
                                  handleSourceSent={handleSourceSent}
                                  handleTargetSent={handleTargetSent}
                                  newSourceSent={newSourceSent}
                                  newTargetSent={newTargetSent}
                                  origLanguage={origLanguage}
                                  transLanguage={transLanguage}
                                  sentLoading={sentLoading}
                                  addTransSent={addTransSent}
                                />
                                </Grid>
                              }
                                <Grid item xs={12}>
                                {loading ?
                                  <CircularProgress size={20} /> 
                                  :
                                  <Grid container 
                                        spacing={2} 
                                        alignItems='center'
                                        justifyContent='space-between'
                                    >  
                                    {vocabSents && vocabSents.length>0  && vocabSents.map(v => 
                                      <VocabSent 
                                        key={v.id}
                                        {...v} 
                                        editLoading={editLoading}
                                        lang={origLang} 
                                        updatedVocabSent={updatedVocabSent}
                                        deleteVocabSent={deleteVocabSent}
                                      />
                                    )}
                                  </Grid>
                                  }
                                  {!loading && vocabSents && vocabSents.length>0 &&
                                    <Grid item xs={12}>
                                      <Divider sx={{mt:2}} />
                                    </Grid>
                                  }
                                </Grid>
                                </Grid> 
                             </CardContent>     
                          </Grid>
                        </CardContent>
                        <Divider />
                        <CardContent> 
                          <Grid container 
                            spacing={2} 
                            alignItems="center"
                            justifyContent='space-between'
                          >
                            <CardContent> 
                              <Grid container spacing={2}>
                                <Grid item xs={12} >
                                  <Grid container 
                                        spacing={2} 
                                        alignItems='center'
                                  >
                                    <Grid item>
                                      <Typography variant='h5'>
                                       Media
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <BasicLinkIconButton
                                        title='Manage Media'
                                        to={`/user/translation_upload/${id}/${origWord}/${transWord}/${origLang}/${transLang}`} 
                                        variant='contained'
                                        size='small'
                                        icon={<PermMediaIcon />}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                   <AudioBasicClips vocabAudio={vocabAudio} />
                                </Grid>
                                <Grid item xs={12}>
                                   <ImgList vocabImages={vocabImages} />
                                </Grid>
                              </Grid> 
                             </CardContent>     
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <SnackAlert 
                      snack={snack}
                      handleClose={handleClose}
                      msgType={msgType}
                      alertMsg={alertMsg}
                    />
                  </Grid>
              </Container>
            </Grid>
            <Grid item>
              <AdSidebar />
            </Grid>
          </Grid>
  

}