import React, { useState, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useLiveQuery } from "dexie-react-hooks"

import {
  langSwitch,
  splitSentence1,
  checkQuizCount,
}from '../utils'

import { dexdb } from "../App"

import {
  getTranslation,
} from '../queries/workerQueries'

import {
    createPlaylist2,
    removePlaylist,
} from '../queries/playListQueries'

import {
    createRead
} from '../queries/readQueries'

import { incrementArtProgress } from '../components/Buttons/ArticleReadButton'

import { getPlayArt } from '../queries/recommendationQueries'

import {
    createSentListen,
    getListenArtCount,
} from '../queries/artListenQueries'
  
import {
    markPlayedArt
} from '../queries/playListQueries'
  
import {
  addVocabWordArtSent
} from '../queries/vocabWordQueries'


import {analytics} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import PlayArticlePage from '../components/Pages/PlayArticlePage'

const minSpeed=0.1
const maxSpeed=1.0
const increment=0.1

export default function PlayArticle(){

    const synth = window.speechSynthesis;

    const utterThis = useMemo(() => new SpeechSynthesisUtterance(), [])

    const {
        id, 
        lang, 
    } = useParams()

    const art_id = parseInt(id)
  
    const art1 = useLiveQuery(() => dexdb.articles.where('id').equals(parseInt(id)).toArray(),[id])

    const loaded = art1 && art1.length>0

    const title1 = useMemo(() => loaded  ? art1[0].title : '', [art1, loaded])
    const readArt = useMemo(() => loaded && art1[0].read.length>0 ? art1[0].read[0].active : false, [art1, loaded])
    const playlist = useMemo(() => loaded && art1[0].playlist.length>0  ? art1[0].playlist[0].active : false, [art1, loaded])
    const playlistId = useMemo(() => loaded && art1[0].playlist.length>0 ? art1[0].playlist[0].id : 'none', [art1, loaded])

    const member = useLiveQuery(() => dexdb.member.toArray(), [])

    const native_lang = useMemo(() => member ? member[0].native_lang : 'en', [member])

    const userLangs = useMemo(() => member ? member[0].langs : [], [member])

    const [ artText, setArtText ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ searchresults, setsearchresults ] = useState({})
    const [ transloading, setTransLoading ] = useState(false)
    const [ sentIdx, setsentIdx ] = useState(0)
    const [ linkError, setLinkError ] = useState(false)
    const [ linkerrorMsg, setlinkErrorMsg ] = useState('')
    const [ voiceIdx, setVoiceIdx ] = useState(0)
    const [ voices, setVoices ] = useState([])
    const [ rate, setRate ] = useState(1.0)
    const [ playing, setPlaying ] = useState(false)
    const [ adReload, setAdReload ] = useState(true)
    const [ listenedSentsIdx, setlistenedSentsIdx] = useState([])
    const [ vocabloading, setVocabLoading ] = useState(false)

    const sourceLang = langSwitch(lang)
    const targetLang = langSwitch(native_lang)

    const selectSource = `Select ${sourceLang}`
    const selectTarget = `Select ${targetLang}`

    const [ sourceWord, setSourceWord] = useState(selectSource)
    const [ targetWord, setTargetWord] = useState(selectTarget)
    

    const sents = splitSentence1(artText) ?? []

    const handleClose = () => { 
        setLinkError(false)
        setlinkErrorMsg('')
        setSnack(false)
    }

    const updateVoiceIdx = async index => await dexdb.langVoice.update(lang, {index})

    const updateVoiceRate = async rate => await dexdb.langVoice.update(lang, {rate})
    
    const handleVoice = async event =>{ 
        const index = event.target.value
        setVoiceIdx(index)
        await updateVoiceIdx(index)
    }

    const handleRate = async event => {
        const rate = event.target.value
        setRate(rate)
        await updateVoiceRate(rate)
    }

    const handleSource = () => {
        const selObj = window.getSelection();
        const srcTxt = selObj.toString()
        setSourceWord(srcTxt)
    }

    const handleTarget = () =>{ 
        const selObj = window.getSelection();
        const srcTxt = selObj.toString()
        setTargetWord(srcTxt)
    }

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
    }

    const updateDBIdx = async (num) => await dexdb.artSentIdx1.put({id, sentIdx: sentIdx+num})

    const navSent = async (num) => {
        synth.cancel()
        setAdReload(false)
        setsentIdx(prevPage => prevPage + num)
        setSourceWord(selectSource)
        setTargetWord(selectTarget)
        setAdReload(true)
        await updateDBIdx(num)
    }

    const forward = async () => await navSent(+1)

    const back = async () => await navSent(-1)

    const faster = async () => {
        if(rate<maxSpeed){
          setRate(prevPage => prevPage + increment)
          const newRate = rate + increment
          await updateVoiceRate(newRate)
        }
    }
    
    const slower = async () => {
        if(rate.toFixed(1)>minSpeed){
            setRate(prevPage => prevPage - increment)
            const newRate = rate - increment
            await updateVoiceRate(newRate)
        }
    }

    const origText = sents[sentIdx] ?? ''

    const curTranslation = searchresults[sentIdx]?.translation  ?? ''

    const changePlaylist = async () => {
        if(playlist){         
            try {
                await removePlaylist({
                    playlistId, 
                    art_id,
                })
            } catch(error){
                showSnack('error', 'Error adding to playlist.')
            } 
        } else {
            try {
                await createPlaylist2({
                    art_id,
                    rec_id: null,
                    playlistId, 
                    art_date: art1[0].date,
                    title: title1,
                    lang,
                  })
            } catch(error){
                showSnack('error', 'Error adding to playlist.')
            } 
        }
    }
  
    const updatePlaylist = async () => {
        try {
            await createRead({art_id})
            await incrementArtProgress()
        } catch(error){
            showSnack('error', 'Error adding to read list.')
        } 
    }

    const addVocab = async () => {
        if(sourceWord.length===0 | targetWord.length === 0){
            showSnack('error', `Please select a ${sourceLang} word and a ${targetLang} word.`)
            return
        }
        setVocabLoading(true)
        try {
            await addVocabWordArtSent({
                source_word: sourceWord, 
                target_word: targetWord,
                source_lang: lang,
                target_lang: native_lang,
                source_sent: origText, 
                target_sent: curTranslation,
                artId: id,
            })
            showSnack('success','Vocabulary word added!')
            setSourceWord(selectSource)
            setTargetWord(selectTarget)
            logEvent(analytics, 'add_vocab', {
                source_lang: lang, 
                target_lang: native_lang, 
                number: 1,  
                type: 'gutenberg',
                device: 'web',
            })
        } catch(error){
            showSnack('error','Error adding vocabulary word!')
        } finally {
            setVocabLoading(false)
        }
    }

    const setTrans = (translation, sentIdx) => {
        const updatedResults = searchresults;
        searchresults[sentIdx] = { translation }
        setsearchresults(updatedResults)  
    }

    const translate = async () => {
        setTransLoading(true)
        try {
        const translation = await getTranslation({
            text: origText, 
            sourceLang: userLangs[0],
            targetLang: native_lang,
        })

        setTrans(translation, sentIdx)
        } catch(error){
        showSnack('error', 'Translation error!')
        } finally {
        setTransLoading(false)
        }
    }

    const listenComplete = async () => {
        const listenedCount = await getListenArtCount({art_id})
        const unreadSents = sents.length - listenedCount
        if(unreadSents===0){
          try {
            await markPlayedArt({art_id})
            await createRead({art_id})
            } catch(error){
                showSnack('error', 'Error with article completion.')
            }
        } else {
           showSnack('error', `You haven't listened to all the sentences! ${unreadSents} left!`)
        }
    }
  
    const updateSentListened = async (
        listenTime,
    ) => {
        if(!listenedSentsIdx.includes(sentIdx)){
            const listen_time_secs = Math.round(listenTime/1000)

            const newListen = {
                art_id,
                rate: rate.toFixed(1),
                listen_time_secs,
                sentIdx,
            }
       
            try {
                const newSentsIdx = [...listenedSentsIdx, sentIdx]
                const dexSentsIdx = [...new Set(newSentsIdx.map(s => ({sent_index: s})))]
                setlistenedSentsIdx(newSentsIdx)
                await createSentListen(newListen)
                await dexdb.art_texts.put({id: art_id, text_art: artText, listen_time: dexSentsIdx})
            } catch(error){
            
            }
        }
        
    }

    const startSpeech = () => setPlaying(true)
    
    const pauseSpeech = () => setPlaying(false)

    const resumeSpeech = () => setPlaying(true)

    const markListened = (event) => {
        setPlaying(false)
        updateSentListened(event.elapsedTime)
        if(sents.length-1===sentIdx){
            listenComplete()
        } else {
            forward()
        }
    }
  
    const speakText = () => {
        utterThis.voice = voices[voiceIdx]
      
        utterThis.rate = rate
    
        utterThis.lang = lang
    
        utterThis.pitch = 1.0
    
        utterThis.volume = 1

        if(synth.speaking && !synth.paused){
          synth.pause()
          return
        }
    
        if(synth.speaking && synth.paused){
          synth.resume()
          return
        }

        synth.speak(utterThis)
    
        utterThis.onpause = () => pauseSpeech()
    
        utterThis.onresume = () => resumeSpeech()
    
        utterThis.onend = (event) => markListened(event)
    
        utterThis.onstart = () => startSpeech()
    
    }

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    
    useEffect(()=> {

        const populateVoiceList = () => {
            const voices = synth.getVoices();
            const langVoices = voices.filter(v => v.lang.includes(lang)).filter(v => v.localService) 
            setVoices(langVoices)
        }

        const setVoiceIndex = async () => {
           const voice = await dexdb.langVoice.where('id').equals(lang).toArray()
        
           if(voice.length>0){
            const index = voice[0].index ?? 0
            const rate = voice[0].rate ?? 1
            setVoiceIdx(index)
            setRate(rate)
           } else {
            await dexdb.langVoice.put({id: lang, rate: 1, index: 0})
           }
        }

        async function setSentIdx(id) {
            try {
                const sentId = await dexdb.artSentIdx1.where({'id': id}).toArray();
                if(sentId.length>0){ 
                    setsentIdx(sentId[0].sentIdx)
                } else { 
                    setsentIdx(0)
                    await dexdb.artSentIdx1.add({
                        id: id,
                        sentIdx: 0
                    })
                }
            } catch(error){
           
            }
        }

        const getArtText = async (id) => {

            const arttxt = await dexdb.art_texts.where('id').equals(parseInt(id)).first()
           
            if(arttxt){
                setArtText(arttxt.text_art)
                const sentIdx = arttxt.listen_time.map(s => s.sent_index)
        
                if(sentIdx .length>0){
                    const dexSentsIdx = [...new Set(sentIdx)]
                    setlistenedSentsIdx(dexSentsIdx)
                   
                }
            
            } else {
                const plart = await getPlayArt({id})
                const sentIdx = plart.listen_time.map(s => s.sent_index)
                setArtText(plart.text_art)

                if(sentIdx .length>0){
                    setArtText(plart.text_art)
                    setlistenedSentsIdx(sentIdx)
                }
            }
        }

        populateVoiceList()

        setSentIdx(id)
   
        if(artText.length<1){
            getArtText(id)
        }

        utterThis.text = origText

        setVoiceIndex()

        checkQuizCount()

        return () => {
            synth.cancel()
        }
    
    },[synth, lang,  id, sentIdx, artText, utterThis, origText])

    const ratestr = parseFloat(rate).toFixed(1)

    return  <PlayArticlePage 
                lang={lang}
                clusters={[]}
                snack={snack}
                msgType={msgType}
                alertMsg={alertMsg}
                handleClose={handleClose}
                isError={false} 
                searchresults={sents}
                sentIdx={sentIdx}
                translate={translate}
                forward={forward}
                back={back}
                title={title1}
                transloading={transloading}
                speakText={speakText}
                handleVoice={handleVoice}
                voices={voices}
                voiceIdx={voiceIdx}
                playing={playing}
                handleRate={handleRate}
                faster={faster}
                slower={slower}
                rate={rate}
                linkError={linkError}
                linkerrorMsg={linkerrorMsg}
                origText={origText}
                curTranslation={curTranslation}
                adReload={adReload}
                handleSource={handleSource}
                handleTarget={handleTarget}
                sourceWord={sourceWord}
                targetWord={targetWord}
                addVocab={addVocab}
                vocabloading={vocabloading}
                playlist={playlist}
                id={id}
                read={readArt}
                playlistId={playlistId}
                changePlaylist={changePlaylist}
                updatePlaylist={updatePlaylist}
                art_id={art_id}
                sents={sents}
                showSnack={showSnack}
                ratestr={ratestr}
                lisetenedSentsIdx={listenedSentsIdx}
                sentsCount={sents.length}
            />
}