import React from 'react'
import Grid from '@mui/material/Grid'

import {
  LinkBasic, 
  CaptionDate,
} from '../ListItems/BasicLinks'

export default function ArtLinkItem({
  art_date, 
  title, 
  lang, 
  id,
  art_id,
}){ 
    const link = `/user/article/${art_id}/${lang}`
    return  <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} >
                  <CaptionDate artDate={art_date} />
                </Grid>
                <Grid item xs={12} >
                  <LinkBasic 
                    to={link} 
                    title={title}
                  />
                </Grid>
              </Grid>
            </Grid> 
  }