import React from "react";

import Button from '@mui/material/Button'

export default function BasicButton({
    actionFunc, 
    title,
}){

  return(
      <Button 
        variant="contained" 
        onClick={actionFunc}
        fullWidth
        sx={{
          color:'white',
          backgroundColor: '#6415ff',
          '&:hover': {
            backgroundColor: '#5010CC',
          },
        }}
      >
        {title}
      </Button>
  )
}