import React from "react";
import { useHistory } from "react-router-dom";

import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import { IconButton } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function VocabAnswer({
    ansCorrect,
    answered,
    correct_count,
    answer_count,
    vocab_id,
    target_word,
    source_lang,
    target_lang,
}){
    const history = useHistory()
    const link =`/user/translation/${vocab_id}/${answered}/${target_word}/${source_lang}/${target_lang}`
    const goToVocabWord = () =>  history.push(link)
    return(
        <Paper >
            <Alert severity={ansCorrect ? 'success' : 'error' } action={
                <IconButton color="inherit" size="small" to={link} onClick={goToVocabWord}>
                    <ArrowForwardIcon />
                </IconButton>
            } >
                {answered} ({correct_count}/{answer_count})
            </Alert>
   
        </Paper>
    )
}