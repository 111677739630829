import {supabase} from "../supabase.js"

import { getAuthuser } from "./memberQueries.js"

import { getLinkCount } from '../utils'

import { dexdb } from "../App.js"

const table = 'clusters'

// MUTATIONS
export const createCluster =  async ({
    clusterName,
    lang,
}) => {

    const user = await getAuthuser()
    const aid = user.id
   
    if(user){
        const newCluster = {
            cluster_name: clusterName,
            lang,
            active: true,
            aid,
        }
        const { data, error } = await supabase
            .from(table)
            .insert(newCluster)
            .select(`
                id
            `)

        const clust = data[0]
        const newCluster1 = {
            id: clust.id,
            cluster_name: clusterName,
            active: true,
            lang, 
            translated_arts:[],
        }
 
    await dexdb.clusters.put(newCluster1)
    if(error) throw new Error(error.message)
    return data
    } else {
        throw new Error('Not logged in!')
    }
}

export const updateCluster = async update => {
    const { error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)

    if(error) {
        throw new Error(error.message)
    }

    return 
}

export const deleteCluster = async ({cluster_id}) => {
    const { error } = await supabase
        .from(table)
        .delete()
        .eq('id', cluster_id)
    if(error) throw new Error(error.message)
    const cid = parseInt(cluster_id)
    await dexdb.clusters.delete(cid)
    return
}

// QUERIES

export const getClusters2 = async () => {
    const { data, error } = await supabase
        .from(table)
        .select(`
            id,
            cluster_name,
            active,
            lang,
            profile(
                langs,
                native_lang,
                role
            ),
            translated_arts(
                title,
                url,
                id,
                lang
                )
            )`)
            .order('cluster_name')

    if(error) throw new Error(error.message)

    if(!data)  throw new Error("Cluster not found")

    let linkCount = getLinkCount(data)
   
    localStorage.setItem('count', linkCount.toString())
    return data
}