import React, {useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";

import {  
  formatRecLinks,
  sortDates,
  langSwitch,
  checkQuizCount,
} from '../utils'

import { getAndStoreCurRecs } from '../queries/recommendationQueries'

import RecommendationsPage from '../components/Pages/RecommendationsPage'
import { dexdb } from "../App";

export default function Recommendations(){

    const recommendations = useLiveQuery(() => dexdb.recommendations.toArray(), []);
    const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []) ?? [];
    
    const clusterRecs = recommendations ? formatRecLinks(recommendations) : []
  
    const lang = clusters && clusters.length>0 ?  clusters[0].lang : 'en'

    const language = langSwitch(lang)

    const recsSort = sortDates(clusterRecs)

    const refreshRecs = async () => await getAndStoreCurRecs()

    const [ selCluster, setSelCluster ] = useState('all')

    const handleCluster = event => setSelCluster(event.target.value)

    const selClusterRecs = selCluster!=='all' ? recsSort.filter(c => c.cluster_id === selCluster) : recsSort
    const recCount = selClusterRecs ? selClusterRecs.length : 0

    useEffect(()=> {
      checkQuizCount()
    },[])
    if(!recommendations) return <div></div>

    return <RecommendationsPage  
            recommendations={selClusterRecs}
            refreshRecs={refreshRecs}
            handleCluster={handleCluster}
            selCluster={selCluster}
            clusters={clusters}
            recCount={recCount}
            language={language}
            lang={lang}
            isLoading={false}
          />
  }
