import React from 'react'
import useWindowDimensions from '../../helper'
import useMediaQuery from '@mui/material/useMediaQuery';

import { signup } from '../../constants/help'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import LangaLogo from '../ImageElements/Logo'
import WelcomeButton from '../Buttons/WelcomeButton'
import Copyright from '../Login/Copyright'

const FirstClusterInstructions = ({
    title, 
    instructions, 
}) => {
  return  <Grid container spacing={2} justifyContent='start' >
            <Grid item xs={12}>
                <Typography variant='h4'>
                    {title}
                </Typography>
            </Grid>
            {instructions.map((c, i) => 
              <Grid key={i} item xs={12}>
                <Typography>
                    {i+1}. {c.step}
                </Typography>
              </Grid>
            )}
          </Grid>
}

const TutorialCard = ({
  title, 
  instructions, 
  image, 
}) => {
  const { width } = useWindowDimensions();

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  console.log(matches)
  const curWidthWeb = matches ? width*.55 : width*.8
  console.log(curWidthWeb)
  const curWidth = curWidthWeb

  return  <Grid container spacing={3} justifyContent='center'  >  
              <Grid item md={3}>
                <FirstClusterInstructions  
                  title={title}
                  instructions={instructions}
                />
              </Grid>
              <Grid item md={9}>
                <img style={{borderRadius: 10}} width={curWidth} src={image} alt={title} />  
              </Grid>  
          </Grid>
}

export default function WelcomeVocabQuiz({history}){
  const adsf = signup[0]
  return (
      <Container component="main" maxWidth="lg">
        <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
          <Grid container 
                justifyContent='center' 
                alignItems='center'
                spacing={2}
          >
            <Grid item >
                <LangaLogo size={4} />
            </Grid>
            <Grid item >
              <Typography 
                  variant="h4" 
                  sx={{color:'#6415ff'}}
              >
                Welcome to Langa Learn
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{mb: 2}} />
            </Grid>  
            <Grid item xs={12}>
              <TutorialCard
                {...adsf}
              />
            </Grid>  
            <Grid item xs={12}>
               <WelcomeButton  
                history={history} 
                title='Create Your First Cluster'
                route='/user/first_cluster'
               />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Paper>
      </Container>
  )
}