import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getTranslations } from '../../utils'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import AdSidebar from "../Ads/AdSidebar"
import PlaylistCard from '../Cards/PlaylistCard'
import SnackAlert from '../Dialogs/SnackAlert'
import { CircularProgress } from '@mui/material';
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton';

export default function PlaylistPage({
    playlist, 
    playlistCount,
    onDragEnd,
    errorMsg,
    isError,
    loading,
    snack,
    msgType,
    alertMsg,
    handleClose,
}){
    const curLang = getTranslations()
    const { reorder } = curLang

    return <Grid container spacing={1} justifyContent='center' >
              <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                  <main >
                    <Grid container 
                        alignItems="center" 
                        alignContent="space-between"
                        spacing={1} 
                    > 
                        <Grid item xs={12}>
                            <Paper sx={{padding: 2}} > 
                              <Grid container 
                                    alignItems="center" 
                                    spacing={2} 
                                    justifyContent="space-between"
                                  > 
                                  <Grid item > 
                                    <Typography variant={'h5'} >
                                      {curLang.playlist} - {!loading && playlistCount}
                                    </Typography>
                                  </Grid>
                                  <Grid item > 
                                    <Typography >
                                      {reorder}
                                    </Typography>
                                  </Grid>
                                  <Grid item > 
                                    <BasicLinkIconButton 
                                      title='Played Articles'
                                      to='/user/playedlist'
                                      icon={<PlaylistAddCheckIcon />}
                                      size='small'
                                    />
                                  </Grid>
                              </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                        {playlist && playlist.length==0 &&   
                          <Grid item>
                            <Alert severity="error">
                              No playlist
                            </Alert>
                          </Grid>
                        }
                        {!playlist ?
                          <CircularProgress />
                          :
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {playlist && playlist.map((card, i) => 
                                  <Draggable key={card.playlist_position} draggableId={card.playlist_position.toString()} index={i}>
                                    {(provided, snapshot) => (
                                      <div  ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                          <PlaylistCard card={card} />
                                      </div>
                                    )}
                                  </Draggable>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        }
                        </Grid> 
                        <SnackAlert 
                          snack={snack}
                          handleClose={handleClose}
                          msgType={msgType}
                          alertMsg={alertMsg}
                        />
                    </Grid> 
                  </main>
                </Container>
              </Grid>
              <Grid item>
                  <AdSidebar />
              </Grid>
            </Grid>
              

};
