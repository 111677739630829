import React, { useState, useEffect } from "react";

import PlayerInterface from './PlayerInterface'

import {
  createSentListen,
  getListenArtCount,
} from '../../queries/artListenQueries'

import {
  createRead
} from '../../queries/readQueries'

import {
  markPlayedArt
} from '../../queries/playListQueries'


export default function Player({
    lang,
    origText,
    minSpeed,
    maxSpeed,
    increment,
    art_id,
    sentIdx,
    sentsCount,
    showSnack,
}){

    const synth = window.speechSynthesis;

    const [ voiceIdx, setVoiceIdx ] = useState(0)
    const [ voices, setVoices ] = useState([])
    const [ rate, setRate ] = useState(1.0) 
    const [ playing, setPlaying ] = useState(false)
    const [ paused, setPaused ] = useState(false)

    const handleVoice = event => setVoiceIdx(event.target.value)

    const faster = () => {
        if(rate<maxSpeed){
          setRate(prevPage => prevPage + increment)
        }
    }
    
    const slower = () => {
        if(rate>minSpeed){
            setRate(prevPage => prevPage - increment)
        }
    }

    const listenComplete = async () => {
      const listenedCount = await getListenArtCount({art_id})
      // check there number of records in liste_time for article equals sentCount (all sents have been listened to)
      // if so mark read and played
      // if not snack saying not all sentences listen to (listen_time count - sent count)
      if(listenedCount===sentsCount){
        try {
          await markPlayedArt({art_id})
          await createRead({art_id})
      } catch(error){
         showSnack('error', 'Error with article completion.')
      }
      } else {
        showSnack('error', "Cannot be marked complete. You haven't listened to all the sentences for this article!")
      }
  }

  const updateSentListened = async (
      listenTime,
  ) => {
      const listen_time_secs = Math.round(listenTime/1000)
      const newListen = {
          art_id,
          rate,
          listen_time_secs,
          sentIdx,
      }
      await createSentListen(newListen)
  }

  const speakText = () => {
    const utterThis = new SpeechSynthesisUtterance(origText)

    utterThis.voice = voices[voiceIdx]
  
    utterThis.rate = rate

    utterThis.lang = lang

    utterThis.pitch = 1.0

    utterThis.volume = 1

    if(playing){
      synth.pause()
      setPlaying(false)
      setPaused(true)
      return
    }

    if(paused){
      synth.resume()
      setPlaying(true)
      setPaused(false)
      return
    }

    synth.speak(utterThis)

    const startSpeech = () => {
      setPlaying(true)
    }

    const pauseSpeech = () => {
      setPlaying(false)
      setPaused(true)
    }

    const resumeSpeech = () => {
      setPlaying(false)
      setPaused(true)
    }

    const markListened = (event) => {
      updateSentListened(event.elapsedTime)
      if(sentsCount-1===sentIdx){
          listenComplete()
      }
      setPlaying(false)
    }

    utterThis.onpause = () => pauseSpeech()

    utterThis.onresume = () => resumeSpeech()

    utterThis.onend = (event) => markListened(event)

    utterThis.onstart = () => startSpeech()

  }

  useEffect(()=> {

      function populateVoiceList() {
        const voices = synth.getVoices();
        
        const langVoices = voices.filter(v => v.lang.includes(lang)).filter(v => v.localService)

        setVoices(langVoices)
      }
      populateVoiceList()
      
      return () => {
        synth.cancel()
      }
      
    },[synth, lang])


    const ratestr = parseFloat(rate).toFixed(1);

    return <PlayerInterface
                voices={voices}
                voiceIdx={voiceIdx}
                handleVoice={handleVoice}
                ratestr={ratestr}
                slower={slower}
                faster={faster}
                playing={playing}
                speakText={speakText}
                minSpeed={minSpeed}
                maxSpeed={maxSpeed}
            />
}