import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import {updateMember} from '../../queries/memberQueries'

import { 
  getTranslations, 
  postAuth,
  minimalTimezoneSet, 
} from '../../utils'

import { logoutFunc } from '../Nav/ResponsiveAppBar'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

import FormControl from '@mui/material/FormControl'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import ConfirmDelete from '../Dialogs/ConfirmDelete1'

const baseUrl =  process.env.REACT_APP_FB_FUNC_URL

const deleteAccttUrl = `${baseUrl}/deleteAccountReq`

export default function UpdateUser({
  name,
  email,
  time_zone,
}){

    let history = useHistory()

    const curLang = getTranslations()
    const { 
      update,
      submit,
    } = curLang


    const [ name1, setName ] = useState('')
    const [ newEmail, setNewEmail ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ deleteLoading, setDeleteLoading ] = useState(false)
    const [ deleteConfirm, setDeleteConfirm ] = useState(false)
    const [ timeZone, setTimeZone ] = useState('')

    const confirmDelete = () => setDeleteConfirm(!deleteConfirm)

    const handleClose = () => setSnack(false)

    const handleName = event => setName(event.target.value)

    const handleTimeZone = e => setTimeZone(e.target.value)

    const handleEmail = event => setNewEmail(event.target.value)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const updateProfile = async () => {
      setLoading(true)
      try {
       await updateMember({ 
          name: name1, 
          email: newEmail,
          time_zone: timeZone,
        })
        showSnack('success', 'Updated profile!')
      } catch(error){
        showSnack('error', 'Error updating profile!')
      } finally {
        setLoading(false)
      }
    }

    const deleteAccount = async () => {
      setDeleteLoading(true)
  
      try {
        confirmDelete()
        await postAuth(deleteAccttUrl, {});
        showSnack('success', 'Account deleted!')
        logoutFunc(history)
      } catch(error){
        showSnack('error', 'Error deleting account.')
      } finally {
        setDeleteLoading(false)
      }

    }

    useEffect(() => {
      setNewEmail(email)
      setName(name)
      setTimeZone(time_zone)
    },[email, name, time_zone])

    const deleteMsg =  'All your recommendations, vocabulary and quiz progress will be deleted. This action is permanent.'

    return(
          <Grid container spacing={1}>
            <Grid item  xs={12}> 
              <Card>
                <CardContent>
                  <Typography variant={'h5'} 
                    sx={{ color:'#6415ff' }}
                  >
                    {update} Profile
                  </Typography>
                </CardContent>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <TextField 
                        required
                        value={name1} 
                        onChange={handleName} 
                        sx={{ width: '100%'}}
                        label='Name'
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField 
                        required
                        value={newEmail} 
                        onChange={handleEmail} 
                        sx={{ width: '100%'}}
                        label='Email'
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl  fullWidth>
                        <InputLabel >
                          Timezone
                        </InputLabel>
                        <Select
                          label='Timezone'
                          value={timeZone}
                          onChange={handleTimeZone}
                          sx={{backgroundColor: 'white'}}
                        >
                          {minimalTimezoneSet.map(t => 
                            <MenuItem key={t} value={t}>
                              {t}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                
                </CardContent>
                <CardContent>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                       <LoadingIconButton
                          fullWidth={false}
                          loading={deleteLoading}
                          icon={<DeleteIcon />}
                          onClick={confirmDelete} 
                          title='Delete Account'
                          color='#f50057'
                          bkcolor='#ab003c'
                        />
                    </Grid>
                    <Grid item>
                      <LoadingIconButton
                        fullWidth={false}
                        loading={loading}
                        icon={<EditIcon />}
                        onClick={updateProfile} 
                        title={submit}
                      />
                    </Grid>
                  </Grid>
                </CardContent> 
              </Card>
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
             <ConfirmDelete 
              deleteAction={deleteAccount}
              confirm={deleteConfirm}
              closeConfirm={confirmDelete}
              title='Delete account?'
              question='Are you sure you want to delete your account?'
              message={deleteMsg}
              disagree='Dismiss'
              agree='Delete Account'
            />
          </Grid>
  )
}