import React from "react";

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import SpeedIcon from '@mui/icons-material/Speed'

import TooltipButton from "../Buttons/TooltipButton"
import PlayerDropDown from '../DropDowns/PlayerDropDown'

const PlayerInterface = ({
    voices,
    voiceIdx,
    handleVoice,
    ratestr,
    slower,
    faster,
    playing,
    speakText,
    minSpeed,
    maxSpeed,
  }) => {
    return  <Grid  container
                  alignItems='center'
                  spacing={2}
            >
              <Grid item>
                {voices.length>0 &&
                  <PlayerDropDown 
                      voices={voices}
                      voiceIdx={voiceIdx}
                      handleVoice={handleVoice}
                      labelName='Voices'
                  />
                }
                </Grid>
                <Grid item>
                  <TooltipButton
                    title="Slower"
                    disabled={ratestr===minSpeed}
                    func={slower}
                    icon={<SpeedIcon style={{transform: 'scaleX(-1)'}} />}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    {ratestr}
                  </Typography>
                </Grid>
                <Grid item>
                  <TooltipButton
                    title="Faster"
                    disabled={ratestr===maxSpeed}
                    func={faster}
                    icon={<SpeedIcon />}
                  />
                </Grid>
                <Grid item>
                  <TooltipButton
                    title={playing ? "Pause" : 'Speak'}
                    disabled={false}
                    func={speakText}
                    icon={playing ?  <PauseIcon /> :  <PlayArrowIcon />}
                    color={playing ? "error": 'success'}
                  />
                </Grid>   
            </Grid>  
  }

  export default PlayerInterface