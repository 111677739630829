import React from 'react'

import Grid from '@mui/material/Grid'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import FlagRound from '../ImageElements/FlagRound'

const flagSize = 'medium'

const QuizFlags = ({
    orig_lang, 
    native_lang,
}) => 
    <Grid 
        container 
        justifyContent="space-around" 
        alignItems='center'
    >
        <Grid item >
            <FlagRound 
                flagSize={flagSize}
                lang={orig_lang}
            />
        </Grid> 
        <Grid item >
            <ArrowForwardIcon fontSize="large"/>
        </Grid> 
        <Grid item >
            <FlagRound 
                flagSize={flagSize}
                lang={native_lang}
            />
        </Grid> 
    </Grid> 

export default QuizFlags