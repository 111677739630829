import React, {useEffect} from "react"
import { useLiveQuery } from "dexie-react-hooks"

import { dexdb } from "../App"

import {  
  formatRecLinks,
  sortDates,
  langSwitch,
  getProgresDays,
  checkQuizCount
} from '../utils'



import DashboardPage from '../components/Pages/DashboardPage'

export default function Dashboard(){

    const now = new Date()
    const month = now.getMonth() + 1
    const year = now.getFullYear()
    const progKey = month.toString()+year.toString()
    const recommendations = useLiveQuery(() => dexdb.recommendations.toArray(), []);
    const member1 = useLiveQuery(() => dexdb.member.toArray(), []) ?? [];
    const progress1 = useLiveQuery(() => dexdb.progress_months.where("id").equals(progKey).toArray(), []) ?? [];

    const goals = useLiveQuery(() => dexdb.goals.toArray(),[])
    const activeGoals = goals ? goals.filter(g => g.active) : []

    const progress = progress1.length>0 ? progress1[0]['days'] : []

    const lang = member1.length>0 ? member1[0].langs[0] : 'en'

    const clusterRecs = recommendations ? formatRecLinks(recommendations) : []

    const language = langSwitch(lang)

    const recsSort = sortDates(clusterRecs)

    const recCount = recsSort ? recsSort.length : 0

    const {artCount, answerCount, listenCount, nowDays } = getProgresDays(progress)

    const fourteenDays = nowDays.reverse().slice(0,14)

    useEffect(()=> {
     checkQuizCount()
    },[])
    
    if(!recommendations) return <div></div>
    return <DashboardPage  
            recommendations={recsSort}
            recCount={recCount}
            language={language}
            lang={lang}
            isLoading={false}
            userProgress={fourteenDays}
            answerCount={answerCount}
            artCount={artCount}
            listenCount={listenCount}
            activeGoals={activeGoals}
          />
  }
