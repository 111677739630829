import React , {useState} from 'react'

import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card'

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function AudioCardSearch({
    url,
    id,
    selectedAudio,
    selectAudio,
    removeAudio,
  }){
  
    const [ playing, setPlaying ] = useState(false)

    const selected = selectedAudio.includes(id)
  
    const addAudio = async () => {
      if(selected){
        removeAudio(id)
      } else {
        selectAudio(id)
      }
    }

    var audio = new Audio(url);
  
    const playSound = async () => {
      setPlaying(true)
      audio.play().then(d => {
        setPlaying(false)
      });
    }
  
    const pauseSound = () =>{ 
      setPlaying(false)
      audio.pause()
    }
  
    return <Card> <Grid container alignItems='center' justifyContent='space-around' >
              <Grid item>
                <Tooltip title="Play">
                  <IconButton aria-label="delete" onClick={playing ? pauseSound : playSound} >
                    <PlayArrowIcon color='success'  />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={selected ? 'Added' : 'Add'}>
                  <IconButton 
                    onClick={addAudio}
                  >
                    {selected ? 
                      <CheckIcon color='success' fontSize='small' /> 
                      : 
                      <AddIcon color='primary' fontSize='small' />  } 
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid> 
            </Card> 
  }
  