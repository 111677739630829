import React from 'react'
import Button from '@mui/material/Button'
import red from '@mui/material/colors/red'

import CloseIcon from '@mui/icons-material/Close'

const RedButton = ({ 
    title,
    onClick,
    size='medium',
    icon=<CloseIcon />,
}) => 
    <Button
        size={size}
        onClick={onClick}
        startIcon={icon}
        variant="contained"
        sx={{ 
            backgroundColor: red['A400'],
            '&:hover': {
                backgroundColor: red['A700'],
            },
        }}
    >
        {title}
    </Button>

export default RedButton

