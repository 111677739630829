import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useLiveQuery } from "dexie-react-hooks";

import { 
  isValidHttpUrl,
  checkQuizCount,
 } from '../utils'

import {analytics, dexdb} from '../App'

import { 
  logEvent, 
} from "firebase/analytics";

import {
  uploadVocabAudioToLibrary,
  uploadVocabImageToLibrary,
  uploadVocabImageUrlToLibrary,
  uploadVocabAudioUrlToLibrary,
} from '../queries/vocabMediaQueries'

import TranslationUploadbPage from '../components/Pages/TranslationUploadPage'

const urlErrorMsg = 'Please use a valid url!'

export default function TranslationUpload(){

    const { 
      id,
      orig_word, 
      trans_word,
      orig_lang,
      trans_lang,
    } = useParams()

    const media = useLiveQuery(() => dexdb.vocab_media.where('id').equals(parseInt(id)).first(), [id]);

    const pictures = media &&  media.vocab_images ? media.vocab_images : []
    const audio1 =  media && media.vocab_audio ? media.vocab_audio : []

    const [ origWord, setOrigWord ] = useState('')
    const [ transWord, setTransWord ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ imgUrl, setImgUrl ] = useState('')
    const [ audioUrl, setAudioUrl ] = useState('')
    const [ showImgUrl, setShowImgUrl ] = useState(false)
    const [ showAudioUrl, setShowAudioUrl ] = useState(false)
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ imageLoading, setImageLoading ] = useState(false)
    const [ audioLoading, setAudioLoading ] = useState(false)
    const [ isLoading, setisLoading ] = useState(false)

    const handleImageUrl = event => setImgUrl(event.target.value)
    const handleAudioUrl = event => setAudioUrl(event.target.value)

    const toggleAudioUrl = () => setShowAudioUrl(!showAudioUrl)
    const toggleImgUrl = () => setShowImgUrl(!showImgUrl)

    const onImageChange = async (e) => {
      setImageLoading(true)
      if(e.target.files.length>1){
        showSnack('error', 'Please upload only one image at a time!')
        return
      }
      const file = e.target.files[0]
 
      try {
         await uploadVocabImageToLibrary({
          file, 
          vocabId: id,
          sourceLang: orig_lang,
          sourceWord: orig_word,
          targetLang: trans_lang,
          targetWord: trans_word,

        })

        showSnack('success', 'Image Uploaded')
       
        logEvent(analytics, 'upload_vocab_image', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          device: 'web',
        })
        logEvent(analytics, 'add_vocab_image', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          count: 1,
          device: 'web',
        })
      } catch(error){
        showSnack('error', error.message)
      } finally{
        setImageLoading(false)
      }
    }

    const onAudioChange = async (e) => {
      setAudioLoading(true)
      if(e.target.files.length>1){
        showSnack('error', 'Please upload only one audio file at a time!')
        return
      }
      const file = e.target.files[0]
      try {
        await uploadVocabAudioToLibrary({
          file, 
          vocabId: id,
          sourceLang: orig_lang,
          sourceWord: orig_word,
          targetLang: trans_lang,
          targetWord: trans_word,
        })
        showSnack('success', 'Audio Uploaded')
        logEvent(analytics, 'upload_vocab_audio', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
        })
        logEvent(analytics, 'add_vocab_audio', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          count: 1,
        })
      } catch(error){
        showSnack('error', error.message)
      } finally{
        setAudioLoading(false)
      }
    }


    const addImageUrl = async (e) => {
      setImageLoading(true)
      
      const urlok = isValidHttpUrl(imgUrl)
    
      if(!urlok){
        showSnack('error', urlErrorMsg)
        return
      }

      try {

        await uploadVocabImageUrlToLibrary({
          url: imgUrl,
          vocabId: id,
          sourceLang: orig_lang,
          sourceWord: orig_word,
          targetLang: trans_lang,
          targetWord: trans_word,
      })
        setImgUrl('')
        showSnack('success', 'Image Uploaded')
        logEvent(analytics, 'url_vocab_image', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          device: 'web',
        })
        logEvent(analytics, 'add_vocab_image', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          count: 1,
          device: 'web',
        })
      } catch(error){
        showSnack('error', error.message)
      } finally{
        setImageLoading(false)
      }
    }

    const addAudioUrl = async (e) => {
      setAudioLoading(true)
     
      const urlok = isValidHttpUrl(audioUrl)
    
      if(!urlok){
        showSnack('error', urlErrorMsg)
        return
      }

      try {

        await uploadVocabAudioUrlToLibrary({
          url: audioUrl,
          vocabId: id,
          sourceLang: orig_lang,
          sourceWord: orig_word,
          targetLang: trans_lang,
          targetWord: trans_word,
      })
        setAudioUrl('')
        showSnack('success', 'Audio Uploaded')
        logEvent(analytics, 'url_vocab_audio', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          device: 'web',
        })
        logEvent(analytics, 'add_vocab_audio', { 
          source_lang: orig_lang,
          target_lang: trans_lang, 
          count: 1,
          device: 'web',
        })
      } catch(error){
        showSnack('error', error.message)
      } finally{
        setImageLoading(false)
      }
    }

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setImageLoading(false)
      setAudioLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    useEffect(() => {
      const controller = new AbortController();

      setisLoading(true)
      setOrigWord(orig_word)
      setTransWord(trans_word)
      checkQuizCount()
      return () => {
        controller.abort();
      }
    },[id, orig_word, trans_word]);

    return  <TranslationUploadbPage
              origWord={origWord}
              transWord={transWord}
              origLang={orig_lang}
              transLang={trans_lang}
              handleClose={handleClose}
              snack={snack}
              loading={isLoading}
              msgType={msgType}
              alertMsg={alertMsg}
              imageLoading={imageLoading}
              audioLoading={audioLoading}
              pictures={pictures}
              audio={audio1}
              onImageChange={onImageChange}
              onAudioChange={onAudioChange}
              vocabId={id}
              imgUrl={imgUrl}
              handleImageUrl={handleImageUrl}
              toggleImgUrl={toggleImgUrl}
              addImageUrl={addImageUrl}
              showImgUrl={showImgUrl}
              audioUrl={audioUrl}
              handleAudioUrl={handleAudioUrl}
              toggleAudioUrl={toggleAudioUrl}
              showAudioUrl={showAudioUrl}
              addAudioUrl={addAudioUrl}
            />
}