import React from 'react'

import { getTranslations } from '../../utils'

import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { DialogContent } from '@mui/material'
import grey from '@mui/material/colors/grey'

import Translation from '../Article/Translation'

const DialogTitle = (props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle sx={{
      margin: 0,
      padding: 2,
    }} {...other}>
      <Typography >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" sx={{
          position: 'absolute',
          right:1,
          top: 1,
          color: grey[500],
        }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default function ArticleTranslationsModal({
  onClose, 
  open, 
  translations,
}){

  const trans = getTranslations()

    return (
      <Dialog onClose={onClose} open={open}>
        <Paper sx={{backgroundColor:'rgb(240, 246, 255)', minWidth: '33vw'}}  >
          <DialogTitle onClose={onClose} >
            {trans.translations}
          </DialogTitle>  
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} color='black' />
          <DialogContent  >
            <Grid container spacing={2} sx={{padding: 1}} >   
              {translations && translations.map((t,i) => 
                <Translation key={i} {...t} />
              )}  
            </Grid>
          </DialogContent>
        </Paper>
      </Dialog>
    )
  }