import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import {supabase} from '../supabase'

// mui
import Box from '@mui/material/Box'

import { clearUserData } from '../components/Nav/ResponsiveAppBar'

//protected routes pages

import History from "../pages/History"
import Article from "./Article"
import Progress from "../pages/Progress"
import Recommendations from "../pages/Recommendations"
import VocabTestStart from "../pages/VocabTestStart"
import VocabStart from "../pages/VocabStart"
import VocabTestLang from "../pages/VocabTestLang"
import Translations from "../pages/Translations"
import Translation from "../pages/Translation"
import TranslationUpload from '../pages/TranslationUpload'
import VocabTest from "../pages/VocabTest"
import VocabQuizSent from "../pages/VocabQuizSent"
import Dashboard from "./Dashboard"
import SupporTutorial from "./SupportTutorial"
import ReadGutenberg from "./ReadGutenberg1"
import Library from './Library'

import Playlist from "./Playlist"
import Playedlist from "./Playedlist"
import PlayArticle from './PlayArticle'

import Clusters from "../pages/Clusters"
import Cluster from "../pages/Cluster"
import FirstCluster from "../pages/FirstCluster"
import ClusterAddText from "../pages/ClusterAddText"
import ClusterArtText from "../pages/ClusterArtText"
import ClusterArtExtText from '../pages/ClusterAddExtText'

import Profile from "../pages/Profile"
import SupportLogged from "../pages/SupportLogged"
import Goals from '../pages/Goals'

import Bookstore from '../pages/Bookstore'

import ResponsiveAppBar from "../components/Nav/ResponsiveAppBar"

const routes = [ 
  { path: "/user/progress",  component: Progress, exact: true },
  { path: "/user/history",  component: History, exact: true },
  { path: "/user/playlist",  component: Playlist, exact: true },
  { path: "/user/playedlist",  component: Playedlist, exact: true },
  { path: "/user/profile",  component: Profile, exact: true },
  { path: "/user/article/:id/:lang",  component: Article, exact: false },
  { path: "/user/recommendations",  component: Recommendations, exact: true },
  { path: "/user/vocabteststart",  component: VocabTestStart, exact: true },
  { path: "/user/vocabstart",  component: VocabStart, exact: true },
  { path: "/user/vocab_test_lang/:lang",  component: VocabTestLang, exact: false },
  { path: "/user/vocabtest/:lang",  component: VocabTest, exact: true },
  { path: "/user/clusters",  component: Clusters, exact: true },
  { path: "/user/cluster/:cluster_id",  component: Cluster, exact: false },
  { path: "/user/cluster_add_text_ext/:link_id",  component: ClusterArtExtText, exact: false },
  { path: "/user/first_cluster",  component: FirstCluster, exact: true },
  { path: "/user/cluster_add_text/:cluster_id/:cluster_name/:lang/:count",  component: ClusterAddText, exact: false },
  { path: "/user/cluster_art_text/:cluster_id/:cluster_name/:lang/:count/:link_id",  component: ClusterArtText, exact: false },
  { path: "/user/translations/:lang",  component: Translations, exact: false },
  { path: "/user/translation/:id/:orig_word/:trans_word/:orig_lang/:trans_lang",  component: Translation, exact: false },
  { path: "/user/translation_upload/:id/:orig_word/:trans_word/:orig_lang/:trans_lang",  component: TranslationUpload, exact: false },
  { path: "/user/sent_vocab_quiz/:id/:user_vocab_word_id/:source_lang/:target_lang",  component: VocabQuizSent , exact: false },
  { path: "/user/support",  component: SupportLogged, exact: true },
  { path: "/user/dashboard",  component: Dashboard, exact: true },
  { path: "/user/tutorial",  component:   SupporTutorial, exact: true },
  { path: "/user/gutenberg/:lang/:url",  component:   ReadGutenberg, exact: false },
  { path: "/user/library",  component:   Library, exact: true },
  { path: "/user/play_article/:id/:lang",  component:   PlayArticle, exact: false },
  { path: "/user/bookstore/:lang",  component:   Bookstore, exact: false },
  { path: "/user/goals",  component:   Goals, exact: false },
]


export default function User({history}){

  useEffect(() => {
    // get session data if there is an active session
    const{ data: { subscription } } = supabase.auth.onAuthStateChange(
     
      async (event, session) => {
        console.log(session)
        if(session===null || !session.user===null){
         
          await clearUserData()
          return history.push('/auth/signin')
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [history]);

    return  <Box sx={{ flexGrow: 1 }}>
              <ResponsiveAppBar />
                <Switch key="router">
                  {routes.map(route => (
                    <Route
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                </Switch>
            </Box>
}
