import React, { Fragment } from "react"
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'


const AppBarButton = ({
    title,
    link,
    component
}) =>   <Fragment>
            <Button 
                component={Link} 
                to={link} 
                color="inherit" 
                startIcon={component} 
                sx={{mr:2}}
            >
                {title}
            </Button>
        </Fragment>

export default AppBarButton