import React, {useState} from "react";
  
import {
    deleteTranslatedArt
} from '../../queries/translatedArtQueries'

import Grid from '@mui/material/Grid'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import {LinkBasic} from '../ListItems/BasicLinks'
import SnackAlert from '../Dialogs/SnackAlert'

export default function ClusterArtLink({
    id, 
    translated_arts_id,
    title,
    cluster_id,
    targetLang,
    clusterName,
    count,
}){

    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
      }
    
    const deleteArt = async () => {
        try {
            await deleteTranslatedArt({
                id,
                cluster_id,
            })
        } catch(error){
            showSnack('error', 'Error deleting cluster!')
        }
    }
  
    const deleteLink = async () => await deleteArt() 

    const link = `/user/cluster_art_text/${cluster_id}/${clusterName}/${targetLang}/${count}/${translated_arts_id}`
    return(
            <Grid container alignItems="center" justifyContent="space-between" style={{margin:10}} >
                <Grid item xs={11} >
                    <LinkBasic 
                        to={link} 
                        title={title && title}
                    />
                </Grid>
                <Grid item xs={1} >
                    <IconButton aria-label="delete" onClick={deleteLink} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                />
            </Grid>
    )
}