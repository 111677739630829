import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'

import appImage from '../../images/appstore.png'
import playImage from '../../images/playstore.png'
import chromeImage from '../../images/langaextension.png'

const AppGrid = ({dlUrl, image, altText}) => 
   <Grid item xs={4}>
        <a href={dlUrl}>
            <img src={image} alt={altText} />
        </a>
    </Grid>

export default function DownloadApps(){

    const apps = [
        {
            dlUrl: 'https://apps.apple.com/us/app/langa-learn/id1541975692', 
            image: appImage, 
            altText: 'Download from App Store', 
        },
        {
            dlUrl: 'https://play.google.com/store/apps/details?id=com.langalearn.android', 
            image: playImage, 
            altText: 'Download from Play Store', 
        },
        {
            dlUrl: 'https://chrome.google.com/webstore/detail/langa-learn/ljliknkhknggbakafobjeiodcgehjgnl?hl=en', 
            image: chromeImage, 
            altText: 'Download from Chrome Web Store', 
        }
    ]

    return <Card sx={{
                    padding: 1,
                    marginTop: 2,
                    marginBottom: 2,
            }}>
                <Grid container >
                    <Grid item xs={12}>
                    <Typography variant='h5' 
                        sx={{ color: '#6415ff',
                              marginBottom: 1,
                            }}>
                        Download the apps 
                    </Typography>
                    </Grid>
                    {apps.map(a => 
                        <AppGrid {...a} key={a.altText} />
                    )}
                </Grid>
            </Card>

}