import React from "react";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'


export default function ConfirmParsedArt({
    title, 
    addParsedArt,
    confirm,
    closeConfirm,
    artText,
    disagree,
    agree,
  }){

    return  <Dialog onClose={closeConfirm} open={confirm}>
              <DialogTitle >
                  Confirm Url Parsing
              </DialogTitle>
                <DialogContent >
                  <DialogContentText  sx={{color: '#6415ff', mb:1}}>
                    {title}
                  </DialogContentText>
                  <DialogContentText >
                    {artText.substring(0,800) + "..."}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={addParsedArt} sx={{color: green['A700']}} >
                    {agree}
                  </Button>
                  <Button onClick={closeConfirm} sx={{color: red['A400'] }}  autoFocus>
                    {disagree}
                  </Button>
                </DialogActions>
            </Dialog>
}