import {supabase} from "../supabase"
import { dexdb } from "../App.js"
import {incrementProgress} from './progressQueries'
import {
    getNowIso,
} from '../utils'
import { getAuthuser } from "./memberQueries.js"
import { setDbArt } from "./recommendationQueries"

const table = 'read'

// MUTATIONS

export const createRead = async ({art_id}) => {
    const user= await getAuthuser()
    const read_date = getNowIso()
    if(user){
        const aid = user.id 
        const { error } = await supabase
            .from(table)
            .insert({
                art_id,
                aid,
                active: true,
                created_at: read_date,
            })

        if(error) throw new Error(error.message)
        await incrementProgress('arts_count', 1)
        return await setDbArt(art_id)
    } else {
        throw new Error('You are not authenticated!')
    }
}

export const incrementReadProgress = async () => {
    const now = new Date()
    const day = now.getDate()
    const mon = now.getMonth() + 1
    const yr = now.getFullYear()
    const progKey = mon.toString()+yr.toString()
    const crup = await dexdb.progress_months.where("id").equals(progKey).toArray()
    const progm = crup[0]
    progm['days'][day-1]['arts_count']=progm['days'][day-1]['arts_count']+1
    await dexdb.progress_months.update(progKey, progm)
  }