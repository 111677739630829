import React from 'react'
import Grid from '@mui/material/Grid'

import {
  LinkBasic, 
} from '../ListItems/BasicLinks'

export default function VocabArt({
  origLang,
  artId, 
  artTitle,
}){
  const link = `/user/article/${artId}/${origLang}`
  return(
    <Grid item xs={12} >
        <LinkBasic
          to={link} 
          title={artTitle}
        />
    </Grid>
    )
}