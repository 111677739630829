import React, { useState, useEffect } from 'react'

import { 
  formatPlaylistLinks1,
  checkQuizCount,
 } from '../utils'

import {
  getPlayedlist1,
} from '../queries/playListQueries'

import PlayedlistPage from '../components/Pages/PlayedlistPage'

export default function Playlist({history}){

    const [ playlist, setPlaylist ] = useState([])
    const [ isError, setisError ] = useState(false)
    const [ isErrorMsg, setisErrorMsg ] = useState('')
    const [ isLoading, setisLoading ] = useState(false)

    const goToPlayPlaylist = () => history.push(`/play_playlist`)

    useEffect(() => { 
      const getPlaylist = async () => {
        setisLoading(true)
        try {
          const pl = await getPlayedlist1()
          if(pl.length>0){
            const formatPl = formatPlaylistLinks1(pl)
            setPlaylist(formatPl)
          } else {
            setisError(true)
            setisErrorMsg('No played articles!')
          }
        } catch(error){
          setisError(true)
          setisErrorMsg('Error loading playlist!')
        } finally{
          setisLoading(false)
        }
      
      }

      getPlaylist()
      checkQuizCount()
    }, [])

    const playlistCount = playlist.length

    return  <PlayedlistPage  
              playlist={playlist}
              goToPlayPlaylist={goToPlayPlaylist}x
              playlistCount={playlistCount}
              isError={isError} 
              loading={isLoading}
              errorMsg={isErrorMsg}
          />

}