import React from 'react'

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import VocabCard from '../Cards/VocabCard'
import AdSidebar from "../Ads/AdSidebar"

export default function VocabStartPage({
  langs,
}){
    const { quizinstructions } = getTranslations()

    return(
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                    <main>
                      <Paper sx={{ padding:2, marginBottom:1 }} > 
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12}>
                            <Typography variant='h5'>
                              {quizinstructions}
                            </Typography>
                          </Grid>
                          {langs.map((l,i) => 
                            <VocabCard key={i} lang={l} /> 
                          )}
                        </Grid> 
                      </Paper>
                    </main>
                  </Container>
                </Grid>
                <Grid item>
                  <AdSidebar />
                </Grid>
            </Grid>

    )
}