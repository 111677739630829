import React from "react"

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Typography from '@mui/material/Typography'
import Divider from "@mui/material/Divider"
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import Container from '@mui/material/Container'

import ArtLinkItem from '../ListItems/ArtLinkItem'
import FlagRound from '../ImageElements/FlagRound'
import IconButton from '../Buttons/IconButton'
import AdSidebar from "../Ads/AdSidebar"
import ClusterDropDown from '../DropDowns/ClusterDropDown'
import SnackAlert from '../Dialogs/SnackAlert'

export default function HistoryPage({
    selectedDate,
    lang,
    handleDateChange,
    searchRecs,
    handleChange,
    langlist,
    clusters,
    selDate,
    selClusterRecs,
    recCount ,
    selCluster,
    handleCluster,
    recCount1,
    snack,
    msgType,
    alertMsg,
    handleClose,
}){
  const translations = getTranslations()

  const { 
    language, 
    search,
  } = translations

    const flagSize = 'medium'

    return(
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                      <main >
                          <Paper sx={{padding: 2, minHeight:800}}>
                              <Grid container 
                                  spacing={2} 
                                  alignItems="center"
                              >
                            <Grid item>
                                <FlagRound 
                                  flagSize={flagSize}
                                  lang={lang}
                                />
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth>
                                  <InputLabel required >
                                    {language}
                                  </InputLabel>
                                  <Select
                                    size='small'
                                    label={language}
                                    value={lang}
                                    onChange={handleChange}
                                  >
                                    {langlist.map(l => 
                                      <MenuItem key={l.lang} value={l.lang}>
                                        {l.language}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label='Recommendation Date'
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField  size='small' {...params} />}
                              />
                            </LocalizationProvider>
                            </Grid>
                          
                            <Grid item >
                              <IconButton 
                                onClick={searchRecs} 
                                title={search}
                                icon={<SearchIcon />}
                              />
                            </Grid>
                          </Grid>
                        <Divider sx={{marginTop: 3, marginBottom: 3}} />
                        <Grid item>
                        {recCount1 > 0 ?
                          <Grid container 
                            spacing={2} 
                            alignItems="center" 
                            justifyContent='space-between'
                          > 
                            {lang && 
                              <Grid item>
                                <Typography variant={'h6'}>
                                  {selDate} - {recCount}
                                </Typography >
                              </Grid> 
                            }
                            <Grid item>
                              <Grid container
                                    spacing={1}
                                    alignItems='center'
                              >
                                <Grid item>
                                  {clusters && clusters.length &&
                                  <ClusterDropDown
                                    selCluster={selCluster}
                                    clusters={clusters}
                                    handleCluster={handleCluster}
                                  />
                                  }
                                </Grid> 
                              </Grid>
                            </Grid>
                          </Grid> 
                          : 
                          <Alert severity="error">
                            No recommendations for this date! Select a different date. 
                          </Alert>
                          }
                          <Grid container spacing={2} sx={{marginBottom: 3}} > 
                            {selClusterRecs && selClusterRecs.map((r,i) => 
                              <ArtLinkItem 
                                key={i} 
                                selectedDate={selectedDate} 
                                lang={lang} 
                                {...r}
                              />
                            )}  
                          </Grid> 
                        </Grid>
                      </Paper>
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                    </main>
                  </Container>
                </Grid>
                <Grid item>
                  <AdSidebar />
                </Grid>
              </Grid>

  )
}