import {supabase} from "../supabase"

import { getAuthuser } from "./memberQueries.js"

import {insertVocabWordSent} from './vocabSentenceQueries'

import { 
    getCurrentDayStartEnd,
} from "../utils"

const table = 'user_vocab_words'

// MUTATIONS

const query = ` id,
                last_answered,
                answer_count,
                correct_count,
                easiness,
                source_lang,
                target_lang,
                source_word,
                target_word
              `

export const addVocabWord =  async ({
    source_word,
    source_lang,
    target_lang,
    target_word, 
    source_sent,
    target_sent,
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const { data, error } = await supabase
        .from(table)
        .insert({
            source_word,
            source_lang,
            target_lang,
            target_word,
            source_sent,
            target_sent,
            correct_count: 0, 
            answer_count: 0,
            aid,
        })
        .select()

    if(error) throw error
   
    return data
}

export const updateVocabWords = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)
        .select()

    if(error) throw new Error(error.message)
    return data
}

export const addVocabWordArtSent = async ({
    source_word,
    target_word,
    source_lang,
    target_lang,
    source_sent = "",
    target_sent = "",
    rec_id = null,
    artId = null,
    url=null,
    title=null,
  }) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const {data, error} = await supabase
        .from(table)
        .insert({
          source_word,
          source_lang,
          target_lang,
          target_word,
          correct_count: 0,
          answer_count: 0,
          aid,
        })
        .select('id')
  
    if (error) throw error;

    const user_vocab_id = data[0].id;
  
    const {data: sent, error1} = await supabase
        .from("user_vocab_sentences")
        .insert({
          "source_sent": source_sent,
          "target_sent": target_sent,
          "user_vocab_id": user_vocab_id,
          "rec_id": rec_id,
          "art_id": artId,
          "url": url,
          "title": title,
          "aid": aid,
        })
        .select('id')
  
    if (error1) throw error1;

    const sentid = sent[0].id;
    
    return {
      user_vocab_id,
      sentid,
    };
  };

export const addUserVocabWord = async ({
    source_word,
    target_word,
    source_lang,
    target_lang,
    source_sent = '',
    target_sent = '',
    rec_id = null,
}) => {
    // check if word is already in user's vocab list
    try {

    const id = await checkUserVocabWord({
        source_word, 
        source_lang, 
        target_lang, 
    })

    if(id!=='none'){
         // word was previously in user_vocab_word, set to active
         return await reactivateVocabWord({
            id,
            source_sent,
            target_sent,
            rec_id,
        })
    }

    const user = await getAuthuser()
    const aid = user.id
    if(user){
        const newWord = {
            source_word,
            target_word,
            source_lang,
            target_lang,
            source_sent,
            target_sent,
            correct_count: 0, 
            answer_count: 0,
            rec_id,
            active: true,
            aid,
        }
        const { data, error } = await supabase
            .from(table)
            .insert(newWord)
            .select()

        if(error) throw new Error(error.message)
        return data    

    } else {
        throw new Error('Not logged in!')
    }
    
    } catch(error){
        throw new Error(error.message)
    }
}

export const updateUserVocabWords1 = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)
        .select('*')

    if(error) throw error

    return data
}

export const updateUserVocabWordsArt = async update => {
    const { data, error } = await supabase
        .from(table)
        .upsert({
            'member_id':update['memberId'], 
            'word_list_word_id': update['id'],
            'art_id': update['art_id'],
        })
        .eq('id', update.id)
        .select()

    if(error) {
        throw new Error(error.message)
    }

    return data
}

export const reactivateVocabWord = async ({
    id,
    source_sent,
    target_sent,
    rec_id=null
}) => { 
    
    const { data, error } = await supabase
        .from(table)
        .update({
            'active': true,
        })
        .eq('id', id)
        .select('id')

    if(rec_id!==null){
        await insertVocabWordSent({
            id,
            source_sent,
            target_sent,
            rec_id
        })
    }

    if(error) throw new Error(error.message)

    return data[0]
}

export const deleteVocabWord = async id => { 
    const { error } = await supabase
    .from(table)
    .update({'active': false})
    .eq('id', id)

    if(error) throw new Error(error.message)
}

export const updateVocabQuizProgress = async (
    quizUpdate, 
    id,
) => {

    const {data, error} = await supabase
        .from(table)
        .update(quizUpdate)
        .eq('id', id)
        .select('answer_count, correct_count')
    
    if(error) throw error.message

    return data
}

// QUERIES

export const checkReactivate = async ({
    source_word, 
    source_lang, 
    target_lang,
    member_id,
}) => {
   const id =  checkUserVocabWord({
    source_word, 
    source_lang, 
    target_lang,
    member_id,
   })
    if(id!=='none'){
        // word was previously in user_vocab_word, set to active
        await reactivateVocabWord(id)
        return
   }
}

export const checkWordUserVocab = async ({
    id
}) => {
   let { error, data } = await supabase
    .from(table)
    .select("id")
    .eq('word_list_word_id', id)

    if(error) throw new Error(error.message)
    
    return data
}

export const getVocabWord = async ({
    id,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq('id', id)
        .single()

    if(error)  throw new Error(error.message)

    if(!data) throw new Error("Vocab Word not found")

    return data
}

export const checkVocabWord = async ({
    source_word,
    source_lang
}) => {
    const { data, error } = await supabase
        .from('word_list_words')
        .select('id')
        .eq('source_word', source_word)
        .eq('source_lang', source_lang)

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        return 'none'
    }
    if(data.length>0){
        return data[0]['id'];
    }
    return 'none'
    
}

export const checkVocabWord1 = async ({
    source_word,
    source_lang
}) => {
    const { data, error } = await supabase
        .from('word_list_words')
        .select('id')
        .eq('source_word', source_word)
        .eq('source_lang', source_lang)

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        return 'none'
    }
    if(data.length>0){
        return data[0]['id'];
    }
    return 'none'
    
}

export const checkVocabWordEdit = async ({
    source_word,
    target_word,
    source_lang,
    target_lang,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select('id')
        .eq('source_word', source_word)
        .eq('target_word', target_word)
        .eq('source_lang', source_lang)
        .eq('target_lang', target_lang)

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        throw new Error("Vocab Word not found")
    }
    if(data.length>0){
        return data[0]['id'];
    }
    return 'none'
    
}

export const checkUserVocabWord = async ({
    source_word,
    source_lang,
    target_lang,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select('id')
        .eq('source_word', source_word)
        .eq('source_lang', source_lang)
        .eq('target_lang', target_lang)

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        return 'none'
    }
    if(data.length>0){
        return data[0]['id'];
    }
    return 'none'
    
}

export const checkUserVocabWord2 = async ({
    source_word,
    source_lang,
    target_lang,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select(`id, target_word`)
        .eq('source_word', source_word)
        .eq('source_lang', source_lang)
        .eq('target_lang', target_lang)

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        return []
    }
   
    return data;
    
}

export const getVocabWords = async ({
    select, 
    field, 
    term,
}) => {
  
   let { data, error } = await supabase
  .from(table)
  .select(select)
  .eq(field, term)
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data || data.length===0) {
      throw new Error("Records not found")
    }
  
    return data
}

export const getVocabWordsCount = async () => {
  
    let { error, count } = await supabase
        .from(table)
        .select('id',{ count: 'exact', head: true })
        .eq("active", true)

    if(error) {
      throw new Error(error.message)
    }

    if(!count) {
      throw new Error("Records not found")
    }
   
    return count
}

export const getWordListTranslationSB = async ({
    sourceWord,
    sourceLang, 
    targetLang,
}) => {

    let { data, error } = await supabase
        .from("word_list_words")
        .select("id, target_word")
        .eq('source_word', sourceWord)
        .eq('source_lang', sourceLang)
        .eq('target_lang', targetLang)

    if(error) throw new Error(error.message)

    if(!data || data.length===0) return []

    return data
}

export const checkWordListTranslationSB = async ({
    sourceWord,
    sourceLang, 
    targetLang,
    targetWord,
}) => {
  
   let { data, error } = await supabase
    .from("word_list_words")
    .select("id")
    .eq('source_word', sourceWord)
    .eq('target_word', targetWord)
    .eq('source_lang', sourceLang)
    .eq('target_lang', targetLang)
  
    if(error) {
      throw new Error(error.message)
    } 
  
    if(!data || data.length===0) {
      return []
    }
    return data
}

export const getCurrentVocabWords = async () =>  {
    let { data: user_vocab, error } = await supabase
        .from(table)
        .select(`   id, 
                    source_word, 
                    source_lang, 
                    target_word, 
                    target_lang, 
                    answer_count, 
                    correct_count, 
                    member_id
                    `)
        .eq("active", true)
        .order("last_answered", {ascending: false})
        .order("created_at", {ascending: false})
        .order("source_word")
        .limit(10);

    if(error) throw error

    return user_vocab

}

export const getCurrentVocabWordsPaginated = async (
    page,
    asc
) =>  {
    const start=page*10
    const end=10*page+9

    let { data: userVocabsWords, error, count } = await supabase
      .from(table)
      .select(`id,
              last_answered,
              answer_count,
              correct_count,
              source_word,
              source_lang,
              target_word,
              target_lang
              `, { count: "exact" })
      .eq("active", true)
      .order("last_answered", {nullsFirst:false, ascending: !asc})
      .range(start, end)

    if(error){
        throw error
    }
    
    return {
        userVocabsWords, 
        count,
    }
}

export const getCurrentVocabWordsPaginatedSourceSort = async (
    page,
    asc,
    wordType,
) =>  {
    const start=page*10
    const end=10*page+9

    let { data: userVocabsWords, error, count } = await supabase
      .from(table)
      .select(`id,
              last_answered,
              answer_count,
              correct_count,
              source_word,
              source_lang,
              target_word,
              target_lang
              `, { count: "exact" })
      .eq("active", true)
      .order(wordType, {ascending: asc})
      .range(start, end)

    if(error){
        throw error
    }
    
    return {
        userVocabsWords, 
        count,
    }
}

const newQuery = `
id,
last_answered,
source_sent,
target_sent,
answer_count,
correct_count,
source_word,
source_lang,
target_word,
target_lang,
words_lists(
    id,
    title
),
recommendations(
    articles(
        id,
        title
    )
),
words_list(
    title
)
`

export const getVocabWord1 = async ({
    id,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select(newQuery)
        .eq('id', id)
        .single()

    if(error) {
        throw new Error(error.message)
    }

    if(!data) {
        throw new Error("Vocab Word not found")
    }

    return data
}

export const getLastAnswered = async (lang) => {
    let { data, error } = await supabase
        .from(table)
        .select(query)
        .eq("source_lang", lang)
        .eq("active", true)
        .order("last_answered", {ascending: false})
        .limit(20)
  
    if(error) throw error

    if(!data) return []

    return data
}

export const getRandom = async  (lang) => {
    const user = await getAuthuser()
    const aid = user.id
    let { data, error } = await supabase
        .from('random_vocab_word')
        .select(query)
        .eq('aid', aid)
        .eq("source_lang", lang)
        .eq("active", true)
        .limit(20)
  
    if(error) throw error

    if(!data) return []

    return data
}

export const getRecentlyAdded = async (lang) => {
    let { data, error } = await supabase
        .from(table)
        .select(query)
        .eq("source_lang", lang)
        .eq("active", true)
        .order("created_at", {ascending: false})
        .order("last_answered", {ascending: false})
        .limit(20)

    if(error) throw error

    if(!data) return []

    return data
}

export const getUnanswered = async (lang) => {
let { data, error } = await supabase
    .from(table)
    .select(query)
    .is("last_answered", null)
    .eq("source_lang", lang)
    .eq("active", true)
    .order("created_at", {ascending: false})
    .limit(20)

if(error) throw error

if(!data) return []

return data
}

export const getSpaced = async (lang) => {
    const {
        start, 
        end,
    } = getCurrentDayStartEnd()

    let { data, error } = await supabase
        .from(table)
        .select(query)
        .eq("source_lang", lang)
        .eq("active", true)
        .gte('next_review', start)
        .lte('next_review', end)
        .order("created_at")

    if(error) throw error

    if(!data) return []

    return data
}

export const checkPrevWords = async ({
    source_word,
    source_lang,
    target_lang,
    rec_id,
}) => {

try {
    const words = await checkUserVocabWord2({
        source_word,
        source_lang,
        target_lang,
    })
    if(words.length===0){
        return []
    }
    const user_vocab_id = words[0].id

    await reactivateVocabWord({
        id: user_vocab_id,
        rec_id,
    })

    return words
} catch(error){
    throw error
}

}