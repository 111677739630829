import React from 'react'
  
import {
    removePlaylist,
} from '../../queries/playListQueries'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'

import FlagRound from '../ImageElements/FlagRound'

import {
    LinkBasic, 
    CaptionDate,
  } from '../ListItems/BasicLinks'

export default function PlaylistCard({ 
    card, 
 }){

    const {
        id,
        title,
        lang,
        artDate,
        art_id,
    } = card

    const link = `article/${art_id}/${lang}`

    const removeFromPlaylist1 = async () => { 
        
        try {
            await  removePlaylist({
                playlistId: id,
                art_id,
            })
        } catch(error){

        }
    }

    return  <Card sx={{mb: 1, pt: 1, pb: 1, pr: 2, pl: 2 }}>
                <Grid container 
                    alignItems="center" 
                    spacing={1} 
                >
                    <Grid item >
                        <FlagRound 
                            flagSize='xsmall'
                            lang={lang}
                        />
                    </Grid>
                    <Grid item >
                        <CaptionDate artDate={artDate} />
                    </Grid>
                </Grid>
                <Grid container 
                        alignItems="center" 
                        justifyContent="space-between" 
                >
                <Grid item xs={11}>
                    <LinkBasic 
                        to={link} 
                        title={title}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton 
                        aria-label="delete" 
                        onClick={removeFromPlaylist1} 
                        color='error'
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
}