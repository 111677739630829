import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import PlaylistPlay from '@mui/icons-material/PlaylistPlay'

import AdSidebar from "../Ads/AdSidebar"
import PlayedlistCard from '../Cards/PlayedlistCard'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton';

import { CircularProgress } from '@mui/material';

export default function PlayedlistPage({
    playlist, 
    playlistCount,
    errorMsg,
    isError,
    loading,
}){

    return <Grid container spacing={1} justifyContent='center' >
              <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                  <main >
                    <Grid container 
                        alignItems="center" 
                        alignContent="space-between"
                        spacing={1} 
                    > 
                        <Grid item xs={12}>
                            <Paper sx={{padding: 2}} > 
                              <Grid container 
                                    alignItems="center" 
                                    spacing={2} 
                                    justifyContent="space-between"
                                  > 
                                  <Grid item > 
                                    <Typography variant={'h5'} >
                                      Articles Played - {!loading && playlistCount}
                                    </Typography>
                                  </Grid>
                                  <Grid item > 
                                    <BasicLinkIconButton 
                                      title='Playlist'
                                      to='/user/playlist'
                                      icon={<PlaylistPlay />}
                                      size='small'
                                    />
                                  </Grid>
                              </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                          {!loading && isError &&   
                          <Grid item>
                            <Alert severity="error">
                              {errorMsg}
                            </Alert>
                          </Grid>
                        }
                        {!loading && !isError && playlist.length===0 &&   
                          <Grid item>
                            <Alert severity="error">
                              No articles played
                            </Alert>
                          </Grid>
                        }
                        {loading ?
                          <CircularProgress />
                          :
                          <>
                            {playlist && playlist.map((card, i) => 
                              <PlayedlistCard key={i} card={card} />
                            )}
                          </>
                        }
                        </Grid> 
                    </Grid> 
                  </main>
                </Container>
              </Grid>
              <Grid item>
                  <AdSidebar />
              </Grid>
            </Grid>
              

};
