import {supabase} from "../supabase.js"
import { getAuthuser } from "./memberQueries.js"
import { dexdb } from "../App.js"

const table = 'translated_arts'

// MUTATIONS

export const newClusterUrltoDB = async (newArt, cluster_id) => {
    const cluster = await dexdb.clusters.where('id').equals(parseInt(cluster_id)).first()
    cluster.translated_arts = [...cluster.translated_arts, newArt]
    await dexdb.clusters.put(cluster)
}

export const createTranslatedArt1 =  async ({
    url,
    lang,
    title, 
    orig_text,
    orig_lang,
    cluster_id,
    source,
}) => {
    const user = await getAuthuser()
    const aid = user.id
    const { data, error } = await supabase
        .from(table)
        .insert({
           url,
           lang,
           title, 
           orig_text,
           orig_lang,
           cluster_id,
           source,
           aid,
        })
        .select('id')

    if(error) throw new Error(error.message)

    const newArt = {
            id: data[0]['id'],
            title,
            url,
        }

    await newClusterUrltoDB(newArt, cluster_id)

    return
}

export const updateTranslatedArt = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)
        .select()

    if(error) throw new Error(error.message)
    return data
}

const deleteClustDex = async (id, cluster_id) => {
  const clusts = await dexdb.clusters.toArray()
  const indexclusts = clusts.findIndex(a => a.id==cluster_id)
  clusts[indexclusts]['translated_arts']=clusts[indexclusts]['translated_arts'].filter(c => c.id!==id)
  await dexdb.clusters.bulkPut(clusts)
}

export const deleteTranslatedArt = async ({
    id,
    cluster_id,
}) => {
    const { error } = await supabase
        .from(table)
        .delete()
        .eq('id', id)

    if(error) throw new Error(error.message)
    await deleteClustDex(id, cluster_id)
    return
}

export const deleteNewTranslatedArt = async (id) => {
    const { error } = await supabase
        .from('new_translated_arts')
        .delete()
        .eq('id', id)

    if(error) throw new Error(error.message)
    return
}


// QUERIES
export const getTranslatedArt = async ({
    id,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select()
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("TranslatedArt not found")

    return data
  }

export const getNewTranslatedArt = async ({
    id,
}) => {
    const newTransArtQuery = `
        url,
        title,
        orig_text,
        lang,
        orig_lang,
        source,
        aid,
        cluster_id,
        clusters(
            cluster_name
        )
        `
    const { data, error } = await supabase
        .from('new_translated_arts')
        .select(newTransArtQuery)
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("TranslatedArt not found")

    return data
  }

export const getTranslatedArts = async ({
    select, 
    field, 
    term,
}) => {
   let { data, error } = await supabase
    .from(table)
    .select(select)
    .eq(field, term)
  
    if(error) throw new Error(error.message)
  
    if(!data || data.length===0) throw new Error("Records not found")
  
    return data
  }

export const getClusterTranslatedArts = async ({
    select, 
    field, 
    term,
}) => {
   let { data, error } = await supabase
  .from(table)
  .select(select)
  .eq(field, term)
  
if(error) throw new Error(error.message)

if(!data || data.length===0) throw new Error("Records not found")

return data
}
