import React from 'react'

import Grid from '@mui/material/Grid'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography'

import TooltipButton from "../Buttons/TooltipButton"

export default function PaginationController({
    page,
    maxPage,
    handleBack,
    handleForward,
}){
    const curPage = maxPage === 0 ? 0 : page+1
    const bkDisable = page===0 ? true : false
    const fwdDisable  = page+1 === maxPage ? true : false

    return <Grid container 
            spacing={2}
            alignItems="center"
            >
                <Grid item>
                    <TooltipButton
                        title="Previous Sentence"
                        disabled={bkDisable}
                        func={handleBack}
                        icon={<ChevronLeftIcon />}
                    />
                </Grid>
                <Grid item>
                    <Typography color='primary'>
                        {curPage} / {maxPage}
                    </Typography>
                </Grid>
                <Grid item>
                    <TooltipButton
                        title="Next Sentence"
                        disabled={fwdDisable}
                        func={handleForward}
                        icon={<ChevronRightIcon/>}
                    />
                </Grid>
            </Grid>
}