import React from "react";

import Grid from '@mui/material/Grid'
import { IconButton, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/CloseRounded';
import Tooltip from '@mui/material/Tooltip';

export default function Translated({
    transWord,
    translatedWord,
    resetTranslation,
    addTranslation,
}){
    return(
            <Grid container alignItems="center" spacing={1}>
                <Grid item > 
                    <Typography>
                        {transWord} = <span style={{color: '#6415ff'}}>{translatedWord}</span> 
                    </Typography>
                </Grid > 
                <Grid item > 
                    <Tooltip title="Add to Vocabulary">
                        <IconButton onClick={addTranslation} color='primary'>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid > 
                <Grid item > 
                    <Tooltip title="Reset Translation">
                        <IconButton onClick={resetTranslation} color='primary'>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Grid > 
            </Grid > 
    )
}