import React from "react"
import { Route, Switch } from "react-router-dom"

import Signin from "../pages/Signin"
import SignupAll from "../pages/SignupAll"
import SignupComplete from "../pages/SignupComplete"

import ForgotPassword from "../pages/ForgotPassword"
import SupportNonLogged from "../pages/SupportNonLogged"

export const routes = [
    { path: "/auth/signin",  component: Signin, exact: true },
    { path: "/auth/signup",  component: SignupAll, exact: true },
    { path: "/auth/signup_complete",  component: SignupComplete, exact: true },
    { path: "/auth/forgotpassword",  component: ForgotPassword, exact: true },
    { path: "/auth/support",  component: SupportNonLogged, exact: true },
  ]
  
  export default function Auth(){
    return( 
        <Switch key="router">
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
  )
}