import React from "react";

import Button from '@mui/material/Button'


export default function BasicIconButton({
    actionFunc, 
    title,
    icon,
    color="primary",
    size='medium'
}){

  return(
      <Button 
        size={size}
        color={color}
        variant="contained" 
        onClick={actionFunc}
        startIcon={icon}
      >
        {title}
      </Button>
  )
}