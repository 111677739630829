import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { getLangs1 } from '../../utils'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import { Button } from '@mui/material'
import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import WordInput from '../Inputs/WordInput'
import PaginationController from '../VocabQuiz/PaginationController'
import TranslationItem from '../ListItems/TranslationItem'
import FlagRound from '../ImageElements/FlagRound'
import VocabSearch from '../Inputs/VocabSearch'
import AdSidebar from "../Ads/AdSidebar"
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SearchIcon from '@mui/icons-material/Search'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
export default function TranslationsPage({
  lang,
  language,
  native_lang,
  translations,
  origWord,
  transWord,
  handleOrigWord,
  handleTransWord,
  addTranslation,
  snack,
  msgType,
  alertMsg,
  handleClose,
  buttonLoading,
  loading,
  pageBack,
  pageForward,
  page,
  maxPage,
  clearSearch,
  vocabSearchTerm,
  handleVocabSearch,
  searchVocab,
  setSearchLang,
  searchLang,
  vocabSearchedSource,
  vocabSearchedTarget,
  answerCount,
  correctCount,
  lastAnswered,
  searchId,
  itemCount,
  handleSortType,
  handleWordType,
  toggleAddWord,
  toggleSearch,
  addWord,
  search,
  ascAnswered,
  ascSource,
  ascTarget,
  toggleAscSource,
  toggleAscTarget,
  toggleAscAnsewred,
  sortType,
  wordType,
  setSourceSort,
  setTargetSort,
  setAnsweredSort,
}){

    const handleBack = () => pageBack()
    const handleForward = () => pageForward()

    const chooseSource = () => {
      setSourceSort()
      handleSortType('vocab')
      handleWordType('source_word')
    }

    const toggleSource = () => toggleAscSource()
    const toggleTarget = () => toggleAscTarget()
    const toggleAnswered = () => toggleAscAnsewred()

    const chooseTarget = () => {
      setTargetSort()
      handleSortType('vocab')
      handleWordType('target_word')
    }

    const chooseAnswered = () => {
      setAnsweredSort()
      handleSortType('lastanswer')
      handleWordType('none')
   
    }

    const flagSize = 'small'
    const origLanguage = getLangs1(native_lang)['language']
    const dt = new Date(lastAnswered)
    const percent = answerCount > 0 ? Math.round(correctCount/answerCount* 100) : 0
    const momenttime = moment(dt)
    const datestr = lastAnswered ? momenttime.fromNow() : 'No answers' //.format('MMMM Do YYYY, h:mm:ss a')
    const link = `/user/translation/${searchId}/${vocabSearchedSource}/${vocabSearchedTarget}/${lang}/${native_lang}`
    return(
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                    <main >
                      <Card  sx={{mb:2}}>
                        <CardContent> 
                          <Grid container 
                                spacing={2} 
                                justifyContent='space-between'
                                alignItems="center"
                          >
                          <Grid item  >
                            <Grid container 
                                  spacing={2} 
                                  alignItems="center"
                            >
                              <Grid item >
                                <FlagRound 
                                  flagSize={flagSize}
                                  lang={lang}
                                />
                              </Grid>
                              <Grid item  >
                                <ArrowForwardIcon />
                              </Grid>
                              <Grid item  >
                                <FlagRound 
                                  flagSize={flagSize}
                                  lang={native_lang}
                                />
                              </Grid>
                              <Grid item  >
                                <Typography 
                                  component="h5" 
                                  variant="h5" 
                                  sx={{ color: '#6415ff' }}
                                >
                                   {language} Translations 
                                </Typography>
                              </Grid>
                              <Grid item  >
                                <Typography 
                                  component="h5" 
                                  variant="h5" 
                                  sx={{ color: '#6415ff' }}
                                >
                                  {itemCount>0 ? itemCount : ''}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Button 
                                  variant='contained' 
                                  size='small' 
                                  onClick={toggleAddWord}
                                  startIcon={<AddIcon />}
                                >
                                  Add Word
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button 
                                  variant='contained' 
                                  size='small' 
                                  onClick={toggleSearch}
                                  startIcon={<SearchIcon />}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item  >
                            <PaginationController  
                              page={page}
                              maxPage={maxPage}
                              handleBack={handleBack}
                              handleForward={handleForward}
                            />
                          </Grid>
                        </Grid>
                        </CardContent> 
                        <Divider />
                        {addWord &&
                         <CardContent>
                          <Grid container 
                                spacing={2} 
                                alignItems="center"
                                justifyContent='space-between'
                          >
                            <Grid item md={5} sm={12} >
                              <WordInput 
                                name="orignal_word"
                                label={language}
                                id="oringal_word" 
                                onChange={handleOrigWord}
                                value={origWord}
                                submitFunc={addTranslation}
                              />
                            </Grid>
                            <Grid item md={5} sm={12}>
                              <WordInput 
                                name="trans_word"
                                label={origLanguage}
                                id="translated_word" 
                                onChange={handleTransWord}
                                value={transWord}
                                submitFunc={addTranslation}
                              />
                            </Grid>
                            <Grid item md={2}>
                              <LoadingIconButton 
                                loading={buttonLoading}
                                title="Add"
                                onClick={addTranslation}
                                icon={<AddIcon />}
                              />
                            </Grid>
                          </Grid> 
                          
                          </CardContent> 
                        }
                        {search &&
                         <CardContent>
                            <VocabSearch
                              handleVocabSearch={handleVocabSearch}
                              vocabSearchTerm={vocabSearchTerm}
                              lang={lang}
                              searchLang={searchLang}
                              setSearchLang={setSearchLang}
                              nativeLang={native_lang}
                              buttonLoading={buttonLoading}
                              searchVocab={searchVocab}
                            />   
                          {vocabSearchedSource.length>0 && 
                            <div  >
                            <Divider sx={{mt:2, mb:2}} />
                            <Grid container 
                              alignContent='center' 
                              alignItems='center'
                              justifyContent='space-between'
                            >
                              <Grid item>
                                <Grid container 
                                      spacing={2} 
                                      justifyContent='space-between'
                                      alignItems='center'
                                >
                                    {vocabSearchedTarget.length>0 ?
                                    <>
                                    <Grid item>
                                      <Typography >
                                        Searched: 
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Link 
                                        style={{
                                          overflowWrap: 'break-word',
                                          color: '#6415ff'
                                        }}
                                        to={link}
                                      >
                                        {vocabSearchedSource} = {vocabSearchedTarget} 
                                      </Link>
                                    </Grid>
                                    <Grid item>
                                      <Typography >
                                        Performance: 
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography sx={{color: '#6415ff'}}>
                                        {correctCount} / {answerCount} ({percent}%)
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography >
                                        Last Answered:
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography sx={{color: '#6415ff'}}>
                                        {datestr}
                                      </Typography>
                                    </Grid>
                                    </>
                                    :
                                    <Grid item>
                                      <Typography sx={{color: '#6415ff'}}>
                                        Word not found!
                                      </Typography>
                                    </Grid>
                                    }
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                          }
                         
                        </CardContent>
                        }
                       
                        </Card>
                          <Grid container>
                            <Grid item xs={3} zeroMinWidth>
                              <Grid container  alignItems='center' spacing={1}>
                                <Grid item  >
                                  <Typography sx={{cursor: 'pointer'}} onClick={chooseSource} color={wordType==='source_word' ? 'primary' : 'black'}>
                                    Source
                                  </Typography>
                                </Grid>
                                <Grid item  >
                                  <IconButton  
                                    size='small'
                                    onClick={toggleSource}
                                  > 
                                   {ascSource ? <ArrowUpwardIcon fontSize='10' /> : <ArrowDownwardIcon fontSize='10' />}
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3} zeroMinWidth>
                              <Grid container alignItems='center' spacing={1}>
                                <Grid item  >
                                  <Typography onClick={chooseTarget} sx={{cursor: 'pointer'}} color={wordType==='target_word' ? 'primary' : 'black'}>
                                    Target
                                  </Typography>
                                </Grid>
                                <Grid item  >
                                  <IconButton  
                                    size='small'
                                    onClick={toggleTarget}
                                  > 
                                     {ascTarget ? <ArrowUpwardIcon fontSize='10' /> : <ArrowDownwardIcon fontSize='10' />}
                                  </IconButton>
                                </Grid>
                               
                              </Grid>
                            </Grid>
                            <Grid item xs={3} zeroMinWidth>
                              <Typography >
                                  Answers
                                </Typography>
                            </Grid>
                            <Grid item xs={3} zeroMinWidth>
                              <Grid container  alignItems='center' spacing={1}>
                                <Grid item  >
                                  <Typography onClick={chooseAnswered} sx={{cursor: 'pointer'}}  color={sortType==='lastanswer' ? 'primary' : 'black'}>
                                    Last Answered
                                  </Typography>
                                </Grid>
                                <Grid item  >
                                  <IconButton  
                                    size='small'
                                    onClick={toggleAnswered}
                                  > 
                                     {ascAnswered ? <ArrowUpwardIcon fontSize='10' /> : <ArrowDownwardIcon fontSize='10' />}
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider sx={{mt: 1, mb:1}}/>
                          {loading  && 
                            <CircularProgress size={24} />
                          }
                          {!loading && translations && translations.map(t => 
                            <TranslationItem
                              key={t.id} 
                              {...t} 
                            />
                          )}
         
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                    </main>
                  </Container>
                </Grid>
                <Grid item>
                  <AdSidebar />
                </Grid>
              </Grid>
    )
}