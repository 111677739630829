import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"

import {supabase} from "../supabase"

import { 
  dexdb,
  analytics,
} from '../App'

import { checkQuizCount } from '../utils'

import { 
    logEvent, 
} from "firebase/analytics";

import {
  deleteVocabWord,
  updateUserVocabWords1,
} from '../queries/vocabWordQueries'

import {
  addVocabSentence,
  updateVocabWordSent,
  deleteVocabWordSent,
} from '../queries/vocabSentenceQueries'

import TranslationPage from '../components/Pages/TranslationPage'

const query = `
id,
last_answered,
source_word,
source_lang,
target_word,
target_lang,
source_sent,
target_sent,
answer_count,
correct_count,
vocab_images(
  id, 
  vocab_image_library(
      id,
      url
  )
),
vocab_audio(
  id, 
  vocab_audio_library(
      id,
      url
  )
),
user_vocab_sentences(
  id,
  source_sent,
  target_sent,
  url,
  title,
  articles(
    id,
    title
  )
)
`

export default function Translation(){

    const { 
      id,
      orig_word, 
      trans_word,
      orig_lang,
      trans_lang,
    }  = useParams()

    const history = useHistory()

    async function getVocabWord1(){
      const { data: vocabWord, error } = await supabase
        .from('user_vocab_words')
        .select(query)
        .eq('id', id)
        .single()
      if(error) {
        showSnack('error','Error getting vocabulary word!')
      }

      if(!vocabWord) {
        showSnack('error','Error getting vocabulary word!')
      }

      setAnswerCount(vocabWord['answer_count'])
      setCorrectCount(vocabWord['correct_count'])

      if(vocabWord['last_answered']!==null){
        setLastAnswered(vocabWord['last_answered'])
      }

      if(vocabWord['source_sent']!==null){
        setOrigSent(vocabWord['source_sent'])
        setTransSent(vocabWord['target_sent'])
      }

      if(vocabWord['articles']){
        setArtId(vocabWord['articles']['id'])
        setArtTitle(vocabWord['articles']['title'])
      }

      if(vocabWord['user_vocab_sentences']!==null){
        setVocabSents(vocabWord['user_vocab_sentences'])
      }

      if(vocabWord['vocab_images']!==null){
        setVocabImages(vocabWord['vocab_images'])
      }

      if(vocabWord['vocab_audio']!==null){
        setVocabAudio(vocabWord['vocab_audio'])
      }

      setisLoading(false)
    }

    const [ origWord, setOrigWord ] = useState('')
    const [ transWord, setTransWord ] = useState('')
    const [ origSent, setOrigSent ] = useState('')
    const [ transSent, setTransSent ] = useState('')
    const [ answerCount, setAnswerCount ] = useState('')
    const [ correctCount, setCorrectCount ] = useState('')
    const [ artId, setArtId ] = useState('')
    const [ artTitle, setArtTitle ] = useState('')
    const [ lastAnswered, setLastAnswered ] = useState()
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ buttonLoading, setButtonLoading ] = useState(false)
    const [ showEdit, setShowEdit ] = useState(false)
    const [ wordDeleted, setWordDeleted ] = useState(false)
    const [ isLoading, setisLoading ] = useState(false)
    const [ vocabSents, setVocabSents ] = useState([])
    const [ open, setOpen ] = useState(false)
    const [ selUrl, setSelUrl ] = useState(false)
    const [ newSourceSent, setSourceSent ] = useState('')
    const [ newTargetSent, setTargetSent ] = useState('')
    const [ addSent, setAddSent ] = useState(false)
    const [ sentLoading, setSentLoading ] = useState(false)
    const [ editLoading, setEditLoading ] = useState(false)
    const [ vocabImages, setVocabImages ] = useState([])
    const [ vocabAudio, setVocabAudio ] = useState([])

    const closeDialog = () => setOpen(false)

    const handleClose = () => setSnack(false)
    const handleOrigWord = event => setOrigWord(event.target.value)
    const handleTransWord = event => setTransWord(event.target.value)
    const handleSourceSent = event => setSourceSent(event.target.value)
    const handleTargetSent = event => setTargetSent(event.target.value)
    const handleOrigSent = event => setOrigSent(event.target.value)
    const handleTransSent = event => setTransSent(event.target.value)
    const handleEdit = () => setShowEdit(!showEdit)
    const toggleSent = () => setAddSent(!addSent)
   
    const showSnack = (type, message) => {
      setButtonLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const showImage = fileName => {
      setSelUrl(fileName)
      setOpen(true)
    }

    const updatedVocabSent = async ({
      sourceSent,
      targetSent, 
      sent_id,
    }) => {
      setEditLoading(true)
    
      try {
        await updateVocabWordSent({
          sent_id,
          source_sent: sourceSent,
          target_sent: targetSent, 
        })
        await getVocabWord1(id)
        showSnack('success','Vocabulary sentence updated!')
      } catch(error){
        showSnack('error','Error updating vocabulary sentence!' )
      } finally{
        setEditLoading(false)
      }
    }

    const deleteVocabSent1 = async sent_id => {
      setEditLoading(true)
      try {
        await deleteVocabWordSent(
          sent_id
        )
        await getVocabWord1(id)
        showSnack('success','Vocabulary sentence deleted!')
      } catch(error){
        showSnack('error','Error deleting vocabulary sentence!')
      } finally{
        setEditLoading(false)
      }
    }

    const deleteTranslation = async () => {
      try {
        await  deleteVocabWord(
          id
        )
        setWordDeleted(true)
        history.push(`/user/translations/${orig_lang}`)
      } catch(error){
        showSnack('error','Error deleting vocabulary word! ')
      } finally{
        setButtonLoading(false)
      }
    }

    const editTranslation =  async () => {
      setButtonLoading(true)
      try {
        await updateUserVocabWords1({
          id,
          source_word: origWord, 
          target_word: transWord,
          source_sent: origSent, 
          target_sent: transSent,
        })
        await getVocabWord1(id)
        showSnack('success','Vocabulary word updated!')
      } catch(error){
        showSnack('error','Error updating vocabulary words!' )
      } finally{
        setButtonLoading(false)
      }
    }

    const addTransSent = async () => {
      setSentLoading(true)
      try {
        await addVocabSentence({
          source_sent: newSourceSent,
          target_sent: newTargetSent,
          user_vocab_id: id,
        })
        await getVocabWord1(id)
        setSourceSent('')
        setTargetSent('')
        showSnack('success','Sentence added!')
        
        logEvent(analytics, 'add_sent', {
          source_lang: orig_lang,
          target_lang: trans_lang,
          type: 'manual',
          device: 'web',
        })
      } catch(error){
        showSnack('error','Error adding sentence!' )
      } finally{
        setSentLoading(false)
      }
    }
    
    useEffect(() => {
      const controller = new AbortController();

      async function getVocabWord1(){
        const { data: vocabWord, error } = await supabase
        .from('user_vocab_words')
        .select(query)
        .eq('id', id)
        .single()

      if(error) {
        showSnack('error','Error getting vocabulary word!')
      }

      if(!vocabWord) {
        showSnack('error','Error getting vocabulary word!')
      }
 
      setAnswerCount(vocabWord['answer_count'])
      setCorrectCount(vocabWord['correct_count'])

      if(vocabWord['last_answered']!==null){
        setLastAnswered(vocabWord['last_answered'])
      }

      if(vocabWord['source_sent']!==null){
        setOrigSent(vocabWord['source_sent'])
        setTransSent(vocabWord['target_sent'])
      }


      if(vocabWord['user_vocab_sentences']!==null){
        setVocabSents(vocabWord['user_vocab_sentences'])
      }

      if(vocabWord['vocab_images']!==null){
        setVocabImages(vocabWord['vocab_images'])
      }

      if(vocabWord['vocab_audio']!==null){
        setVocabAudio(vocabWord['vocab_audio'])
      }
     
      await dexdb.vocab_media.put({id: parseInt(id), 
        vocab_images: vocabWord['vocab_images'],
        vocab_audio: vocabWord['vocab_audio'] 
       })
      setisLoading(false)
    }
      setisLoading(true)
      setOrigWord(orig_word)
      setTransWord(trans_word)
      getVocabWord1()
      checkQuizCount()
      return () => {
        controller.abort();
      }
    },[id, orig_word, trans_word]);

    return  <TranslationPage 
              id={id}
              wordDeleted={wordDeleted}
              origWord={origWord}
              transWord={transWord}
              origLang={orig_lang}
              transLang={trans_lang}
              artId={artId}
              origSent={origSent}
              transSent={transSent}
              answerCount={answerCount}
              correctCount={correctCount}
              lastAnswered={lastAnswered}
              artTitle={artTitle}
              handleOrigWord={handleOrigWord}
              handleTransWord={handleTransWord}
              handleOrigSent={handleOrigSent}
              handleTransSent={handleTransSent}
              deleteTranslation={deleteTranslation}
              editTranslation={editTranslation}
              handleClose={handleClose}
              handleEdit={handleEdit}
              showEdit={showEdit}
              snack={snack}
              loading={isLoading}
              msgType={msgType}
              alertMsg={alertMsg}
              buttonLoading={buttonLoading}
              closeDialog={closeDialog}
              selUrl={selUrl}
              showImage={showImage}
              open={open}
              vocabSents={vocabSents}
              handleSourceSent={handleSourceSent}
              handleTargetSent={handleTargetSent}
              newSourceSent={newSourceSent}
              newTargetSent={newTargetSent}
              addSent={addSent}
              sentLoading={sentLoading}
              toggleSent={toggleSent}
              addTransSent={addTransSent}
              updatedVocabSent={updatedVocabSent}
              deleteVocabSent={deleteVocabSent1}
              editLoading={editLoading}
              vocabImages={vocabImages}
              vocabAudio={vocabAudio}
            />
}