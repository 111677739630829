import React, {useEffect, useState} from "react";

import {
  createRead
} from '../../queries/readQueries'

import { dexdb } from "../../App";

import Button from '@mui/material/Button'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import SnackAlert from '../Dialogs/SnackAlert'

export const incrementArtProgress = async () => {
  const now = new Date()
  const day = now.getDate()
  const mon = now.getMonth() + 1
  const yr = now.getFullYear()
  const progKey = mon.toString()+yr.toString()
  const crup = await dexdb.progress_months.where("id").equals(progKey).toArray()
  const progm = crup[0]
  progm['days'][day-1]['arts_count']=progm['days'][day-1]['arts_count']+1
  await dexdb.progress_months.update(progKey, progm)
}

export default function ArticleReadButton({
    id,
    read,
    size='medium'
}){

    const [ readActive, setReadActive] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const updateRead = async () => { 
      try {
         await createRead({
          art_id: id,
        })
        setReadActive(true)
        await incrementArtProgress()
      } catch(error) {
        showSnack('error', 'Error adding to read list.')
      }
    }

    useEffect(() => {
      setReadActive(read)
    },[read])

    return(<>
        <Button 
            size={size}
            onClick={updateRead} 
            startIcon={readActive ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
            variant="contained" 
            color={readActive ? "success" : "disabled"} >
            Read
        </Button> 
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          />
        </>
    )
}

