import React from 'react'

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import SendIcon from '@mui/icons-material/Send'

import SnackAlert from '../Dialogs/SnackAlert'
import LoginHeader  from '../Login/LoginHeader'
import LoginHelp from '../Login/LoginHelp'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function ForgotPasswordPage({
    alertMsg,
    email,
    handleEmail,
    resetPassword,
    snack,
    msgType,
    handleClose,
    loading
}){
  
  const { 
    emailsub, 
    // signup,
    resetpwd,
 } = getTranslations()

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <Grid container 
              justifyContent='center' 
              textAlign='center'
              spacing={2}
        >
           <Grid item xs={12}>
            <LoginHeader   title= {`${resetpwd}`} />
          </Grid>  
          <Grid item  xs={12}>
            <LoginInput  
              name="email"
              label={emailsub}
              handleFunc={handleEmail} 
              value={email} 
              error={alertMsg} 
            />
          </Grid>
          <Grid item  xs={12}>
            <LoadingIconButton
              loading={loading}
              onClick={resetPassword} 
              title={resetpwd} 
              icon={<SendIcon />}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <LoginHelp />
        </Box>
        <Box mt={3}>
          <Copyright />
        </Box>
      </Paper>
      <SnackAlert 
        snack={snack}
        handleClose={handleClose}
        msgType={msgType}
        alertMsg={alertMsg}
      />
    </Container>
  )
}