import React from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import SendIcon from '@mui/icons-material/Send'
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import AdSidebar from "../Ads/AdSidebar"

export default function SupportPage({
    supportTypes,
    submitSupport,
    handleSupportQuestion,
    handleSupportType,
    supportType,
    supportQuestion,
    error,
    errorTextMsg,
    snack,
    handleClose,
    msgType,
    alertMsg,
    loading,
}){

    return <Grid container spacing={1} justifyContent='center' >
            <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                    <Card > 
                        <CardContent>
                            <Grid container spacing={3} alignItems="center" justifyContent='space-between' >
                                <Grid item xs={12}>
                                    <Typography variant='h4' style={{color: '#6415ff'}}>
                                        Support
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel  >
                                            Support Type
                                        </InputLabel>
                                        <Select
                                            fullWidth 
                                            value={supportType} 
                                            onChange={handleSupportType} 
                                        >
                                            {supportTypes.map((l,i)=> 
                                                <MenuItem key={i} value={l}>
                                                    {l}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        error={error}
                                        required 
                                        id="standard-required" 
                                        label="Support Question" 
                                        value={supportQuestion} 
                                        onChange={handleSupportQuestion} 
                                        helperText={error? errorTextMsg : ''}
                                    />
                                </Grid>  
                                <Grid item >
                                    <LoadingIconButton
                                        loading={loading}
                                        onClick={submitSupport} 
                                        title="Submit Request"
                                        icon={<SendIcon />}
                                    /> 
                                </Grid>
                            </Grid>
                        </CardContent>
                        <SnackAlert 
                            snack={snack}
                            handleClose={handleClose}
                            msgType={msgType}
                            alertMsg={alertMsg}
                        />
                    </Card>
                </Container>
            </Grid>
            <Grid item>
              <AdSidebar />
            </Grid>
        </Grid>

}