import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { checkQuizCount } from "../utils";

import {
  getTranslatedArt, 
  updateTranslatedArt,
} from '../queries/translatedArtQueries'

import ClusterArtTextPage from '../components/Pages/ClusterArtTextPage'

const CHARACTER_LIMIT = 10000
const MIN_CHARACTER = 200

export default function ClusterArtText(){

    const { 
      link_id,
      cluster_id,
      cluster_name,
      count,
      lang, 
    } = useParams()

    const tooLongError = `Please add text that is less than ${CHARACTER_LIMIT} words.`

    const tooShortError = `Please enter text that is at least ${MIN_CHARACTER} characters`

    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ text, setText ] = useState('Loading...')
    const [ title, setTitle ] = useState('')
    const [ url, setUrl ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ updating, setUpdating ] = useState(false)

    const wordCount = text ? text.length : 0

    const handleText = event => setText(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const setArticle = (art) => {
      setTitle(art['title'])
      setUrl(art['url'])
      setText(art['orig_text'])
    }

    const checkItem = () => wordCount >CHARACTER_LIMIT || wordCount<MIN_CHARACTER ? false : true

    const articleTextUpdate = async () => {
      setUpdating(true)

      if(wordCount>CHARACTER_LIMIT){
        setLoading(false)
        showSnack('error', tooLongError)
        return
      }

      if(wordCount<MIN_CHARACTER){
        setLoading(false)
        showSnack('error', tooShortError)
        return
      }
      
      try {
        await updateTranslatedArt({
          id: link_id,
          orig_text: text,
          translation: '',
        })
        showSnack('success', 'Article updated!')
      } catch(error){
        showSnack('error', 'Error updating article!')
      } finally{
        setUpdating(false)
      }
    }

    useEffect(()=> {
      
      const getSetArt = async () => {

      try {
        setLoading(true)
        const translated_art = await getTranslatedArt({id: link_id})
        if(translated_art){
          setArticle(translated_art)
        }
        } catch(error){
        } finally{
          setLoading(false)
        }
      }

      getSetArt()
      checkQuizCount()
    }, [link_id])

    return <ClusterArtTextPage 
            clusterName={cluster_name}
            cluster_id={cluster_id}
            clusterActive={false}
            lang={lang}
            updating={updating}
            count={count}
            url={url}
            title={title}
            text={text}
            loading={loading}
            handleText={handleText}
            updateText={articleTextUpdate}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            checkItem={checkItem}
            characterLimit={CHARACTER_LIMIT}
            characterMin={MIN_CHARACTER}
            wordCount={wordCount}
            buttonTitle='Update Article'
          />
}