import React from 'react'

import { getTranslations } from '../../utils'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

const translations = getTranslations()

const ClusterDropDown = ({
    selCluster,
    handleCluster,
    clusters,
}) => 
    <FormControl variant="standard" 
        sx={{ m: 1, minWidth: 150 }}
    >
        <InputLabel  >
            {translations.clusters}
        </InputLabel>
        <Select
            autoWidth={true}
            label={translations.clusters}
            value={selCluster}
            onChange={handleCluster}
        >
            <MenuItem key='all' value='all'>
                All
            </MenuItem>
            {clusters.map(l => 
                <MenuItem key={l.id} value={l.id}>
                    {l.cluster_name}
                </MenuItem>
            )}
        </Select>
    </FormControl>

export default ClusterDropDown