import React from 'react'

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import apple_image from '../../images/appstore.png'
import android_image from '../../images/playstore.png'

const apple_url = 'https://apps.apple.com/us/app/langa-learn/id1541975692'
const android_url = 'https://play.google.com/store/apps/details?id=com.langalearn.android'

export default function GetMobileApp(){

    const { getmobile } = getTranslations()

    return(
        <Grid container style={{marginTop: 20}}>
            <Grid item xs={12} style={{color: '#6415ff'}}>
                <Typography >
                    {getmobile}
                </Typography>
            </Grid>
            <Grid item>
                <a href={apple_url}>
                    <img src={apple_image} alt="applestore" />
                </a>
            </Grid>
            <Grid item>
                <a href={android_url}>
                    <img src={android_image} alt="playstore" />
                </a>
            </Grid>
        </Grid>
    )
}