import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks"

import {
  analytics, 
  dexdb,
} from '../App'

import { 
  logEvent, 
} from "firebase/analytics";

import { 
  langSwitchQuiz,
  getSpacedRepititionNextDate,
  getNowIso,
 } from '../utils'

import {
  updateVocabQuizProgress,
} from '../queries/vocabWordQueries'

import {incrementProgress} from '../queries/progressQueries'

import {
  createQuizAnswer, 
} from '../queries/quizAnswersQueries'

import { updateQuiz } from '../queries/quizQueries'

import VocabQuizSentPage from '../components/Pages/VocabQuizSentPage'

export default function VocabQuizSent(){

    const { 
      id,
      source_lang,
      target_lang,
      user_vocab_word_id,
    } = useParams()

    const { language } = langSwitchQuiz(target_lang)
    const qId = parseInt(id)
    const quiz = useLiveQuery(() => dexdb.quiz.where('id').equals(qId).first(), [id]);

    const source_word = quiz ? quiz.source_word : ''
    const target_word = quiz ? quiz.target_word : ''

    const [ guess, setGuess ] = useState('')
    const [ feedback, setFeedback ] = useState(false)
    const [ correct, setCorrect ] = useState(false)
    const [ correctAnswers, setCorrectAnswers ] = useState(0)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(true)

    const toggle = () => setCorrect(false)
    const handleClose = () => setSnack(false)
    const handleGuess = event => setGuess(event.target.value)

    const answered = async (correct) => {
      const new_correct_count = correct ? quiz.correct_count+1 : quiz.correct_count
      const new_answer_count =  quiz.answer_count+1
      const prevEasiness = quiz.easiness
      const percent = new_correct_count/new_answer_count

      setCorrect(correct)
    
      await incrementProgress('quiz_count', 1)
      const nextReview = getSpacedRepititionNextDate(new_answer_count, prevEasiness, percent)
      
      const { 
        newReviewDate,
        easiness, 
      } = nextReview

      try {
        await updateProgress1(new_correct_count, new_answer_count, correct, newReviewDate, easiness)
      } catch(error){
        showSnack('error', 'Error answer!')
        throw error
      }
     
      try {
        await updateQuiz({id, answered: true})
        await dexdb.quiz.update(qId, {answered: true})
      } catch(error){
        showSnack('error', 'Error answer!')
        throw error
      }

      try {
        await createQuizAnswer({ 
          answer_word: guess,
          correct,
          user_vocab_word_id,	
        })
      } catch(error){
        showSnack('error', 'Error answer!')
        throw error
      }
    }

    // for German words capitalization matters 
    const checkCorrect = () => source_lang==='de' ? target_word.trim()===guess.trim() ? true : false :  target_word.trim().toLowerCase()===guess.trim().toLowerCase() ? true : false

    const answersArray = words =>  source_lang==='de' ? words.map((a) => a.trim()) : words.map((a) => a.toLowerCase().trim())

    const checkAnswersArray = answers => source_lang==='de' ? answers.includes(guess.trim()) : answers.includes(guess.toLowerCase().trim())

    // check is answer answer is one of the multiple target language words
    // target words for Nachfrage = 'demand | inquiry | exit poll' - any of these words will be acceptable
    const checkCorrectMultiple = async (separate) => {
      const words = target_word.split(separate)
      const answers = answersArray(words)
      const correctAnswer = checkAnswersArray(answers)
      setCorrect(correctAnswer)
      if(correctAnswer){
        setCorrectAnswers(correctAnswers + 1)
        await answered(true, guess)
      } else {
        await answered(false, guess)
      }
      setFeedback(true)
      return 
    }

    const checkAnswer = async () => {
      if(guess.length===0){
        showSnack('error', 'Please enter a word!')
        return 
      }

      setFeedback(true)
      const multiple = target_word.includes('|')
      if(multiple){
   
        await checkCorrectMultiple('|')
        return 
      }

      const multiplecomma = target_word.includes(',')
      if(multiplecomma){
    
        await checkCorrectMultiple(',')
        return 
      }

      const correctAnswer = checkCorrect()
      if(correctAnswer){
        setCorrectAnswers(correctAnswers + 1)
      }
      try {
        await answered(correctAnswer, guess)
      } catch(error) {
        showSnack('error', 'Error answer!')
      }

    }

    const updateProgress1 = async (
      new_correct_count, 
      new_answer_count,
      correct,
    ) => {
      const nowIso = getNowIso()

      const prevEasiness = quiz.easiness
      const percent = new_answer_count>0 ? new_correct_count / new_answer_count : 0.0
      const nextReview = getSpacedRepititionNextDate(new_answer_count, prevEasiness, percent)
      const { 
        newReviewDate,
        easiness, 
      } = nextReview
    
      const quizUpdate = {
        answer_count: new_answer_count,
        correct_count: new_correct_count,
        last_answered: nowIso,
        next_review: newReviewDate,
        easiness,
      }

      try {
        await updateVocabQuizProgress(quizUpdate,  user_vocab_word_id)
      } catch(error){
        showSnack('error', 'Error updating quiz!')
      }
      
      logEvent(analytics, 'sent_quiz', {
        source_lang, 
        target_lang,
        device: 'web',
      })
    }

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    return  <VocabQuizSentPage 
              checkAnswer={checkAnswer}
              toggle={toggle}
              handleGuess={handleGuess}
              native_lang={target_lang} 
              language={language}
              guess={guess}
              feedback={feedback} 
              newQuestion={source_word}
              curQuestion={source_word}
              curAnswer={target_word}
              correct={correct}
              lang={source_lang}
              snack={snack}
              msgType={msgType}
              alertMsg={alertMsg}
              loading={loading}
              handleClose={handleClose}
              isLoading={false}
            />
}