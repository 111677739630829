import React from 'react'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import {
  getLocalTime,
} from '../../utils'

export default function TranslationItem({
    id,
    source_word, 
    target_word, 
    source_lang, 
    target_lang, 
    last_answered,
    answer_count,
    correct_count,
  }){
    const percent = answer_count > 0 ? Math.round(correct_count/answer_count* 100) : 0
    const link =`/user/translation/${id}/${source_word}/${target_word}/${source_lang}/${target_lang}`
    const datestr =  last_answered ? getLocalTime(last_answered).fromNow() : 'No answers' 
    return  <>
        <Grid container 
              alignItems="center"
              justifyContent='space-between'
            >
              <Grid item xs={3} zeroMinWidth>
                  <Link 
                    sx={{
                      overflowWrap: 'break-word',
                      fontSize: 18,
                    }}
                    to={link}
                  >
                    {source_word}
                  </Link> 
               
              </Grid>
              <Grid item xs={3} zeroMinWidth>
                <Typography style={{overflowWrap: 'break-word'}}>
                  {target_word}
                </Typography>
              </Grid>
              <Grid item xs={3} zeroMinWidth>
                <Typography style={{overflowWrap: 'break-word'}} >
                  {correct_count}/{answer_count} ({percent}%)
                </Typography>
              </Grid>
              <Grid item xs={3} zeroMinWidth>
                <Typography style={{overflowWrap: 'break-word'}} >
                    {datestr}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{
              marginTop: 1, 
              marginBottom: 1,
              }} />
            </>
  }