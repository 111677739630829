import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';

const LoadingIconButton = ({ 
    title,
    onClick,
    icon,
    loading,
    size='medium',
    color='#6415ff',
    bkcolor='#5010CC',
    fullWidth=true
}) => 
    <LoadingButton
        size={size}
        loading={loading}
        fullWidth={fullWidth}
        loadingPosition="center"
        startIcon={icon}
        onClick={onClick}
        variant="contained"
        sx={{ 
            backgroundColor: color,
            '&:hover': {
                backgroundColor: bkcolor,
            },
        }}
    >
        {title}
    </LoadingButton>

export default LoadingIconButton

