import React from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Divider from '@mui/material/Divider'

import Container from '@mui/material/Container'

import FlagRound from '../ImageElements/FlagRound'
import AdSidebar from "../Ads/AdSidebar"
import { LinkBasic } from '../ListItems/BasicLinks'
import ImageList from '../Media/Images/ImageList'
import AudioClips from '../Media/Audio/AudioClips'
import SnackAlert from '../Dialogs/SnackAlert'

import { 
  langSwitch, 
} from '../../utils'

export default function TranslationUploadbPage({
  origWord,
  transWord,
  origLang,
  transLang,
  handleClose,
  snack,
  msgType,
  alertMsg,
  pictures,
  audio,
  onImageChange,
  onAudioChange,
  imageLoading,
  audioLoading,
  vocabId,
  imgUrl,
  handleImageUrl,
  toggleImgUrl,
  addImageUrl,
  showImgUrl,
  audioUrl,
  handleAudioUrl,
  toggleAudioUrl,
  addAudioUrl,
  showAudioUrl,
}){

  const flagSize = 'small'
  const link = `/user/translations/${origLang}`
  const wordlink = `/user/translation/${vocabId}/${origWord}/${transWord}/${origLang}/${transLang}`
  return <Grid container spacing={1} justifyContent='center' >
            <Grid item xs={12} lg={9}>
              <Container component="main" maxWidth="xl" >
                  <Grid container 
                    spacing={2} 
                  >
                    <Grid item xs={12}>
                      <Card >
                        <CardContent> 
                          <Grid container 
                            spacing={2} 
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                            <LinkBasic
                              variant="h5" 
                              to={link} 
                              title={`${langSwitch(origLang)} Vocabulary`}
                            />
                            </Grid>
                            <Grid item >
                              <Grid container 
                                  spacing={1} 
                                  alignItems='center'
                                  justifyContent='space-between'
                              >
                                <Grid item xs={12}>
                                  <FlagRound 
                                    flagSize={flagSize}
                                    lang={origLang}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <LinkBasic
                                    variant="h5" 
                                    to={wordlink} 
                                    title={origWord}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item >
                              <Grid container 
                                  spacing={1} 
                                  alignItems='center'
                                  justifyContent='space-between'
                              >
                              <Grid item xs={12}>
                                <FlagRound 
                                    flagSize={flagSize}
                                    lang={transLang}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <LinkBasic
                                      variant="h5" 
                                      to={wordlink} 
                                      title={transWord}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>      
                        <Divider />
                        <CardContent> 
                          <Grid container 
                            spacing={2} 
                            justifyContent='space-between'
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <AudioClips 
                                audio={audio} 
                                audioLoading={audioLoading}
                                onAudioChange={onAudioChange}
                                sourceLang={origLang}
                                sourceWord={origWord}
                                targetLang={transLang}
                                vocabId={vocabId}
                                showImgUrl={showImgUrl}
                                audioUrl={audioUrl}
                                handleAudioUrl={handleAudioUrl}
                                toggleAudioUrl={toggleAudioUrl}
                                showAudioUrl={showAudioUrl}
                                addAudioUrl={addAudioUrl}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ImageList 
                                pictures={pictures} 
                                imageLoading={imageLoading}
                                onImageChange={onImageChange}
                                sourceLang={origLang}
                                sourceWord={origWord}
                                targetLang={transLang}
                                vocabId={vocabId}
                                origWord={origWord}
                                imgUrl={imgUrl}
                                handleImageUrl={handleImageUrl}
                                toggleImgUrl={toggleImgUrl}
                                addImageUrl={addImageUrl}
                                showImgUrl={showImgUrl}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <SnackAlert 
                      snack={snack}
                      handleClose={handleClose}
                      msgType={msgType}
                      alertMsg={alertMsg}
                    />
                  </Grid>
                </Container>
              </Grid>
            <Grid item>
              <AdSidebar />
            </Grid>
          </Grid>
  

}