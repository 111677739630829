import React, { useState, useEffect,} from 'react'
import validator from 'validator'
import {supabase} from '../supabase'

import { 
  getTranslations,
  formatDate1,
  getLinkCount,
 } from '../utils'

 import {analytics, dexdb } from '../App'

 import { 
  setUserId, 
  setUserProperties,
} from "firebase/analytics";

import { 
  getLoginData,
} from '../queries/memberQueries'

import {
  getAndStoreCurRecs
} from '../queries/recommendationQueries'

import { 
  getAndStorePlaylist,
} from '../queries/playListQueries'

import {
  getProg
} from '../queries/progressQueries'

import {
  getGoals, 
} from '../queries/goalQueries'

import SignInPage from '../components/Pages/SignInPage'

export const setLoginData = async () => {
  const logdata1 = await getLoginData()

  const logdata = logdata1[0]
 
  const member ={
    id: logdata.id,
    email: logdata.email,
    name: logdata.name,
    langs: logdata.langs,
    native_lang: logdata.native_lang,
    fcm_token: logdata.fcm_token,
    role: logdata.role,
    country: logdata.country,
    time_zone: logdata.time_zone,
    badge_count: logdata.badge_count,
  }

  try {
    await setDexieLogin({...logdata, member})
  } catch(error){
  }
}

const setDexieLogin = async ( {
  library, 
  quiz, 
  clusters,
  member,
}) => {
  await dexdb.library.bulkPut(library);
  await dexdb.member.put(member)
  await dexdb.quiz.bulkPut(quiz);
  await dexdb.clusters.bulkPut(clusters);
}

export default function SignIn({history}){

  const trans = getTranslations()
  const email1 = trans.emailsub
  const pwd = trans.pwd

  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ alert, setAlert ] = useState('')
  const [ loading, setLoading] = useState(false)
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ provloading, setProvLoading] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handlePassword = event => setPassword(event.target.value)
  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const loginPassword = async () => {
    if (!validator.isEmail(email)) {
      setAlert('Invalid email!')
      return
    } 
    setLoading(true)
    const {
      error,
    } = await supabase.auth.signInWithPassword({ email, password })

    if (error)  {
      setAlert(error.message)
      showSnack('error', 'Error logging in.')
    }  
  }

  const loginProvider = async (provider) => {

    setLoading(true)
    
    const { error } = await supabase.auth.signInWithOAuth({
      provider
    })

   if (error)  {
      setAlert(error.message)
      showSnack('error', 'Error logging in.')
   }
   
}

  const loginGoogle = async () => await loginProvider('google')

  const loginApple = async () => await loginProvider('apple')

  const inputs = [
    {
      name:'email',
      label: email1,
      handleFunc: handleEmail,
      value: email
    },
    {
      name:'password',
      label: pwd,
      handleFunc: handlePassword,
      value: password
    }
  ]


  useEffect(() => {

    const setProg = async () => {
      const now = new Date()
      const month = now.getMonth() + 1
      const year = now.getFullYear()
      try {
        const data = await getProg({
          month,
          year,
        })
  
        const progKey = month.toString()+year.toString()
        await dexdb.progress_months.put({id: progKey, days: data})
        await getGoals() 
      } catch(error){
        showSnack('error', 'Error getting data.')
      }
    }

    const setLoginData1 = async (logdata) => {

      const clusters = logdata.clusters
  
      const member = {
        id: logdata.id,
        email: logdata.email,
        name: logdata.name,
        langs: logdata.langs,
        native_lang: logdata.native_lang,
        fcm_token: logdata.fcm_token,
        role: logdata.role,
        country: logdata.country,
        time_zone: logdata.time_zone,
        badge_count: logdata.badge_count,
      }
  
      try {
        await setDexieLogin({...logdata, member})
        if(clusters===0){
          return history.push('/user/first_cluster')
        }
    
        let linkCount = getLinkCount(clusters)
        localStorage.setItem('count', linkCount.toString())
        if(linkCount<3){
          return history.push('/user/first_cluster')
        }
        await setProg()
      } catch(error){
        showSnack('error', 'Error getting data.')
      }
  
    }

    
    const checkUser = async () => {
      setProvLoading(true)
      try {
        const logdata1 = await getLoginData()
         if(logdata1.length===0){ 
          history.push('/auth/signup_complete')
          return
        }
  
        const user = logdata1[0]
     
        const token_active = user.fcm_token.length>0 ? 'true' : 'false'
        setUserId(analytics, { id: user.id });
        setUserProperties(analytics, {
            native_lang: user.native_lang,
            target_lang: user.langs[0], 
            token_active: token_active ,
            country: user.country, 
            role: user.role,
        });
        await setLoginData1(user)
        await getAndStoreCurRecs()
        await getAndStorePlaylist()
        setProvLoading(false)
        return history.push('/user/dashboard')
       } catch(error){
         showSnack('error', 'Error loggin in.')
         setProvLoading(false)
        if(error.message==='JSON object requested, multiple (or no) rows returned'){
          history.push('/auth/signup_complete')
        }
      }
    }
  
    const getSess = async () => {
     await supabase.auth.getSession().then(({ data: { session } }) => {
        if(session){
          if(session.user!==null){
            const today = new Date()
            const date = formatDate1(today,'/')
            localStorage.setItem('historyDate', date);
            localStorage.setItem("vocab_page", "0")
            localStorage.setItem("user_vocab_page", "0")
            checkUser()
          }
        }
      })
    }
    

    const{ data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if(session){
        if(session.user!==null){
          // console.log(session)
          const today = new Date()
          const date = formatDate1(today,'/')
          localStorage.setItem('historyDate', date);
          localStorage.setItem("vocab_page", "0")
          localStorage.setItem("user_vocab_page", "0")
          checkUser()
        }
      }
    })

    getSess()

    return () => {
      subscription.unsubscribe();
    };
  }, [history])

  return <SignInPage
          inputs={inputs} 
          loginPassword={loginPassword} 
          alert={alert}
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          loginGoogle={loginGoogle}
          loginApple={loginApple}
          provloading={provloading}
        />
}