import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import LangaLogo from '../ImageElements/Logo'
import WelcomeButton from '../Buttons/WelcomeButton'
import Copyright from '../Login/Copyright'

export default function Welcome({history}){


  return (
      <Container component="main" maxWidth="xs">
        <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
          <Grid container 
                justifyContent='center' 
                textAlign='center'
                spacing={2}
          >
            <Grid item xs={12}>
                <LangaLogo size={5} />
            </Grid>
            <Grid item xs={12}>
              <Typography 
                  variant="h5" 
                  sx={{color:'#6415ff'}}
              >
                Welcome to Langa Learn
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{mb: 2}} />
            </Grid>  
            <Grid item xs={12}>
              <Typography variant='h4' sx={{color:'#6415ff'}} >
                Create cluster
              </Typography>
            </Grid>  
            <Grid item xs={12}>
              <Typography variant='h6' >
                Create cluster in a subject you are interested in.  
              </Typography>
            </Grid>  
            <Grid item xs={12}>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemText primary=" 1. Choose a name."  secondary="eg. Movies, Programming, Crypto"  />
              </ListItem>
            </List>
            <Box sx={{mt:2}}  />
              <Typography variant='caption'  >
                *Maximum of 3 active clusters per user.
              </Typography>
            </Grid>  
            <Grid item xs={12}>
               <WelcomeButton 
                history={history} 
                title='Add cluster urls'
                route='/welcome_add_urls'
               />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Paper>
      </Container>
  )
}