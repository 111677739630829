import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch'
import grey from '@mui/material/colors/grey'

const LangaPurpleSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: grey[300],
    '&.Mui-checked': {
      color: '#6415ff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
}));

export default LangaPurpleSwitch
  