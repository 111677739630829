import React from "react";
import { dexdb } from "../../App";
import { useLiveQuery } from "dexie-react-hooks";

import CurrentQuizzesAll from '../VocabQuiz/CurrentQuizzesAll'

import {
    Banner2503001, 
    Banner2503002, 
    Banner250300,
} from './Banner250300'

import {
    ITalkiLink,
    MondlyLink,
    StripeLink,
    BookstoreLink,
    MosaLingLink,
    LingoPieLink,
    PreplyLink,
    BabbleLink,
} from './AffiliateLinks'

export default function AdSidebar(){
    const member = useLiveQuery(() => dexdb.member.toArray(), []) ?? [];
    const lang = member.length>0 ? member[0].langs[0] : 'en' 
    const nativeLang = member.length>0?  member[0].native_lang : 'en'
    return  (
           <div></div>
            // <div style={{minWidth: 300}}>
            //     <CurrentQuizzesAll />
            //     <div style={{height: 10}} />
            //     <BookstoreLink targetLang={lang} />
            //     <div style={{height: 10}} />
            //     <ITalkiLink nativeLang={nativeLang} />
            //     <div style={{height: 10}} />
            //     <LingoPieLink />
            //     <div style={{height: 10}} />
            //     <MondlyLink nativeLang={nativeLang} />
            //     <div style={{height: 10}} />
            //     <MosaLingLink />
            //     <div style={{height: 10}} />
            //     <BabbleLink />
            //     <div style={{height: 10}} />
            //     {member.length>0 &&
            //         <PreplyLink sourceLang={nativeLang} targetLang={lang} />
            //     }
            //     <div style={{height: 10}} />
            //     <StripeLink />
            //     <div style={{height: 10}} />
            //         <Banner250300 source='web_sidebar' />
            //     <div style={{height: 10}} />
            //         <Banner2503001 source='web_sidebar' />
            //     <div style={{height: 10}} />
            //         <Banner2503002 source='web_sidebar' />
            // </div>
            )
}

export function AdSidebar1(){
    return  <div style={{minWidth: 300}}>
                <div style={{height: 10}} />
                <Banner2503001 />
            </div>
}

 