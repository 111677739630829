import React, { Suspense} from "react"
import Dexie from 'dexie'
import { Route, Switch } from "react-router-dom"

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { ThemeProvider, createTheme } from '@mui/material/styles'
import {purpleTheme} from './themes'

import NotFoundPage from "./components/Pages/NotFoundPage"

import User from "./pages/User"
import Signin from "./pages/Signin"
import SignupAll from "./pages/SignupAll"
import Auth from "./pages/Auth"

import Welcome from "./components/Welcome/Welcome"
import WelcomeAddUrl from "./components/Welcome/WelcomeAddUrl"
import WelcomeRecs from "./components/Welcome/WelcomeRecs"
import WelcomeReadArts from "./components/Welcome/WelcomeReadArts"
import WelcomeListenArt from "./components/Welcome/WelcomeListenArt"
import WelcomeVocabQuiz from "./components/Welcome/WelcomeVocabQuiz"
import WelcomePlaylist from "./components/Welcome/WelcomePlaylist"
import WelcomeFirstCluster from './components/Welcome/WelcomeFirstCluster'


const firebaseConfig = {
  apiKey: "AIzaSyDmJCy7VmsU7x27MaH6Pmy44dpsQc7PEMM",
  authDomain: "langalearn-335922.firebaseapp.com",
  projectId: "langalearn-335922",
  storageBucket: "langalearn-335922.appspot.com",
  messagingSenderId: "839878569942",
  appId: "1:839878569942:web:9a5f1fc3f79e1d3f6c3e77",
  measurementId: "G-1GGJJTGWV6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const dexdb = new Dexie('myDatabase');

dexdb.version(6).stores(
  { 
    gutenberg: "++id, url, title, origin_text, translation",
    gutenbergIdx: "++id, url, sentIdx",
    artSentIdx1:  "id, sentIdx",
    playlist: "id, aid, art_date, art_id, lang, timestamp, title, playlist_position",
    articles: "id, url, title, date, read, playlist, user_vocab_sentences",
    recommendations: "id, aid, articles, cluster_id, lang, rec_date",
    clusters:  "id, cluster_name, active, lang, translated_arts",
    member: "id, aid, email, name, langs, native_lang, fcm_token, role, country, time_zone, badge_count",
    library: "id, url, title, lang",
    art_texts: "id, art_text",
    progress_months:  "id, days",
    vocab_media: "id, vocab_images, vocab_audio",
    langVoice: "id, index, rate",
    goals: "id, quiz_goal, read_goal, listen_goal, active, lang, aid",
    quiz: "id, aid, answer_count, answered, correct_count, date, end_time, lang, quiz_active, quiz_time_zone, quiz_type, source_lang, source_word, start_time,  target_lang, target_word, token, tz_offset, user_vocab_word_id"
  }
)

// dexdb.tables.forEach(function (table) {
//   console.log(table);
// });

// dexdb.delete().then(function() {
//   console.log('Database successfully deleted: ');
// }).catch(function (err) {
//   console.error('Could not delete database: ', err);
// }).finally(function() {
//   console.log('Done. Now executing callback if passed.');
//   if (typeof cb === 'function') {
    
//   }
// });

const theme = createTheme(purpleTheme)

export const routes = [
  { path: "/", component: Signin, exact: true },
  { path: "/user",  component: User, exact: false },
  { path: "/signin",  component: Signin, exact: false },
  { path: "/signup",  component: SignupAll, exact: false },
  { path: "/auth",  component: Auth, exact: false },
  { path: "/welcome",  component: Welcome, exact: false },
  { path: "/welcome_add_urls",  component: WelcomeAddUrl, exact: false },
  { path: "/welcome_recs",  component: WelcomeRecs, exact: false },
  { path: "/welcome_read_arts",  component: WelcomeReadArts, exact: false },
  { path: "/welcome_listen_arts",  component:   WelcomeListenArt, exact: false },
  { path: "/welcome_quiz",  component:   WelcomeVocabQuiz, exact: false },
  { path: "/welcome_playlist",  component:   WelcomePlaylist, exact: false },
  { path: "/welcome_first_cluster",  component:   WelcomeFirstCluster, exact: false },
]

export default function App(){
    return( 
    <ThemeProvider theme={theme}> 
      <Suspense fallback={<div></div>}>
        <Switch key="router">
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </ThemeProvider>
  )
}
