import React from "react";

import Button from '@mui/material/Button'
import grey from '@mui/material/colors/grey'

const SelectButton = ({
  actionFunc, 
  title,
  selectedQuizType,
  quizType,
}) => {
  const bkgdColor = selectedQuizType===quizType ? '#6415ff' : grey[300]
  const hoverbkgdColor = selectedQuizType===quizType ? '#5010CC' : grey[400]
  const textColor = selectedQuizType===quizType ? 'white' : grey[900]
  return  <Button 
            variant="contained" 
            onClick={actionFunc}
            fullWidth
            sx={{
              color: textColor,
              backgroundColor: bkgdColor ,
              '&:hover': {
                backgroundColor: hoverbkgdColor,
              },
            }}
          >
            {title}
          </Button>
}

export default SelectButton