import React from "react";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button'
import red from '@mui/material/colors/red'


export default function ConfirmDelete({
    title, 
    deleteAction,
    confirm,
    closeConfirm,
    question,
    message,
    disagree,
    agree,
  }){

    return  <Dialog onClose={closeConfirm} open={confirm}>
              <DialogTitle sx={{color: '#6415ff' }}>
                {title}
              </DialogTitle>
                <DialogContent >
                  <DialogContentText  sx={{color: red['A400']}}>
                    {question}
                  </DialogContentText>
                  <DialogContentText >
                    {message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={deleteAction} sx={{color: red['A400']}} >
                    {agree}
                  </Button>
                  <Button onClick={closeConfirm} sx={{color: '#6415ff' }}  autoFocus>
                    {disagree}
                  </Button>
                </DialogActions>
            </Dialog>
}