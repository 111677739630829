import React from "react";

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Box, CircularProgress, Tooltip } from "@mui/material";
import Card from '@mui/material/Card'
import Divider from "@mui/material/Divider"

import AddIcon from '@mui/icons-material/Add'
import PlaylistPlay from '@mui/icons-material/PlaylistPlay'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import TranslateIcon from '@mui/icons-material/Translate'
import CheckIcon from "@mui/icons-material/Check";
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import TooltipButton from "../Buttons/TooltipButton";

import PaginationController from '../VocabQuiz/PaginationController'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import AdSidebar from "../Ads/AdSidebar"
import FlagRound from '../ImageElements/FlagRound'
import CurrentSentence from '../Player/CurrentSentence'
import PlayerInterface from '../Player/PlayerInterface'

export default function PlayArticlePage({
    lang,
    loading,
    snack,
    msgType,
    alertMsg,
    handleClose,
    errorMsg,
    isError,
    searchresults,
    sentIdx,
    translate,
    forward,
    back,
    title,
    transloading,
    origText,
    curTranslation,
    adReload,
    handleSource,
    handleTarget,
    sourceWord,
    targetWord,
    addVocab,
    vocabloading,
    read,
    playlist,
    changePlaylist,
    updatePlaylist,
    speakText,
    voices,
    voiceIdx,
    handleVoice,
    ratestr,
    slower,
    faster,
    playing,
    lisetenedSentsIdx,
    sentsCount,
}){
   
    const transload = transloading 

    const vocabdisableed = sourceWord.includes('Select') | targetWord.includes('Select') | targetWord.length===0 | sourceWord.length===0
 
    return( 
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                      
                          <Card  > 
                            <Grid container 
                                  justifyContent="space-between" 
                                  alignItems="center" 
                            >
                            <Grid item xs={12}> 
                              <Box sx={{m:1.5}}>
                                <Grid container 
                                    alignItems='center'
                                    spacing={1}
                                >
                                  <Grid item xs={1} zeroMinWidth>
                                    <FlagRound 
                                        flagSize='small'
                                        lang={lang}
                                    />
                                  </Grid>
                                  <Grid item xs={11} zeroMinWidth> 
                                    <Typography variant="h5" >
                                        {title}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12}> 
                              <Divider />
                            </Grid>
                            <Grid item xs={12}> 
                              <Grid container 
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{mt:1, mb:1}}
                              >
                                <Grid item > 
                                    <TooltipButton
                                        title={playlist ? 'Remove Playlist' : 'Add Playlist'}
                                        disabled={false}
                                        func={changePlaylist}
                                        color={playlist ? "error" : "inherit"}
                                        icon={<PlaylistPlay />}
                                    />
                                </Grid>
                                <Grid item > 
                                    <TooltipButton
                                        title={read ? 'Read' : 'Mark Read'}
                                        disabled={false}
                                        func={updatePlaylist}
                                        color={read ? "success" : "inherit"} 
                                        icon={read ?  <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                                    />
                                </Grid>
                                <Grid item > 
                                  <TooltipButton
                                    title='Translate'
                                    disabled={transload}
                                    func={translate}
                                    icon={transloading ?  <CircularProgress size={25} /> : <TranslateIcon />}
                                  />
                                </Grid>
                                <Grid item>
                                  <PlayerInterface
                                      voices={voices}
                                      voiceIdx={voiceIdx}
                                      handleVoice={handleVoice}
                                      ratestr={ratestr}
                                      slower={slower}
                                      faster={faster}
                                      playing={playing}
                                      speakText={speakText}
                                      minSpeed={0.1}
                                      maxSpeed={1.0}
                                    
                                  />
                                </Grid>
                                <Grid item>
                                  <Grid container 
                                      spacing={2} 
                                      alignItems='center'
                                      justifyContent='space-between'
                                  >
                                     <Grid item >
                                      {lisetenedSentsIdx.includes(sentIdx) ? 
                                        <Tooltip title="Sentence Played">
                                          <CheckIcon color='success' />
                                        </Tooltip>
                                        :
                                        <Box width={24} />
                                      }  
                                     </Grid> 
                                    <Grid item > 
                                      <Typography variant="body1" color="initial">
                                          {lisetenedSentsIdx.length} / {sentsCount}
                                      </Typography>
                                    </Grid>  
                                  </Grid>
                                 </Grid>
                                <Grid item > 
                                  <PaginationController  
                                    page={sentIdx}
                                    maxPage={searchresults.length}
                                    handleBack={back}
                                    handleForward={forward}
                                  />
                                </Grid> 
                              </Grid> 
                            </Grid> 
                            {curTranslation.length>0 &&
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            }   
                            {curTranslation.length>0 &&
                              <Grid item xs={12}>
                                <Grid container 
                                      spacing={2} 
                                      alignItems='center'
                                      justifyContent='space-between'
                                >
                                  <Grid item >
                                      <Typography variant="h6">
                                        <b>Source:</b> {sourceWord}
                                      </Typography>
                                    </Grid>
                                    <Grid item >
                                      <Typography variant="h6">
                                        <b>Target:</b> {targetWord}
                                      </Typography>
                                    </Grid>
                                    <Grid item >
                                      {!vocabdisableed ? 
                                        <LoadingIconButton
                                          size="small"
                                          title='Add Vocabulary'
                                          onClick={addVocab}
                                          icon={<AddIcon />}
                                          loading={vocabloading}
                                          fullWidth={false}
                                        />
                                        : 
                                        <Button variant='contained' disabled={true} size="small" startIcon={<AddIcon />}>
                                          Add Vocabulary'
                                        </Button>
                                      }
                                    </Grid>
                                  </Grid>                               
                              </Grid>     
                            }
                            </Grid>
                          </Card >
                      {origText.length>0 && 
                        <Box sx={{mt: 4}}>
                          {loading ? 
                            <CircularProgress />
                            :
                            <CurrentSentence
                              origText={origText}
                              curTranslation={curTranslation}
                              handleSource={handleSource}
                              handleTarget={handleTarget}
                              showTranslation={curTranslation.length>0}
                            />
                          }
                        </Box>
                      }
                      {!loading && isError &&   
                        <Alert severity="error" sx={{mt:2}}>
                          {errorMsg}
                        </Alert>
                      }
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />

                  </Container>
                  </Grid>
                  <Grid item>
                    {adReload ?
                       <AdSidebar />
                      :
                      <div style={{minWidth: 300}} />
                    }
                  </Grid>
              </Grid>
        
    )
}