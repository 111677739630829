import React from 'react'
import { Link } from 'react-router-dom'

import { langSwitch } from '../../utils'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import FlagRound from '../ImageElements/FlagRound'

export default function QuizCard({lang}){
    const language = langSwitch(lang)
    const flagSize='large'
    const link = `/user/vocab_test_lang/${lang}`
    return(
        <Grid item > 
            <Link sx={{textDecoration: 'none'}} to={link}>
                <Button 
                    sx={{
                        marginTop:1, 
                        backgroundColor: '#6415ff', 
                        color: 'white',
                    }} 
                    variant="contained" >
                    <Grid   container 
                            spacing={2}
                            alignItems="center"
                            style={{padding:10}}
                    >
                        <Grid item>
                            <FlagRound 
                                flagSize={flagSize}
                                lang={lang}
                            />
                        </Grid>
                        <Grid item>
                            <Typography  variant='h5'>
                                {language}
                            </Typography>
                            
                        </Grid>
                    </Grid>   
                </Button>
            </Link> 
        </Grid> 
    )
}
