import React from 'react'
import useWindowDimensions from '../../helper'

import Grid from '@mui/material/Grid'
import { Button, TextField} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingIconButton from '../Buttons/LoadingIconButton'
import AddIcon from '@mui/icons-material/Add';

const AddUrlForm = ({
    url, 
    handleUrl, 
    addUrl, 
    buttonLoading,
    toggleUrl,
}) => {
    
    const {width}= useWindowDimensions()
 
    const fieldwidth = width*.5
    return <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <TextField
            style={{width: fieldwidth}}
            size="small"
            variant="outlined"
            required
            name="url"
            label='Url'
            id="url"
            onChange={handleUrl}
            value={url}
          />
      </Grid>
      <Grid item>
        <LoadingIconButton 
            loading={buttonLoading}
            title="Add"
            onClick={addUrl}
            icon={<AddIcon />}
        />  
      </Grid>
      <Grid item>
        <Button 
            onClick={toggleUrl} 
            startIcon={<Close />}  
            variant='contained' 
            color='error' 
        > 
            Close
        </Button>
       
      </Grid>
    </Grid>
  }
  
  export default AddUrlForm