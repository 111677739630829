import React from 'react'
import TextField from '@mui/material/TextField'

const WordInput = ({
    onChange,
    value,
    id,
    label,
    submitFunc,
  }) => {
    const submitAnswer = event => {
      if (event.key === 'Enter') {
        submitFunc()
      }
    }

    return <TextField
            size="small"
            variant="outlined"
            onKeyDown={submitAnswer}
            required
            fullWidth={true}
            name={id} 
            label={label}
            id={id} 
            onChange={onChange}
            value={value}
          />
  }

export default WordInput