export const signup = [ 
    {title: 'First Cluster', 'image': '/firstcluster.gif', 'instructions': [
      {step: 'Create name for your cluster (eg Tech, Movies, Investing...)', image: ''},
      {step: 'Add a url related to that topic', image: ''},
      {step: 'Check url has parsed correctly', image: ''},
      {step: 'Add at least 3 urls', image: ''},
      {step: 'Generate related article recommendations in the language you are learning.', image: ''},
    ], videoId: 'WihIBqaKFcA',},
  ]
  
  export const categories = [
    {title: 'Recieve Recommendations', 'image': '/receiverecs.gif', 'instructions': [
      {step: 'Go to Cluters from the Nav Bar', image: ''},
      {step: 'Click the toggle switch next to the cluster you want to receive recommendations from.', image: ''},
      {step: 'You can also toggle recommendations from cluster page.', image: ''},
      {step: 'You can only have 3 active clusters for recommendations at a time.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Recommendations', 'image': '/recs.gif', 'instructions': [
      {step: 'Go to Recommendations from the Nav Bar', image: ''},
      {step: 'Filter your recommendations by cluster.', image: ''},
      {step: 'Click on a article link to read the article.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Search Recommendation History', 'image': '/searchrecs.gif', 'instructions': [
      {step: 'Go to Recommendations History from the Recommendations Page', image: ''},
      {step: 'Select a date and click search.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Read Article', 'image': '/readart.gif', 'instructions': [
      {step: 'Click a link from your recommendations', image: ''},
      {step: 'Read the article in a webview.', image: ''},
      {step: 'Add the article to your playlist to listen to it on your mobile device.', image: ''},
      {step: 'Marked the article "read".', image: ''},
      {step: 'Select an unfamiliar word and translate it.', image: ''},
      {step: 'Show all the words you have translated for this article.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Vocabulary Quiz', 'image': '/webtakequiz.gif', 'instructions': [
      {step: 'Click "Start Quiz."', image: ''},
      {step: 'Select Quiz Type (Random, Unanswered, Recently Answered...).', image: ''},
      {step: 'Enter the translation of the word in your native language.', image: ''},
      {step: 'Click on a word from the answer list to edit the vocabulary word.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Edit Mobile Quiz', 'image': '/editquiz.gif', 'instructions': [
      {step: 'Go to Quiz from the Nav Bar', image: ''},
      {step: 'If you have downloaded, the mobile app (ios/android), you can receive a vocabulary quiz every hour within a time frame you choose.', image: ''},
      {step: 'Click the switch to activate or deactivate the quiz notifications.', image: ''},
      {step: 'Click the "Edit" Button.', image: ''},
      {step: 'To set the timeframe you want to receive notifications, select a start time, end time and timezone from the drop down menus.', image: ''},
      {step: 'Choose the quiz type for the quiz notifications.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Playlist', 'image': '/webplaylist.gif', 'instructions': [
      {step: 'Add or remove articles from the playlist.', image: ''},
      {step: 'Go to Playlist from the Nav Bar', image: ''},
      {step: 'Reorder the playlist', image: ''},
      {step: 'Remove items from the playlist by clicking the red "trash can"', image: ''},
      {step: 'Review articles you have already listened to.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Vocabulary Word', 'image': '/webvocabword.gif', 'instructions': [
      {step: 'Go to Vocabulary from the Nav Bar', image: ''},
      {step: 'Select a word', image: ''},
      {step: 'Edit word and the translation.', image: ''},
      {step: 'Review your quiz performance', image: ''},
      {step: 'See sentences that use the word.', image: ''},
      {step: 'Add a sentence that use the word of your own.', image: ''},
      {step: 'See the article the word originally appeared in.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Add Vocabulary Word', 'image': '/addvocab.gif', 'instructions': [
      {step: 'Go to Vocabulary from the Nav Bar', image: ''},
      {step: 'Type in the source (learning language) word and target word (native language)', image: ''},
      {step: 'Press "Add" button.', image: ''},
      {step: 'Review the vocabulary word.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Search Vocabulary Word', 'image': '/searchvocab.gif', 'instructions': [
      {step: 'Go to Vocabulary from the Nav Bar', image: ''},
      {step: 'Type in either the source (learning language) word or target word (native language)', image: ''},
      {step: 'Select which language for the word above.', image: ''},
      {step: 'Click "Search" button.', image: ''},
      {step: 'Click on the link in the results to go to the word.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Progress', 'image': '/progress.gif', 'instructions': [
      {step: 'Go to Progress from the Nav Bar', image: ''},
      {step: 'Review how quizzes you have taken and articles read for the month.', image: ''},
      {step: 'See how many quizzes and articles were completed for each day.', image: ''},
      {step: 'Navigate to review your progress for different months.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Review Cluster', 'image': '/webcluster.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Select Cluster from the list.', image: ''},
      {step: 'Review the cluster links for that cluster.', image: ''},
      {step: 'Review current recommendations from the cluster.', image: ''},
      {step: 'You choose receive recommendations from this cluster by toggling the switch.', image: ''},
      {step: 'Type in url to create a new cluster link (max 10 per cluster).', image: ''},
      {step: 'Delete the cluster. This will delete all cluster links. If you do not want to receive recommendations from this cluster, simply toggle the switch rather than delete the cluster.', image: ''},
      {step: 'Search your recommendation history.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Add Cluster', 'image': '/addclusterandlink.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Type in a name for your cluster and click the add button.', image: ''},
      {step: 'On the cluster page, paste a link to an article related to the cluster and click the "Add" button.', image: ''},
      {step: 'Review the article text parsed correctly and, if so, click "Add"', image: ''},
      {step: 'Add at least 3 links for quality recommendations. You can add up to 10 urls per cluster.', image: ''},
      {step: 'The url text may not parse correctly if the url is password protected or not public accessible.', image: ''},
      {step: 'If the url text did not parse correct, you can either copy and paste the text with "Add Article Text" or visit the page and use chrome extension to add the page to the cluster.', image: ''},
      {step: 'You can create as many clusters as you want, but you can only have 3 active clusters for recommendations at a time.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Add Cluster Link', 'image': '/addclusterandlink.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Type in a name for your cluster and click the add button.', image: ''},
      {step: 'On the cluster page, paste a link to an article related to the cluster and click the "Add" button.', image: ''},
      {step: 'The url text may not parse correctly if the url is password protected or not public accessible.', image: ''},
      {step: 'Review the article text parsed correctly and, if so, click "Add"', image: ''},
      {step: 'If the url text did not parse correct, you can either copy and paste the text with "Add Article Text" or visit the page and use chrome extension to add the page to the cluster.', image: ''},
      {step: 'You can add 10 links per cluster.', image: ''},
      {step: 'Other ways to add links to clusters: Add from your browser with the chrome extension or share a page from another app on your mobile device.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Add Cluster Text', 'image': '/addclustertext.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Select the cluter.', image: ''},
      {step: 'Copy and past the article title, url and article text', image: ''},
      {step: 'Click "Add"', image: ''},
      {step: 'Article must be less than 7,000 characters.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
  ]
  
  export const mobilecategories = [
    {title: 'Recieve Recommendations', 'image': '/mobilereceiverecs.gif', 'instructions': [
      {step: 'Go to Cluters Screen', image: ''},
      {step: 'Click the toggle switch next to the cluster you want to receive recommendations from.', image: ''},
      {step: 'You can also toggle recommendations from cluster page.', image: ''},
      {step: 'You can only have 3 active clusters for recommendations at a time.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Search Recommendation History', 'image': '/mobilerechistory.gif', 'instructions': [
      {step: 'Go to Recommendations History from the Recommendations Page', image: ''},
      {step: 'Select a date and click search.', image: ''},
    ], route: 'BMfG0ow9IOY',},
    {title: 'Read Article', 'image': '/mobilereadart2.gif', 'instructions': [
      {step: 'Click on link in your recommendations', image: ''},
      {step: 'Read the article in a webview.', image: ''},
      {step: 'Add the article to your playlist to listen to it on your mobile device.', image: ''},
      {step: 'Marked the article "read".', image: ''},
      {step: 'Select an unfamiliar word and translate it.', image: ''},
      {step: 'Listen to the article tapping the play button.', image: ''},
    ], videoId: 'BMfG0ow9IOY',},
    {title: 'Listen to Article', 'image': '/mobileplayart.gif', 'instructions': [
      {step: 'From an Article Screen, tap the green play button (arrow).', image: ''},
      {step: 'The article will read, displaying each sentence.', image: ''},
      {step: 'You can navigate foward or backward through the sentences by tapping the arrows on either  side of the sentence count.', image: ''},
      {step: 'You can choose the voice by tapping on the name at the bottom.', image: ''},
      {step: 'You slow down or speed up the pace of reading by tapping the buttons on either side o the speed indicator (1.0x).', image: ''},
      {step: 'You can translate the current sentence by tapping the "Translate" button. You can select speciic words in the sentence to add to your vocabulary list.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Vocabulary Quiz', 'image': '/mobiletakequiz1.gif', 'instructions': [
      {step: 'Select Quiz Type (Random, Unanswered, Recently Answered...)', image: ''},
      {step: 'Translate the word to your native language', image: ''},
      {step: 'Tap on "Answers" to see a list of your previously answers.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Notifications Quiz', 'image': '/mobilesentquiz.gif', 'instructions': [
      {step: 'Go to Vocabulary Quiz from the Nav Bar', image: ''},
      {step: 'Toggle on or off to receive hourly push notification vocabulary quizzes', image: ''},
      {step: 'Edit the time frame you want to receive notifications (Between 8am and 6pm PST)', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Edit Mobile Quiz', 'image': '/mobileeditquiz.gif', 'instructions': [
      {step: 'You can receive a vocabulary quiz every hour within a time frame you choose.', image: ''},
      {step: 'Tap the switch to activate or deactivate the quiz notifications.', image: ''},
      {step: 'Tap the time frame or quiz type to edit.', image: ''},
      {step: 'To set the timeframe you want to receive notifications, select a start time, end time and timezone from the drop down menus.', image: ''},
      {step: 'Choose the quiz type for the quiz notifications.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Vocabulary Word', 'image': '/mobilevocabbasic.gif', 'instructions': [
      {step: 'Go to Vocabulary from the Nav Bar', image: ''},
      {step: 'Select a word', image: ''},
      {step: 'Review your quiz performance', image: ''},
      {step: 'Listen to the word spoken in different voices.', image: ''},
      {step: 'See sentences that use the word.', image: ''},
      {step: 'See the article the word originally appeared in.', image: ''},
      {step: 'Edit word and the translation.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Review Cluster', 'image': '/mobilecluster.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Select Cluster from the list.', image: ''},
      {step: 'Review the cluster links for that cluster.', image: ''},
      {step: 'Review current recommendations from the cluster.', image: ''},
      {step: 'You choose receive recommendations from this cluster by toggling the switch.', image: ''},
      {step: 'Type in url to create a new cluster link (max 10 per cluster).', image: ''},
      {step: 'Delete the cluster. This will delete all cluster links. If you do not want to receive recommendations from this cluster, simply toggle the switch rather than delete the cluster.', image: ''},
      {step: 'Search your recommendation history.', image: ''},
    ], route: '5R3hqyi3Mas',},
    {title: 'Add Cluster', 'image': '/mobileaddcluster.gif', 'instructions': [
      {step: 'Go to Clusters from the Nav Bar', image: ''},
      {step: 'Type in a name for your cluster and click the add button.', image: ''},
      {step: 'On the cluster page, paste a link to an article related to the cluster and click the "Add" button.', image: ''},
      {step: 'Review the article text parsed correctly and, if so, click "Add"', image: ''},
      {step: 'Add at least 3 links for quality recommendations. You can add up to 10 urls per cluster.', image: ''},
      {step: 'The url text may not parse correctly if the url is password protected or not public accessible.', image: ''},
      {step: 'If the url text did not parse correct, you can either copy and paste the text with "Add Article Text" or visit the page and use chrome extension to add the page to the cluster.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Add Cluster Link', 'image': '/mobileaddclusterlink.gif', 'instructions': [
      {step: 'Go to a clusters', image: ''},
      {step: 'Tap the "Add Url" Button', image: ''},
      {step: 'Type or past a url and tap "Add Url"', image: ''},
      {step: 'Review the article text parsed correctly and, if so, click "Add"', image: ''},
      {step: 'If the url text did not parse correct, you can either copy and paste the text with "Add Article Text" or visit the page and use chrome extension to add the page to the cluster.', image: ''},
      {step: 'You can add 10 links per cluster.', image: ''},
      {step: 'Other ways to add links to clusters: Add from the website, add from your browser with the chrome extension or share a page from another app on your mobile device.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Add Cluster Link By Sharing Article From Another App', 'image': '/sendartclusterlink.gif', 'instructions': [
      {step: 'From another app (possibly a news app), go to an article you want to add to a cluster.', image: ''},
      {step: 'Tap share icon on the page.', image: ''},
      {step: 'Select the Langa Learn App from the bottom sheet that appears.', image: ''},
      {step: 'The url will appear in the Langa Learn App.', image: ''},
      {step: 'Select Cluster you want to add the url to.', image: ''},
      {step: 'Check that the article text parsed correctly.', image: ''},
      {step: 'If so, click "Texts Looks Fine" and the url will be added to your cluster.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Progress', 'image': '/mobileprogress.gif', 'instructions': [
      {step: 'Go to Progress from the Nav Bar', image: ''},
      {step: 'Review how quizzes you have taken and articles read for the month.', image: ''},
      {step: 'See how many quizzes and articles were completed for each day.', image: ''},
      {step: 'Navigate to review your progress for different months.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Listen to Playlist', 'image': '/mobileplayplaylist.gif', 'instructions': [
      {step: 'Go to Playlist Screen', image: ''},
      {step: 'Tap "Play Playlist"', image: ''},
      {step: 'Tap the Play (Green Arrow) button.', image: ''},
      {step: 'Article will begin playing (see "Listen to Article" above). If the Autoplay switch is on, the next article in the playlist will begin playing automatically after current article is finished.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Review Played Articles ', 'image': '/mobileplayedlist.gif', 'instructions': [
      {step: 'Go to Playlist Screen', image: ''},
      {step: 'Tap "Played Articles"', image: ''},
      {step: 'You can review a list articles you have already listened to.', image: ''},
    ], videoId: '5R3hqyi3Mas',},
    {title: 'Camera Translation', 'image': '/mobilecameratrans.gif', 'instructions': [
      {step: 'Click "Camera Translation"', image: ''},
      {step: 'Take picture of text in the language you are learning.', image: ''},
      {step: 'Click "Use Image"', image: ''},
      {step: 'The text of the image will appear below.', image: ''},
      {step: 'Highlight a word and click "Translate"', image: ''},
      {step: 'The translation will appear next to word in the text.', image: ''},
      {step: 'The translation will automatically be added to your vocabulary.', image: ''},
    ], videoId: 'WihIBqaKFcA',},
  ]
  
  export const extcategories = [
    {title: 'Translate Word',  'image': '/ext-translateselect.gif', 'instructions': [
      {step: 'Click sign up', image: '/adcluterinks1.jpg'},
      {step: 'Enter Email and Password',image: '/adcluterinks1.jpg'},
      {step: 'Check for email verfication', image: '/adcluterinks1.jpg'},
      {step: 'Complete profie section', image: '/adcluterinks1.jpg'},
      {step: 'Create first Cluster', image: '/adcluterinks1.jpg'},
    ], videoId: '7XoxCDlMKJo',},
    {title: 'Add Url To Cluster', 'image': '/ext-addclusterlink3.gif', 'instructions': [
      {step: 'Navigate to the page you want to add to cluster.', image: ''},
      {step: 'Click the extension icon.', image: ''},
      {step: 'Select the Cluster from the dropdown.', image: ''},
      {step: 'Click "Add Page to Cluster" Button.', image: ''},
      {step: 'A new window will open with the parsed text.', image: ''},
      {step: 'Edit the parsed text to what you want..', image: ''},
      {step: 'Click the "Add Article" Button', image: ''},
      {step: 'The new page will appear in the cluster links list on the Cluster Page.', image: ''},
    ], videoId: 'WihIBqaKFcA',}
  ]
  
  export const sitetypes = [categories, mobilecategories, extcategories]
  
  export const typeNames = ['Website', 'Mobile (ios/android)','Chrome Extension']