import React from "react";

import TextField from '@mui/material/TextField'

const ClusterInput = ({
    error, 
    errorMsg, 
    value, 
    label, 
    handleFunc,
    submitFunc,
  }) => 
    <TextField 
      fullWidth
      variant="outlined"
      error={error}
      onKeyDown={submitFunc}
      required 
      id="standard-required" 
      label={label}
      value={value} 
      onChange={handleFunc} 
      helperText={errorMsg}
      size="small"
    />

export default ClusterInput

  