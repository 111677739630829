import React, { useEffect, useMemo } from 'react'
import { useLiveQuery } from "dexie-react-hooks";
import { dexdb } from '../App';
import { checkQuizCount } from '../utils';
import VocabTestStartPage from '../components/Pages/VocabTestStartPage'

export default function VocabTestStart({
  history
}){


  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const langs = useMemo(() => member ? member[0].langs : [], [member]);

  useEffect(() => {
    checkQuizCount()
    if (langs.length===1){ 
      history.push(`/user/vocab_test_lang/${langs[0]}`)
    }
  },[langs, history])

  return <VocabTestStartPage langs={langs} />
}