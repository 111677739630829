import React, {useState} from 'react'

import {analytics, dexdb} from '../../App'

import { 
  langSwitch, 
  langs as offeredLangs,
} from '../../utils'

import { 
    logEvent, 
} from "firebase/analytics";

import {setLoginData} from '../../pages/Signin'

import {updateMember} from '../../queries/memberQueries'
import {deactivateOtherLangs} from '../../queries/quizQueries'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider';
 
import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import LangSelectButton from '../Buttons/LangSelectButton'

export default function UpdateLangs({ 
  langs, 
  role,
  native_lang, 
}){

    const userLangs = langs ? langs : []
    const [ newLangs, setNewLang ] = useState(userLangs )
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const handleLang1 = (lang) => setNewLang([lang])

    const deactivateClusters = async curLang => {
      const queryItems = await dexdb.clusters.toArray()
      const curActiveItems = queryItems ? queryItems.filter(c => c.active && c.lang !== curLang) : []
      if(role==='basic' && curActiveItems.length>0){
        await deactivateOtherLangs({
          curLang, 
          table: 'clusters', 
          field: 'active',
        })
      }
    }

    const deactivateQuizzes = async curLang => {
      const queryItems = await dexdb.quiz.toArray()
      const curActiveItems = queryItems ? queryItems.filter(c => c.quiz_active && c.lang !== curLang) : []
      if(role==='basic' && curActiveItems.length>0){
        await deactivateOtherLangs({
          curLang, 
          table: 'quiz', 
          field: 'quiz_active',
        })
      }
    }
  
    const updateLangs = async () => {
      setLoading(true)
  
      try {
        await updateMember({
          langs: newLangs,
        })
        showSnack('success', 'Updated languages!')
        const curLang = newLangs[0]
        await deactivateClusters(curLang)
        await deactivateQuizzes(curLang)
        await setLoginData()
        logEvent(analytics, 'change_lang', {
          old_lang: userLangs[0],
          new_lang: newLangs[0],
          device: 'web',
        })
      } catch(error){
        showSnack('error', 'Error updating languages!')
      } finally {
        setLoading(false)
      }
    }
    
    const availableLangs = offeredLangs.filter(l => l.lang !== native_lang)

    return(
    <>
    <Card>
       <CardContent>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item >
            <Typography variant={'h5'} sx={{color:'#6415ff'}}>
              Target Language
            </Typography>
          </Grid> 
          <Grid item >
            <LoadingIconButton
              loading={loading}
              icon={<EditIcon />}
              onClick={updateLangs} 
              title='Update Target Language'
            />
          </Grid> 
        </Grid> 
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2} justifyContent='space-between'>
          {availableLangs.map((l,i) =>  
            <LangSelectButton 
              width={200}
              key={i}
              handleLang={() => handleLang1(l.lang)}
              lang={l.lang}
              newLangs={newLangs}
              language={langSwitch(l.lang)}
            />)}
        </Grid> 
      </CardContent>
    </Card>
    <SnackAlert 
      snack={snack}
      handleClose={handleClose}
      msgType={msgType}
      alertMsg={alertMsg}
    />
    </>
  )
}


