import  React, { useState } from 'react'

// packages
import { 
    useHistory,
    useLocation
} from "react-router-dom"

// DATA

// client
import { supabase } from '../../supabase'
import {dexdb} from '../../App'

import { getTranslations } from '../../utils'

// UI
// mui

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import TranslationIcon from '@mui/icons-material/Translate'
import ListAlt from '@mui/icons-material/ListAlt'
import PlaylistPlay from '@mui/icons-material/PlaylistPlay'
import GroupWork from '@mui/icons-material/GroupWork'
import Home from '@mui/icons-material/Home'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

// custom components
import ResponsiveAppBarComponents from './ResponsiveAppBarComponent'

export const clearUserData = async () => {
    localStorage.setItem('count', '0')
    await dexdb.recommendations.clear()
    await dexdb.articles.clear()
    await dexdb.playlist.clear()
    await dexdb.clusters.clear()
    await dexdb.quiz.clear()
    await dexdb.progress_months.clear()
    await dexdb.goals.clear()
  }

export const logoutFunc = async (history) => {
    const { error } = await supabase.auth.signOut()
    if(error){
      return
    }
    await clearUserData()
    history.push({pathname: `/auth/signin`})
  }

const siteTitle =  'Langa Learn'

const ResponsiveAppBar = () => {

    let history = useHistory()

    const { pathname } = useLocation();

    const firstclustpath = pathname === '/user/first_cluster'

    const curLang = getTranslations()

    const section1 = firstclustpath ? [] : [ 
        {
            title: curLang.siderecs, 
            link:"/user/recommendations", 
            component:<ListAlt />,
        },
        {
            title: curLang.clusters, 
            link:"/user/clusters",
            component:<GroupWork />,
        },
        {
            title: curLang.vocab, 
            link: '/user/vocabstart', 
            component:<TranslationIcon />,
        },
        {
            title: 'Quiz', 
            link: '/user/vocabteststart', 
            component:<QuestionAnswerIcon />,
        },
        {
            title: curLang.playlist,
            link: "/user/playlist",
            component:<PlaylistPlay />,
        },
        {
            title: 'Progress',
            link: "/user/progress",
            component:<EventAvailableIcon />,
        },
     ]

     const section2 = firstclustpath ? [] : [ 
        {
            title: 'Dashboard', 
            link:"/user/dashboard", 
            component:<Home />,
        },
        {
            title: curLang.siderecs, 
            link:"/user/recommendations", 
            component:<ListAlt />,
        },
        {
            title: curLang.clusters, 
            link:"/user/clusters",
            component:<GroupWork />,
        },
        {
            title: curLang.vocab, 
            link: '/user/vocabstart', 
            component:<TranslationIcon />,
        },
        {
            title: 'Quiz', 
            link: '/user/vocabteststart', 
            component:<QuestionAnswerIcon />,
        },
        {
            title: curLang.playlist,
            link: "/user/playlist",
            component:<PlaylistPlay />,
        },
        {
            title: 'Progress',
            link: "/user/progress",
            component:<EventAvailableIcon />,
        },
     ]

    const profileButtons = [
        {   
            title: curLang.profile, 
            link:"/user/profile", 
        },
        {
            title: 'Help', 
            link:"/user/tutorial", 
        },
        {
            title: 'Support', 
            link:"/user/support", 
        },
    ]

    // state
    const [ anchorElNav, setAnchorElNav] = useState(null)
    const [ anchorProfileElNav, setAnchorProfileElNav] = useState(null)
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)
    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget)
    const handleCloseNavMenu = () => setAnchorElNav(null)

    const handleOpenProfileMenu = (event) => setAnchorProfileElNav(event.currentTarget)
    const handleCloseProfileMenu = () => setAnchorProfileElNav(null)

    const logout = async () => await logoutFunc(history);

    return <ResponsiveAppBarComponents
                buttons={section1}
                profileButtons={profileButtons}
                siteTitle={siteTitle}
                anchorElNav={anchorElNav}
                anchorProfileElNav={anchorProfileElNav}
                snack={snack}
                handleClose={handleClose}
                handleOpenNavMenu={handleOpenNavMenu}
                handleCloseNavMenu={handleCloseNavMenu}
                handleOpenProfileMenu={handleOpenProfileMenu}
                handleCloseProfileMenu={handleCloseProfileMenu}
                logout={logout}
                section2={section2}
            />
}

export default ResponsiveAppBar
