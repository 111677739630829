import React from "react";

import Button from '@mui/material/Button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'


const WelcomeButton = ({
    title,
    route,
    size='large',
    history,
}) =>

    <Button 
        variant="contained" 
        size={size}
        color="primary" 
        fullWidth
        endIcon={<ArrowForwardIcon />}
        onClick={() =>  history.push(route)}
        style={{
            backgroundColor:'#6415ff',
            '&:hover': {
              backgroundColor: '#5618d0',
            },
            color:'white'
          }}
        >
        Next - {title}
    </Button>

export default WelcomeButton