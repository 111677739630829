import {supabase} from "../supabase.js"

const table = 'service_desk'

// MUTATIONS
export const createSupportRequest =  async ({
    supportType,
    supportQuestion,
    auth_id,
    email,
}) => {
  
    const {data, error} = await supabase
      .from(table)
      .insert([
        {
          auth_id,
          email,
          type: supportType,
          issue: supportQuestion,
          resolved: false,
        },
      ])
      .select()

    if(error) throw new Error(error.message)

    return data
}

