import React from "react";

import {toMin} from '../../utils'

import Grid from '@mui/material/Grid'
import {  Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import { grey } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
const width = 100

const ProgressDate = ({
    quiz_count,
    arts_count,
    listen_count,
    activeGoals,
  }) => { 
    const quizGoal = activeGoals.length>0 ? activeGoals[0].quiz_goal : -1
    const readGoal = activeGoals.length>0 ? activeGoals[0].read_goal : -1
    const listenGoal = activeGoals.length>0 ? activeGoals[0].listen_goal : -1
    return(
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ProgressChip
                count={quiz_count}
                label={quizGoal!==-1 ? `${quiz_count}/${quizGoal}` : quiz_count}
                bkColor='#00695f'
                width={width}
                goal={quizGoal}
              />
            </Grid>
            <Grid item xs={12}>
              <ProgressChip
                count={arts_count}
                label={readGoal!==-1 ? `${arts_count}/${readGoal}` : arts_count}
                bkColor='#2a3eb1'
                width={width}
                goal={readGoal}
              />
            </Grid>
            <Grid item xs={12}>
              <ProgressChip
                  count={toMin(listen_count)}
                  label={listenGoal!==-1 ? `${toMin(listen_count)}/${listenGoal}` : toMin(listen_count)}
                  bkColor='#b12a3e'
                  width={width}
                  goal={listenGoal}
              />
            </Grid>
          </Grid>
    
  )}

  
  
  const ProgressItem = ({
    pastMonth,
    day,
    curDay,
    quiz_count,
    arts_count,
    listen_count,
    activeGoals,
  }) => {

    return <Grid item xs={4} >
            <Grid container spacing={2}>
              <Grid item>
                <Typography color={day <= curDay || pastMonth ? 'black' : grey[400]} >
                  {day.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}
                </Typography>
              </Grid>
              <Grid item >
                {day <= curDay || pastMonth ?
                  <ProgressDate  
                    quiz_count={quiz_count}  
                    arts_count={arts_count}
                    listen_count={listen_count}
                    activeGoals={activeGoals}
                  /> : <div></div>
                }
              </Grid>
            </Grid>
          </Grid>

  }
    
  export const ProgressChip = ({
    label,
    bkColor,
    width=60,
    count,
    goal,
    size='small'
  }) => 
      <Chip 
        label={label} 
        size={size}
        avatar={count>=goal && goal>0 ? 
          <Avatar sx={{ 
            bgcolor: bkColor, 
            color:'white', 
           }}>
            <CheckIcon color='check' />
          </Avatar> 
          : 
          null
        }
        sx={{
          backgroundColor: count>0 ? bkColor : 'grey', 
          color: count>0 ? 'white' : grey[500], 
          width: width,
        }} 
      />

export default ProgressItem