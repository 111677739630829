import React from "react";
// import { getTranslations } from '../../utils'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/Description'
import Divider from "@mui/material/Divider";
import Container from '@mui/material/Container'

import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import FlagRound from "../ImageElements/FlagRound"
import AdSidebar from "../Ads/AdSidebar"
import Error from '../Dialogs/Error'

import {
  LinkBasic, 
} from '../ListItems/BasicLinks'
import { CircularProgress } from "@mui/material";

export default function ClusterAddTextPage({ 
    clusterName, 
    lang, 
    count,
    url,
    title,
    text,
    loading,
    updating,
    handleText,
    updateText,
    cluster_id,
    clusterActive,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    checkItem,
    characterLimit,
    wordCount,
    buttonTitle,
    isError,
    instructions
 }){
  const loginError = 'Error adding url! Are you logged in with the same account as the extension?'

  if(isError) return <Error errorMsg={loginError} />

    const link = `/user/cluster/${cluster_id}/${clusterName}/${lang}/${count}/${clusterActive}`

    const charsOk = checkItem()
    const count1 = count && count.length<0 ? `${count} - `: ''

    const asdf = `${count1}${clusterName}`

    return <Grid container 
                 spacing={1}  
                 justifyContent='center' 
            >
              <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                  <Card sx={{ 
                          padding: 3,
                          marginBottom: 1,
                        }}
                  > 
                    <Grid container 
                          alignItems="center" 
                          spacing={2}
                    >
                      <Grid item>
                        <FlagRound 
                          lang={lang}
                          flagSize='small'
                        />
                      </Grid>
                      <Grid item>
                        <LinkBasic 
                          variant='h5'
                          to={link} 
                          title={asdf }
                        />
                      </Grid>
                      <Grid item xs={12}> 
                        <Typography>
                          {instructions}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{
                        marginBottom: 2, 
                        marginTop: 2,
                      }} 
                    />
                    {loading ?
                        <CircularProgress /> 
                        :
                        <Grid container  alignItems="center" spacing={2} >
                          <Grid item xs={12}> 
                              <a href={url} target="_blank" rel="noopener noreferrer" sx={{color:'#6415ff'}}>
                                <Typography variant="h6" sx={{color:'#6415ff'}}>
                                  {title}
                                </Typography>
                              </a>
                          </Grid>
                          <Grid item xs={12}> 
                            <FormControl sx={{width: '100%'}}>
                              <TextField 
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={12}
                                error={errorText}
                                sx={{width: '100%'}}
                                required 
                                id="standard-required" 
                                label="Article Text" 
                                value={text} 
                                inputProps={{
                                  maxLength: characterLimit
                                }}
                                onChange={handleText} 
                                helperText={`${wordCount}/${characterLimit}`}

                              />
                            </FormControl>
                          </Grid>
                          <Grid item  >
                            {charsOk ? 
                              <LoadingIconButton  
                                onClick={updateText}
                                title={buttonTitle}
                                loading={updating}
                                icon={<TextSnippetIcon />}
                              /> 
                              : 
                              <Button
                                disabled
                                variant="contained"
                                startIcon={<TextSnippetIcon />}
                              >
                                {buttonTitle}
                              </Button>
                            } 
                          </Grid>
                        </Grid>
                      }
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                  </Card> 
                </Container>
              </Grid>
              <Grid item>
                <AdSidebar />
              </Grid>
            </Grid>
    

}