import React from "react";
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button'

export default function BasicLinkIconButton({
    title,
    to,
    icon,
    size="medium",
    fullWidth=false,
    color='primary'
}){

  return(
      <Button 
        color={color}
        fullWidth={fullWidth}
        size={size}
        to={to}
        startIcon={icon}
        component={RouterLink} 
        variant="contained" 
        onClick={()=>{}}
      >
        {title}
      </Button>
  )
}