import {supabase} from "../supabase"
import { v4 as uuidv4 } from 'uuid';
import { getAuthuser } from "./memberQueries.js"
import { dexdb } from "../App";

const image_table = 'vocab_images'
const audio_table = 'vocab_audio'
const vocab_table = 'user_vocab_words'

const image_library_table = 'vocab_image_library'
const audio_library_table = 'vocab_audio_library'

const sb_url = process.env.REACT_APP_SUPABASE_URL
const insrt_url = `${sb_url}/storage/v1/object/public/vocab-images/`

// STORAGE

const uploadImage = async  (file) => {
    const suffix = file.name.split('.')[1]
    const fileName = uuidv4();
    const fullPath = `private/${fileName}.${suffix}`

    const { data, error } = await supabase.storage
        .from('vocab-images')
        .upload(fullPath, file, {
            cacheControl: '3600',
            upsert: false
        })

    if(error) throw error
    return insrt_url+data['path']
}

const uploadAudio = async  (file) => {
    const suffix = file.name.split('.')[1]
    const fileName = uuidv4();
    const { data, error } = await supabase
    .storage
    .from('vocab-audio')
    .upload(`private/${fileName}.${suffix}`, file, {
        cacheControl: '3600',
        upsert: false
    })

  if(error) throw error
  return data['path']
}

// MUTATIONS

const storeMedia = async (vocabId) => {
    try{
        const media = await getVocabMedia({vocabId})
        await dexdb.vocab_media.put(media)
    } catch(error) {
        throw error
    }
}

// IMAGES

export const addVocabImage =  async ({
    vocabId,
    url,
    fileName,
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const { error } = await supabase
        .from(image_table)
        .insert({
            'user_vocab_id': vocabId,
            'url': url,
            'file_name': fileName,
            'aid': aid,
        })

    if(error) throw error
   
    return
}

export const addVocabImageFromLibrary =  async ({
    imageLibraryId,
    vocabId,
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const { error } = await supabase
        .from(image_table)
        .insert({
            'image_library_id': imageLibraryId,
            'user_vocab_id': vocabId,
            'aid': aid,
        })

    if(error) throw error
    return await storeMedia(vocabId)
}

export const addVocabImagesFromLibrary =  async (
    allSel
) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const inserts = allSel.map(a => ({
        'image_library_id': a.image_library_id,
        'user_vocab_id': a.user_vocab_id,
        'aid': aid,
    }))
    const { error } = await supabase
        .from(image_table)
        .insert(inserts)

    if(error) throw error
    const vocabId = allSel[0]['user_vocab_id']
    await storeMedia(vocabId)
    return
}

export const addVocabImageLibrary =  async ({
    sourceLang, 
    targetLang,
    sourceWord,
    targetWord,
    url,
}) => {
    const { data, error } = await supabase
        .from(image_library_table)
        .insert({
            'source_lang': sourceLang,
            'source_word': sourceWord,
            'target_lang': targetLang,
            'target_word': targetWord,
            'url': url,
        })
        .select('id')

    if(error) throw error
   
    return data[0]['id']
}


export const uploadVocabImage = async ({
    file,
    vocabId,
}) => {
    try {
        const url = await uploadImage(file)
        await addVocabImage({
            vocabId, 
            url, 
            fileName: '',
        })
        return
    } catch (error){
        throw error;
    }
}



export const uploadVocabImageUrlToLibrary = async ({
    url,
    vocabId,
    sourceLang,
    targetLang,
    sourceWord,
    targetWord,
}) => {
    try {

        const imageLibraryId = await addVocabImageLibrary({
            sourceLang,
            targetLang,
            sourceWord,
            targetWord,
            url, 
        })
   
        await addVocabImageFromLibrary({
            vocabId,
            imageLibraryId,
        })
        return
    } catch (error){
        throw error;
    }
}

export const uploadVocabImageToLibrary = async ({
    file,
    vocabId,
    sourceLang,
    targetLang,
    sourceWord,
    targetWord,
}) => {
    try {
        const url = await uploadImage(file)

        await uploadVocabImageUrlToLibrary({
            url,
            vocabId,
            sourceLang,
            targetLang,
            sourceWord,
            targetWord,
        })

        // const imageLibraryId = await addVocabImageLibrary({
        //     sourceLang,
        //     targetLang,
        //     sourceWord,
        //     targetWord,
        //     url, 
        // })
   
        // await addVocabImageFromLibrary({
        //     vocabId,
        //     imageLibraryId,
        // })
        return
    } catch (error){
        throw error;
    }
}

export const deleteVocabImage = async ({
    id,
}) => { 
    const { data, error } = await supabase
        .from(image_table)
        .delete()
        .eq('id', id)
        .select('user_vocab_id')
    if(error) throw new Error(error.message)
    await storeMedia(data[0]['user_vocab_id'])
}

// AUDIO

export const addVocabAudio =  async ({
    vocabId,
    url,
    fileName,
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const { error } = await supabase
        .from(audio_table)
        .insert({
            'user_vocab_id': vocabId,
            'url': url,
            'file_name': fileName,
            'aid': aid,
        })

    if(error) throw error
   
    return
}

export const addVocabAudioLibrary =  async ({
    url,
    sourceLang,
    targetLang,
    sourceWord,
    targetWord,
}) => {
    const { data, error } = await supabase
        .from(audio_library_table)
        .insert({
            'source_lang': sourceLang,
            'source_word': sourceWord,
            'target_lang': targetLang,
            'target_word': targetWord,
            'url': url,
        })
        .select('id')

    if(error) throw error
   
    return data[0]['id']
}

export const deleteVocabAudio = async ({
    id,
}) => { 
    const { data, error } = await supabase
        .from(audio_table)
        .delete()
        .eq('id', id)
        .select('user_vocab_id')
    if(error) throw new Error(error.message)
    await storeMedia(data[0]['user_vocab_id'])
}

export const addVocabAudioFromLibrary =  async ({
    audioLibraryId,
    vocabId,
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const { error } = await supabase
        .from(audio_table)
        .insert({
            'audio_library_id': audioLibraryId,
            'user_vocab_id': vocabId,
            'aid': aid,
        })

    if(error) throw error
    await storeMedia(vocabId)
    return
}

export const addVocabAudiosFromLibrary =  async (
    allSel
) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const inserts = allSel.map(a => ({
        'audio_library_id': a.audio_library_id,
        'user_vocab_id': a.user_vocab_id,
        'aid': aid,
    }))
    const { error } = await supabase
        .from(audio_table)
        .insert(inserts)

    if(error) throw error
    const vocabId = allSel[0]['user_vocab_id']
    await storeMedia(vocabId)
    return
}


export const uploadVocabAudio = async ({
    file,
    vocabId,
}) => {
    try {
        const url = await uploadAudio(file)
   
        await addVocabAudioLibrary({
            vocabId, 
            url, 
            fileName: '',
        })
        return
    } catch (error){
        throw error;
    }
}

export const uploadVocabAudioToLibrary = async ({
    file,
    vocabId,
    sourceLang,
    targetLang,
    sourceWord,
    targetWord,
}) => {
    try {
        const url = await uploadAudio(file)
        await uploadVocabAudioUrlToLibrary({
            url,
            vocabId,
            sourceLang,
            targetLang,
            sourceWord,
            targetWord,
        })
        // const audioLibraryId = await addVocabAudioLibrary({
        //     sourceLang,
        //     targetLang,
        //     sourceWord,
        //     targetWord,
        //     url, 
        //     fileName: '',
        // })
   
        // await addVocabAudioFromLibrary({
        //     vocabId,
        //     audioLibraryId,
        // })
        return
    } catch (error){
        throw error;
    }
}

export const uploadVocabAudioUrlToLibrary = async ({
    url,
    vocabId,
    sourceLang,
    targetLang,
    sourceWord,
    targetWord,
}) => {
    try {

        const audioLibraryId = await addVocabAudioLibrary({
            sourceLang,
            targetLang,
            sourceWord,
            targetWord,
            url, 
            fileName: '',
        })
   
        await addVocabAudioFromLibrary({
            vocabId,
            audioLibraryId,
        })
        return
    } catch (error){
        throw error;
    }
}

// QUERIES

const query1 = `id,
                vocab_images(
                    id, 
                    vocab_image_library(
                        id,
                        url
                    )
                ),
                vocab_audio(
                    id, 
                    vocab_audio_library(
                        id,
                        url
                    )
                )
                `

export const getVocabMedia = async ({
    vocabId,
}) => {
 
   let { data, error } = await supabase
    .from(vocab_table)
    .select(query1)
    .eq('id', vocabId)
    .single()
  
    if(error) throw new Error(error.message)
  
    if(!data || data.length===0) throw new Error("Records not found")
  
    return data
}

const searchquery = `id,
                     url
                    `

export const searchVocabImages = async ({
    sourceLang,
    targetLang,
    sourceWord,
}) => {
  
   let { data, error } = await supabase
        .from(image_library_table)
        .select(searchquery)
        .eq('source_word', sourceWord)
        .eq('target_lang', targetLang)
        .eq('source_lang', sourceLang)

  
    if(error) throw new Error(error.message)
  
    if(!data || data.length===0) throw new Error("Images not found")
  
    return data
}

export const searchVocabAudio = async ({
    sourceLang,
    targetLang,
    sourceWord,
}) => {
  
   let { data, error } = await supabase
        .from(audio_library_table)
        .select(searchquery)
        .eq('source_word', sourceWord)
        .eq('target_lang', targetLang)
        .eq('source_lang', sourceLang)

    if(error) throw new Error(error.message)
  
    if(!data || data.length===0) throw new Error("Images not found")
  
    return data
}
