import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'

import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export function LinkBasic({
    to, 
    title,  
    variant='inherit',
}){
    return   <Link 
                to={to} 
                component={RouterLink} 
                color='#6415ff' 
                variant={variant} 
                sx={{cursor: 'pointer'}} 
            >
                {title}
            </Link> 
}

export function CaptionDate({
    artDate,
}){
    return  <Typography variant={'caption'} >
                {moment(artDate).format('MMMM Do, YYYY')}
            </Typography>
}