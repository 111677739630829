
import React, {useRef, useEffect} from 'react'
import {analytics} from '../../App'

import { 
    logEvent, 
} from "firebase/analytics";

const atOptions = {
  'key' : '844c009b7a9b1ccf4a53cc483b029483',
  'format' : 'iframe',
  'height' : 250,
  'width' : 300,
  'params' : {}
}

const logAd = (source) => {
  logEvent(analytics, 'ad_impression', {
    ad_platform: 'adsterra',
    ad_source: source,
    ad_unit_name: atOptions.key,
    ad_format: `banner ${atOptions.height}x${atOptions.width}`,
  });
}

export function Banner250300({source}) {

    const banner = useRef()
    useEffect(() => {
   
    if (!banner.current.firstChild) {
        const conf = document.createElement('script')
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//www.topdisplayformat.com/${atOptions.key}/invoke.js`
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
  
        if (banner.current) {
            banner.current.append(conf)
            banner.current.append(script)
        }
    }
    logAd({source})
    return () => {
      banner.current = null;
    };
  }, [source, banner])
  
   return <div style={{marginTop: 20}} ref={banner}></div>
  }

  export function Banner2503001({source}) {

    const banner = useRef()
    useEffect(() => {

      setTimeout(()=>{
      
        if (!banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.topdisplayformat.com/${atOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
      
            if (banner.current) {
                banner.current.append(conf)
                banner.current.append(script)
            }
        }
        logAd({source})
      }, 500)
      return () => {
        banner.current = null;
      };
  }, [source])
  
   return <div style={{marginTop: 20}} ref={banner}></div>
  }

  export function Banner2503002({source}) {

    const banner = useRef()
    useEffect(() => {

      setTimeout(()=>{

          const atOptions = {
            'key' : '844c009b7a9b1ccf4a53cc483b029483',
            'format' : 'iframe',
            'height' : 250,
            'width' : 300,
            'params' : {}
          }
      
        if (!banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.topdisplayformat.com/${atOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
      
            if (banner.current) {
                banner.current.append(conf)
                banner.current.append(script)
            }
        }
        logAd({source})
       
      }, 1000)
      return () => {
        banner.current = null;
      };
  }, [source, banner])
  

   return <div style={{marginTop: 20}} ref={banner}></div>
  }

  export function Banner2503003({source}) {

    const banner = useRef()
    useEffect(() => {

      setTimeout(()=>{

        if (!banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.topdisplayformat.com/${atOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
      
            if (banner.current) {
                banner.current.append(conf)
                banner.current.append(script)
            }
        }
        logAd({source})
      }, 1500)
      return () => {
        banner.current = null;
      };
  }, [source, banner])

   return <div style={{marginTop: 20}} ref={banner}></div>
  }
