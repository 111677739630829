import React, { useState, useEffect, useMemo } from "react";
import { useLiveQuery } from "dexie-react-hooks";

import { useHistory } from "react-router-dom";

import {
  langSwitch,
  isValidHttpUrl,
  splitSentence1,
  checkQuizCount
}from '../utils'

import {analytics, dexdb} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import {
  getGutenberg,
} from '../queries/workerQueries'

import {
  deleteLibraryItem
} from '../queries/libraryQueries'

import LibraryPage from '../components/Pages/LibraryPage'

export default function Library(){

  const history = useHistory()

  const library = useLiveQuery(() => dexdb.library.toArray(), []) ?? [];
  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const libraryItems = library
  const isLoading = false
  const isError = false
  const error = ''

  const userLangs = useMemo(() => member && member.length>0 ? member[0].langs : [], [member]);
  const langs = useMemo(() => member && member.length>0 ? member[0].langs : [], [member]);

  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ lang, setLang ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ searchurl, setUrl ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ linkError, setLinkError] = useState(false)
  const [ linkerrorMsg, setlinkErrorMsg] = useState('')

  const handleClose = () => { 
    setLinkError(false)
    setlinkErrorMsg('')
    setSnack(false)
  }
  
  const handleLink = event => setUrl(event.target.value)

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const wrongLang = () => {
    const userLang = userLangs[0]
    const language = langSwitch(userLang)
    const errmsg = `This document is not in ${language}`
    showSnack('error', errmsg)
    setLinkError(true)
    setlinkErrorMsg(errmsg)
  }

  const getStoredGuten = async url => await dexdb.gutenberg.where("url").equalsIgnoreCase(url).toArray();

  const getStoredGutenIdx = async url => await dexdb.gutenbergIdx.where("url").equalsIgnoreCase(url).toArray();

  const addGutenIdx = async () => {
   await dexdb.gutenbergIdx.add({
      url: searchurl,
      sentIdx: 0
    })
  }

  const addToGutenStore = async (sentences, title, url) => {
    const dexsents = sentences.map(s =>  ({
      orig_text: s, 
      url, 
      title,
      translation: '',
    }))

    await dexdb.gutenberg.bulkAdd(dexsents);
  }

  const goToGutenPage = (searchurl) => {
    logEvent(analytics, 'import_public_domain', {
      lang: lang, 
      type: 'gutenberg',
      device: 'web',
    })
    const urlEncoded = encodeURIComponent(searchurl)
    const link = `/user/gutenberg/${lang}/${urlEncoded}`
    history.push(link)
  }

  const searchGuten = async () => {
    setLoading(true)

    const urlok = isValidHttpUrl(searchurl)

    if(!urlok){
      const invalidurl = 'Invalid Url!'
      showSnack('error', invalidurl)
      setLinkError(true)
      setlinkErrorMsg(invalidurl)
      setLoading(false)
      return
    }

    try {
      const sents = await getStoredGuten(searchurl)
  
      if(sents.length>0){

        const sentId = await getStoredGutenIdx(searchurl)
   
        if(sentId.length===0){ 
          addGutenIdx()
        } 

        goToGutenPage(searchurl)
        return
      } else {
    
        try {
          const work = await getGutenberg({searchurl})
          const {art, lang, title} = work
  
          if(lang!==userLangs[0]){
            wrongLang()
            return
          }
    
          const sentences = splitSentence1(art);

          await addToGutenStore(sentences, title, searchurl) 

          addGutenIdx()
          goToGutenPage(searchurl)
        } catch(error){
          showSnack('error', error.message)
          setLinkError(true)
          setlinkErrorMsg(error.message)
        } finally{
          setLoading(false)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const deleteItemDex = async url => {
   await dexdb.gutenberg.where("url").equalsIgnoreCase(url).delete()
   await dexdb.gutenbergIdx.where("url").equalsIgnoreCase(url).delete(url)
  }


  const deleteItem = async url => { 
    await deleteItemDex(url)
    await deleteLibraryItem({
      url,
    })
  }

  // langs[0]

  useEffect(() => {
   if(langs.length>0){
    setLang(langs[0])
   }
   checkQuizCount()
  }, [langs])

  if(library.length==0) return <div></div>
  return <LibraryPage 
            lang={lang}
            langs={langs}
            addCluster={searchGuten}
            handleLink={handleLink}
            loading={loading}
            snack={snack}
            msgType={msgType}
            error={error}
            alertMsg={alertMsg}
            handleClose={handleClose}
            errorMsg={linkerrorMsg}
            isError={isError}
            searchurl={searchurl}
            linkError={linkError}
            linkerrorMsg={linkerrorMsg}
            libraryItems={libraryItems}
            isLoading={isLoading}
            deleteItem={deleteItem}
            setLang={setLang}
        />
}