import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import ListIcon from '@mui/icons-material/List'
import Container from '@mui/material/Container'
import Button  from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import ArticleTranslationsModal from '../Dialogs/ArticleTranslationsModal'
import PlayListButton from '../Buttons/PlayListButton'
import ArticleReadButton from '../Buttons/ArticleReadButton'
import Translated from '../Article/Translated'
import TranslationField from '../Inputs/TranslationField'
import ArticleFrame from '../Article/ArticleFrame'
import SnackAlert from '../Dialogs/SnackAlert'

export default function ArticlePage({
  link, 
  title,
  id,
  playlist,
  translated,
  transWord,
  translatedWord,
  resetTranslation,
  translateWord1,
  handleTransWord,
  handleClose,
  handleClickOpen,
  handleClickClose,
  open,
  translations,
  snack,
  msgType,
  alertMsg,
  transLoading,
  art_id,
  rec_id,
  read,
  playlistId,
  addTranslation,
  goToPlayArt
}){
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const buttonSize = matches ? 'medium' : 'small'
    const textSize = matches ? 'h6' : 'body1'

    return (
      <Container component="main" maxWidth="lg" >
        <Grid container  alignItems="center" >
          <Grid item xs={12}>
            <Paper sx={{p: 2}}> 
              <Grid container  
                    alignItems="center" 
                    justifyContent="space-between" 
                    spacing={1}
              >
                <Grid item xs={12}>
                  <Link 
                    variant={textSize}
                    href={link} 
                    target="_blank" 
                    rel="noreferrer"
                    color='#6415ff' 
                  >
                    {title}
                  </Link>
                </Grid> 
                <Grid item xs={12}>
                  <Grid container  
                      alignItems="center" 
                      justifyContent='space-between'   
                      spacing={2} 
                  >
                  <Grid item >
                    <Grid container  
                      alignItems="center" 
                      spacing={2}
                    >
                     <Grid item >
                      <PlayListButton 
                        size={buttonSize}
                        id={id}
                        playlist={playlist}
                        art_id={art_id}
                        rec_id={rec_id}
                        playlistId={playlistId}
                      />
                    </Grid>
                    <Grid item >
                      <ArticleReadButton
                        size={buttonSize}
                        id={id}
                        read={read}
                        art_id={art_id}
                      />
                    </Grid>
                    <Grid item >
                      <Tooltip title='Play Article' >
                        <span>
                          <IconButton 
                            disabled={false}
                            onClick={goToPlayArt}
                            color='success'
                          >
                            <PlayArrowIcon  />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item > 
                    {translated ?
                      <Translated
                        addTranslation={addTranslation}
                        transWord={transWord}
                        translatedWord={translatedWord}
                        resetTranslation={resetTranslation}
                      />
                      :
                      <TranslationField
                        transLoading={transLoading}
                        transWord={transWord}
                        translateWord1={translateWord1}
                        handleTransWord={handleTransWord}
                      />
                    }     
                    </Grid>   
                    <Grid item > 
                      <Button 
                        variant="contained" 
                        disabled={translations.length===0}
                        onClick={handleClickOpen}
                        startIcon={<ListIcon />}
                        size={buttonSize}
                      >
                        All Translations
                      </Button>
                    </Grid>
                  </Grid> 
                </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {link.length>0 && 
            <ArticleFrame link={link} id={id} />
          }
          <ArticleTranslationsModal 
            translations={translations}
            open={open} 
            onClose={handleClickClose} 
          />
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          />
      </Container>
    )
}