import React, { useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";

import {analytics, dexdb } from '../App'

import { checkQuizCount } from "../utils";

import { 
    logEvent, 
} from "firebase/analytics";

import {
  createCluster,
} from '../queries/clusterQueries'

import ClustersPage from '../components/Pages/ClustersPage'

export default function Clusters({history}){

  const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []) ?? [];

  const clusters1 = clusters ?? []

  const [ clusterName, setClusterName ] = useState('')
  const [ lang, setLang ] = useState('')
  const [ langs, setLangs ] = useState([])
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)

  const handleClose = () => setSnack(false)
  
  const handleLink = event => setClusterName(event.target.value)

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const addClust = async () => {
    if(clusterName.includes('http')){
      showSnack('error', 'Enter a cluster name not a url. You can add urls on the cluster page.')
    } else {
      try {
        await createCluster({
          clusterName,
          lang,
        })
        logEvent(analytics, 'add_cluster', {
          lang,
          device: 'web'
        })
        showSnack('success', 'Success! New cluster added!')
      } catch(error){
        showSnack('error', 'Error adding new cluster!')
      }
    }
  }

  useEffect(()=> {
    const setAllLangs = async () => {
      const mem = await dexdb.member.toArray()
      if(mem.length>0){
        const curMem = mem[0]
        const userLangs = curMem.langs ?? []
        const lang = userLangs.length>0 ? userLangs[0] : 'en'
        setLang(lang)
        setLangs(userLangs)
      }
    }
    checkQuizCount()
    setAllLangs()
  },[])

  if(clusters.length==0) return <div></div>
  return <ClustersPage 
          clusterName={clusterName} 
          lang={lang}
          langs={langs}
          addCluster={addClust}
          handleLink={handleLink}
          clusters={clusters1}
          setLang={setLang}
          loading={false}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          isError={false} 
        />
}