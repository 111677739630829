import React, {useEffect, useMemo} from 'react'
import { useLiveQuery } from "dexie-react-hooks";
import { dexdb } from '../App';
import { checkQuizCount } from '../utils';

import VocabStartPage from '../components/Pages/VocabStartPage'

export default function VocabStart({
  history
}){

  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const langs = useMemo(() => member ? member[0].langs : [], [member]);

  useEffect(() => {
    if (langs.length===1){ 
      history.push(`/user/translations/${langs[0]}`)
    }
    checkQuizCount()
  },[langs, history])

  
  return <VocabStartPage langs={langs} />
}