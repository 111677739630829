import React from 'react'

import { 
  getTranslations,
  langSwitchQuiz
} from '../../utils'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'

import SnackAlert from '../Dialogs/SnackAlert'
import VocabAnswer from '../VocabQuiz/VocabAnswer'
import QuizFlags from '../ImageElements/QuizFlags'
import BasicButton from '../Buttons/BasicButton'
import SelectButton from '../Buttons/SelectButton'
import AdQuizBar from '../Ads/AdQuizBar'

const Answers = ({answereds}) => 
  <Grid container spacing={1}>
    {answereds.map((a,i) => 
      <Grid item key={i} xs={12}>
        <VocabAnswer {...a} />
      </Grid>
    )}
  </Grid>

export default function VocabTestPage({
  checkAnswer,
  toggle,
  handleGuess,
  guess,
  feedback, 
  correct,
  answereds,
  correctAnswers,
  setNextQuestion,
  currQ,
  resetAlert,
  snack,
  msgType,
  alertMsg,
  handleClose,
  getLastAnswered,
  getUnanswered,
  getRecentlyAdded,
  getRandom,
  getSpaced,
  quizType,
  adReload,
}){

    const { 
      what, 
      mean, 
      translation,
    } = getTranslations()

    const answerNum = answereds.length

    const percentCorrect = answerNum>0 ? Math.round(correctAnswers/answerNum * 100) : 0

    const source_lang = currQ ? currQ.source_lang : 'en'
    const target_lang = currQ ? currQ.target_lang : 'en'

    const source_word= currQ ? currQ.source_word : 'en'
    const target_word= currQ ? currQ.target_word : 'en'

    const { language } = langSwitchQuiz(target_lang)

    const submitAnswer = event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        feedback ? setNextQuestion() : checkAnswer()
      }
    }

    return( 
          <main >
            <Grid container spacing={3} >
              <Grid item md={6}>
                <Paper sx={{
                    padding: 2,
                    mb: 1,
                  }}>
                  <Typography sx={{color:'#6415ff', fontSize: 20}}>
                    Choose Quiz Type
                  </Typography>
                  <Divider sx={{mt:1, mb:2}} />
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <SelectButton 
                        title='Recently Answered'
                        actionFunc={getLastAnswered}
                        selectedQuizType={quizType}
                        quizType='lastanswered'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectButton 
                        title='Spaced Repetition'
                        actionFunc={getSpaced}
                        selectedQuizType={quizType}
                        quizType='spaced'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectButton 
                        title='Recently Added'
                        actionFunc={getRecentlyAdded}
                        selectedQuizType={quizType}
                        quizType='recentadd'
                      />
                    </Grid>
                    <Grid item xs={6}>
                       <SelectButton 
                        title='Random Word'
                        actionFunc={getRandom}
                        selectedQuizType={quizType}
                        quizType='random'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectButton 
                        title='Unanswered Words'
                        actionFunc={getUnanswered}
                        selectedQuizType={quizType}
                        quizType='unanswered'
                      />
                    </Grid>
                  </Grid>
                </Paper>
                {currQ &&
                <Paper sx={{padding:3 }}> 
                  <Grid container 
                        spacing={2} 
                        alignItems="center"
                  >
                    <Grid item xs={12}>
                      <QuizFlags 
                        orig_lang={source_lang} 
                        native_lang={target_lang}  
                      />
                    </Grid> 
                    {resetAlert ?
                     <Grid item xs={12}>
                      <Typography variant='h5'>
                        Select quiz above
                      </Typography>
                      </Grid> 
                    : 
                    <>
                    <Grid item xs={12}>
                      <Typography variant={'h6'}>
                        {what} <span style={{color:'#6415ff'}}>{source_word}</span> {mean} {language}?
                      </Typography>
                    </Grid> 
                    <Grid item xs={12}>
                      <TextField 
                        onChange={handleGuess} 
                        autoComplete='off'
                        onKeyDown={submitAnswer}
                        value={guess} 
                        sx={{ width:'100%' }}
                        label={translation}  
                        placeholder="Guess"
                      />
                    </Grid> 
                    <Grid item xs={12}>
                      <Grid container 
                            justifyContent='space-between' 
                            alignItems="center"
                      >
                        <Grid item>
                          <BasicButton 
                            title={feedback ? 'Next Question' : 'check'}
                            actionFunc={feedback ? setNextQuestion : checkAnswer} 
                          />
                        </Grid> 
                        <Grid item >
                          <Typography sx={{color:'#6415ff', fontSize:20}}>
                            {correctAnswers} / {answerNum} ({percentCorrect}%)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    </>
                  }
                  </Grid> 
                </Paper>
                }
                {feedback &&
                <>
                  <Paper sx={{ marginTop: 1 }} onClick={toggle}> 
                    <Alert severity={correct ? 'success' : 'error' } 
                           sx={{ fontSize:20 }}
                    >
                     <Typography >
                        {source_word} = <b>{target_word}</b>.
                      </Typography > 
                    </Alert>
                  </Paper>
                  {adReload &&
                    <div style={{marginTop: 10}}>
                      <AdQuizBar />
                    </div>
                  }
                </>
                }
              </Grid> 
              <Grid item md={6} >
                  <div style={{height: '84vh', overflow: 'auto', padding: 4}} >
                    <Answers answereds={answereds} />
                  </div>
              </Grid>
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
          </main>
  )
}