import React , {useState} from 'react'

import {
    deleteVocabAudio,
} from '../../../queries/vocabMediaQueries'

import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import SnackAlert from '../../Dialogs/SnackAlert'

export default function AudioCard({
    vocab_audio_library,
    id,
  }){
    
    const {url} = vocab_audio_library
  
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ playing, setPlaying ] = useState(false)
  
    const handleClose = () => setSnack(false)
  
    const deleteImage = async () => {
      try {
        await deleteVocabAudio({
          id,
          url,
        })
    
      } catch(error){
        showSnack('error', 'Error deleting image!')
      }
    }
    var audio = new Audio(url);
  
    const playSound = async () => {
      setPlaying(true)
      audio.play().then(d => {
        setPlaying(false)
      });
    }
  
  
    const pauseSound = () =>{ 
      setPlaying(false)
      audio.pause()
    }
  
    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }
  
    return  <Grid container alignItems='center'>
              <Grid item>
                <Tooltip title="Play">
                  <IconButton aria-label="delete" onClick={playing ? pauseSound : playSound} >
                    <PlayArrowIcon color='success'  />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Delete">
                  <IconButton aria-label="delete" onClick={deleteImage}>
                    <DeleteIcon color='error' fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Grid>
            
              <SnackAlert 
                snack={snack}
                handleClose={handleClose}
                msgType={msgType}
                alertMsg={alertMsg}
              />
            </Grid> 
  }
  