import { dexdb } from "../App";

import {supabase} from "../supabase"

import {
    getOffset, 
    getLinkCount
} from '../utils'

import {
    createQuiz, 
} from './quizQueries';

const table = 'profile'

const loginQuery4 = `
    *,
    library(
        id,
        url,
        title,
        lang
    ),
    quiz(
        id, 
        aid, 
        answer_count, 
        answered, 
        correct_count, 
        date, 
        end_time, 
        lang, 
        quiz_active, 
        quiz_time_zone, 
        quiz_type, 
        source_lang, 
        source_word, 
        start_time,  
        target_lang, 
        target_word, 
        token, 
        tz_offset, 
        user_vocab_word_id
    ),
    clusters(
      id,
      cluster_name,
      active,
      lang,
      translated_arts(
          title,
          url,
          id
      )
    )
`

export const getLoginData = async () => {
    const { data, error } = await supabase
        .from(table)
        .select(loginQuery4)
    if(error) throw new Error(error.message)
    if(!data || data.length===0) return []
    const clusters = data[0].clusters
    let linkCount = getLinkCount(clusters)
    localStorage.setItem('count', linkCount.toString())
    return data
}

export const getAuthuser = async () => {
    const response = await supabase.auth.getSession()
    if(response.data.session!==null){
        return response.data.session.user
    }
    return null
}

export const createMember =  async ({
    name,
    langs,
    nativeLang,	
    timeZone,
    country, 
}) => {
    const user = await getAuthuser()
    const { id, email } = user
    const { data, error } = await supabase
    .from(table)
    .insert({
        source: 'web',
        email,
        name,
        fcm_token: '',	
        langs,
        native_lang: nativeLang,	
        badge_count: 0,	
        online: false,	
        role: 'basic',
        time_zone: timeZone,
        country,
        id,
    })
    .select()

    if(error) throw new Error(error.message)

    const lang = langs[0];

    try {

        await createQuiz({
            quiz_time_zone: timeZone, 
            lang,
            source_lang: lang, 
            target_lang: nativeLang,
            aid: user.id,
        });

    } catch(error) {
      throw new Error(error.message)
    }
    return data
}

export const updateMember = async update => {
    const user = await getAuthuser()
    if(user){
        const {id, email} =  user
        const aid = id
        const { data, error } = await supabase
            .from(table)
            .update(update)
            .eq('id', aid)
            .select()
    
        if(error) throw new Error(error.message)

        const newMember = data[0]
      
        await dexdb.member.put(newMember)
        
        const quiz_time_zone = newMember.time_zone
        const tz_offset = getOffset(quiz_time_zone)
    
        const { error: errorq } = await supabase
            .from('quiz')
            .update({
                quiz_time_zone,
                tz_offset,
            })
            .eq('aid', aid)
        if(errorq) throw new Error(errorq.message)
        if (email !== update.email){
            const {
                error: emerror,
            } = await supabase.auth.updateUser({email})
            throw new Error(emerror)
        }
        return data
    } else {
        throw new Error('You are not logged in!')
    }
}

export const getMember = async () => {
    const { data, error } = await supabase
      .from(table)
      .select()

    if(error) throw new Error(error.message)
  
    if(!data) throw new Error("Member not found")

    return data[0]
  }
