import React  from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { GlobalProvider } from './context/GlobalContext';
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
      <GlobalProvider>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </GlobalProvider>, document.getElementById('root'))

serviceWorker.unregister()