import React, {useState} from "react";

import {
    deleteTranslatedArt
} from '../../queries/translatedArtQueries'

import Grid from '@mui/material/Grid'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'

import SnackAlert from '../Dialogs/SnackAlert'

export default function FirstClusterArtLink({
    id, 
    title,
    url,
}){
    
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
      }

  
    const deleteLink = async () =>{ 
        try {
            await deleteTranslatedArt({
                id,
                cluster_id: 0,
            })
        } catch(error){
            showSnack('error', 'Error deleting cluster!')
        }
    }

    return(
            <Grid container alignItems="center" justifyContent="space-between" style={{margin:10}} >
                <Grid item xs={11} >
                <Link 
                    variant='body1'
                    href={url} 
                    target="_blank" 
                    rel="noreferrer"
                    color='#6415ff' 
                    
                  >
                    {title && title}
                  </Link>
                </Grid>
                <Grid item xs={1} >
                    <IconButton aria-label="delete" onClick={deleteLink} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                />
            </Grid>
    )
}