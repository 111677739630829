import React from "react"

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import grey from '@mui/material/colors/grey'

import FlagRound from '../ImageElements/FlagRound'

export default function LangButton({
    selectLang1,
    l,
    lang,
    size='medium'
}) {
    const handleLang = () => selectLang1(l)
    const curLang = getTranslations()
    const flagSize = 'xsmall'
    return(
        <Button 
            size={size}
            sx={{
                backgroundColor: lang === l ? grey[400] : grey[200],
                '&:hover': {
                    backgroundColor: grey[400],
                  },
                  '&:active': {
                    backgroundColor: grey[400],
                  },
                color: grey[800]
            }}
            onClick={handleLang} 
        >   
            <Grid   container 
                    spacing={1}
                    alignItems="center"
            >
                <Grid item>
                    <FlagRound 
                        flagSize={flagSize}
                        lang={l}
                    />
                </Grid>
                <Grid item>
                    {curLang[l]}
                </Grid>
            </Grid>
        </Button >
    )
  }