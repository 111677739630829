import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState1 = {
  searchLinkResults:{
      searchLink:'',
      title:'',
      linkRecommendations:[]
  },
  historyRecommendations:{
      searchDate: new Date(),
      searchLang:'',
      dateRecommendations:[]
  },
  tab:0,
  lang:'',
  rec_lang:'',
};

export const GlobalContext = createContext(initialState1);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState1);

  function findLink(linkResults) {
    dispatch({
      type: "SEARCH_LINK",
      payload: linkResults
    });
  }
 
  function findDate(selectedDateLang) {
    dispatch({
      type: "SEARCH_DATE",
      payload: selectedDateLang
    });
  } 

  function switchTab(selectedTab) {
    dispatch({
      type: "SWITCH_TAB",
      payload: selectedTab
    });
  } 

  function switchLang(lang) {
    dispatch({
      type: "SWITCH_LANG",
      payload: lang
    });
  } 

  function switchRecLang(lang) {
    dispatch({
      type: "SWITCH_REC_LANG",
      payload: lang
    });
  } 

  return (
    <GlobalContext.Provider
      value={{
        searchLinkResults: state.searchLinkResults,
        historyRecommendations: state.historyRecommendations,
        tab: state.tab,
        lang: state.lang,
        rec_lang: state.rec_lang,
        findLink,
        findDate,
        switchTab,
        switchLang,
        switchRecLang,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};