import {supabase} from "../supabase"

import { dexdb } from "../App.js"

import { getAuthuser } from "./memberQueries.js"

const table = 'goals'

// MUTATIONS

export const createGoal = async ({
    read_goal,
    quiz_goal,
    listen_goal,
    lang,
}) => {
    try {
        const user = await getAuthuser()
        if(user){
            const aid = user.id 
            const { data, error } = await supabase
                .from(table)
                .insert({
                    read_goal: parseInt(read_goal),
                    quiz_goal: parseInt(quiz_goal),
                    listen_goal: parseInt(listen_goal),
                    aid,
                    lang,
                })
                .select("*")

            if(error) throw new Error(error.message)
         
            await dexdb.goals.add(data[0])

            return 
        } else {
            throw new Error('You are not authenticated!')
        }
    } catch(error){
        throw new Error('Goal creation error!')
    }
}

export const updateGoals = async (
    goals,
) => {
        const { error } = await supabase
            .from(table)
            .upsert(goals)

        if(error) throw new Error(error.message)

        await dexdb.goals.bulkPut(goals)
        
        return 
}

export const deleteGoal = async ({
    id,
    goals,
}) => {
        const { error } = await supabase
            .from(table)
            .delete(id)

        if(error) throw new Error(error.message)
        const newGoals = goals.filter(g => g.id!==id)
        await dexdb.goals.bulkPut(newGoals)
        return 
}

// FETCH

export const getGoals = async () => {
    let { data, error } = await supabase
        .from(table)
        .select('*')

    if(error) throw new Error(error.message)
    await dexdb.goals.bulkPut(data)
    return data
}