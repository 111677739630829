import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => 
    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <CircularProgress sx={{color:'#6415ff'}}  />
    </Box>

export default Loading