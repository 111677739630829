import React from "react";

import { getTranslations } from '../../utils'

import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from "@mui/material/Typography";

import ClusterArtLink from '../ListItems/ClusterArtLink'

const { addlinks } = getTranslations()

const ClusterLinkCard = ({ 
    clusterLinks,
    count,
    clusterName,
    cluster_id,
 }) => <Card sx={{padding: 3, minHeight: 500, height: '100%'}}>
        <Typography variant="h6">
            Cluster Links - {count}
          </Typography>
          <Divider sx={{
              marginTop: 1,
              marginBottom: 1,
            }} />
          {clusterLinks && clusterLinks.length>0 ? 
            <>
              {clusterLinks.map((l, index) => 
                <ClusterArtLink 
                  key={l.id} 
                  idx={index}
                  {...l} 
                  clusterName={clusterName} 
                  count={count} 
                  cluster_id={cluster_id} 
                />
              )}
            </>
            :
            <Typography variant="h6">
              {addlinks}
            </Typography>
          }
      </Card> 

export default ClusterLinkCard