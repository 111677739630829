import React from "react"

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from "@mui/material/Divider"


const CurrentSentence = ({
    origText,
    curTranslation,
    handleSource,
    handleTarget,
    showTranslation,
  }) => 
        <Grid container 
          spacing={5} 
          justifyContent="space-between" 
          alignItems='center'
        >
            <Grid item xs={12}>
                <Typography variant="h6" onMouseUp={handleSource}>
                    {origText}
                </Typography>
            </Grid>
            {showTranslation &&
                <Grid item  xs={12}>
                    <Divider />
                </Grid>
            }
            <Grid item xs={12}>
                <Typography variant="h6" onMouseUp={handleTarget}>
                    {curTranslation}
                </Typography>
            </Grid>
        </Grid>

export default CurrentSentence