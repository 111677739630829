import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import SendIcon from '@mui/icons-material/Send'
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import LoginHeader  from '../Login/LoginHeader'
import LoginHelp from '../Login/LoginHelp'
import Copyright from '../Login/Copyright'
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function SupportNonLoggedPage({
    alertMsg,
    snack,
    email,
    handleEmail,
    supportTypes,
    submitSupport,
    handleSupportQuestion,
    handleSupportType,
    supportType,
    supportQuestion,
    msgType,
    handleClose,
    loading
}){

  return  <Container component="main" maxWidth="xs">
          <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
            <Grid container 
                  justifyContent='center' 
                  textAlign='center'
                  spacing={2}
            >
              <Grid item xs={12}>
                <LoginHeader title= {`Support`} />
              </Grid>  

              <Grid item xs={12}>
                <TextField 
                  fullWidth
                  rows={12}
                  required 
                  label="Email" 
                  value={email} 
                  autoComplete="email"
                  onChange={handleEmail} 
                  sx={{backgroundColor: 'white'}}
                />
              </Grid> 
              <Grid item xs={12}> 
                <FormControl fullWidth>
                  <InputLabel  >
                      Support Type
                  </InputLabel>
                  <Select
                      label='Support Type'
                      fullWidth 
                      value={supportType} 
                      onChange={handleSupportType} 
                      sx={{backgroundColor: 'white'}}
                  >
                      {supportTypes.map((l,i)=> 
                          <MenuItem key={i} value={l} >
                              {l}
                          </MenuItem>
                      )}
                  </Select>
                </FormControl>
              </Grid>  
              <Grid item xs={12}>
                <TextField 
                    fullWidth
                    multiline
                    rows={12}
                    required 
                    label="Support Question" 
                    value={supportQuestion} 
                    onChange={handleSupportQuestion} 
                    sx={{backgroundColor: 'white'}}
                />
              </Grid>   
              <Grid item xs={12}>
                <LoadingIconButton
                  loading={loading}
                  onClick={submitSupport} 
                  title="Submit Request"
                  icon={<SendIcon />}
                /> 
              </Grid>  
            </Grid>
          <Box mt={3}>
            <LoginHelp />
          </Box>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Paper>
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        />   
      </Container>

}