import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import useWindowDimensions from '../../helper'

import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'

import LangButton from '../Buttons/LangButton'
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function VocabSearch({
    handleVocabSearch,
    vocabSearchTerm,
    lang,
    searchLang,
    setSearchLang,
    nativeLang,
    buttonLoading,
    searchVocab,
}){
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const {width}= useWindowDimensions()
  const fieldWidth = md ? width*.4 : width*.84
  const buttonSize = md ? 'medium' : 'small'
    return    <Grid container 
                spacing={1} 
                alignItems="center"
                justifyContent='space-between'
              >
                <Grid item >
                <TextField
                    size="small"
                    variant="outlined"
                    onKeyDown={searchVocab}
                    required
                    fullWidth={true}
                    name="vocab_search_word"
                    label='Vocabulary Word...'
                    id="vocab_search_word" 
                    onChange={handleVocabSearch}
                    value={vocabSearchTerm}
                    sx={{width: fieldWidth}}
                  />
                
                </Grid>
                <Grid item >
                    <Grid container 
                      spacing={1} 
                      alignItems="center"
                    >
                      <Grid item >
                        <LangButton 
                          size={buttonSize}
                          l={lang} 
                          lang={searchLang} 
                          selectLang1={setSearchLang} 
                        />
                      </Grid>
                      <Grid item>
                        <LangButton 
                          size={buttonSize}
                          l={nativeLang} 
                          lang={searchLang} 
                          selectLang1={setSearchLang} 
                          />
                      </Grid>
                      <Grid item >
                        <LoadingIconButton 
                          size={buttonSize}
                          loading={buttonLoading}
                          title="Search"
                          onClick={searchVocab}
                          icon={<SearchIcon />}
                        />
                      </Grid>
                    </Grid>
                </Grid>
                
              </Grid> 
    }