import React from "react";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const TooltipButton = ({
    disabled,
    title,
    func,
    icon,
    color='primary'
  }) => {
    return <Tooltip title={title} >
            <span>
              <IconButton 
                disabled={disabled}
                onClick={func}
                color={color}
              >
                {icon}
              </IconButton>
            </span>
          </Tooltip>
  }
  
export default TooltipButton