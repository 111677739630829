import React from 'react'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'

import AudioBasicCard from './AudioBasicCard'

const AudioBasicClips = ({
    vocabAudio,
}) => {

  return (
          <Grid container 
            spacing={1} 
            alignItems="center"
          >
            <Grid item xs={12} >
                <Typography variant='h6'>
                    Audio
                </Typography>
            </Grid>
            <Grid item >
                <Grid container 
                    spacing={2} 
                    alignItems="center"
                >
                {vocabAudio.map((a, i) => 
                    <Grid item key={i}>
                        <Card >
                            <AudioBasicCard {...a} />
                        </Card>
                    </Grid>
                )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AudioBasicClips