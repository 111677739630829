import React, { useState, useEffect } from 'react'
import {supabase} from "../supabase"

import {analytics} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import { createMember } from '../queries/memberQueries'

import { setLoginData } from './Signin'
import { 
  getTranslations, 
  minimalTimezoneSet, 
} from '../utils'

import SignUpCompletePage from '../components/Pages/SignUpCompletePage'

export default function SignUpComplete({history}){

  const {
    fullname,
    selectlang,
    en,
    es,
    fr,
    de,
    it,
    pt,
 } = getTranslations()

 const langugages = [
  {lang:'de', language: de},
  {lang:'en', language: en},
  {lang:'es', language: es},
  {lang:'fr', language: fr},
  {lang:'it', language: it},
  {lang:'pt', language: pt},
]

  const [ name, setName ] = useState('')
  const [ email, setEmail] = useState('')
  const [ nativeLang, setNativeLang ] = useState('')
  const [ targetLang, setTargetLang ] = useState('')
  const [ country, setCountry ] = useState('')
  const [ timeZone, setTimeZone ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ signUpSuccess, setSignUpSuccess ] = useState(false)

  const targetLanguages = nativeLang ? langugages.filter(l => l.lang !== nativeLang) : langugages

  const handleName = event => setName(event.target.value)

  const handleClose = () => setSnack(false)

  const handleNativeLang = event => setNativeLang(event.target.value)

  const handleTargetLang = event => setTargetLang(event.target.value)

  const handleCountry = event => setCountry(event.target.value)

  const handleTimeZone = e => setTimeZone(e.target.value)

  const inputs = [
    {
        name:'name',
        label:'Name',
        handleFunc: handleName,
        value: name
    },
  ]

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const addNewUsertoSB = async () => {
    if (name.length<3){
      showSnack('error', fullname)
      return 
    }

    if (nativeLang.length===0 || targetLang.length===0){
      showSnack('error', selectlang)
      return 
    }

    setLoading(true)
    try {
      await createMember({
        name,
        langs: [targetLang],
        nativeLang,	
        timeZone,
        country, 
      })
      await setLoginData()
      logEvent(analytics, 'complete_sign_up', {
        lang: targetLang,
        native_lang: nativeLang,
      })
      setSignUpSuccess(true)
      history.push('/welcome')
    } catch(error){
      showSnack('error', 'Error creating your account!')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if(session){
        if(session.user!==null){
          setEmail(session.user.email)
        }
      }
    })
  
    const { subscription } = supabase.auth.onAuthStateChange((_event, session) => {

      if(session){
        if(session.user!==null){
          setEmail(session.user.email)
        }
      }
    })
    return () => {
      subscription?.unsubscribe();
    };
  }, [])

  return <SignUpCompletePage  
          inputs={inputs} 
          handleNativeLang={handleNativeLang} 
          nativeLang={nativeLang} 
          handleTargetLang={handleTargetLang} 
          targetLang={targetLang} 
          signUp={addNewUsertoSB} 
          langugages={langugages} 
          targetLanguages={targetLanguages} 
          loading={loading}
          snack={snack}
          msgType={msgType}
          error={alertMsg}
          alertMsg={alertMsg}
          handleClose={handleClose}
          handleTimeZone={handleTimeZone}
          country={country}
          timeZone={timeZone}
          minimalTimezoneSet={minimalTimezoneSet}
          handleCountry={handleCountry}
          email={email}
          signUpSuccess={signUpSuccess}
        />
}