import React, { useEffect, useState } from 'react'

import {
  sitetypes, 
  typeNames,
} from '../constants/help'

import SupportTutorialPage from '../components/Pages/SupportTutorialPage'

export default function SupporTutorial(){
  
    const [ catchoice, setCatChoice ] = useState(0)
    const [ typechoice, setTypeChoice ] = useState(0)

    const handleChoice = (value) => setCatChoice(value)
    const handleType = (value) =>{ 
      setTypeChoice(value)
      handleChoice(0)
    }

    useEffect(() => {
      speechSynthesis.cancel()
    });

    return(
      <main sx={{flexGrow: 1}}>
        <SupportTutorialPage
          catchoice={catchoice}
          handleChoice={handleChoice}
          sitetypes={sitetypes}
          typechoice={typechoice}
          handleType={handleType}
          typeNames={typeNames}
        />
      </main>
    )
}