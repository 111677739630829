import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import {
  LinkBasic, 
} from '../ListItems/BasicLinks'

const Translation = ({
    orig_text,
    trans_text,
    orig_lang,
    trans_lang,
    id,
}) =>
            <Grid container 
              alignItems="center"
              justifyContent='space-between'
            >
              <Grid item xs={6} zeroMinWidth>
                <LinkBasic 
                    to={`/user/translation/${id}/${orig_text}/${trans_lang}/${orig_lang}/${trans_lang}`} 
                    title={orig_text}
                  />

                {/* <Typography sx={{ color:'#6415ff',  overflowWrap: 'break-word', }} >
                    {orig_text}
                </Typography>  */}
              </Grid>

              <Grid item xs={6} zeroMinWidth>
                <Typography style={{overflowWrap: 'break-word'}}>
                {trans_text}
                </Typography>
              </Grid>
            <Grid item xs={12} >
                <Divider sx={{
                    marginTop: 1, 
                    marginBottom: 1,
                    }} />
            </Grid>
            </Grid>

export default Translation