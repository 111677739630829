import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import {CircularProgress, Divider} from '@mui/material'

import LoginHeader  from '../Login/LoginHeader'
import LoginHelp from '../Login/LoginHelp'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import EmailIcon from '@mui/icons-material/Email';
import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
// import { Google, Apple } from '@mui/icons-material'

export default function SignInPage({
    inputs, 
    loginPassword, 
    alert,
    snack,
    msgType,
    alertMsg,
    handleClose,
    loading,
    // loginGoogle,
    // loginApple,
    provloading,
}){

  // const buttonItems = [
  //   {
  //     title: 'Email Sign In',
  //     icon: <EmailIcon />,
  //     onClick: loginPassword,
  //   },
  //   {
  //     title: 'Google Sign In',
  //     icon: <Google />,
  //     onClick: loginGoogle,
  //   },
  //   {
  //     title: 'Apple Sign In',
  //     icon: <Apple />,
  //     onClick: loginApple,
  //   },
  // ]

  return (
      <Container component="main" maxWidth="xs">
        <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
          <Grid container 
            justifyContent='center' 
            textAlign='center'
            spacing={2}
          >
            {/* <Grid item xs={12}>
              <LoginHeader title='Sign In' />
            </Grid>  */}
            {provloading ? 
              <Grid item xs={12}  >
                <CircularProgress />
              </Grid>
            :
            <>
            {/* {inputs.map(l => 
              <Grid item xs={12} key={l.name} >
                <LoginInput {...l} error={alert} submitFunc={loginPassword} />
              </Grid>
            )}  */}
            <Grid item xs={12} >
              {/* <LoadingIconButton 
                title='Email Sign In'
                icon={<EmailIcon />}
                onClick={loginPassword}
                size='large'
                loading={loading}
              /> */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>  
            {/* {buttonItems.map(b => 
              <Grid item xs={12} key={b.title}>
                <LoadingIconButton 
                  size='large'
                  {...b}
                  loading={loading}
                />
              </Grid>
            )} */}
            </>
          }
          </Grid>
          <Box mt={3}>
            {/* <LoginHelp /> */}
          </Box>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Paper>
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        />
      </Container>
  )
}