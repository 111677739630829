import React, { useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'

import IconButton from '../Buttons/IconButton'
import QuizSelect from '../DropDowns/QuizSelect'
import NotificationSwitch from '../Switches/NotificationSwitch'
import SelectButton from '../Buttons/SelectButton'
import RedButton from '../Buttons/RedButton'

import { 
  minimalTimezoneSet,
  times1,
  getTranslations,
  getQuizType,
 } from '../../utils'

export default function UpdateQuiz({
  quizExists,
  quizActive,
  toggleSwitch,
  startTime,
  handleStartTime,
  endTime,
  handleEndTime,
  handleSuccess,
  success,
  quizTimeZone,
  handleTimeZone,
  updateVocabQuiz,
  addVocabQuiz,
  quizType,
  handleQuizType,
  edit,
  toggleEdit,
}){

  const { 
    onoff, 
    on, 
    off, 
    hourly, 
    and, 
    update, 
    updated,
    add, 
    quiz,
 } = getTranslations()

  useEffect(() => {
    speechSynthesis.cancel()
  }); 

    return(
        <div style={{margin:5}}> 
        {quizExists ?
          <Grid container 
                alignItems="center" 
                spacing={2} 
          >
             <Grid item>
              <Typography  >
                {onoff}
              </Typography>
            </Grid>
            <Grid item>
              <Typography >
                <strong style={{color: quizActive ? green['A400'] : red['A400']}}> {quizActive ? on : off}</strong>
              </Typography>
            </Grid>
            <Grid item>
              <NotificationSwitch
                checked={quizActive ? quizActive : false }
                onChange={toggleSwitch}
              />
            </Grid>
            {!edit &&
              <Grid item>
                <Typography>
                  Quiz Type:  <span style={{color: '#6415ff'}}>{getQuizType(quizType)}</span>
                </Typography>
              </Grid>
            }
            {!edit &&
              <Grid item xs={12} >
                <Typography>
                  Sent Hourly Between <span style={{color: '#6415ff'}}>{startTime}</span> and <span style={{color: '#6415ff'}}>{endTime} {quizTimeZone}</span> 
                </Typography>
              </Grid>
            }
            
            <Grid item xs={12}>
            {edit &&
                <Grid container
                      spacing={2}
                      alignItems='center'
                >
                  <Grid item>
                    {hourly}
                  </Grid>
                  <Grid item>
                    <QuizSelect 
                      label='Start'
                      value={startTime}
                      handleFunc={handleStartTime}
                      propName='startTime'
                      items={times1}
                    />
                  </Grid>
                  <Grid item>
                    {and}
                  </Grid>
                  <Grid item>
                    <QuizSelect
                      label='End' 
                      value={endTime}
                      handleFunc={handleEndTime}
                      propName='endTime'
                      items={times1}
                    />
                  </Grid>
                  <Grid item>
                    <QuizSelect 
                      label='Time Zone'
                      value={quizTimeZone}
                      handleFunc={handleTimeZone}
                      propName='timeZone'
                      items={minimalTimezoneSet}
                    />
                  </Grid>
                  <Grid item>
                  <Grid container spacing={1}>
                    <Grid item >
                      <SelectButton 
                        title={getQuizType('lastanswered')}
                        actionFunc={()=> handleQuizType('lastanswered')}
                        selectedQuizType={quizType}
                        quizType='lastanswered'
                      />
                    </Grid>
                    <Grid item >
                      <SelectButton 
                        title={getQuizType('spaced')}
                        actionFunc={()=> handleQuizType('spaced')}
                        selectedQuizType={quizType}
                        quizType='spaced'
                      />
                    </Grid>
                    <Grid item  >
                      <SelectButton 
                        title={getQuizType('recentadd')}
                        actionFunc={()=> handleQuizType('recentadd')}
                        selectedQuizType={quizType}
                        quizType='recentadd'
                      />
                    </Grid>
                    <Grid item  >
                       <SelectButton 
                        title={getQuizType('random')}
                        actionFunc={()=> handleQuizType('random')}
                        selectedQuizType={quizType}
                        quizType='random'
                      />
                    </Grid>
                    <Grid item  >
                      <SelectButton 
                        title={getQuizType('unanswered')}
                        actionFunc={()=> handleQuizType('unanswered')}
                        selectedQuizType={quizType}
                        quizType='unanswered'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              }
            </Grid>
            <Grid item>
              <IconButton 
                onClick={edit ? updateVocabQuiz : toggleEdit} 
                title={edit ? update : 'Edit'}
                icon={<EditIcon />}
              />
            </Grid>
            {edit && 
              <Grid item>
                <RedButton  
                  title='Close'
                  onClick={toggleEdit}
                />
               </Grid>
            }
            {success && 
              <Grid item>
                <Alert severity="success" onClose={handleSuccess}>
                  {updated}
                </Alert>
              </Grid>
            }
          </Grid> 
          :
          <IconButton 
            onClick={addVocabQuiz} 
            title={`${add} ${quiz}`}
            icon={<AddIcon />}
          />
        }     
      </div>
      )
    }