import React from "react";
import { Link as RouterLink } from 'react-router-dom'

import { useLiveQuery } from "dexie-react-hooks"

import { dexdb } from "../../App"

import Link from "@mui/material/Link";
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip';
import { Typography } from "@mui/material";

export default function GoalsProgress(){

    const goals = useLiveQuery(() => dexdb.goals.toArray(),[])
    const activeGoals = goals ? goals.filter(g => g.active) : []

    return(
        <React.Fragment> 
            {activeGoals && activeGoals.length>0 ? 
              <Grid container 
                  spacing={2}
                  alignItems="center"
              > 
                <Grid item  >
                  <Link 
                    to='/user/goals'
                    component={RouterLink} 
                    color='#6415ff' 
                    variant='h6'
                    sx={{cursor: 'pointer'}} 
                  >
                    Daily
                  </Link> 
                </Grid> 
                <Grid item    >
                  <Chip 
                      label={`${activeGoals[0].quiz_goal} ${activeGoals[0].quiz_goal>1 ? 'Quiz' : 'Quiz'}`}
                      size='small'  
                      sx={{
                        backgroundColor: '#00695f', 
                        color: 'white', 
                        width: 80
                      }} 
                    />
                  </Grid> 
                <Grid item >
                  <Chip 
                    label={`${activeGoals[0].read_goal} ${activeGoals[0].read_goal>1 ? 'Read' : 'Read'}`}
                    size='small'  
                    sx={{
                      backgroundColor: '#2a3eb1', 
                      color: 'white', 
                      width: 80
                    }} 
                  />
                </Grid> 
                <Grid item >
                  <Chip 
                    label={`${activeGoals[0].listen_goal} Listen`}
                    size='small'  
                    sx={{
                      backgroundColor: '#b12a3e', 
                      color: 'white', 
                      width: 80
                    }} 
                  />
                </Grid> 
              </Grid> 

            :  
              <Grid container 
                    spacing={2}
                    alignItems="center"
              > 
                <Grid item >
                  <Link 
                    to='/user/goals'
                    component={RouterLink} 
                    color='#6415ff' 
                    variant='h6'
                    sx={{cursor: 'pointer'}} 
                  >
                    Goals
                  </Link> 
                </Grid> 
                <Grid item >
                  <Typography variant="h6">None</Typography>
                </Grid> 
              </Grid> 
            }
          </React.Fragment>
      )
  }