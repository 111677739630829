/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useLiveQuery } from "dexie-react-hooks"

import {analytics, dexdb} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import { 
  deleteCluster,
  updateCluster,
} from '../queries/clusterQueries'

import {
  createTranslatedArt1
} from '../queries/translatedArtQueries'

import {
  parseArt
} from '../queries/workerQueries'

 import { 
  isValidHttpUrl,
  getTranslations,
  formatRecLinks,
  formatClusterLinks1,
  checkQuizCount,
 } from '../utils'

import ClusterPage from '../components/Pages/ClusterPage'

const agree = 'Text looks fine.'
const disagree = "Didn't parse correctly. Close"

const getClusterRecs = (recs, cluster_id) => formatRecLinks(recs.filter(r => r.cluster_id == cluster_id))

const getClusterLink1 = (links, clusterName, lang, cluster_id) => formatClusterLinks1(links, clusterName, lang, cluster_id)

export const getClusterItem = (clusters, cluster_id) => clusters.filter(l => l.id == cluster_id)[0]

export default function Cluster({history}){

  const subscribeLink = `/user/profile`

  const { invalidurl } = getTranslations()

  const { 
    cluster_id, 
  } = useParams()

  const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []);
  const recommendations = useLiveQuery(() => dexdb.recommendations.where('cluster_id').equals(parseInt(cluster_id)).toArray(), []) ?? [];

  const clusterNum = clusters ? clusters.map(c => c.active==true).length : 0

  const cluster = clusters && getClusterItem(clusters, cluster_id)

  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const role = member && member.length>0 ? member[0].role : 'basic' 
  const nativeLang = member && member.length>0 ?  member[0].native_lang : 'en' 

  const lang = cluster ? cluster.lang : 'en'
  const clusterName = cluster ? cluster.cluster_name : 'cluster'
  const clusterLinks3 = cluster ? cluster.translated_arts : []
  const linkCount = clusterLinks3.length
  const clusterLinks2 = cluster ? getClusterLink1(clusterLinks3, clusterName, lang, parseInt(cluster_id)) : []

  const active = cluster ? cluster.active : false

  const clusterRecs = recommendations ? getClusterRecs(recommendations, cluster_id) : []

  const [ url, setSearchLink ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ confirm, setConfirm ] = useState(false)
  const [ deleteConfirm, setDeleteConfirm ] = useState(false)
  const [ error, setError] = useState(false)
  const [ linkError, setLinkError] = useState(false)
  const [ linkerrorMsg, setlinkErrorMsg] = useState('')
  const [ errorMsg, setErrorMsg] = useState('')
  const [ clusterActive, setClusterActive ] = useState(false)
  const [ activateMsg, setActivateMsg] = useState('')
  const [ parsedArtTitle, setParsedArtTitle ] = useState('')
  const [ parsedArtText, setParsedArtText ] = useState('')
  const [ addUrl, setAddUrl ] = useState(false)

  const toggleUrl = () => setAddUrl(!addUrl)
  
  const confirmDelete = () => setDeleteConfirm(!deleteConfirm)

  const closeConfirm = () => setConfirm(false)

  const handleClose = () => setSnack(false)

  const handleLink = event => setSearchLink(event.target.value)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  const activateCluster = async () => {
    try {
      await updateCluster({
        id: cluster_id,
        active: !clusterActive
      })
      setClusterActive(!clusterActive)
      cluster.active=!clusterActive
      await dexdb.clusters.put(cluster)
      logEvent(analytics, 'toggle_cluster', {
        lang, 
        active,
        device: 'web',
      })
    } catch(error){
      showSnack('error', 'Error activating cluster!')
    }
  }

  const addTransArt = async () => {
    try {
      await createTranslatedArt1({
        url,
        lang,
        title: parsedArtTitle, 
        orig_text: parsedArtText,
        orig_lang: nativeLang,
        cluster_id,
      })
      setSearchLink('')
      showSnack('success', 'Url added!')
      logEvent(analytics, 'add_cluster_link', {
        lang,
        cluster_id,
        device: 'web',
      })
    } catch(error){
       showSnack('error', 'Error adding url!')
    } finally {
      setLoading(false)
      setConfirm(false)
    }
  }
  
  const deleteClusterConfirm = async () =>{ 
    setDeleteConfirm(false)
    await delClust()
  }

  const delClust = async () => {
    try {
      await deleteCluster({
        cluster_id,
      })
     
      history.push('/user/clusters')
    } catch(error){
      showSnack('error', 'Error deleting cluster!')
    }
  }
   
  const handleActivate = async () => {
    if(role==='plus' || role==='admin'){
      await activateCluster()
      return
    }

    if(clusterNum>=3 && !clusterActive){
      setError(true)
      setErrorMsg('Only have 3 active clusters allowed.')
      setActivateMsg('')
    } else {
      await activateCluster()
    }
  }

  const getParsedArt = async () => {
    setLinkError(false)
    const urlok = isValidHttpUrl(url)

    if(!urlok){
      setLinkError(true)
      setlinkErrorMsg(invalidurl)
      setLoading(false)
      return
    }
    setLoading(true)

    try {
      const data = {
        url, 
        selector: 'title, p', 
        nativeLang,
       }

      const parsedArt = await parseArt(data);

      setParsedArtTitle(parsedArt.title)
      setParsedArtText(parsedArt.art)
      setConfirm(true)
      setLinkError(false)
      setlinkErrorMsg('')
    } catch(error){
      showSnack('error', error.message)
      setLinkError(true)
      setlinkErrorMsg(error.message)
    } finally {
      setLoading(false)
    }
  }
  
  useEffect(() => {
    setClusterActive(active)
    checkQuizCount()
  },[active])
  if(!clusters) return <div></div>
  return <ClusterPage 
          clusterName={clusterName} 
          cluster_id={cluster_id}
          lang={lang}
          count={linkCount}
          url={url}
          loading={false}
          linkLoading={loading}
          error={error}
          errorMsg={errorMsg}
          activateMsg={activateMsg}
          clusterLinks={clusterLinks2}
          clusterRecs={clusterRecs}
          handleLink={handleLink}
          deleteCluster={deleteClusterConfirm}
          confirmDelete={confirmDelete}
          closeConfirm={closeConfirm}
          deleteConfirm={deleteConfirm}
          confirm={confirm}
          clusterActive={clusterActive}
          handleActivate={handleActivate}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          handleClose1={handleClose1}
          subscribeLink={subscribeLink}
          linkError={linkError}
          linkerrorMsg={linkerrorMsg}
          parseArt={getParsedArt}
          parsedArtTitle={parsedArtTitle}
          parsedArtText={parsedArtText}
          disagree={disagree}
          agree={agree}
          addParsedArt={addTransArt}
          recLoading={false}
          isLoading={false}
          toggleUrl={toggleUrl}
          addUrl={addUrl}
        />
}