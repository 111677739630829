import React from 'react'

import { getTranslations } from '../../utils'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ListAlt from '@mui/icons-material/ListAlt'
import TranslationIcon from '@mui/icons-material/Translate'
import Container from '@mui/material/Container'

import QuizFlags from '../ImageElements/QuizFlags'
import BasicButton from '../Buttons/BasicButton'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import AdQuizBar from '../Ads/AdQuizBar'
import AdSidebar from '../Ads/AdSidebar'

export default function VocabQuizSentPage({
  checkAnswer,
  toggle,
  handleGuess,
  native_lang, 
  language,
  guess,
  feedback, 
  newQuestion,
  curQuestion,
  curAnswer,
  correct,
  lang,
  snack,
  msgType,
  alertMsg,
  handleClose,
  isLoading,
}){

    const { 
      what, 
      mean, 
      submit, 
      translation,
    } = getTranslations()

    const submitAnswer = event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        checkAnswer()
      }
    }
      
    return( 
      <Grid container spacing={1}  >
      <Grid item xs={12} lg={9}>
        <Container component="main" maxWidth="xl" >
          <main >
              <Grid container spacing={3} >
                <Grid item md={8}>
                {!isLoading && 
                  <Paper sx={{ padding:3 }}> 
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <QuizFlags 
                          orig_lang={lang} 
                          native_lang={native_lang}  
                        />
                      </Grid> 
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>
                          {what} <span style={{color:'#6415ff'}}>{newQuestion}</span> {mean} {language}?
                        </Typography>
                      </Grid> 
                      <Grid item xs={12}>
                        <TextField 
                          onChange={handleGuess} 
                          value={guess} 
                          onKeyDown={submitAnswer}
                          sx={{ width:'100%' }}
                          id="vocab-guess" 
                          label={translation}  
                          placeholder="Guess"
                          variant="outlined"
                        />
                      </Grid> 
                      <Grid item xs={12}>
                        {feedback ?
                          <Button 
                            disabled 
                            variant='contained' 
                            fullWidth
                          >
                            {submit}
                          </Button>
                          :
                          <BasicButton  
                            actionFunc={checkAnswer} 
                            title={submit}
                          /> 
                        }
                      </Grid>
                    </Grid> 
                  </Paper>
                  }
                  {feedback &&
                    <Paper sx={{ mt:1, mb: 2 }} onClick={toggle}> 
                      <Alert 
                        severity={correct ? 'success' : 'error' } 
                        sx={{fontSize:18}}
                      >
                        <b>{curQuestion}</b> = <b>{curAnswer}</b>.
                      </Alert>
                    </Paper>
                  }
                  {feedback &&
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <BasicLinkIconButton 
                            fullWidth={true}
                            to={`/user/vocabteststart`}
                            title='Vocabuluary Quiz'
                            icon={<QuestionAnswerIcon />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BasicLinkIconButton 
                            fullWidth={true}
                            to={`/user/translations/${lang}`}
                            title='Edit Vocabuluary'
                            icon={<TranslationIcon />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BasicLinkIconButton 
                            fullWidth={true}
                            to={`/user/recommendations`}
                            title='Read Articles'
                            icon={<ListAlt />}
                          />
                        </Grid>
                      </Grid> 
                  }
                  {feedback &&
                    <div style={{marginTop: 10}}>
                      <AdQuizBar />
                    </div>
                  }
                </Grid> 
                {feedback &&
                  <div style={{marginTop: 5, marginLeft: 20}}>
                    <AdSidebar />
                  </div>
                }
              </Grid> 
              <SnackAlert 
                snack={snack}
                handleClose={handleClose}
                msgType={msgType}
                alertMsg={alertMsg}
              />
            </main>
        </Container>
      </Grid>
    </Grid>

  )
}