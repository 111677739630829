import React from "react";

import Grid from '@mui/material/Grid'

import {
    LinkBasic, 
  } from '../ListItems/BasicLinks'

import DeleteIcon from "@mui/icons-material/Delete";
import FlagRound from '../ImageElements/FlagRound'

import TooltipButton from "../Buttons/TooltipButton";

export default function LibraryItem({
    title,
    url,
    lang,
    deleteFunc
}){
    const urlEncoded = encodeURIComponent(url)
    const link = `/user/gutenberg/${lang}/${urlEncoded}`
    const deleteItem = () => deleteFunc(url)

    return  <Grid item xs={12}>
                <Grid container 
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Grid item >
                        <Grid container 
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid item >
                                <FlagRound 
                                    flagSize='xsmall'
                                    lang={lang}
                                />
                            </Grid>
                            <Grid item>
                                <LinkBasic to={link} title={title} variant='body1' />
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item>
                        <TooltipButton 
                        disabled={false}
                        title='Delete from Library'
                        func={deleteItem}
                        color='error'
                        icon={<DeleteIcon />}
                        />
                    </Grid>
                </Grid>
            </Grid>
}
