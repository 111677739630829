import React from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

const QuizSelect = ({
    value,
    handleFunc,
    propName, 
    items,
    label,
  }) => 
    <FormControl variant="standard" >
       <InputLabel required >
        {label}
      </InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleFunc}
        inputProps={{
          name: propName,
          id: propName,
        }}
      >
        {items.map(t => 
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        )}
      </Select>
    </FormControl>

export default QuizSelect