import {supabase} from '../supabase'
import moment from 'moment'
import { dexdb } from "../App.js"
import { getAuthuser } from './memberQueries';
import { postAuth } from '../utils';

const baseurl1 = process.env.REACT_APP_CF_WORKER_URL

// const baseurl1 = 'http://0.0.0.0:8787'

const filterArray = (array, day, count)=> array.filter((a) => a.day===day).length>0 ? array.filter((a) => a.day===day)[0][count] : 0;

const thirtyone = [1, 3, 5, 7, 10, 12];

const getDays = days => [...Array(days).keys()]

const checkLeapYear = (year) => ((0 === year % 4) && ((0 !== year % 100) || (0 === year % 400)));

const getMonthDays = (month, year) => {
    const leap = checkLeapYear(year);
    if (month===3) {
      return leap ? getDays(29) : getDays(28)
    }
    if (!thirtyone.includes(month) && month!==3) {
      return getDays(31)
    }
    if (thirtyone.includes(month) && month!==3) {
      return getDays(30)
    }
};

function getDayCount(days){
	const now = new Date()
	var temp = new Date()
	temp.setDate(temp.getDate()-days);
	return getDatesInRange(temp.toISOString(), now.toISOString())
	
}

function getDatesInRange(startDate, endDate) {
  const start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
  const end = new Date(new Date(endDate).setUTCHours(0, 0, 0, 0));
  
  const date = new Date(start.getTime());

  const dates = [];

  while (date <= end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export const userProgress = async ({
    month,
    year,
  }) => {
    const user = await getAuthuser()
    if(user){
    const aid = user ? user.id : ''
    const currMonthDays = getMonthDays(month+1, year);

    const quiz_prog = await userQuizProgress({
      aid,
      month,
      year,
    });

    const art_prog = await userArtsProgress({
      aid,
      month,
      year,
    });
  
    return currMonthDays.map((d) => ({
      day: d+1,
      arts_count: filterArray(art_prog, d+1, "art_count"),
      quiz_count: filterArray(quiz_prog, d+1, "answer_count"),
    }));
  }
  };

const userQuizProgress = async ({
  aid,
  month,
  year,
}) => {

  const {data, error} = await supabase
      .rpc("user_quiz_answer_progress", {
        uaid: aid,
        month: month,
        year: year,
      });

  if (error) throw error.message;

  return data.map((r) => ({
    answer_count: r.answer_count,
    day: parseInt(r.quiz_date.slice(8,10)),
  }));
};

const userArtsProgress = async ({
  aid,
  month,
  year,
}) => {
  const {data, error} = await supabase
      .rpc("user_arts_read_count1", {
        uaid: aid,
        month: month,
        year: year,
      });

  if (error) throw error.message;

  return data.map((r) => ({
    art_count: r.art_count,
    day: parseInt(r.art_read_date.slice(8,10)),
  }));
};

const userQuizProgressSeven = async () => {
  const user = await getAuthuser()
  const aid = user.id

  const {data, error} = await supabase
      .rpc("user_quiz_answer_progress_seven", {
        uaid: aid,
      });

  if (error) throw error.message;

  return data.map((r) => ({
    answer_count: r.answer_count,
    day: r.quiz_date.slice(0, 10),
  }));
};

const userArtsProgressSeven = async () => {
  const user = await getAuthuser()
  const aid = user.id
  const {data, error} = await supabase
      .rpc("user_arts_read_progress_seven", {
        uaid: aid,
      });

  if (error) throw error.message;

  return data.map((r) => ({
    art_count: r.art_count,
    day: r.art_read_date.slice(0, 10),
  }));
};

export const userProgressSeven = async () => {
  const currMonthDays = getDayCount(14);

  const currMonthDays1 = currMonthDays.map((d) => d.toISOString().slice(0, 10));

  const art_prog = await userArtsProgressSeven();

  const quiz_prog = await userQuizProgressSeven();

  const progress = currMonthDays1.map((d) => ({
    day: moment(d).format("MM-DD-YYYY"),
    arts_count: filterArray(art_prog, d, "art_count"),
    quiz_count: filterArray(quiz_prog, d, "answer_count"),
  }));

  progress.reverse();
  return progress;
};

export const getProg = async ({
  month,
  year,
}) => {
  const linkUrl1 = `${baseurl1}/get_progress`

  try {
    return await postAuth(linkUrl1,  {
      month,
      year,
    })
  } catch(error){
    throw error
  }
}

export const incrementProgress = async (progressField, increment) => {
  const now = new Date()
  const day = now.getDate()
  const mon = now.getMonth() + 1
  const yr = now.getFullYear()
  const progKey = mon.toString()+yr.toString()
  const crup = await dexdb.progress_months.where("id").equals(progKey).toArray()
  const progm = crup[0]
  progm['days'][day-1][progressField]=progm['days'][day-1][progressField]+increment
  await dexdb.progress_months.update(progKey, progm)
}