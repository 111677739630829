import React from "react";
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import useWindowDimensions from '../../helper'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import TranslateIcon from '@mui/icons-material/Translate'

const TranslationField = ({
    transWord,
    translateWord1,
    handleTransWord,
    transLoading,
}) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const {width}= useWindowDimensions()
    const fieldWidth = matches ? width*.28 : width*.84
    const buttonSize = matches ? 'medium' : 'small'
    
    return  <Grid container spacing={1}  alignItems="center"  >
              <Grid item > 
                <TextField 
                    size="small"
                    variant='outlined'
                    value={transWord} 
                    onChange={handleTransWord} 
                    label='Select and paste'
                    fullWidth
                    sx={{width: fieldWidth}}
                /> 
              </Grid>
              <Grid item > 
                <LoadingIconButton 
                  size={buttonSize}
                  loading={transLoading}
                  title="Translate"
                  onClick={translateWord1}
                  icon={<TranslateIcon />}
                />
              </Grid>
            </Grid>
}

export default TranslationField