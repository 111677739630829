import React from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField'
import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox'

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'

import AdSidebar from "../Ads/AdSidebar"
import SnackAlert from '../Dialogs/SnackAlert'
import FlagRound from '../ImageElements/FlagRound'
import { Divider, IconButton } from '@mui/material';
import { langSwitch } from '../../utils'


export default function GoalsPage({
    lang,
    handleReadGoal,
    read_goal,
    handleClose,
    handleListenGoal,
    listen_goal,
    handleQuizGoal,
    quiz_goal,
    loading,
    addGoals,
    deleteGoal1,
    activateGoal,
    goals,
    snack,
    msgType,
    alertMsg,
}){
    const language = langSwitch(lang)
    return(
        <Grid container spacing={1} justifyContent='center' >
        <Grid item xs={12} lg={9}>
          <Container component="main" maxWidth="xl" >
    <Card>
       <CardContent>
            <Typography variant={'h5'} sx={{color:'#6415ff'}}>
                Goals
            </Typography>
        </CardContent>
        <Divider />
        <CardContent>       
            <Grid container spacing={2} alignItems='center'  >
                    {lang.length>0 &&  <Grid item ><FlagRound flagSize='small' lang={lang} /> </Grid>}
                <Grid item >
                    <Typography variant='h5'>{language}</Typography>
                </Grid>
                <Grid item >
                    <TextField  
                        size='small'
                        label="Articles/Day" 
                        variant="outlined" 
                        onChange={handleReadGoal} 
                        type='number'
                        value={read_goal}
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        size='small' 
                        label="Quizzes/Day" 
                        variant="outlined" 
                        onChange={handleQuizGoal} 
                        type='number'
                        value={quiz_goal}
                    />
                </Grid>
                <Grid item >
                    <TextField  
                        size='small'
                        label="Listen (mins)/Day" 
                        variant="outlined" 
                        onChange={handleListenGoal} 
                        type='number'
                        value={listen_goal}
                    />
                </Grid>
                <Grid item >
                    <LoadingButton
                        variant='contained'
                        loading={loading}
                        startIcon={<AddIcon />}
                        onClick={addGoals} 
                    >
                         Add Goal
                    </LoadingButton>
                </Grid>
            </Grid>
        </CardContent>  
        <Divider />  
        <CardContent>       
            <TableContainer >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell align="right">Articles</TableCell>
                        <TableCell align="right">Quizzes</TableCell>
                        <TableCell align="right">Listening (mins)</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {goals && goals.length>0 && goals.map((row, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={row.active}
                                    onChange={() => activateGoal(row.id)}
                                />
                            </TableCell>
                            <TableCell >{row.lang.length>0 && <FlagRound flagSize='small' lang={row.lang} />}</TableCell>
                            <TableCell align="right">{row.read_goal}</TableCell>
                            <TableCell align="right">{row.quiz_goal}</TableCell>
                            <TableCell align="right">{row.listen_goal}</TableCell>
                            <TableCell align="right"><IconButton onClick={() => deleteGoal1(row.id)}  ><DeleteIcon color="error" /> </IconButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
        </CardContent>       
    </Card>
    <SnackAlert 
      snack={snack}
      handleClose={handleClose}
      msgType={msgType}
      alertMsg={alertMsg}
    />
     </Container>
    </Grid>
    <Grid item>
      <AdSidebar />
    </Grid>
  </Grid>
  )
}


