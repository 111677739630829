import {supabase} from "../supabase"
import {
    getNowIso,
    getMillli,
    formatRecLinks1,
} from '../utils'
import { getAuthuser } from "./memberQueries.js"
import { setDbArt } from "./recommendationQueries"
import { dexdb } from '../App'

const table = 'playlist'

export const createPlaylist = async ({
    art_id,
    rec_id,
    aid,
    playlist_position,
    playlist_date,
}) => {
        const { data, error } = await supabase
            .from(table)
            .insert({
                art_id,
                rec_id,
                aid,
                played: false,
                active: true,
                playlist_position,
                playlist_date,
            })
            .select('id')

        if(error) throw new Error(error.message)

        return data[0]['id']
}

export const createPlayedArtPlaylist = async ({
    art_id,
    aid,
    played_date,
}) => {
        const { error } = await supabase
            .from(table)
            .insert({
                art_id,
                aid,
                played: true,
                active: false,
                played_date,
            })

        if(error) throw new Error(error.message)

        return
}

const storePLDex = async ({
    art_date,
    title,
    art_id,
    lang,
    id,
    aid,
    playlist_position,
}) => {
    const newPL = {
        art_date,
        title,
        art_id,
        lang,
        id,
        aid,
        timestamp: getMillli(art_date),
        playlist_position,
      }
    await dexdb.playlist.add(newPL)
    await setDbArt(art_id)
}

export const createPlaylist2 = async ({
    art_id, 
    rec_id,
    playlistId, 
    art_date,
    title,
    lang,
}) => {
    const user= await getAuthuser()
    if(!user) throw new Error('You must be logged in!')
    const aid = user.id 
    const count = await getPlaylistCount()
    const playlist_position = count+1
    const playlist_date = getNowIso()
    if(playlistId==='none'){
        const newid = await createPlaylist({
            art_id, 
            rec_id,
            aid,
            playlist_position,
            playlist_date,
        })
        await storePLDex({
            art_date,
            title,
            art_id,
            lang,
            id: newid,
            aid,
            playlist_position,
        })
        return newid
    } else {
        await updatePlaylist1({
            playlistId,
            playlist_position,
            playlist_date,
        })
        await storePLDex({
            art_date,
            title,
            art_id,
            lang,
            id: playlistId,
            aid,
            playlist_position,
        })
        return playlistId
    }
}

export const markPlayedArt = async ({
    art_id, 
}) => {
    const user= await getAuthuser()
    if(!user) throw new Error('You must be logged in!')
    const aid = user.id 
    const items = await getPlaylistArtCount({art_id})

    const played_date = getNowIso()
    if(items.length===0){
        const newid = await createPlayedArtPlaylist({
            art_id, 
            aid,
            played_date,
        })
        return newid
    } else {
        const playlistId = items[0]['id']
        await updatePlayedArtPlaylist({
            playlistId,
            played_date,
        })
    
        return playlistId
    }
}


export const updatePlaylist1 = async ({
    playlistId, 
    playlist_position,
    playlist_date,
}) => {
    const user= await getAuthuser()
    if(user){
        const { error } = await supabase
            .from(table)
            .update({
                active: true,
                playlist_position,
                playlist_date,
            })
            .eq('id', playlistId)

        if(error) throw new Error(error.message)

        return playlistId
    } else {
        throw new Error('You are not authenticated!')
    }
}

export const updatePlayedArtPlaylist = async ({
    playlistId, 
    played_date,
}) => {
    const user= await getAuthuser()
    if(user){
        const { error } = await supabase
            .from(table)
            .update({
                active: true,
                played_date,
            })
            .eq('id', playlistId)

        if(error) throw new Error(error.message)

        return playlistId
    } else {
        throw new Error('You are not authenticated!')
    }
}

const removePlDEx = async ({
    playlistId, 
    art_id
}) => {
    try {
        await dexdb.playlist.delete(playlistId)
        await setDbArt(art_id)
    } catch(error){
        throw error
    }
}
export const removePlaylist = async ({playlistId, art_id}) => {
    const user= await getAuthuser()
    if(user){
        const aid = user.id 
        const { error } = await supabase
            .from(table)
            .update({
                active: false,
                playlist_position: null,
            })
            .eq('id', playlistId)
        if(error) throw new Error(error.message)
        try {
            await removePlDEx({playlistId, art_id})
        } catch(error){
            throw error
        }
        return aid
    } else {
        throw new Error('You are not authenticated!')
    }
}

export const updatePlaylistOrder1 = async updatedPlaylist => {
    const updates = updatedPlaylist.map((p, i) => ({
        playlist_position: p.playlist_position, 
        id: p.id,
        aid: p.aid,
      }))
    const { error } = await supabase
        .from(table)
        .upsert(updates)
    if(error) throw new Error(error.message)
    return 
}

// QUERIES

const playlistQuery = `
    id,
    active,
    playlist_position,
    played,
    played_date,
    aid,
    articles(
        id,
        title,
        date,
        lang
    )
`

export const getPlaylistCount = async () => {
    let { error, count } = await supabase
        .from(table)
        .select('*', { count: 'exact' })
        .eq('active', true)
        .eq('played', false)

    if(error) throw new Error(error.message)

    return count
}
// article_id sent_index aid
export const getPlaylistArtCount = async ({art_id}) => {
    let { data, error} = await supabase
        .from(table)
        .select('id')
        .eq('played', false)
        .eq('art_id', art_id)

    if(error) throw new Error(error.message)
    return data
}

export const getAndStorePlaylist = async () => {
    const user_playlist = await getPlaylist1()
    const playlist = user_playlist.length>0 ? formatRecLinks1(user_playlist) : []
    await dexdb.playlist.bulkPut(playlist);
}

export const getPlaylist1 = async () => {
    let { data, error } = await supabase
        .from(table)
        .select(playlistQuery)
        .eq('active', true)
        .eq('played', false)
        .order('playlist_position')

    if(error) throw new Error(error.message)

    return data
}


export const getPlayedlist1 = async () => {
    let { data, error } = await supabase
        .from(table)
        .select(playlistQuery)
        .eq('played', true)
        .order('played_date', {
            ascending: false,
        })

    if(error) throw new Error(error.message)

    return data
}
