import React, {useMemo, useEffect}  from 'react'
import { useLiveQuery } from "dexie-react-hooks";

import { dexdb} from '../App'

import { checkQuizCount } from '../utils';

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import UpdateUser from '../components/Profile/UpdateUser'
import UpdateLangs from '../components/Profile/UpdateLangs'
import DownloadApps from '../components/Profile/DownloadApps'
import UpdateGoals from '../components/Profile/UpdateGoals'

export default function Profile(){

  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const fcm_token= useMemo(() => member && member.length>0 ? member[0].fcm_token : '', [member]);

  useEffect(() => {
    checkQuizCount()
  }, [])

  if(!member) return <div />
 
  return(
        <Container component="main" maxWidth="xl" >                    
          <Grid container spacing={2} alignItems='stretch'>
            <Grid item  xs={12} >
              <UpdateUser {...member[0]} />
              <div style={{height: 10}} />
              <UpdateLangs {...member[0]} /> 
              <div style={{height: 10}} />
              <UpdateGoals  />
            </Grid>
          </Grid>                
            {fcm_token.length===0 && <DownloadApps />}
        </Container>
  )
}