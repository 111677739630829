import React, { useState, useEffect } from "react"
import { useLiveQuery } from "dexie-react-hooks";

import {analytics, dexdb} from '../../App'

import { 
    logEvent, 
} from "firebase/analytics";
  
import {
    updateCluster,
} from '../../queries/clusterQueries'

import { langSwitch } from '../../utils'

import { getClusterItem } from '../../pages/Cluster'

import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import FlagRound from '../ImageElements/FlagRound'
import PurpleSwitch from '../Switches/LangaPurpleSwitch1'

import {LinkBasic} from './BasicLinks'
  
export default function ClusterLink({
    id,
    cluster_name: clusterName,
    lang,
    active,
    translated_arts,
}){

    const flagSize = 'small'
    const link = `/user/cluster/${id}`
    const subscribeLink = `profile`

    const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []) ?? [];
    const member = useLiveQuery(() => dexdb.member.toArray(), []) ?? [];

    const cluster = clusters && getClusterItem(clusters, id)

   const langs = member && member.length>0 ? member[0].langs : []

    const role = member && member.length>0 ?  member[0].role : 'basic'
    
    const clusterNum =  clusters.map(c => c.active==true).length 

    const singleLang =  langs[0] ?? 'en' 

    const language = langSwitch(singleLang)

    const [ clusterActive, setClusterActive ] = useState(false)
    const [ error, setError] = useState(false)
    const [ errorMsg, setErrorMsg] = useState('')
    const [ activateMsg, setActivateMsg] = useState('')
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setError(false);
    };

    const activateCluster = async () => {
        try {
            await updateCluster({
                id,
                active: !clusterActive
            })
            setClusterActive(!clusterActive)
            cluster.active=!clusterActive
            await dexdb.clusters.put(cluster)
            logEvent(analytics, 'toggle_cluster', {
                lang, 
                active,
                device: 'web',
            })
        } catch(error){
            setError(true)
            setErrorMsg('Error updating cluster!')
            setActivateMsg('')
        }
      }
       
    const activate = async () => await activateCluster() // activateClusterMutation.mutate()

    const handleActivate = async () => {
    if(clusterNum && clusterNum>0) {

    const selectedLang = langs.includes(lang)
    
    if(!selectedLang && role==='basic'){
        setError(true)
        setErrorMsg('You can only activate clusters for current active languages.')
        setActivateMsg('Manage your active langauges.')
        return
    }

    if(role==='plus' || role==='admin'){
        return activate()
    }

    if(clusterNum>=3 && !clusterActive){
        setError(true)
        setErrorMsg('Only have 3 active clusters allowed.')
        setActivateMsg('')
        return
    } 
    activate()
    } else {
        setError(true)
        setErrorMsg('Add links to your cluster before activating it.')
        setActivateMsg('')
        return
    }
    }

    useEffect(()=> {
    setClusterActive(active)
    },[active])
      
    const clusterLinksCount = translated_arts && translated_arts.length> 0 ?  translated_arts.length : 0

    return(
        <Grid   container 
                spacing={3} 
                justifyContent="space-between" 
                alignItems='center'
        >
            <Grid item>
                <Grid   container 
                        alignItems="center" 
                        spacing={3}
                >
                    <Grid item>
                        <FlagRound 
                            flagSize={flagSize}
                            lang={lang}
                        />
                    </Grid>
                    <Grid item>
                        <LinkBasic
                            variant="h5" 
                            to={link} 
                            title={`${clusterLinksCount} - ${clusterName}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {clusterLinksCount>0 &&
                <Grid item >
                    <PurpleSwitch
                        checked={clusterActive}
                        onChange={handleActivate}
                    />
                </Grid>
            }
            <Snackbar 
                open={error} 
                onClose={handleClose}
                anchorOrigin={{ 
                    vertical: 'top', 
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleClose} severity="error">
                    {errorMsg} Active language {language}.
                    {activateMsg.length>0 &&
                        <LinkBasic
                            variant="h6" 
                            to={subscribeLink} 
                            title={activateMsg}
                        />
                }
                </Alert>
            </Snackbar>
        </Grid>
    )
}
