import React from "react";

import { getTranslations } from '../../utils'

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import Alert from '@mui/material/Alert'

import Paper from '@mui/material/Paper'
import Divider from "@mui/material/Divider"
import AddIcon from '@mui/icons-material/Add'
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import ClusterLink from '../ListItems/ClusterLink'
import LangButton from '../Buttons/LangButton'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import AdSidebar from "../Ads/AdSidebar"
import { CircularProgress } from "@mui/material";

export default function ClustersPage({
    clusterName,
    lang,
    langs,
    addCluster,
    handleLink,
    clusters,
    setLang,
    loading,
    snack,
    msgType,
    alertMsg,
    handleClose,
    errorMsg,
    isError,
}){

    const trans = getTranslations()

    const submitAnswer = event => {
      if (event.key === 'Enter') {
        addCluster()
      }
    }
    return( 
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                      <main>
                        <Paper sx={{
                            padding: 3,
                            marginBottom: 2,
                          }} 
                        > 
                          <FormControl sx={{ width:'100%' }}>
                            <Grid container 
                                  justifyContent="space-between" 
                                  alignItems="center" 
                                  spacing={2} 
                            >
                              <Grid item md={6}> 
                                <TextField 
                                  fullWidth
                                  sx={{ width:'100%' }}
                                  required  
                                  onKeyDown={submitAnswer}
                                  label={trans.name} 
                                  value={clusterName} 
                                  onChange={handleLink} 
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                              <Grid item >
                                <ButtonGroup variant="contained">
                                {langs.map((l,i) => 
                                  <LangButton 
                                    key={i} 
                                    l={l} 
                                    lang={lang} 
                                    selectLang1={setLang} 
                                  />
                                )}
                                </ButtonGroup>
                              </Grid>
                              <Grid item >
                                <LoadingIconButton
                                  title='Add Cluster'
                                  onClick={addCluster}
                                  icon={<AddIcon />}
                                  loading={loading}
                                />
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Paper>
                        <Paper sx={{padding: 2}} > 
                          <Grid container 
                                justifyContent="space-between" 
                                alignItems="center" 
                                spacing={2} 
                          >
                          <Grid item > 
                            <Typography variant="h5" >
                              {trans.clusters}
                            </Typography>
                          </Grid>
                          <Grid item > 
                          <Typography  >
                              Receive Recommendations
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider  />
                          </Grid>
                          <Grid item xs={12}>
                            {loading ? 
                            <CircularProgress />
                            :
                            <Grid container 
                              spacing={1} 
                              justifyContent="space-between" 
                              alignItems='center'
                              >
                              {!isError && clusters && clusters.length>0 && clusters.map((l, idx)=> 
                                <Grid item xs={12} key={l.id}>
                                  <ClusterLink key={l.id}  {...l} idx={idx} />
                                </Grid>
                              )}
                            </Grid>
                            }
                          </Grid>
                        </Grid>
                      </Paper>
                      {!loading && isError &&   
                          <Alert severity="error" sx={{mt:2}}>
                            {errorMsg}
                          </Alert>
                      }
                      {!loading && clusters.length===0 &&   
                          <Alert severity="error" sx={{mt:2}}>
                            No Clusters. Add one now.
                          </Alert>
                      }
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                    </main>
                  </Container>
                </Grid>
                <Grid item>
                  <AdSidebar />
                </Grid>
              </Grid>
        
    )
}