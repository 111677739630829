import React from "react";

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import Alert from '@mui/material/Alert'
import { CircularProgress } from "@mui/material";
import Paper from '@mui/material/Paper'
import Divider from "@mui/material/Divider"
import DownloadIcon from '@mui/icons-material/Download'

import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'

import LangButton from '../Buttons/LangButton'

import LoadingIconButton from '../Buttons/LoadingIconButton'

import LibraryItem from '../ListItems/LibraryItem'
import AdSidebar from "../Ads/AdSidebar"
import { langSwitch } from "../../utils";

const GutenLang = ({lang}) => {
  const gutenlink = `https://www.gutenberg.org/browse/languages/${lang}`
  const language = langSwitch(lang)
  return (
    <Grid item > 
      <Typography variant='body2'>
        <a style={{color: '#6415ff'}} target="_blank" rel="noreferrer" href={gutenlink}>{language}</a> 
      </Typography>
    </Grid>
  )
}

export default function LibraryPage({
    lang,
    langs,
    addCluster,
    handleLink,
    setLang,
    loading,
    snack,
    msgType,
    alertMsg,
    error,
    handleClose,
    errorMsg,
    isError,
    searchurl,
    linkError,
    linkerrorMsg,
    libraryItems,
    isLoading,
    deleteItem,
}){

    const submitAnswer = event => {
      if (event.key === 'Enter') {
        addCluster()
      }
    }
    
    const language = langSwitch(lang)
    const libraryLang = libraryItems && libraryItems.length>0 ? libraryItems.filter(t => t.lang === lang) : []
    return( 
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                      <main>
                        <Paper sx={{
                            padding: 2,
                            marginBottom: 2,
                          }} 
                        > 
                          <FormControl sx={{ width:'100%' }}>
                            <Grid container 
                                  justifyContent="space-between" 
                                  alignItems='flex-start'
                                  spacing={1} 
                            >
                               <Grid item md={8} xs={12}> 
                                <Typography variant="h5">
                                  Public Domain Library
                                </Typography>
                               </Grid>
                               <Grid item md={8} xs={12}> 
                                <Grid container spacing={1} >
                                  <Grid item>
                                    <Typography variant='body2'>
                                      Urls from <a style={{color: '#6415ff'}} target="_blank" rel="noreferrer" href='https://www.gutenberg.org'>Project Gutenberg</a> 
                                    </Typography>
                                  </Grid>
                                  {langs.map(l => 
                                    <GutenLang 
                                      key={l}
                                      lang={l}
                                    />
                                  )}
                                  </Grid>
                               </Grid>  
                              <Grid item md={8} xs={12}> 
                                <TextField 
                                  error={linkError}
                                  helperText={linkerrorMsg}
                                  fullWidth
                                  sx={{ width:'100%' }}
                                  required  
                                  onKeyDown={submitAnswer}
                                  label='Text Url' 
                                  value={searchurl} 
                                  onChange={handleLink} 
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                              <Grid item md={4} xs={12}> 
                                <Grid container 
                                    alignItems='flex-start'
                                    justifyContent='flex-end'
                                    spacing={2} 
                                >
                                  <Grid item >
                                    <ButtonGroup variant="contained">
                                      {langs.map((l,i) => 
                                        <LangButton 
                                          key={i} 
                                          l={l} 
                                          lang={lang} 
                                          selectLang1={setLang} 
                                        />
                                      )}
                                    </ButtonGroup>
                                  </Grid>
                                  <Grid item >
                                    <LoadingIconButton
                                      title='Load Text'
                                      onClick={addCluster}
                                      icon={<DownloadIcon />}
                                      loading={loading}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>  
                              <Grid item xs={12}> 
                                <Divider />
                              </Grid>  
                
                              {isLoading &&
                                <Grid item xs={12}>  
                                  <CircularProgress />
                                </Grid>  
                              }
                              {!isLoading && libraryItems && libraryLang.length>0 && libraryLang.map(t => 
                                <LibraryItem 
                                  key={t.url} 
                                  deleteFunc={deleteItem}
                                  {...t} 
                                />
                              )} 
                               {!isLoading && libraryItems && libraryLang.length===0 &&  
                                <Grid item> 
                                    <Alert severity="error" sx={{mt:2}}>
                                      {`Library Empty for ${language} ! Load some texts above.`}
                                    </Alert>
                                  </Grid>  
                                }
                            </Grid>
                          </FormControl>
                        </Paper>
                      {!loading && isError &&   
                        <Alert severity="error" sx={{mt:2}}>
                          {errorMsg}
                        </Alert>
                      }
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                    </main>
                  </Container>
                  </Grid>
                  <Grid item>
                    <AdSidebar />
                  </Grid>
              </Grid>
        
    )
}