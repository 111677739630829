import {supabase} from "../supabase"

import { getAuthuser } from "./memberQueries.js"

const table = 'user_vocab_sentences'

export const updateVocabWordSent = async ({
    id,
    source_sent,
    target_sent,
}) => { 
    const { error } = await supabase
        .from(table)
        .update({
            'source_sent': source_sent,
            'target_sent': target_sent,
        }, {returning: 'minimal'})
        .match({ id: id })

    if(error) throw error
    return 
}

export const deleteVocabWordSent = async (
    id
) => {   
    const { error } = await supabase
        .from(table)
        .delete({returning: 'minimal'})
        .eq('id', id)

    if(error) {
        throw new Error(error.message)
    }

    return
}

export const insertVocabWordSent = async ({
    user_vocab_id,
    source_sent,
    target_sent,
    rec_id=null,
    art_id=null,
}) => { 
    const user = await getAuthuser()
    const aid = user.id
    const { data, error } = await supabase
    .from(table)
    .insert({
        'source_sent': source_sent,
        'target_sent': target_sent,
        'user_vocab_id': user_vocab_id,
        'aid': aid,
    })
    .select()

    if(error) throw new Error(error.message)

    return data[0]
}

export const addVocabSentence = async ({
    source_sent,
    target_sent,
    user_vocab_id,
}) => {
    const {error} = await supabase
    .from("user_vocab_sentences")
    .insert({
      "source_sent": source_sent,
      "target_sent": target_sent,
      "user_vocab_id": user_vocab_id,
    });

    if(error) throw error;
    return
}