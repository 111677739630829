import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom"
import { useLiveQuery } from "dexie-react-hooks"

import {
  langSwitch,
  splitSentence1,
  checkQuizCount,
}from '../utils'

import { 
  dexdb,
  analytics,
} from "../App";

import {
  getTranslation,
  getGutenberg,
} from '../queries/workerQueries'

import {
  addVocabWordArtSent
} from '../queries/vocabWordQueries'

import {
  createLibraryItem,
} from '../queries/libraryQueries'

import { 
    logEvent, 
} from "firebase/analytics";

import ReadGutenbergPage from '../components/Pages/ReadGutenbergPage'
import Error from '../components/Dialogs/Error'

export default function ReadGutenberg(){

  const { 
    url,
    lang, 
  } = useParams()

  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const synth = window.speechSynthesis;

  const decodedUrl = decodeURIComponent(url)

  const native_lang = useMemo(() => member ? member[0].native_lang : 'en', [member]);

  const userLangs = useMemo(() => member ? member[0].langs : [], [member]);

  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ title, setTitle ] = useState('')
  const [ searchresults, setsearchresults ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ transloading, setTransLoading ] = useState(false)
  const [ sentIdx, setsentIdx ] = useState(0)
  const [ curIdx, setCurIdx ] = useState()
  const [ linkError, setLinkError ] = useState(false)
  const [ linkerrorMsg, setlinkErrorMsg ] = useState('')
  const [ voiceIdx, setVoiceIdx ] = useState(0)
  const [ voices, setVoices ] = useState([])
  const [ rate, setRate ] = useState(1.0)
  const [ playing, setPlaying ] = useState(false)
  const [ adReload, setAdReload ] = useState(true)
  const [ vocabloading, setVocabLoading ] = useState(false)
  const [ errorMsg, seterrorMsg ] = useState('')

  const sourceLang = langSwitch(lang)
  const targetLang = langSwitch(native_lang)

  const selectSource = `Select ${sourceLang}`
  const selectTarget = `Select ${targetLang}`

  const [ sourceWord, setSourceWord] = useState(selectSource)
  const [ targetWord, setTargetWord] = useState(selectTarget)

  const handleClose = () => { 
    setLinkError(false)
    setlinkErrorMsg('')
    setSnack(false)
  }
  
  const handleVoice = event => setVoiceIdx(event.target.value)

  const handleRate = event => setRate(event.target.value)

  const handleSource = () => {
    const selObj = window.getSelection();
    const srcTxt = selObj.toString()
    setSourceWord(srcTxt)
  }
  const handleTarget = () =>{ 
    const selObj = window.getSelection();
    const srcTxt = selObj.toString()
    setTargetWord(srcTxt)
  }

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const updateDBIdx = async (num) => await dexdb.gutenbergIdx.update(curIdx, {sentIdx: sentIdx+num})

  const navSent = async (num) => {
    setAdReload(false)
    if(curIdx!==null){
      await updateDBIdx(num)
    }
    setsentIdx(prevPage => prevPage + num)
    setSourceWord(selectSource)
    setTargetWord(selectTarget)
    setAdReload(true)
  }

  const forward = async () => await navSent(+1)

  const back = async () => await navSent(-1)

  const faster = () => {
    if(rate<1){
      setRate(prevPage => prevPage + .1)
    }
  }

  const slower = () => {
    if(rate>0.1){
      setRate(prevPage => prevPage - .1)
    }
  }

  const getStoredGuten = async url => await dexdb.gutenberg.where("url").equalsIgnoreCase(url).toArray();

  const getStoredGutenIdx = async url => await dexdb.gutenbergIdx.where("url").equalsIgnoreCase(url).toArray();

  const origText = searchresults[sentIdx]?.orig_text ?? ''

  const curTranslation = searchresults[sentIdx]?.translation  ?? ''

  const addVocab = () => {
    if(sourceWord.length===0 | targetWord.length === 0){
      showSnack('error', `Please select a ${sourceLang} word and a ${targetLang} word.`)
      return
    }
    setVocabLoading(true)
    try {
      addVocabWordArtSent({
        source_word: sourceWord, 
        target_word: targetWord,
        source_lang: lang,
        target_lang: native_lang,
        source_sent: origText, 
        target_sent: curTranslation,
        url: decodedUrl,
        title,
      })
      showSnack('success','Vocabulary word added!')
      setSourceWord(selectSource)
      setTargetWord(selectTarget)
      logEvent(analytics, 'add_vocab', {
        source_lang: lang, 
        target_lang: native_lang, 
        number: 1,  
        type: 'gutenberg',
        device: 'web',
      })
    } catch(error){
      showSnack('error','Error adding vocabulary word!')
    } finally {
      setVocabLoading(false)
    }
  }

  const addLibraryItem = async () => {
    setLoading(true)
    try {
     await createLibraryItem({
        lang,
        url,
        title,
      })
      showSnack('success','Added to library!')
      logEvent(analytics, 'add_library', {
        lang: lang, 
        type: 'gutenberg',
        device: 'web',
      })
    } catch(error){
      showSnack('error','Error adding to library!')
    } finally {
      setLoading(false)
    }
  }

  const setTrans = (translation, sentIdx) => {
    const updatedResults = [...searchresults];
    updatedResults[sentIdx]['translation'] = translation
    setsearchresults(updatedResults)  
  }

  const translate = async () => {
    setTransLoading(true)
    const input = searchresults[sentIdx]
    try {
      const translation = await getTranslation({
        text: input.orig_text, 
        sourceLang: userLangs[0],
        targetLang: native_lang,
      })
      setTrans(translation, sentIdx)
      await dexdb.gutenberg.update(input.id, {translation})
    } catch(error){
      showSnack('error', 'Translation error!')
    } finally {
      setTransLoading(false)
    }
  }

  const speakText = () => {
  
      const utterThis = new SpeechSynthesisUtterance(origText)

      utterThis.voice = voices[voiceIdx]
    
      utterThis.rate = rate

      utterThis.lang = lang

      utterThis.pitch=1.0

      utterThis.volume=1

      if(playing){
        synth.cancel()
        return
      }

      synth.speak(utterThis)

      utterThis.addEventListener('start', (event) => {
        setPlaying(true)
      })
      utterThis.addEventListener('end', (event) => {
        setPlaying(false)
      })
  }

  useEffect(()=> {
    const wrongLang = () => {
      const userLang = userLangs[0]
      const language = langSwitch(userLang)
      const errmsg = `This document is not in ${language}`
      showSnack('error', errmsg)
      setLinkError(true)
      setlinkErrorMsg(errmsg)
      seterrorMsg(errmsg)
    }
  
    const addGutenIdx = async (url) => {
      await dexdb.gutenbergIdx.add({
         url,
         sentIdx: 0
       })
    }
   
    const addToGutenStore = async (sentences, title, url) => {
      const dexsents = sentences.map(s =>  ({
        orig_text: s, 
        url, 
        title,
        translation: '',
      }))
  
      await dexdb.gutenberg.bulkAdd(dexsents);
    }

    function populateVoiceList() {
      const voices = synth.getVoices();
      const langVoices = voices.filter(v => v.lang.includes(lang))
      setVoices(langVoices)
    }

    async function getGutenbergText(pd_url){
      try {
        const work = await getGutenberg({searchurl: pd_url})
        const {art, lang, title} = work

        if(!userLangs.includes(lang)){
          wrongLang()
          return
        }
  
        const sentences = splitSentence1(art);

        if(sentences.length>0){
          await addToGutenStore(sentences, title, pd_url) 

          await addGutenIdx(pd_url)
          setsentIdx(0)
          setCurIdx(0)
          const sents = await getStoredGuten(pd_url)
          if(sents.length>0){
            await setStoredText(sents, pd_url)
          }
        }
      } catch(error){
        showSnack('error', error.message)
        setLinkError(true)
        setlinkErrorMsg(error.message)
      } finally{
        setLoading(false)
      }
    }

    const setStoredText = async (sents, pd_url) => {
      setsearchresults(sents)
      setTitle(sents[0].title)
      const sentId = await getStoredGutenIdx(pd_url)

      if(sentId.length>0){ 
        setsentIdx(sentId[0].sentIdx)
        setCurIdx(sentId[0])
      } else { 
        setsentIdx(0)
        
      }
    }

    const setText = async (pd_url) => {
      try {
        const sents = await getStoredGuten(pd_url)
        if(sents.length>0){
          await setStoredText(sents, pd_url)
        } else {
          
            try {
              await getGutenbergText(pd_url)
            } catch(error){
              showSnack('error', error.message)
              setLinkError(true)
              setlinkErrorMsg(error.message)
            } finally{
              setLoading(false)
            }
        }
      } finally {
        setLoading(false)
      }
    }

    const decodedUrl = decodeURIComponent(url)

    populateVoiceList()
    setLoading(true)
   
    if(userLangs.length>0){
      setText(decodedUrl)
    }
    checkQuizCount()
    return () => {
      synth.cancel()
    }
   
  },[synth, userLangs, url, lang])

  if (errorMsg.length>0) return <Error errorMsg={errorMsg} />

  return <ReadGutenbergPage 
          lang={lang}
          addCluster={addLibraryItem}
          clusters={[]}
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          isError={false} 
          searchresults={searchresults}
          sentIdx={sentIdx}
          translate={translate}
          forward={forward}
          back={back}
          title={title}
          transloading={transloading}
          speakText={speakText}
          handleVoice={handleVoice}
          voices={voices}
          voiceIdx={voiceIdx}
          playing={synth.speaking}
          handleRate={handleRate}
          faster={faster}
          slower={slower}
          rate={rate}
          linkError={linkError}
          linkerrorMsg={linkerrorMsg}
          origText={origText}
          curTranslation={curTranslation}
          adReload={adReload}
          handleSource={handleSource}
          handleTarget={handleTarget}
          sourceWord={sourceWord}
          targetWord={targetWord}
          addVocab={addVocab}
          vocabloading={vocabloading}
          errorMsg={errorMsg}
        />
}