import React from "react";

import { 
  getTranslations, 
  toMin
} from '../../utils'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import { grey } from "@mui/material/colors";

import ArtLinkItem from '../ListItems/ArtLinkItem'
import FlagRound from '../ImageElements/FlagRound'
import AdSidebar from "../Ads/AdSidebar"
import Chip from '@mui/material/Chip';
import {ProgressChip} from '../ListItems/ProgressItem'

import { CircularProgress } from "@mui/material";
import GoalsProgress from '../../components/Goals/GoalsProgress'

export default function DashboardPage({
  recommendations,
  userProgress,
  recCount,
  language,
  lang,
  isLoading,
  answerCount,
  artCount,
  listenCount,
  activeGoals,
}){

  const {articles} = getTranslations()
  const flagSize='small'
  const quizGoal = activeGoals.length>0 ? activeGoals[0].quiz_goal : -1
  const readGoal = activeGoals.length>0 ? activeGoals[0].read_goal : -1
  const listenGoal = activeGoals.length>0 ? activeGoals[0].listen_goal : -1
  return (
  <Grid container spacing={1} justifyContent='center' >
    <Grid item xs={12} lg={9}>
      <Container component="main" maxWidth="xl" >
        <Grid container spacing={1} justifyContent='center' >
          <Grid item xs={12} lg={6}>
            <Card sx={{height:'100%', minHeight: 600}} >
              <CardContent> 
                <Grid container 
                    spacing={2}
                    alignItems="center"
                  > 
                  <Grid item >
                  {!isLoading && language.length>0 && 
                    <FlagRound 
                      flagSize={flagSize}
                      lang={lang}
                    />
                  }  
                  </Grid> 
                  <Grid item >
                  {!isLoading && language.length>0 && 
                    <Typography 
                      variant={'h5'}
                      color='primary'
                    > 
                      {recCount} {language} {articles}
                    </Typography>
                    }
                  </Grid> 
                    
                 </Grid> 

              </CardContent> 
              <Divider />
              <CardContent> 
                <Grid container 
                  spacing={2}
                  alignItems="center"
                >  
                <Grid item xs={12}>
                  {recommendations.length===0 && 
                      <Alert severity="error" sx={{m:2}}>
                        No Recommendations.
                      </Alert>
                  }
                  {isLoading ?
                    <CircularProgress sx={{m: 5}}  />
                    :
                    <Grid container 
                      alignItems="center"
                      spacing={1}
                    > 
                      {recommendations && recommendations.length>0 && recommendations.map((r,i) => 
                        <ArtLinkItem 
                          key={i} 
                          lang={recommendations[0].lang} 
                          {...r}
                        />
                      )}
                    </Grid>
                  }
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card sx={{height:'100%', minHeight: 600}} >
              <CardContent> 
                <Typography variant="h5" color='primary'>
                  Monthly Progress 
                </Typography>
              </CardContent>
              <Divider />
              {activeGoals.length>0 &&
                <React.Fragment>
                  <CardContent> 
                      <GoalsProgress />
                  </CardContent>
                  <Divider />
                </React.Fragment>
              }
              <CardContent> 
                <Grid container spacing={3} >
                  <Grid item >
                    <Chip label={`${answerCount} Quiz`}  sx={{backgroundColor: '#00695f', color: 'white', width: 100}} />
                  </Grid> 
                  <Grid item >
                    <Chip label={`${artCount} Articles`} sx={{backgroundColor: '#2a3eb1', color: 'white', width: 100}} />
                  </Grid> 
                  <Grid item >
                    <Chip label={`${toMin(listenCount)} Min Listened`} sx={{backgroundColor: '#b12a3e', color: 'white', width: 130}} />
                  </Grid> 
                </Grid>
              </CardContent>
              <Divider />
              <CardContent> 
              <Grid container spacing={2}  alignItems='center' >
                  {userProgress.map(p => 
                  <React.Fragment  key={p.day} >
                    <Grid item >
                      <Grid container spacing={2} justifyContent='space-evenly' alignItems='center' key={p.day}>
                        <Grid item >
                          <Typography color={grey[600]}>
                          {p.day}
                          </Typography>
                        </Grid>
                        <Grid item >
                          <ProgressChip
                              size='medium'
                              count={p.quiz_count}
                              label={quizGoal===-1 ?  p.quiz_count : `${p.quiz_count}/${quizGoal}`}
                              bkColor='#00695f'
                              goal={quizGoal}
                              width={90}
                          />
                        </Grid> 
                        <Grid item >
                          <ProgressChip
                            size='medium'
                            count={p.arts_count}
                            label={readGoal===-1 ? p.arts_count : `${p.arts_count}/${readGoal}`}
                            bkColor='#2a3eb1'
                            goal={readGoal}
                            width={90}
                          />
                        </Grid> 
                        <Grid item >
                          <ProgressChip
                            size='medium'
                            count={toMin(p.listen_count)}
                            label={listenGoal===-1 ?  toMin(p.listen_count) : `${toMin(p.listen_count)}/${listenGoal}`}
                            bkColor='#b12a3e'
                            goal={listenGoal}
                            width={90}
                          />
                        </Grid> 
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    </React.Fragment>
                  )}
                  </Grid>
                
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Grid>
    <Grid item>
      <AdSidebar />
    </Grid>
  </Grid>
  )
}
