import React from "react";
import { Link } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/Description'
import Divider from "@mui/material/Divider";
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import ClusterInput from '../Inputs/ClusterInput'
import FlagRound from "../ImageElements/FlagRound"

import AdSidebar from "../Ads/AdSidebar"

export default function ClusterAddTextPage({ 
    clusterName, 
    lang, 
    count,
    url,
    title,
    text,
    loading,
    handleLink,
    handleTitle,
    handleText,
    addText,
    cluster_id,
    clusterActive,
    errorUrl,
    errorUrlMsg,
    errorTitle,  
    errorTitleMsg,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    checkItem,
    instructions,
    characterLimit,
    wordCount,
 }){

    const link = `/user/cluster/${cluster_id}/${clusterName}/${lang}/${count}/${clusterActive}`

    const charsOk = checkItem()
    return   <Grid container spacing={1} justifyContent='center' >
              <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                  <main>
                  
                  <Card sx={{
                    padding: 3,
                    margin: 1,
                  }}> 
                    <Grid container 
                          alignItems="center" 
                          spacing={2}
                    >
                      <Grid item>
                        <FlagRound 
                          lang={lang}
                          flagSize='small'
                        />
                      </Grid>
                      <Grid item>
                      <Link to={link} sx={{color:'#6415ff'}}>
                        <Typography variant="h5" >
                          {count && count.length>0 ? count : 0} - {clusterName}
                        </Typography>
                      </Link>
                      </Grid>
                      <Grid item xs={12}> 
                        <Typography>
                         {instructions}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{
                      marginBottom: 2, 
                      marginTop: 2,
                    }} 
                  />
                    <Grid container  
                          alignItems="center" 
                          spacing={2} 
                    >
                      <Grid item xs={12}> 
                        <Typography variant="h5" sx={{color:'#6415ff'}}>
                          Text Article
                        </Typography>
                      </Grid>
                      <Grid item xs={12}> 
                        <FormControl sx={{ width: '100%'}}>
                          <ClusterInput 
                            error={errorUrl}
                            errorMsg={errorUrlMsg}
                            value={url}
                            label='Url'
                            handleFunc={handleLink} 
                            />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}> 
                        <FormControl sx={{ width: '100%'}}>
                          <ClusterInput 
                            error={errorTitle}
                            errorMsg={errorTitleMsg}
                            value={title}
                            label="Title" 
                            handleFunc={handleTitle} 
                            />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}> 
                        <FormControl sx={{ width: '100%'}}>
                          <TextField 
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={12}
                            error={errorText}
                            sx={{ width: '100%'}}
                            required 
                            id="standard-required" 
                            label="Article Text" 
                            value={text} 
                            inputProps={{
                              maxLength: characterLimit
                            }}
                            onChange={handleText} 
                            helperText={`${wordCount}/${characterLimit}`}

                          />
                        </FormControl>
                      </Grid>
                      <Grid item  >
                        {charsOk ? 
                          <LoadingIconButton
                            onClick={addText}
                            title='Add Article'
                            loading={loading}
                            icon={<TextSnippetIcon />}
                          /> 
                        : 
                        <Button
                          disabled
                          variant="contained"
                          startIcon={<TextSnippetIcon />}
                        >
                          Add Article
                        </Button>
                        } 
                      </Grid>
                    </Grid>
                  </Card>   
                  <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                  /> 
                </main>
                </Container>
              </Grid>
              <Grid item>
                <AdSidebar />
              </Grid>
            </Grid>
    

}