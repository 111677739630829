import {supabase} from "../supabase.js"
import {getOffset} from '../utils';
import { dexdb } from "../App.js";

const table = 'quiz'

// MUTATIONS
export const createQuiz =  async ({
    quiz_time_zone,
    lang,
    source_lang,
    target_lang,
    aid,
}) => {
    var tz_offset = getOffset(quiz_time_zone)
    const { data, error } = await supabase
        .from(table)
        .insert({
            quiz_time_zone,
            tz_offset,
            lang,
            auth_id: aid,
            aid,
            source_lang,
            target_lang,
        })
        .select('id')

    if(error)  throw new Error(error.message)
    return data[0]
}

export const deactivateOtherLangs = async ({
    curLang, 
    table, 
    field,
}) => {
    const { error } = await supabase
        .from(table)
        .update({active: false})
        .not('lang', 'eq', curLang)
        .eq(field, true)

    if(error) throw new Error(error.message)

    return
}

export const updateQuiz = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)
        .select()

    if(error) throw new Error(error.message)

    const time_zone = data[0].quiz_time_zone
    
    const { error: proferr } = await supabase
        .from('profile')
        .update({time_zone})

    if(error) throw new Error(proferr.message)

    return data
}

export const deactiveNativeLangQuizzes = async (curLang) => {
    const { data, error } = await supabase
        .from(table)
        .update({active: false})
        .not('lang', 'eq', curLang)
        .eq('quiz_active', true)
        .select()
        
    if(error) throw new Error(error.message)

    return data
}

// QUERIES
const withvocab = `
        id, 
        aid, 
        answer_count, 
        answered, 
        correct_count, 
        date, 
        end_time, 
        lang, 
        quiz_active, 
        quiz_time_zone, 
        quiz_type, 
        source_lang, 
        source_word, 
        start_time,  
        target_lang, 
        target_word, 
        token, 
        tz_offset, 
        user_vocab_word_id
    `

export const getQuiz = async ({
    id,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Quiz not found")

    return data
  }

export const getQuizzes = async () => {
    let { data, error } = await supabase
        .from(table)
        .select(withvocab)
    
    if(error) throw new Error(error.message)
    await dexdb.quiz.bulkPut(data)
    return data
  }
