import React, {useState, useEffect} from "react";
import { useLiveQuery } from "dexie-react-hooks"

import { dexdb } from "../App"

import {  
  langSwitch,
  getProgresDays,
  checkQuizCount,
} from '../utils'

import { getProg } from '../queries/progressQueries'

import ProgressPage from '../components/Pages/ProgressPage'
import Error from '../components/Dialogs/Error'

export default function Progress(){
    const lang =  'en'

    const language = langSwitch(lang)

    const monthDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

    const months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  
    const goals = useLiveQuery(() => dexdb.goals.toArray(),[])
    const activeGoals = goals ? goals.filter(g => g.active) : []

    const [ month, setMonth ] = useState(0)
    const [ curMonth, setCurMonth ] = useState(0)
    const [ year, setYear ] = useState(2022)
    const [ curProgress, setCurProgress ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isError, setisError ]  = useState(false)

    const setProg = async (progKey, month, year) => {
      const crup = await dexdb.progress_months.where("id").equals(progKey).toArray()

      if(crup.length>0){
        setCurProgress(crup[0].days)
      } else {
        setIsLoading(true)
        try {
          const data = await getProg({
            month,
            year,
          })
          const progKey = month.toString()+year.toString()
          await dexdb.progress_months.put({id: progKey, days: data})
          setCurProgress(data)
        } catch(error) {
          setisError(true)
        } finally {
          setIsLoading(false)
        }
      }
    }

    const pageForward1 = () =>{ 
      if (month === 12 ) {
        const newMonth = 1;
        const newYear = year + 1;
        setMonth(newMonth)
        setYear(newYear)
        const progKey = newMonth.toString()+newYear.toString()
        setProg(progKey, newMonth, newYear)
      } else {
        setMonth(prevPage => prevPage + 1)
        const mon=month+1
        const progKey = mon.toString()+year.toString()
        setProg(progKey, mon, year)
      }
    }

    const pageBack1 = () =>{ 
      if (month === 1) {
        const newMonth = 12;
        const newYear = year - 1;
        setMonth(newMonth)
        setYear(newYear)
        const progKey = newMonth.toString()+newYear.toString()
        setProg(progKey, newMonth, newYear)
    } else {
      setMonth(prevPage => prevPage - 1)
      const mon=month-1
      const progKey = mon.toString()+year.toString()
      setProg(progKey, mon, year)
    }
  }

    useEffect(() => {
      
      const now = new Date()
      const mon = now.getMonth() + 1
      const yr = now.getFullYear()
      const progKey = mon.toString()+yr.toString()
      setProg(progKey, mon, yr)
      setMonth(mon)
      setYear(yr)
      setCurMonth(mon)
      checkQuizCount()
    },[])

    if (isError) return <Error errorMsg='Error geting user progress.' />
    const { artCount, answerCount, listenCount } = getProgresDays(curProgress)
    return <ProgressPage  
            progress={curProgress}
            answerCount={answerCount}
            artCount={artCount}
            monthDays={monthDays}
            language={language}
            lang={lang}
            page={month-1}
            maxPage={curMonth}
            handleBack={pageBack1}
            handleForward={pageForward1}
            months={months}
            year={year}
            isLoading={isLoading}
            listenCount={listenCount}
            activeGoals={activeGoals}
          />
  }
