import React from "react";

import {toMin} from '../../utils'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';

import Chip from '@mui/material/Chip';
import AdSidebar from "../Ads/AdSidebar"
import { CircularProgress, Typography } from "@mui/material";
import ProgressItem from '../../components/ListItems/ProgressItem'
import GoalsProgress from '../../components/Goals/GoalsProgress'

export default function ProgressPage({
  progress,
  answerCount,
  artCount,
  page,
  maxPage,
  handleBack, 
  handleForward,
  months,
  year,
  isLoading,
  listenCount,
  activeGoals,
}){
  
  const now = new Date()
  const curDay = now.getDate()
  const curMonth = now.getMonth()
  const yr = now.getFullYear()
  const cmon = yr === year && page===curMonth
  const fwdDisable  = page+1 === maxPage ? true : false
  const totalHoursMins = toMin(listenCount)

  return (
  <Grid container spacing={1} justifyContent='center' >
    <Grid item xs={12} lg={9}>
      <Container component="main" maxWidth="xl" >
        <Card sx={{minHeight:600}} > 
          <CardContent>
            <Grid container 
                  justifyContent='space-between'
                  alignItems="center"
                  spacing={2}
            > 
            <Grid item>
              <Grid container 
                    alignItems="center"
                    spacing={1}
                    justifyContent='space-between'
              > 
                <Grid item>
                  <Grid container 
                    alignItems="center"
                    spacing={2}
                  > 
                    <Grid item>
                      <IconButton 
                          disabled={false}
                          onClick={handleBack}
                          color="primary"
                      >
                          <ChevronLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton 
                          disabled={fwdDisable}
                          onClick={handleForward} 
                          color="primary"
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>  
                </Grid>
                <Grid item >
                  <Typography variant="h4">
                    {months[page]} {year}
                  </Typography>
                </Grid> 
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: { md:'none', xs: 'block' }}} >
               <Divider />
            </Grid> 
          {/*sx={{ display: { md:'none', xs: 'block' } }} */}
            <Grid item >
              <GoalsProgress />
            </Grid>
            <Grid item xs={12} sx={{ display: { md:'none', xs: 'block' }}} >
               <Divider />
            </Grid> 
            <Grid item  xs={12}>
              <Grid container 
                  spacing={2}
                  alignItems="center"
              > 
                <Grid item >
                  <Chip 
                    label={`${answerCount} Quizzes`}  
                    sx={{backgroundColor: '#00695f', color: 'white'}} 
                  />
                </Grid> 
                <Grid item >
                  <Chip 
                    label={`${artCount} Read`} 
                    sx={{backgroundColor: '#2a3eb1', color: 'white'}} 
                  />
                </Grid> 
                <Grid item >
                  <Chip 
                    label={`${totalHoursMins} Mins Listened`} 
                    sx={{backgroundColor: '#b12a3e', color: 'white'}} 
                  />
                </Grid> 
              </Grid> 
            </Grid>
          </Grid> 
          </CardContent>
          <Divider sx={{ 
               
                }} 
          />
          {isLoading ?
            <CircularProgress sx={{m : 3}} />
            :
            <CardContent>
              <Grid container 
                    alignItems='flex-start'
                    spacing={5}
              > 
                {progress.map(p => 
                  <ProgressItem  
                    key={p.day}
                    {...p}
                    pastMonth={!cmon}
                    curDay={curDay}
                    activeGoals={activeGoals}
                  />
                )}
              </Grid> 
            </CardContent>
          }
        </Card>
      </Container>
    </Grid>
    <Grid item>
      <AdSidebar />
    </Grid>
  </Grid>
    )
  }
