import React from "react";

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import {Link} from "@mui/material";
import FlagRound from '../ImageElements/FlagRound'

import AdSidebar from "../Ads/AdSidebar"
import { langSwitch } from "../../utils";

const BookStoreLink1 = ({
  title, 
  img, 
  bs, 
  abe, 
  amz,
}) => {

  return  <Grid item lg={4} md={6}>
            <Grid container alignItems='start' justifyContent='initial' spacing={1}>
              <Grid item xs={12} >
                <img src={img} width='230' alt={title} />
              </Grid>
              <Grid item  >
                <Grid container>
                  <Grid item>
                    <Typography>
                      {title}
                    </Typography>

                  </Grid>
                  <Grid item>
                    <table>
                      <tr>
                        <td style={{paddingRight: 10}}>
                          <Link variant="body2" href={amz} target="_blank" rel="noreferrer" >
                              Amazon
                          </Link>
                        </td>
                        <td style={{paddingRight: 10}}>
                          <Link variant="body2" href={bs} target="_blank" rel="noreferrer" >
                              Bookstore.org
                          </Link> 
                        </td>
                        <td>
                          <Link variant="body2" href={abe} target="_blank" rel="noreferrer">
                              Used  - Abe
                          </Link>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
  }

export default function BookstorePage({
  newBooks,
  lang,
}){
  const flagSize='small'
  const language = langSwitch(lang)
  return (
  <Grid container spacing={1} justifyContent='center' >
    <Grid item xs={12} lg={9}>
      <Container component="main" maxWidth="xl" >
        <Grid container spacing={1} justifyContent='center' >
          <Grid item xs={12} >
            <Card sx={{height:'100%', minHeight: 600}} >
              <CardContent> 
                <Grid container 
                    spacing={2}
                    alignItems="center"
                  > 
                    <Grid item >
                      
                      <FlagRound 
                        flagSize={flagSize}
                        lang={lang}
                      />
                    
                    </Grid>  
                  <Grid item >
                    <Typography 
                      variant={'h5'}
                      color='primary'
                    > 
                     {language} Books
                    </Typography>
                  </Grid> 

                 </Grid> 
              </CardContent> 
              <Divider />
              <CardContent> 
              <Grid container alignItems='start' justifyContent='start' spacing={2}>
                {newBooks.map(d => 
                  <BookStoreLink1  {...d} />
                )}
              </Grid>
          
             
              </CardContent>
            </Card>
          </Grid>
         
        </Grid>
      </Container>
    </Grid>
    <Grid item>
      <AdSidebar />
    </Grid>
  </Grid>
  )
}
