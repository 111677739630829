import React from 'react'

import logo from '../../assets/langa-logo-192.png'

const LangaLogo = ({
  size = 8, 
  href='https://langalearn.com/',
}) => {
    return <a href={href}>
            <img src={logo} style={{
              height: size * 10,
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 16,
              marginBottom: 16,
              borderRadius: '10%',
            }} alt="langalogo" />
          </a>
}

export default LangaLogo
