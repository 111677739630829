import React, { useState, useContext, useEffect } from "react";
import moment from 'moment'

import { useLiveQuery } from "dexie-react-hooks"

import { checkQuizCount } from "../utils";

import {analytics, dexdb} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import {
  getHistoryRecommendations, 
} from '../queries/recommendationQueries'

import { GlobalContext } from "../context/GlobalContext";

import { 
  langSwitch, 
  langers1, 
  formatDate1, 
  formatRecLinks,
} from '../utils'

import HistoryPage from '../components/Pages/HistoryPage'

export default function History(){

  const { findDate, historyRecommendations } = useContext(GlobalContext);

  const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []) ?? [];

  const clusters1 =  clusters ?? [] 

  const member = useLiveQuery(() => dexdb.member.toArray(), []);

  const langs = member && member.length>0 ? member[0].langs : []

  const { searchDate, searchLang, dateRecommendations } = historyRecommendations 

  const initialLang = langs.length>0 ? langs[0] : ''

  const [ selectedDate, setSelectedDate] = useState(new Date())
  const [ lang, setLang] = useState('en')
  const [ selCluster, setSelCluster ] = useState('all')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)

  const handleClose = () => setSnack(false)

  const handleCluster = event => setSelCluster(event.target.value)

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const handleDateChange = date => {
    setSelectedDate(date)
    const dateStr = formatDate1(date,'/')
    localStorage.setItem('historyDate', dateStr);
  }
  const handleChange = event => setLang(event.target.value)

  const searchDate1 = searchDate ? formatDate1(searchDate, '-') : formatDate1(new Date(),'-') 

  const searchDateRecs =  async () => {
    const selDate = localStorage.getItem('historyDate')
    try {
      const historyRecs = await getHistoryRecommendations({
        lang,
        searchDate: selectedDate,
        dateStr: selDate,
      })

      const recs = formatRecLinks(historyRecs)
      const selectedDateLang = {
        searchDate: selectedDate,
        searchLang: lang,
        dateRecommendations: recs,
      }
      findDate(selectedDateLang)

      logEvent(analytics, 'add_sent', {
        lang,
        date: selDate,
        device: 'web',
      })
      } catch(error){
        showSnack('error', 'Translation error.')
    }
  }
    const recCount1 = dateRecommendations.length
    const selDate = moment(searchDate).format('MMMM Do, YYYY')
    dateRecommendations.sort(({timestamp: a}, {timestamp: b}) => b-a)
    const selClusterRecs = selCluster!=='all' ? dateRecommendations.filter(c => c.cluster_id === selCluster) : dateRecommendations
    const recCount = selClusterRecs ? selClusterRecs.length : 0
    const selClusterName = selCluster !== 'all' ? selClusterRecs.filter(c => c.id === selCluster)['clusterName'] : 'All'

    const langName = langSwitch(lang)

    const langlist = langs.map(l => langers1(l))

    useEffect(() => {
      const selDate = localStorage.getItem('historyDate')
      const date1 = new Date(selDate)
      setLang(initialLang)
      setSelectedDate(date1)
      setSelCluster('all')
      checkQuizCount()
    },[initialLang]);


    return <HistoryPage 
            searchDate={searchDate1} 
            searchLang={searchLang} 
            dateRecommendations={selClusterRecs}
            selectedDate={selectedDate}
            lang={lang}
            handleDateChange={handleDateChange}
            searchRecs={searchDateRecs}
            handleChange={handleChange}
            langName={langName}
            langlist={langlist}
            clusters={clusters1}
            selDate={ selDate}
            selClusterRecs={selClusterRecs}
            recCount={recCount}
            selCluster={selCluster}
            selClusterName={selClusterName}
            handleCluster={handleCluster}
            recCount1={recCount1}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
          />
}