import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

import { 
  getSpacedRepititionNextDate,
  getNowIso,
  checkQuizCount,
 } from '../utils' 

import {createQuizAnswer} from '../queries/quizAnswersQueries'

import {analytics} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import {
  getLastAnswered,
  getRandom,
  getUnanswered,
  getSpaced,
  getRecentlyAdded,
  updateVocabQuizProgress,
} from '../queries/vocabWordQueries'

import {incrementProgress} from '../queries/progressQueries'

import VocabTestPage from '../components/Pages/VocabTestPage'

export default function VocabTest(){

    const { 
      lang, 
    } = useParams()

    const [ guess, setGuess ] = useState('')
    const [ feedback, setFeedback ] = useState(false)
    const [ curQuestionIdx, setCurQuestionIdx ] = useState(0)
    const [ correct, setCorrect ] = useState(false)
    const [ answereds, setAnswereds ] = useState([])
    const [ allWords, setAllWords ] = useState([])
    const [ quizType, setQuizType ] = useState('')
    const [ correctAnswers, setCorrectAnswers ] = useState(0)

    const [ resetAlert, setResetAlert ] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ adReload, setAdReload ] = useState(false)

    const currQ = allWords[curQuestionIdx]

    const toggle = () => setCorrect(false)
    const handleGuess = event => setGuess(event.target.value)
    const handleClose = () => setSnack(false)
    const handleQuizType = e => setQuizType(e.target.value)

    const answered = async (correct, guess) => {
      const new_correct_count = correct ? currQ.correct_count+1 : currQ.correct_count
      const new_answer_count = currQ.answer_count+1
      const prevEasiness = currQ.easiness
      const percent = new_correct_count/new_answer_count
      const nextReview = getSpacedRepititionNextDate(new_answer_count, prevEasiness, percent)
      const { 
        newReviewDate,
        easiness, 
      } = nextReview

      logEvent(analytics, 'quiz', {
        source_lang: currQ.source_lang, 
        target_lang: currQ.target_lang,
        device: 'web',
      })

      await incrementProgress('quiz_count', 1)

      try {
        await updateProgress1(new_correct_count, new_answer_count, correct, newReviewDate, easiness)
      } catch(error){
        showSnack('error', 'Error answer!')
        throw error
      }

      try {
        await createQuizAnswer({ 
          answer_word: guess,
          correct,
          user_vocab_word_id: currQ.id,	
        })
      } catch(error){
        showSnack('error', 'Error answer!')
        throw error
      }
    }

    const setNextQuestion = () => {
        setCorrect(true)
        setGuess('')
        setFeedback(false)
        getQuestion()
        setAdReload(true)
    }

    // for German words capitalization matters 
    const checkCorrect = () => currQ.target_word.trim().toLowerCase()===guess.trim().toLowerCase() ? true : false

    const answersArray = words => words.map((a) => a.toLowerCase().trim())

    const checkAnswersArray = answers => answers.includes(guess.toLowerCase().trim())

    // check is answer answer is one of the multiple target language words
    // target words for Nachfrage = 'demand | inquiry | exit poll' - any of these words will be acceptable
    const checkCorrectMultiple = async (separate) => {
      const words = currQ.target_word.split(separate)
      const answers = answersArray(words)
      const correctAnswer = checkAnswersArray(answers)
      setCorrect(correctAnswer)
      if(correctAnswer){
        setCorrectAnswers(correctAnswers + 1)
        await answered(true, guess)
      } else {
        await answered(false, guess)
      }
      setFeedback(true)
      return 
    }

    const checkAnswer = async () => {
      if(guess.length===0){
        showSnack('error', 'Please enter a word!')
        return 
      }
      setAdReload(false)
      const multiple = currQ.target_word.includes('|')
      if(multiple){
   
        await checkCorrectMultiple('|')
        return 
      }
      const multiplecomma = currQ.target_word.includes(',')
      if(multiplecomma){
    
        await checkCorrectMultiple(',')
        return 
      }
     
      const correctAnswer = checkCorrect()

      setCorrect(correctAnswer)
      if(correctAnswer){
        setCorrectAnswers(correctAnswers + 1)
      }
      
      await answered(correctAnswer, guess)
     
      setFeedback(true)
      setAdReload(true)
    }

    const getQuestion = () => curQuestionIdx===allWords.length-1 ? setResetAlert(true) : setCurQuestionIdx(prevCurQuestionIdx => prevCurQuestionIdx + 1) 
 
    const setLastAnswered = async () => {
      setAdReload(true)
      try {
        const user_vocab = await getLastAnswered(lang)

        user_vocab.sort(() => Math.random() - 0.5)

        setQuizType('lastanswered')
        setCurQuestionIdx(0)
        setAllWords(user_vocab)
        setResetAlert(false)

      } catch(error){
        showSnack('error', 'Error getting new words!')
      }
    }

    const setRandom = async () => {
      setAdReload(true)
      try {
        const user_vocab = await getRandom(lang)
        setQuizType('random')
        setCurQuestionIdx(0)
        setAllWords(user_vocab)
        setResetAlert(false)

      } catch(error){
        showSnack('error', 'Error getting new words!')
      }
    }

    const setRecentlyAdded = async () => {
      setAdReload(true)
      try {
        const user_vocab = await getRecentlyAdded(lang)

        user_vocab.sort(() => Math.random() - 0.5)

        setQuizType('recentadd')
        setCurQuestionIdx(0)
        setAllWords(user_vocab)
        setResetAlert(false)

      } catch(error){
        showSnack('error', 'Error getting new words!')
      }
    }

    const setUnanswered = async () => {
      setAdReload(true)
      try {
        const user_vocab = await getUnanswered(lang)

        user_vocab.sort(() => Math.random() - 0.5)
        
        setQuizType('unanswered')
        setCurQuestionIdx(0)
        setAllWords(user_vocab)
        setResetAlert(false)

      } catch(error){
        showSnack('error', 'Error getting new words!')
      }
    }

    const setSpaced = async () => {
      setAdReload(true)
     
      try {

        const user_vocab = await getSpaced(lang)
        setQuizType('spaced')
        if(user_vocab.length===0){
          showSnack('error', 'No words to review today!')
          return
        }
       
        setCurQuestionIdx(0)
        setAllWords(user_vocab)
        setResetAlert(false)

      } catch(error){
        showSnack('error', 'Error getting new words!')
      }
    }

    const resetVocab = () => {
      setCurQuestionIdx(0)
      setResetAlert(false)
    }

    const updateProgress1 = async (
      new_correct_count, 
      new_answer_count,
      correct,
      newReviewDate, 
      easiness,
    ) => {
      const nowIso = getNowIso()

      const quizUpdate = {
        answer_count: new_answer_count,
        correct_count: new_correct_count,
        last_answered: nowIso,
        next_review: newReviewDate,
        easiness,
      }

      try {
        

        const data = await updateVocabQuizProgress(quizUpdate, currQ.id)

        const answered = {
          ansCorrect: correct, 
          answered: currQ.source_word, 
          art_lang: currQ.source_lang,
          answer_count: new_answer_count,
          correct_count: new_correct_count,
          vocab_id: currQ.id,
          target_word: currQ.target_word,
          source_lang: lang,
          target_lang: currQ.target_lang,
        }

        allWords[curQuestionIdx]['answer_count']=data[0]['answer_count']
        allWords[curQuestionIdx]['correct_count']=data[0]['correct_count']

        answereds.unshift(answered)
        setAnswereds(answereds)
        setAllWords(allWords)
      } catch(error){
        showSnack('error', 'Error updating quiz!')
      }
    }

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    useEffect(() => {
      checkQuizCount()
    }, [])
    

    const count1 = allWords.length

    return  <VocabTestPage 
              checkAnswer={checkAnswer}
              toggle={toggle}
              handleGuess={handleGuess}
              guess={guess}
              feedback={feedback} 
              correct={correct}
              answereds={answereds}
              correctAnswers={correctAnswers}
              setNextQuestion={setNextQuestion}
              resetAlert={resetAlert}
              currQ={currQ}
              resetVocab={resetVocab}
              snack={snack}
              msgType={msgType}
              alertMsg={alertMsg}
              handleClose={handleClose}
              loading={loading}
              getLastAnswered={setLastAnswered}
              getUnanswered={setUnanswered}
              getRecentlyAdded={setRecentlyAdded}
              getSpaced={setSpaced}
              getRandom={setRandom}
              count={count1}
              quizType={quizType}
              handleQuizType={handleQuizType}
              adReload={adReload}
            />
}