import React from 'react'

import Alert from '@mui/material/Alert'

const Error = ({
    errorMsg,
}) => 
        <Alert severity="error">
            {errorMsg}
        </Alert>

export default Error


