import React from 'react'
import useWindowDimensions from '../../helper'
import { useTheme } from '@mui/material/styles';
// import { getTranslations } from '../utils'
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AdSidebar from "../Ads/AdSidebar"
import { Button } from '@mui/material'

const TutorialCard = ({
    title, 
    instructions, 
    image, 
    typechoice
}) => {
    const theme = useTheme();
    const { width } = useWindowDimensions();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const curWidthWeb = matches ? width *.50 :  width*.80

    const curWidthMobile = width *.33

    const curWidth = typechoice === 1 ? curWidthMobile : curWidthWeb

    return  <Grid container spacing={3} alignItems="start"  >  
                <Grid item lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>
                                {title}
                            </Typography>
                        </Grid>
                        {instructions.map((c, i) => 
                            <Grid key={i} item xs={12}>
                                <Typography>
                                    {i+1}. {c.step}
                                </Typography>
                            
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item >
                    <img style={{borderRadius: 10}} width={curWidth} src={image} alt={title} />  
                </Grid>  
            </Grid>
}

export default function SupporTutorialtPage({
    catchoice,
    handleChoice,
    sitetypes,
    typechoice,
    handleType,
    typeNames,
}){
    const catCur = sitetypes[typechoice]
    return <Grid container spacing={1} justifyContent='center' >
            <Grid item xs={12} xl={11}>
                <Container component="main" maxWidth="xl" >
                    <Card > 
                        <CardContent>
                            <Grid container spacing={3} alignItems="center"  >
                                <Grid item >
                                    <Typography variant='h4' style={{color: '#6415ff'}}>
                                        Help
                                    </Typography>
                                </Grid>
                                {typeNames.map((n, i) => 
                                   <Grid item >
                                        <Button 
                                            size='large'
                                            onClick={() => handleType(i)} color={typechoice ===i  ?'primary' : 'disabled'}
                                        >
                                            {n}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    {catCur.map((c,i) => 
                                        <Button 
                                            sx={{mr:1}}
                                            size='small'
                                            key={i} 
                                         
                                            onClick={() => handleChoice(i)}
                                        >
                                            {c.title}
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                   <TutorialCard 
                                    {...catCur[catchoice]} 
                                    typechoice={typechoice}
                                />
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid item>
              <AdSidebar />
            </Grid>
        </Grid>

}