import React, { useState, useEffect } from "react"
import { useLiveQuery } from "dexie-react-hooks";

import { dexdb } from "../App";

import {
  postAuth, 
  langSwitch,
  formatDate1,
  formatClusterLinks1,
} from '../utils'

import { 
  createCluster,
  deleteCluster,
} from '../queries/clusterQueries'

import {
  createTranslatedArt1,
  newClusterUrltoDB,
} from '../queries/translatedArtQueries'

import {
  getInitialRecs,
  addSuggested,
} from '../queries/workerQueries'

import FirstClusterPage from '../components/Pages/FirstClusterPage'

const getClusterLink1 = (links, clusterName, cluster_id) => formatClusterLinks1(links, clusterName, cluster_id)

// const localenv = process.env.NODE_ENV

const baseUrl =  process.env.REACT_APP_FB_FUNC_URL

const baseCFUrl =  process.env.REACT_APP_CF_WORKER_URL
// const baseCFUrl = 'http://127.0.0.1:8787'

const parselinkUrl = `${baseCFUrl}/scrape`

const firstRecommendationsReqUrl1 = `${baseUrl}/firstRecommendationsTask`

const agree = 'Text looks fine.'
const disagree = "Didn't parse correctly. Close"

export default function FirstCluster({history}){

  const subscribeLink = `/user/profile`

  // User Data

  const clusters = useLiveQuery(() => dexdb.clusters.toArray(), []) ?? [];

  const member = useLiveQuery(() => dexdb.member.toArray(), []) ?? [];
  const lang = member && member.length>0 ? member[0].langs[0] : 'en' 
  const nativeLang = member && member.length>0?  member[0].native_lang : 'en'

  const cluster_id = clusters && clusters.length>0 ? clusters[0].id : 0
  const language = langSwitch(nativeLang)
  const clusterName1 = clusters && clusters.length>0 ? clusters[0].cluster_name : ''
  const clusterLinks = clusters && clusters.length>0 ? clusters[0].translated_arts : []

  const clusterLinks1 = clusterLinks.length>0  ? getClusterLink1(clusterLinks, clusterName1, parseInt(cluster_id)) : []

  const [ url, setUrl ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ nameLoading, setNameLoading ] = useState(false)
  const [ confirm, setConfirm ] = useState(false)
  const [ error, setError] = useState(false)
  const [ linkError, setLinkError] = useState(false)
  const [ linkerrorMsg, setlinkErrorMsg] = useState('')
  const [ clusterName, setClusterName ] = useState('')
  const [ parsedArtTitle, setParsedArtTitle ] = useState('')
  const [ parsedArtText, setParsedArtText ] = useState('')

  const [ initRecs, setInitRecs ] = useState([])

  const confirmDelete = () => setConfirm(true)

  const closeConfirm = () => setConfirm(false)

  const handleClose = () => setSnack(false)

  const handleLink = event => setUrl(event.target.value)

  const handleClusterName = event => setClusterName(event.target.value)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const getRecsInit = async () => {
    const recs = await getInitialRecs({clusterName, nativeLang})
    setInitRecs(recs.recommendations)
  }

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  }

  const addCluster = async () => {
    setNameLoading(true)
    try {
      await createCluster({
        clusterName,
        lang,
      })
      showSnack('success', 'Success! New cluster added!')
      await getRecsInit()
    } catch(error){
      showSnack('error', 'Error adding new cluster!')
    } finally {
      setNameLoading(false)
    }
  }

  const deleteCluster1 = async () => {
    setNameLoading(true)

    try {
      await deleteCluster({
        cluster_id
      })
      showSnack('success', 'Cluster deleted!')
    } catch(error){
      showSnack('error', 'Error deleting cluster!')
    } finally {
      setNameLoading(false)
    }
  }

  const parseArt = async () => {
    setLoading(true)
    setLinkError(false)
    setlinkErrorMsg('')

    try {
      const data = {
        url, 
        selector: 'title, p', 
        nativeLang,
       }
      const parsedArt = await postAuth(parselinkUrl, data);
      setParsedArtTitle(parsedArt.title)
      setParsedArtText(parsedArt.art)
      setConfirm(true)
      setLinkError(false)
      setlinkErrorMsg('')
    } catch(error){
      showSnack('error', error.message)
      setLinkError(true)
      setlinkErrorMsg(error.message)
    } finally {
      setLoading(false)
    }
  }

  const firstRecommendations = async () => {
    setLoading(true)
    const today = new Date()
    const date = formatDate1(today, '/')
    
    try {
      await postAuth(firstRecommendationsReqUrl1, {
        cluster_id, 
        trans_lang: lang, 
        date,
      });
      showSnack('success', 'Your recommendations will be available shortly (5 mins)')
    } catch(error){
      showSnack('error', 'Error generating recommendations.')
    } finally {
      setLoading(false)
    }
  }

  const addParsedArt = async () => { 
    setLoading(true)
    try {
      await createTranslatedArt1({
        url,
        lang,
        title: parsedArtTitle, 
        orig_text: parsedArtText,
        orig_lang: nativeLang,
        cluster_id,
      })
      showSnack('success', 'Url added!')
      setUrl('')
    } catch(error){
      showSnack('error', 'Error adding url!')
      setLinkError(true)
      setlinkErrorMsg(error)
    } finally {
      setLoading(false)
      setConfirm(false)
    }
  }

  const addSuggested1 = async (id) => {
    const newurl = await addSuggested({
      art_id: parseInt(id),
      cluster_id,
      lang,
    })
   
    await newClusterUrltoDB(newurl, cluster_id)
  }

  useEffect(()=> {
    const clusterCount = localStorage.getItem('count')
    const clustCnt = parseInt(clusterCount)
      
    if(clustCnt>=3){
      return history.push('/user/dashboard')
    }
  },[history])


  return <FirstClusterPage
          clusterName={clusterName} 
          clusterName1={clusterName1} 
          addCluster={addCluster}
          cluster_id={cluster_id}
          lang={lang}
          language={language}
          url={url}
          loading={false}
          linkLoading={loading}
          nameLoading={nameLoading}
          error={error}
          clusterLinks={clusterLinks1}
          handleLink={handleLink}
          confirmDelete={confirmDelete}
          closeConfirm={closeConfirm}
          confirm={confirm}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          handleClose1={handleClose1}
          subscribeLink={subscribeLink}
          linkError={linkError}
          linkerrorMsg={linkerrorMsg}
          parsedArtTitle={parsedArtTitle}
          parsedArtText={parsedArtText}
          addParsedArt={addParsedArt}
          disagree={disagree}
          agree={agree}
          handleClusterName={handleClusterName}
          firstRecommendations={firstRecommendations}
          parseArt={parseArt}
          deleteCluster={deleteCluster1}
          initRecs={initRecs}
          addSuggested={addSuggested1}
        />
}