import React , {useState} from 'react'

import {
    deleteVocabImage,
} from '../../../queries/vocabMediaQueries'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia';

import { Button, CardActionArea, CardActions } from '@mui/material';

import SnackAlert from '../../Dialogs/SnackAlert'
import ImageModal from './ImageModal'

export default function ImageCard({
    vocab_image_library,
    id,
    origWord,
  }){
    const {url} = vocab_image_library
    const cardUrl = url.replace('private', '200')

    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ confirm, setConfirm ] = useState(false)
  
    const toggleConfirm = () => setConfirm(!confirm)
  
    const handleImageError = (e) => {
      e.target.onerror = null;
      e.target.src = url
    }
  
    const handleClose = () => setSnack(false)
  
    const deleteImage = async () => {
      try {
        await deleteVocabImage({
          id,
          url,
        })
      } catch(error){
        showSnack('error', 'Error deleting image!')
      }
    }
  
    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }


    return <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={cardUrl}
                alt={origWord}
                onClick={toggleConfirm}
                onError={handleImageError}
              />
            </CardActionArea>
            <CardActions>
              <Button size="small" color="error" onClick={deleteImage}>
                Delete
              </Button>
            </CardActions>
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
            <ImageModal
              fullUrl={url}
              confirm={confirm}
              origWord={origWord}
              closeConfirm={toggleConfirm}
            />
          </Card>
  }
  