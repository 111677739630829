import React from "react"
import { useParams } from "react-router-dom"

import BookstorePage from '../components/Pages/BookstorePage'

export default function Bookstore(){

      const {
        lang, 
      } = useParams()

      const langBooks = {
        es: [
          {
            title: 'Complete Spanish Beginner to Intermediate Course: Learn to Read, Write, Speak and Understand a New Language [With Paperback Book]',
            img:'https://images-us.bookshop.org/ingram/9781444177244.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781444177244', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781444177244%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HC9kVR'
          },
          { 
            title: 'Practice Makes Perfect: Complete Spanish Grammar, Premium Fourth Edition',
            img:'https://images-us.bookshop.org/ingram/9781260463156.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781260463156', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781260463156%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3RecSSg'
          },
          { 
            title: 'Spanish Conversation',
            img:'https://images-us.bookshop.org/ingram/9780008111977.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780008111977', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780008111977%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3Y7G13T'
          },
          { 
            title: 'Easy Spanish Step-By-Step',
            img:'https://images-us.bookshop.org/ingram/9780071463386.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780071463386', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780071463386%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3WM20ME'
          },
          { 
            title: "Madrigal's Magic Key to Spanish: A Creative and Proven Approach",
            img:'https://images-us.bookshop.org/ingram/9780385410953.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780385410953', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780385410953%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3wIWrUw'
          },
          { 
            title: "Learn Spanish For Adult Beginners: 3 Books in 1: Speak Spanish In 30 Days!",
            img:'https://images-us.bookshop.org/ingram/9798987545829.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9798987545829', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9798987545829%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3G9AAe3'
          },
        ],
        fr: [
          {
            title: '50 French Coffee Breaks: Short Activities to Improve Your French One Cup at a Time',
            img:'https://images-us.bookshop.org/ingram/9781399802369.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781399802369', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781399802369%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3kTuaYX'
          },
          { 
            title: 'Fluent in French: The most complete study guide to learn French',
            img:'https://images-us.bookshop.org/ingram/9781635872804.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781635872804', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781635872804%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3RjJ0UD'
          },
          { 
            title: 'The Complete French Grammar Course: French beginners to advanced - Including 200 exercises, audios and video lessons',
            img:'https://images-us.bookshop.org/ingram/9798457214255.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9798457214255', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9798457214255%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HCb4yn'
          },
          { 
            title: 'French Grammar for Beginners Textbook + Workbook Included: Supercharge Your French With Essential Lessons and Exercises',
            img:'https://images-us.bookshop.org/ingram/9798885262132.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9798885262132', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9798885262132%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3WJ2RxD'
          },
          { 
            title: "Easy French Step-By-Step: Master High-Frequency Grammar for French Proficiency--Fast!",
            img:'https://images-us.bookshop.org/ingram/9780071453875.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9780071453875', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780071453875%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3kPPTAY'
          },
          { 
            title: "The Complete Pronunciation Course: Learn the French Pronunciation in 55 lessons",
            img:'https://images-us.bookshop.org/ingram/9798573937229.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9798573937229', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3DThe%2520Complete%2520Pronunciation%2520Course%253A%2520Learn%2520the%2520French%2520Pronunciation%2520in%252055%2520lessons%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3ZA9gN3'
          },
        ],
        de: [
          {
            title: 'Collins Easy Learning German - Easy Learning German Grammar and Practice',
            img:'https://images-us.bookshop.org/ingram/9780008141653.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780008141653', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780008141653%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3XKKl9n'
          },
          {
            title: '50 German Coffee Breaks: Short Activities to Improve Your German One Cup at a Time',
            img:'https://images-us.bookshop.org/ingram/9781399802420.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781399802420', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781399802420%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HiOWZn'
          },
          {
            title: 'German Grammar for Beginners Textbook + Workbook Included: Supercharge Your German With Essential Lessons and Exercises',
            img:'https://images-us.bookshop.org/ingram/9781684892754.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781684892754', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781684892754%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3jcHze3'
          },
          {
            title: 'German Made Simple: Learn to Speak and Understand German Quickly and Easily',
            img:'https://images-us.bookshop.org/ingram/9780767918602.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780767918602', 
            abe: 'https://www.abebooks.com/servlet/SearchResults?kn=9780767918602&sts=t&cm_sp=SearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3JGEoq7'
          },
          {
            title: 'The Everything Learning German Book: Speak, Write, and Understand Basic German in No Time',
            img:'https://images-us.bookshop.org/ingram/9781598699890.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9781598699890', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781598699890%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3XOlFwN'
          },

          {
            title: 'Modern German Pronunciation: An Introduction for Speakers of English',
            img:'https://images-us.bookshop.org/ingram/9780719066894.jpg?height=500',
            bs: 'https://bookshop.org/a/83917/9780719066894', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780719066894%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3nM29nK'
          },
        ],
        it: [
          {
            title: 'Italian Made Simple: Revised and Updated',
            img:'https://images-us.bookshop.org/ingram/9780767915397.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9780767915397', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780767915397%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3l2GuWY'
          },
          {
            title: 'Practice Makes Perfect: Complete Italian All-in-One',
            img:'https://images-us.bookshop.org/ingram/9781260455120.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781260455120', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781260455120%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HiOWZn'
          },
          {
            title: 'Italian: The Ultimate Guide for Beginners Who Want to Learn the Italian Language, Including Italian Grammar, Italian Short Stories, and Over 1000 Italian Phrases',
            img:'https://images-us.bookshop.org/ingram/9781092776318.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781092776318', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781092776318%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3kQAXCA'
          },
          {
            title: 'Italian Grammar for Beginners Textbook & Workbook Included',
            img:'https://images-us.bookshop.org/ingram/9781684893201.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781684893201', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781684893201%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3wLmoTt'
          },
          {
            title: 'Complete Italian Step-By-Step',
            img:'https://images-us.bookshop.org/ingram/9781260463231.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781260463231', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781260463231%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3kUmkOB'
          },
          {
            title: 'Learn Italian For Adult Beginners: 3 Books in 1: Speak Italian In 30 Days!',
            img:'https://images-us.bookshop.org/ingram/9798987545812.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9798987545812', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3DLearn%2520Italian%2520For%2520Adult%2520Beginners%253A%25203%2520Books%2520in%25201%253A%2520Speak%2520Italian%2520In%252030%2520Days%2521%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3nLdynu'
          },
        ],
        pt: [
          {
            title: 'Ponto de Encontro: Portuguese as a World Language',
            img:'https://images-us.bookshop.org/ingram/9780205782765.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9780205782765', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9780205782765%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HO3rpy'
          },
          {
            title: 'Portuguese: An Essential Grammar',
            img:'https://images-us.bookshop.org/ingram/9781138234345.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781138234345', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781138234345%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3Hqr8TB'
          },
          {
            title: 'Complete Brazilian Portuguese: Beginner to Intermediate Course',
            img:'https://images-us.bookshop.org/ingram/9781444198447.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781444198447', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781444198447%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3XXJceK'
          },
          {
            title: 'Practice Makes Perfect: Basic Portuguese',
            img:'https://images-us.bookshop.org/ingram/9781260455229.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781260455229', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781260455229%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/40x3bCW'
          },
          {
            title: 'Portuguese: How to Learn Portuguese Fast, Including Grammar, Short Stories, and Useful Phrases',
            img:'https://images-us.bookshop.org/ingram/9781092778503.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9781092778503', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3D9781092778503%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3HrVBk9'
          },
          {
            title: 'Say it Right in Brazilian Portuguese: The Fastest Way to Correct Pronunciation',
            img:'https://images-us.bookshop.org/ingram/9780071492300.jpg?height=500&v=v2',
            bs: 'https://bookshop.org/a/83917/9780071492300', 
            abe: 'https://affiliates.abebooks.com/c/3446986/77416/2029?u=https%3A%2F%2Fwww.abebooks.com%2Fservlet%2FSearchResults%3Fkn%3DSay%2520it%2520Right%2520in%2520Brazilian%2520Portuguese%26sts%3Dt%26cm_sp%3DSearchF-_-topnav-_-Results',
            amz: 'https://amzn.to/3KsiuXt'
          },
        ]
      }
      
    const booklist = langBooks[lang]

    return <BookstorePage  
            newBooks={booklist}
            lang={lang}
          />
  }
