export default function AppReducer(state, action){
    switch (action.type) {
      case "SEARCH_LINK":
        return {
          ...state,
          searchLinkResults: action.payload
        };
      case "SEARCH_DATE":
        return {
          ...state,
          historyRecommendations: action.payload
        };
      case "SWITCH_TAB":
        return {
            ...state,
            tab: action.payload
        };
      case "SWITCH_REC_LANG":
        return {
            ...state,
            rec_lang: action.payload
        };
      default:
        return state;
    }
  };