import {
  postAuth,
} from '../utils'

const baseurl = process.env.REACT_APP_CF_WORKER_URL
// const baseurl = 'http://0.0.0.0:8787'

export const getTranslation = async ({
    text,
    sourceLang,
    targetLang,
}) => {
    
    const query = { 
      text,
    }
   
    const linkUrl1 = `${baseurl}/translation/${sourceLang}${targetLang}`

    try {
      const response = await postAuth(linkUrl1,  query)
      return response.translation
    } catch(error) {
       throw error
    }
}

export const getGutenberg = async ({
  searchurl,
}) => {
  
  const query = {
    "url": searchurl,
    "selector": "p, title"
  }
 
  const url = baseurl + '/gut_scrape'
  try {
    return await postAuth(url, query)
  } catch(error) {
    throw error
  }
}

export const parseArt = async ({
  url,
  nativeLang,
}) => {

  try {
    const query = {
      url, 
      selector: 'title, p', 
      nativeLang,
     }

    const url1 = baseurl + '/scrape'
    return await postAuth(url1, query)
  } catch(error) {
    throw error
  }
}

export const getInitialRecs = async ({
  clusterName,
  nativeLang,
}) => {
  
  const query = {
    clusterName,
    native_lang:  nativeLang,
  }
 
  const url = baseurl + '/initial_recs'
  try {
    return await postAuth(url, query)
  } catch(error) {
    throw error
  }
}

export const addSuggested = async (query) => {
  const url = baseurl + '/suggested_cluster'
  try {
    return await postAuth(url, query)
  } catch(error) {
    throw error
  }
}
