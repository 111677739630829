import React, { useState } from 'react'
import {supabase} from '../supabase'
import validator from 'validator'

import ForgotPasswordPage from '../components/Pages/ForgetPasswordPage'

export default function ForgotPassword(){ 
  const [ alert, setAlert ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ successMsg, setSuccessMsg ] = useState()
  const [ email, setEmail ] = useState('')

  const handleEmail = event => setEmail(event.target.value)

  const resetPassword = async () => {
    if (!validator.isEmail(email)) {
      setAlert(true)
      setAlertMsg('Invalid email!')
      return
    } 
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email)
      if(error){
        setAlert(true)
        setAlertMsg('Error resetting your password! ')
      } 
      if(data){
        setSuccess(true)
        setSuccessMsg('We have sent a reset email. Please check your email!')
      }
    } catch(error){
      setAlert(true)
      setAlertMsg('Error resetting your password! ')
    }
  }

  return <ForgotPasswordPage
          alert={alert}
          success={success}
          alertMsg={alertMsg}
          successMsg={successMsg}
          email={email}
          handleEmail={handleEmail}
          resetPassword={resetPassword}
        />
}