import React, {useState} from 'react'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

import { Upload } from '@mui/icons-material';

import { Button } from '@mui/material';
import { Link } from '@mui/icons-material';

import ImageCard from './ImageCard';
import ImageSearchDialog from './ImageSearchDialog';
import AddUrlForm from '../../Inputs/AddUrlForm'

export default function ImageList({
  pictures,
  imageLoading,
  onImageChange,
  sourceWord,
  sourceLang, 
  targetLang,
  vocabId,
  origWord,
  imgUrl,
  handleImageUrl,
  toggleImgUrl,
  addImageUrl,
  showImgUrl,
}) {
  const [ confirm, setConfirm ] = useState(false)
  
  const toggleConfirm = () => setConfirm(!confirm)

  return <Grid container 
              spacing={2} 
            >           
            <Grid item xs={12} >
              <Grid container 
                alignItems='center'
                justifyContent='space-between'
                spacing={2} 
              >   
               <Grid item >
                <Typography variant='h5'>
                  Images
                </Typography>
              </Grid>
              <Grid item >
                {showImgUrl ?
                    <AddUrlForm    
                      url={imgUrl}
                      handleUrl={handleImageUrl}
                      addUrl={addImageUrl}
                      buttonLoading={imageLoading}
                      toggleUrl={toggleImgUrl}
                    />
                  :
                <Grid container 
                    spacing={2} 
                    alignItems='center'
                >
                  <Grid item  >
                    <Button 
                      onClick={toggleImgUrl} 
                      startIcon={<Link />} 
                      variant='contained' 
                    >
                      Image Url
                    </Button>
                  </Grid>
                  <Grid item  >
                    <LoadingButton
                        variant="contained" 
                        component="label"  
                        startIcon={<Upload />}
                        loading={imageLoading}   
                    >
                        Upload Image
                        <input hidden accept="image/*" multiple type="file" onChange={onImageChange}  />
                      </LoadingButton>
                    </Grid>
                    {/* <Grid item  >
                      <Button 
                        onClick={toggleConfirm} 
                        startIcon={<Search />} 
                        variant='contained' 
                        size='small'  
                      >
                        Search Image Library
                      </Button>
                    </Grid> */}
                </Grid>
                }
              </Grid>
            </Grid> 
          </Grid>
            {pictures.map((item, i) => 
              <Grid item key={i}>
                <ImageCard {...item} origWord={origWord} key={i} />
              </Grid>
            )}
            <ImageSearchDialog
              confirm={confirm}
              closeConfirm={toggleConfirm}
              sourceWord={sourceWord}
              sourceLang={sourceLang}
              targetLang={targetLang}
              vocabId={vocabId}
              pictures={pictures} 
            />
          </Grid>
}
