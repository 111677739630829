import React from "react";

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Typography from "@mui/material/Typography"
import LinkIcon from '@mui/icons-material/Link'
import Container from '@mui/material/Container'
import ListAlt from '@mui/icons-material/ListAlt'
import LoadingButton from '@mui/lab/LoadingButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import ClusterInput from '../Inputs/ClusterInput'
import FirstClusterArtLink from '../ListItems/FirstClusterArtLink'
import FlagRound from "../ImageElements/FlagRound";
import ConfirmParsedArt from '../Dialogs/ConfirmParsedArt'
import { Add } from "@mui/icons-material";
import { Button, CardContent, Divider, IconButton, Tooltip } from "@mui/material";

const SuggestedItem = ({
  art_id,
  title,
  addSuggested,
}) => {
  
  const add = () => addSuggested(art_id)
  return  <>
      <Grid item xs={1}>
        <Tooltip title='Add to Cluster'>
          <IconButton size="small" color='primary' onClick={add}>
            <Add />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Typography>
          {title}
        </Typography>
      </Grid>
      </>
}

export default function ClusterPage({ 
    clusterName, 
    clusterName1,
    addCluster,
    lang, 
    language,
    url,
    linkLoading,
    nameLoading,
    clusterLinks,
    handleLink,
    snack,
    msgType,
    alertMsg,
    handleClose,
    cluster_id,
    linkError,
    linkerrorMsg,
    parsedArtTitle,
    parsedArtText,
    addParsedArt,
    confirm,
    closeConfirm,
    disagree,
    agree,
    handleClusterName,
    firstRecommendations,
    parseArt,
    deleteCluster,
    initRecs,
    addSuggested,
 }){

  const count = clusterLinks.length
  return  <Grid container spacing={1} justifyContent='center' >
            <Grid item xs={12} lg={9}>
              <Container component="main" maxWidth="xl" >
                <main>
                  <Card sx={{p: 2, mb:2}}> 
                    <Grid container 
                          justifyContent="space-between" 
                          alignItems="center" 
                          spacing={2} 
                    >
                      <Grid item >
                        <FlagRound
                          flagSize='small'
                          lang={lang}
                        />
                      </Grid>
                      <Grid item xs={8}> 
                        <Typography variant="h5">
                          Create First Cluster <span style={{color: '#6415ff', marginLeft: '1em'}}>{clusterName1}</span> 
                        </Typography>
                      </Grid>
                      {clusterName1.length>0 &&
                        <Grid item>
                          <LoadingButton
                           loading={nameLoading}
                           variant="contained" 
                           color="error" 
                           onClick={deleteCluster} 
                          >
                             Delete
                          </LoadingButton>
                        </Grid>
                      }
                      {/* <Grid item xs={1}> 
                        <a href="/welcome_first_cluster" target="_blank">
                          <Button variant='text' color="primary" startIcon={<HelpOutlineIcon />}>
                            Help
                          </Button>
                        </a>
                      </Grid> */}
                      {/* {clusterName1.length===0 &&
                      <>
                      <Grid item xs={12} md={7}> 
                        <FormControl sx={{width: '100%'}}>
                          <ClusterInput 
                            error={linkError}
                            errorMsg={linkerrorMsg}
                            value={clusterName}
                            label='Cluster Name'
                            handleFunc={handleClusterName} 
                          />
                        </FormControl>
                      </Grid>
                        <Grid item >
                          <LoadingIconButton
                            title='Add Cluster Name'
                            onClick={addCluster}
                            loading={nameLoading}
                            icon={<LinkIcon />}
                          />
                        </Grid>
                        </> 
                        } */}
                        <Grid item xs={12} md={7}> 
                          <FormControl sx={{width: '100%'}}>
                            <ClusterInput 
                              error={linkError}
                              errorMsg={linkerrorMsg}
                              value={clusterName}
                              label='Cluster Name'
                              handleFunc={handleClusterName} 
                            />
                          </FormControl>
                        </Grid>
                          <Grid item >
                            <LoadingIconButton
                              title='Add Cluster Name'
                              onClick={addCluster}
                              loading={nameLoading}
                              icon={<LinkIcon />}
                            />
                          </Grid>
                      </Grid>
                  </Card> 
                 
                  {clusterName1.length>0 &&
                    <Card sx={{p: 2, mb:2}}> 
                      <Grid container 
                            justifyContent="space-between" 
                            alignItems="center" 
                            spacing={2} 
                      >
                          <Grid item xs={12}> 
                          <Typography  variant="h6">
                            Add at least one (1) url in your native language ({language}). Add at least 3 for best results.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}> 
                          <FormControl sx={{width: '100%'}}>
                            <ClusterInput 
                              error={linkError}
                              errorMsg={linkerrorMsg}
                              value={url}
                              label='Cluster Link Url'
                              handleFunc={handleLink} 
                            />
                          </FormControl>
                        </Grid>
                        <Grid item >
                          <LoadingIconButton
                            title='Add Url'
                            onClick={parseArt}
                            loading={linkLoading}
                            icon={<LinkIcon />}
                          />
                        </Grid>
                      </Grid>
                      <div>
                    
                    {clusterLinks.map((l, index) => 
                      <FirstClusterArtLink 
                        key={l.id} 
                        idx={index}
                        {...l} 
                        clusterName={clusterName} 
                        count={count} 
                        cluster_id={cluster_id} 
                      />
                    )}
                    </div>
                      
                    </Card> 
                  }
                  {count>0 &&
                    <Grid item >
                      <LoadingIconButton
                        title='Get Recommendations'
                        onClick={firstRecommendations}
                        loading={linkLoading}
                        icon={<ListAlt />}
                      />
                    </Grid>
                  }  
                   <Card sx={{mt: 2}}>
                      <CardContent>
                        <Typography variant="h5">
                          Suggestions
                        </Typography>
                      </CardContent>
                      <Divider />
                      <CardContent>
                        <Grid container spacing={1}>
                          {initRecs.length>0 && initRecs.map(r => 
                            <SuggestedItem 
                              key={r.art_id}
                              addSuggested={addSuggested}
                              {...r}
                            />
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                  />
                </main>
              </Container>
            </Grid>
            <ConfirmParsedArt 
              title={parsedArtTitle}
              artText={parsedArtText}
              addParsedArt={addParsedArt}
              closeConfirm={closeConfirm}
              confirm={confirm}
              disagree={disagree}
              agree={agree}
            />
        </Grid>
}