import React, { useState, useEffect } from "react"
import { useLiveQuery } from "dexie-react-hooks";

import {
  createTranslatedArt1,
} from '../queries/translatedArtQueries'

import {
  analytics,
  dexdb
} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import { 
  getTranslations,
  checkQuizCount
 } from '../utils'

import { useParams } from "react-router-dom";

import { isValidHttpUrl } from '../utils'

import ClusterAddTextPage from '../components/Pages/ClusterAddTextPage'

export default function Cluster({history}){

    const { 
      cluster_id, 
      cluster_name, 
      lang, 
      count,
      clusterActive,
    } = useParams()

    const { invalidurl } = getTranslations()

    const CHARACTER_LIMIT = 10000
    const MIN_CHARACTER = 200

    const member = useLiveQuery(() => dexdb.member.toArray(), []);

    const native_lang = member ? member[0].native_lang : 'en'

    const tooLongError = `Please add text that is less than ${CHARACTER_LIMIT} words.`

    const tooShortError = `Please enter text that is at least ${MIN_CHARACTER} characters`

    const titleError = 'Please enter the article title.'

    const instructions = ` If an article link you want to add requires a login or is otherwise not publicly available, you can add the link, article title and text (${MIN_CHARACTER} - ${CHARACTER_LIMIT} characters). `
  
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ url, setSearchLink ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ text, setText ] = useState('')
    const [ loading, setLoading ] = useState('')
    const [ error, setError] = useState(false)
    const [ errorUrl, setUrlError] = useState(false)
    const [ errorUrlMsg, setUrlErrorMsg] = useState('')
    const [ errorText, setTextError] = useState(false)
    const [ errorTextMsg, setTextErrorMsg] = useState('')
    const [ errorTitle, setTitleError] = useState(false)
    const [ errorTitleMsg, setTitleMsgErrorMsg] = useState('')

    const wordCount = text.length ?? 0

    const handleLink = event => setSearchLink(event.target.value)

    const handleText = event => setText(event.target.value)

    const handleTitle = event => setTitle(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const checkItem = () => {
      const chars = text.length
      const urlok = isValidHttpUrl(url)
      const ok = chars<MIN_CHARACTER || chars>CHARACTER_LIMIT || title.length < 3 || !urlok ? false : true
      return ok
    }

    const addText = async () => {
      setLoading(true)
      setError(false)

      setUrlError(false)
      setTitleError(false)
      setTextError(false)
      setUrlErrorMsg('')
      setTextErrorMsg('')
      setTitleMsgErrorMsg('')

      const urlok = isValidHttpUrl(url)
      if(!urlok){
        setUrlError(true)
        setUrlErrorMsg(invalidurl)
        setLoading(false)
        return
      }

      if(title.length<2 || text.length<0){
        setTitleError(true)
        setTitleMsgErrorMsg(titleError)
        setLoading(false)
        return
      }
      const wordCount = text.split(' ').length
  
      if(wordCount<MIN_CHARACTER){
        setTextError(true)
        setTextErrorMsg(tooShortError)
        setLoading(false)
        return
      }

      if(wordCount>CHARACTER_LIMIT){
        setTextError(true)
        setTextErrorMsg(tooLongError)
        setLoading(false)
        return
      }

      setLoading(false)

      try {
        await createTranslatedArt1({
          url,
          lang,
          title, 
          orig_text: text,
          orig_lang: native_lang,
          cluster_id,
          source: 'web',
        })
        showSnack('success', 'Article added!')
        setText('')
        setTitle('')
        setSearchLink('')

        logEvent(analytics, 'add_cluster_link', {
          lang, 
          cluster_id,
          device: 'web',
        })
        const link = `/user/cluster/${cluster_id}`
        history.push(link)
      } catch(error) {
        showSnack('error', 'Error adding article!')
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      checkQuizCount()
    }, [])
    

    return <ClusterAddTextPage 
            clusterName={cluster_name} 
            cluster_id={cluster_id}
            clusterActive={clusterActive}
            lang={lang}
            count={count}
            url={url}
            title={title}
            text={text}
            loading={loading}
            error={error}
            errorUrl={errorUrl}
            errorUrlMsg={errorUrlMsg}
            errorTitle={errorTitle}
            errorTitleMsg={errorTitleMsg}
            errorText={errorText}
            errorTextMsg={errorTextMsg}
            handleLink={handleLink}
            handleText={handleText}
            handleTitle={handleTitle}
            addText={addText}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            checkItem={checkItem}
            instructions={instructions}
            wordCount={wordCount}
            characterLimit={CHARACTER_LIMIT}
            characterMin={MIN_CHARACTER}
          />
}