import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory} from "react-router-dom"
import { useLiveQuery } from "dexie-react-hooks";

import {analytics, dexdb} from '../App'

import { checkQuizCount } from '../utils';

import { 
     logEvent, 
 } from "firebase/analytics";

import { getArt } from '../queries/recommendationQueries'

import {
  checkPrevWords,
  addVocabWordArtSent,
} from '../queries/vocabWordQueries'

import {
  getTranslation
} from '../queries/workerQueries'

import ArticlePage from '../components/Pages/ArticlePage'

export default function Article(){

    const {
      id, 
      lang, 
    } = useParams()

    const history = useHistory()

    const art_id = id

    const art1 = useLiveQuery(() => dexdb.articles.where('id').equals(parseInt(id)).toArray(),[id])

    const loaded = art1 && art1.length>0
    const title3 = useMemo(() => loaded  ? art1[0].title : '', [art1, loaded])
    const link3 = useMemo(() => loaded  ? art1[0].url : '', [art1, loaded])
    const read3 = useMemo(() => loaded && art1[0].read.length>0 ? art1[0].read[0].active : false, [art1, loaded])
    const playlist3 = useMemo(() => loaded && art1[0].playlist.length>0  ? art1[0].playlist[0].active : false, [art1, loaded])
    const playlistId3 = useMemo(() => loaded && art1[0].playlist.length>0 ? art1[0].playlist[0].id : 'none', [art1, loaded])

    const translations1 = useMemo(() => loaded  ? art1[0].user_vocab_sentences.map(t => ({
      orig_text: t.user_vocab_words.source_word, 
      trans_text: t.user_vocab_words.target_word,
      orig_lang: t.user_vocab_words.source_lang, 
      trans_lang: t.user_vocab_words.target_lang, 
      id: t.user_vocab_words.id,
      active: t.user_vocab_words.active
    })).filter(t => t.active) : [], [art1, loaded])

    const member = useLiveQuery(() => dexdb.member.toArray(), []);

    const native_lang = useMemo(() => member ? member[0].native_lang : 'en', [member]);
    const langs = useMemo(() => member ? member[0].langs : [], [member]); 

    const [ open, setOpen ] = useState(false)
    const [ transWord, setTransWord ] = useState('')
    const [ translated, setTranslated ] = useState(false)
    const [ translatedWord, setTranslatedWord ] = useState('')
    const [ loading, setLoading ]= useState(false)
    const [ transLoading, setTransLoading ]= useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClickOpen = () => setOpen(true)
    const handleClickClose = () => setOpen(false)
    const handleClose = () => setSnack(false)
    const handleTransWord = (e) => setTransWord(e.target.value)

    const playLink = `/user/play_article/${id}/${lang}`

    const goToPlayArt = () => history.push(playLink)

    const showSnack = (type, message) => {
      setTransLoading(false)
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const resetTranslation = () => {
      setTransWord('')
      setTranslated(!translated)
    }

    const addTranslation = async () => {
      setTransLoading(true)
      try {
        const {
          user_vocab_id,
        } = await addVocabWordArtSent({
          source_word: transWord,
          target_word: translatedWord,
          source_lang: lang,
          target_lang: native_lang,
          artId: id,
        })
        showSnack('success', 'Word added!')
       
        const newItem =  {
          user_vocab_words: {
            id: user_vocab_id,
            source_word: transWord,
            target_word: translatedWord,
            source_lang: lang,
            target_lang: native_lang,
            active: true,
          }
        }
        
        const user_vocab_sentences = [...art1[0].user_vocab_sentences, newItem]
        const newArt = {...art1[0], user_vocab_sentences}
 
        await dexdb.articles.put(newArt)

        logEvent(analytics, 'add_vocab', { 
          source_lang: lang,
          target_lang: native_lang,
          number: 1,  
          type: 'art',
          device: 'web',
        })
      } catch(error) {
        showSnack('error', 'Error adding word!')
      } finally{
        setTransLoading(false)
      }
    }

    const translateWord1 = async () => {
      if (transWord.length<2){
        const msg = 'Please enter a word to translate.'
        showSnack('error', msg)
        return
      }

      if (transWord.split(' ').length>5){
        showSnack('error','You can only translated phrases 4 words or less')
        return
      }
      const duplicateTranslations = translations1.filter(t => t.orig_text === transWord)

      if (duplicateTranslations.length>0){
        showSnack('error','You have already translated this word. Click the translations button for a list of previously translations.')
        return
      }

      setTransLoading(true)

      try {
        const words = await checkPrevWords({
          source_word: transWord.trim(), 
          source_lang: lang, 
          target_lang: native_lang, 
          art_id,
        })
  
        if(words.length>0){
          const translation = words[0].target_word  
          
          setTranslated(true)
          setTranslatedWord(translation)
        } else {

          try {

            const translation = await getTranslation({
              text: transWord, 
              sourceLang: lang,
              targetLang: native_lang,
            })
            
            logEvent(analytics, 'translate_word', { 
              source_lang: lang,
              target_lang: native_lang,
              from: 'art',
              device: 'web',
            })
            
            setTranslated(true)
            setTranslatedWord(translation)
          } catch(error){
            showSnack('error', 'Translation error.')
          } finally {
            setLoading(false)
            setTransLoading(false)
          }
        }

      } catch(error){
        showSnack('error', 'Translation error.')
        return
      } finally {
        setLoading(false)
        setTransLoading(false)
      }
    }

    useEffect(() => {
      speechSynthesis.cancel()

      const setDbArt = async id => {
        const art = await getArt({id})
        await dexdb.articles.put(art)
      }  
     
      if(art1 && art1.length===0){
        setDbArt(id)
      }

      if(langs.length>0 && !langs.includes(lang)){
        return history.push('/user/dashboard')
      }
      checkQuizCount()
      logEvent(analytics, 'select_art', { 
        lang: lang,
        art_id: id,
        device: 'web',
      })
      checkQuizCount()
    },[id, lang, art1, member, langs, history])

    
    return (
        <ArticlePage 
          link={link3}
          title={title3}
          art_id={art_id}
          translated={translated}
          transWord={transWord}
          translatedWord={translatedWord}
          resetTranslation={resetTranslation}
          translateWord1={translateWord1}
          handleTransWord={handleTransWord}
          loading={loading}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          handleClickClose={handleClickClose}
          open={open}
          translations={translations1}
          lang={lang}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          transLoading={transLoading}
          playlist={playlist3}
          id={id}
          read={read3}
          playlistId={playlistId3}
          addTranslation={addTranslation}
          goToPlayArt={goToPlayArt}
        />
    )
}