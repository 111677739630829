import React from "react";

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Box, CircularProgress } from "@mui/material";
import Paper from '@mui/material/Paper'
import Divider from "@mui/material/Divider"
import AddIcon from '@mui/icons-material/Add'

import TranslateIcon from '@mui/icons-material/Translate'
import Container from '@mui/material/Container'

import SnackAlert from '../Dialogs/SnackAlert'
import TooltipButton from "../Buttons/TooltipButton";

import PaginationController from '../VocabQuiz/PaginationController'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import AdSidebar from "../Ads/AdSidebar"
import Player from '../Player/Player'
import FlagRound from '../ImageElements/FlagRound'
import CurrentSentence from '../Player/CurrentSentence'

export default function ReadGutenbergPage({
    lang,
    addCluster,
    loading,
    snack,
    msgType,
    alertMsg,
    handleClose,
    errorMsg,
    isError,
    searchresults,
    sentIdx,
    translate,
    forward,
    back,
    title,
    transloading,
    origText,
    curTranslation,
    adReload,
    handleSource,
    handleTarget,
    sourceWord,
    targetWord,
    addVocab,
    vocabloading,
}){

    const transload = transloading 

    const vocabdisableed = sourceWord.includes('Select') | targetWord.includes('Select') | targetWord.length===0 | sourceWord.length===0

    return( 
              <Grid container spacing={1} justifyContent='center' >
                <Grid item xs={12} lg={9}>
                  <Container component="main" maxWidth="xl" >
                      <main>
                        {origText.length>0 && 
                          <Paper sx={{padding: 2}} > 
                            <Grid container 
                                  justifyContent="space-between" 
                                  alignItems="center" 
                                  spacing={2} 
                            >
                            <Grid item xs={12}> 
                              <Grid container 
                                    alignItems='center'
                                    justifyContent='space-between'
                                >
                                  <Grid item>
                                  <FlagRound 
                                    flagSize='small'
                                    lang={lang}
                                  />
                                  </Grid>
                                <Grid item > 
                                  <Typography variant="h5" >
                                    {title}
                                  </Typography>
                                </Grid>
                                <Grid item > 
                                  <LoadingIconButton
                                    size="small"
                                    title='Add To Library'
                                    onClick={addCluster}
                                    icon={<AddIcon />}
                                    loading={loading}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}> 
                              <Grid container 
                                    alignItems='center'
                                    justifyContent='space-between'
                              >
                                <Grid item > 
                                  <TooltipButton
                                    title='Translate'
                                    disabled={transload}
                                    func={translate}
                                    icon={transloading ?  <CircularProgress size={25} /> : <TranslateIcon />}
                                  />
                                </Grid>
                                <Grid item>
                                  <Player
                                    lang={lang}
                                    origText={origText}
                                    minSpeed={0.1}
                                    maxSpeed={1.0}
                                    increment={0.1}
                                  />          
                                </Grid>
                                <Grid item > 
                                  <PaginationController  
                                    page={sentIdx}
                                    maxPage={searchresults.length}
                                    handleBack={back}
                                    handleForward={forward}
                                  />
                                </Grid> 
                              </Grid> 
                            </Grid> 
                            {curTranslation.length>0 &&
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            }   
                            {curTranslation.length>0 &&
                              <Grid item xs={12}>
                                <Grid container 
                                      spacing={2} 
                                      alignItems='center'
                                      justifyContent='space-between'
                                >
                                  <Grid item >
                                      <Typography variant="h6">
                                        <b>Source:</b> {sourceWord}
                                      </Typography>
                                    </Grid>
                                    <Grid item >
                                      <Typography variant="h6">
                                        <b>Target:</b> {targetWord}
                                      </Typography>
                                    </Grid>
                                    <Grid item >
                                      {!vocabdisableed ? 
                                        <LoadingIconButton
                                          size="small"
                                          title='Add Vocabulary'
                                          onClick={addVocab}
                                          icon={<AddIcon />}
                                          loading={vocabloading}
                                          fullWidth={false}
                                        />
                                        : 
                                        <Button variant='contained' disabled={true} size="small" startIcon={<AddIcon />}>
                                          Add Vocabulary'
                                        </Button>
                                      }
                                    </Grid>
                                  </Grid>                               
                              </Grid>     
                            }
                            </Grid>
                          </Paper>
                        }
                      <Box sx={{mt: 4}}>
                        {loading ? 
                          <CircularProgress />
                          :
                          <CurrentSentence
                            origText={origText}
                            curTranslation={curTranslation}
                            handleSource={handleSource}
                            handleTarget={handleTarget}
                            showTranslation={curTranslation.length>0}
                          />
                        }
                      </Box>
                      {!loading && isError &&   
                        <Alert severity="error" sx={{mt:2}}>
                          {errorMsg}
                        </Alert>
                      }
                      <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                      />
                    </main>
                  </Container>
                  </Grid>
                  <Grid item>
                    {adReload ?
                       <AdSidebar />
                      :
                      <div style={{minWidth: 300}} />
                    }
                  </Grid>
              </Grid>
        
    )
}