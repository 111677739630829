import React, {useState} from 'react'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import { Button, Card} from '@mui/material';
import { Link } from '@mui/icons-material';
import { Upload } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import AddUrlForm from '../../Inputs/AddUrlForm'

import AudioCard from './AudioCard'
import AudioSearchDialog from './AudioSearchDialog'

const AudioClips = ({
  audio,
  audioLoading,
  onAudioChange,
  sourceWord,
  sourceLang, 
  targetLang,
  vocabId,
  audioUrl,
  handleAudioUrl,
  toggleAudioUrl,
  addAudioUrl,
  showAudioUrl,
}) => {

  const [ confirm, setConfirm ] = useState(false)
  
  const toggleConfirm = () => setConfirm(!confirm)

  return (
          <Grid container 
            spacing={2} 
            alignItems="center"
          >
             <Grid item xs={12} >
              <Grid container 
                alignItems='center'
                justifyContent='space-between'
            
              >   
               <Grid item >
                <Typography variant='h5'>
                  Audio
                </Typography>
              </Grid>
              <Grid item >

              <Grid item >
              {showAudioUrl ?
                    <AddUrlForm    
                      url={audioUrl}
                      handleUrl={handleAudioUrl}
                      addUrl={addAudioUrl}
                      buttonLoading={audioLoading}
                      toggleUrl={toggleAudioUrl}
                    />
                  :
                <Grid container 
                  spacing={2} 
                  alignItems='center'
                >
                   <Grid item  >
                    <Button 
                      onClick={toggleAudioUrl} 
                      startIcon={<Link />} 
                      variant='contained' 
                    >
                      Audio Url
                    </Button>
                  </Grid>
                  <Grid item  >
                    <LoadingButton
                        variant="contained" 
                        component="label"  
                        startIcon={<Upload />}
                        loading={audioLoading}
                    >
                        Upload Audio
                        <input hidden accept="audio/*" multiple type="file" onChange={onAudioChange}  />
                      </LoadingButton>
                    </Grid>
                    {/* <Grid item  >
                      <Button 
                        onClick={toggleConfirm} 
                        startIcon={<Search />} 
                        variant='contained'
                        size='small'  
                      >
                        Search Audio Library
                      </Button>
                    </Grid> */}
                  </Grid>
}
                </Grid>
              </Grid>
            </Grid> 
          </Grid>
            {audio.map((a, i) => 
              <Grid item key={i}>
                <Card >
                  <AudioCard {...a} />
                </Card>
              </Grid>
            )}
            <AudioSearchDialog 
              confirm={confirm}
              closeConfirm={toggleConfirm}
              sourceWord={sourceWord}
              sourceLang={sourceLang}
              targetLang={targetLang}
              vocabId={vocabId}
              audio={audio}
            />
        </Grid>
  )
}

export default AudioClips