import React, {useState, useEffect} from 'react'
import { useLiveQuery } from "dexie-react-hooks"
import {dexdb} from '../../App'

import {
    getGoals, 
    updateGoals, 
    createGoal, 
    deleteGoal,
} from '../../queries/goalQueries'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField'
import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox'

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'

import SnackAlert from '../Dialogs/SnackAlert'
import FlagRound from '../ImageElements/FlagRound'
import { Divider, IconButton } from '@mui/material';


export default function UpdateGoals(){
 
    const member = useLiveQuery(() => dexdb.member.toArray(), [])

    const goals = useLiveQuery(() => dexdb.goals.toArray(), [])

    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ read_goal, setReadGoal ] = useState(0)
    const [ quiz_goal, setQuizGoal ] = useState(0)
    const [ listen_goal, setListenGoal ] = useState(0)
    const [ lang, setLang ] = useState('')

    const handleReadGoal = event => setReadGoal(event.target.value)

    const handleQuizGoal = event => setQuizGoal(event.target.value)

    const handleListenGoal = event => setListenGoal(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const addGoals = async () => {
        setLoading(true)
        const newGoal = {
            read_goal,
            quiz_goal, 
            listen_goal,
            lang,
        }

        try {
            await createGoal(newGoal)
            showSnack('success', 'Goal added!')
            setReadGoal(0)
            setQuizGoal(0)
            setListenGoal(0)
        } catch(error){
            showSnack('error', 'Error adding goals!')
        } finally{
            setLoading(false)
        }
       
    }

    const deleteGoal1 = async (id) => {
        const act = goals.filter(g => g.id===id)[0]
        if(!act.active){
            try {
                await deleteGoal({id, goals})
            } catch(error){
                showSnack('error', 'Error activating goal!')
            }
        } else {
            showSnack('error', 'Deselect before deleting.')
        }
    }

    const activateGoal = async (id) => {
        const act = goals.filter(g => g.id===id)[0]

        if(!act.active){
            const newact = {...act, active: true}

            const notact = goals.filter(g => g.id!==id).map(p => ({...p, active: false}))
            const newgoals = [...notact, newact]

            try {
                await updateGoals(newgoals)
            } catch(error){
                showSnack('error', 'Error activating goal!')
            }
        } 
    }

    useEffect(()=> {
    
        const getGoals1 = async () => {
     
            if(member && member.length>0){
                const lang = member[0].langs[0]
                setLang(lang)

                if(goals && goals!==null){
                    if(goals.length===0){
                        await getGoals() 
                    }
                }
            }
        }

        getGoals1()

    },[member, goals])

    return(
    <>
    <Card>
       <CardContent>
            <Typography variant={'h5'} sx={{color:'#6415ff'}}>
                Goals
            </Typography>
        </CardContent>
        <Divider />
        <CardContent>       
            <Grid container spacing={2} alignItems='stretch'  >
                <Grid item >
                    <TextField  
                        size='small'
                        label="Articles/Day" 
                        variant="outlined" 
                        onChange={handleReadGoal} 
                        type='number'
                        value={read_goal}
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        size='small' 
                        label="Quizzes/Day" 
                        variant="outlined" 
                        onChange={handleQuizGoal} 
                        type='number'
                        value={quiz_goal}
                    />
                </Grid>
                <Grid item >
                    <TextField  
                        size='small'
                        label="Listen (mins)/Day" 
                        variant="outlined" 
                        onChange={handleListenGoal} 
                        type='number'
                        value={listen_goal}
                    />
                </Grid>
                <Grid item >
                    <LoadingButton
                        variant='contained'
                        loading={loading}
                        startIcon={<AddIcon />}
                        onClick={addGoals} 
                    >
                        Add Goal
                    </LoadingButton>
                </Grid>
            </Grid>
        </CardContent>  
        <Divider />  
        <CardContent>       
            <TableContainer >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell align="right">Articles</TableCell>
                        <TableCell align="right">Quizzes</TableCell>
                        <TableCell align="right">Listening (mins)</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {goals && goals.length>0 && goals.map((row, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={row.active}
                                    onChange={() => activateGoal(row.id)}
                                />
                            </TableCell>
                            <TableCell >{lang.length>0 && <FlagRound flagSize='small' lang={lang} />}</TableCell>
                            <TableCell align="right">{row.read_goal}</TableCell>
                            <TableCell align="right">{row.quiz_goal}</TableCell>
                            <TableCell align="right">{row.listen_goal}</TableCell>
                            <TableCell align="right"><IconButton onClick={() => deleteGoal1(row.id)}  ><DeleteIcon color="error" /> </IconButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
        </CardContent>       
    </Card>
    <SnackAlert 
      snack={snack}
      handleClose={handleClose}
      msgType={msgType}
      alertMsg={alertMsg}
    />
    </>
  )
}


