import React from 'react'

import { getTranslations } from '../../utils'

import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import EmailIcon from '@mui/icons-material/Email'
// import { Google, Apple } from '@mui/icons-material'

import LoginLink from '../Login/LoginLink'
import LoginHeader  from '../Login/LoginHeader'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import { Divider } from '@mui/material'

export default function SignUpAllPage({
    inputs, 
    signUpEmail,
    errorMsg,
    snack,
    msgType,
    alertMsg,
    handleClose,
    loading,
    // signUpGoogle,
    // signUpGApple,
}){
  const {         
    signuptitle,
  } = getTranslations()

  // const buttonItems = [
  //   {
  //     title: 'Google Sign In',
  //     icon: <Google />,
  //     onClick: signUpGoogle,
  //   },
  //   {
  //     title: 'Apple Sign In',
  //     icon: <Apple />,
  //     onClick: signUpGApple,
  //   },
  // ]

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <CssBaseline />
          <Grid container 
              justifyContent='center' 
              textAlign='center'
              spacing={2}
          >
          <Grid item xs={12}>
            <LoginHeader title={signuptitle} />
          </Grid>  
          {/* {inputs.map(l => 
            <Grid item xs={12} key={l.name} >
              <LoginInput 
                {...l} 
                error={errorMsg} 
              />
            </Grid>
          )} */}
          <Grid item xs={12} >
            {/* <LoadingIconButton 
                title='Email Sign Up'
                icon={<EmailIcon />}
                onClick={signUpEmail}
                size='large'
                loading={loading}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>  
          {/* {buttonItems.map(b => 
            <Grid item xs={12} key={b.title}>
              <LoadingIconButton 
                {...b}
                size='large'
                loading={loading}
              />
            </Grid>
          )} */}
          <Grid item xs={12}>
            <LoginLink />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Paper>
      <SnackAlert 
        snack={snack}
        handleClose={handleClose}
        msgType={msgType}
        alertMsg={alertMsg}
      />
    </Container>
  )
}