import {supabase} from "../supabase"

import { getAuthuser } from "./memberQueries.js"

import { getNowIso } from "../utils"

const answertable = 'quiz_answers'

// MUTATIONS

export const createQuizAnswer =  async ({
    answer_word,
    correct,
    user_vocab_word_id,	
}) => {
    const user = await getAuthuser()
    const aid = user ? user.id : ''
    const now = getNowIso()
    const { data, error } = await supabase
        .from(answertable)
        .insert({
            answer_word,
            correct,
            user_vocab_word_id,
            aid,
            inserted_at: now,
        })
        .select()

    if(error) throw new Error(error.message)
    
    return data
}
