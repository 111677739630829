import React, { useState } from 'react'

import {createSupportRequest} from '../queries/serviceDeskQueries'

import SupportNonLoggedPage from '../components/Pages/SupportNonLoggedPage'

export default function SupportNonLogged(){ 
    const [ supportType, setSupportType ] = useState('')
    const [ supportQuestion, setSupportQuestion ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ email, setEmail ] = useState('')

    const handleEmail = event => setEmail(event.target.value)
    const handleSupportType = event => setSupportType(event.target.value)
    const handleSupportQuestion = event => setSupportQuestion(event.target.value)
    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const submitSupport = async () => {

      if (email.length===0){
        setMsgType('error')
        return showSnack('error', 'Please enter a valid email.')
      }

      if (supportType.length===0){
        setMsgType('error')
        return showSnack('error', 'Please choose a support type.')
      }
  
      if ( supportQuestion.length===0){
        return showSnack('error', 'Please choose a support question.')
      }

      try {
        const response = await createSupportRequest({
          supportQuestion, 
          supportType,
          email,
          auth_id: null,
        })
        
         if(response.error && response.error.length>0){
           showSnack('error', response.error)
           return
         }
         showSnack('success', 'Support Message Sent!')
 
       } catch(error){
         showSnack('error', error.message)
       } finally{
         setLoading(false)
       }
    }

  const supportTypes = ['Technical','Other']
  
  return <SupportNonLoggedPage
          alert={alert}
          msgType={msgType}
          success={msgType}
          alertMsg={alertMsg}
          successMsg={alertMsg}
          email={email}
          handleEmail={handleEmail}
          submitSupport={submitSupport} 
          supportType={supportType}
          supportQuestion={supportQuestion}
          handleSupportType={handleSupportType}
          handleSupportQuestion={handleSupportQuestion}
          supportTypes={supportTypes}
          showSnack={showSnack}
          snack={snack}
          loading={loading}
          handleClose={handleClose}
        />
}