import React from "react";

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'

import FlagRound from '../ImageElements/FlagRound'


export default function LangSelectButton({
    width,
    handleLang,
    lang,
    newLangs,
    language,
}){
    const selected1 = {
        width: width,
        backgroundColor:'#6415ff',
        color: 'white',
        '&:hover': {
            backgroundColor: '#3100CC',
        },
    }
    
    const notselected = {
        width: width,
        backgroundColor: grey[200],
        color: 'black',
        '&:hover': {
            backgroundColor: grey[300],
        },
    }
    

    const selected = newLangs.includes(lang);
    const flagSize1 = 'small'
    return(
        <Grid item >
            <Button
                onClick={handleLang} 
                variant="contained"
                sx={selected ? selected1 : notselected }
            >
                <Grid   container 
                        spacing={1}
                        alignItems="center"
                        sx={{padding: 1}}
                >
                    <Grid item>
                        <FlagRound 
                            flagSize={flagSize1}
                            lang={lang}
                        />
                    </Grid>
                    <Grid item>
                    <Typography  > 
                        {language}
                    </Typography>
                    </Grid>
                </Grid>
            </Button>
        </Grid>
    )
}