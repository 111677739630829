import React from 'react'

import { getTranslations, countryList } from '../../utils'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import SendIcon from '@mui/icons-material/Send'
import Typography from '@mui/material/Typography'

import LoadingIconButton from '../Buttons/LoadingIconButton'
import LoginHeader  from '../Login/LoginHeader'
import SnackAlert from '../Dialogs/SnackAlert'
import Copyright from '../Login/Copyright'
import LoginInput from '../Inputs/LoginInput'
import LangDropdown from '../DropDowns/LangDropdown'

export default function SignUpCompletePage({
    inputs, 
    handleNativeLang,
    nativeLang,
    handleTargetLang,
    targetLang,
    signUp,
    errorMsg,
    langugages,
    targetLanguages,
    snack,
    msgType,
    alertMsg,
    handleClose,
    timezonelabel,
    timeZone,
    handleTimeZone,
    minimalTimezoneSet,
    handleCountry,
    country,
    loading,
    email,
    signUpSuccess,
}){
  const {         
    nativelang,
    studying,
  } = getTranslations()

  return (
    <Container component="main" maxWidth={signUpSuccess ? "md" : 'xs'}>
      <Paper sx={{ padding: 2, marginTop: 4, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <Grid container 
              justifyContent='center' 
              textAlign='center'
              alignItems='center'
              spacing={2}
        >
          <Grid item xs={12}>
            <LoginHeader title='Complete Sign Up' />
          </Grid>  
          <Grid item sm={12}>
            <Grid container  
                  justifyContent='space-between' >
              <Grid item >
                <Typography>
                  Email:
                </Typography>
              </Grid> 
              <Grid item >
                <Typography color="#6415ff">
                  {email}
                </Typography>
                </Grid> 
            </Grid>
        </Grid> 
          {inputs.map(l => 
            <Grid item  sm={12} key={l.name} >
              <LoginInput {...l} error={alertMsg} />
            </Grid>
          )}
          <Grid item sm={12}>
            <LangDropdown 
              label={nativelang}
              value={nativeLang}
              onChange={handleNativeLang}
              languages={langugages}
            />
          </Grid>
          <Grid item sm={12}>
            <LangDropdown 
              label={studying}
              value={targetLang}
              onChange={handleTargetLang}
              languages={targetLanguages}
            />
           </Grid>
          <Grid item sm={12}>
            <FormControl  fullWidth>
              <InputLabel >
                Timezone
              </InputLabel>
              <Select
                label='Timezone'
                value={timeZone}
                onChange={handleTimeZone}
                sx={{backgroundColor: 'white'}}
              >
                {minimalTimezoneSet.map(t => 
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl  fullWidth>
              <InputLabel >
                Country
              </InputLabel>
              <Select
                label=' Country'
                value={country}
                onChange={handleCountry}
                sx={{backgroundColor: 'white'}}
              >
                {countryList.map(t => 
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <LoadingIconButton
              loading={loading}
              onClick={signUp} 
              size='large'
              title='Complete Sign Up'
              icon={<SendIcon />}
            />   
          </Grid>
         
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Paper>
      <SnackAlert 
        snack={snack}
        handleClose={handleClose}
        msgType={msgType}
        alertMsg={alertMsg}
      />
    </Container>
  )
}