import React, {useState, useEffect} from 'react'
import { useLiveQuery } from "dexie-react-hooks"

import { dexdb } from "../App";

import { 
  reorderList,
  checkQuizCount,
} from '../utils'

import {
  updatePlaylistOrder1,
} from '../queries/playListQueries'

import PlaylistPage from '../components/Pages/PlaylistPage'

export default function Playlist({history}){

    const playlist = useLiveQuery(() => dexdb.playlist.orderBy('playlist_position').toArray(), []);

    const [ orderedPlaylist, setOrderedPlaylist ] = useState([])
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const onDragEnd = async result => {
      if (!result.destination) {
        return;
      }
      // sets prev order to roll back in case of sb update error
     

      const updatedPlaylist = reorderList(
        orderedPlaylist,
        result.source.index,
        result.destination.index
      )
      // updates order on page
      
      // list with new position order for sb update
      const updates1 = updatedPlaylist.map((p, i) => ({
          ...p,
          playlist_position: i, 
      }))
      setOrderedPlaylist(updates1)
      try {
       await updatePlaylistOrder1(updates1)
       await dexdb.playlist.bulkPut(updates1)
     
       showSnack('success', 'Updated playlist!')
      } catch (error){
        // rolls back order change on the page since sb update wasn't successful
       
        showSnack('error', 'Error modifying playlist!')
      }
    }
       
    const goToPlayPlaylist = () => history.push(`/play_playlist`)

    const goToPlayedlist = () => history.push(`/playedlist`)

    const playlistCount = orderedPlaylist.length ?? 0

    useEffect(() => {
      checkQuizCount()
      if(playlist && playlist.length>0){
        setOrderedPlaylist(playlist)
      }
    },[playlist])
   
    return  <PlaylistPage  
              playlist={orderedPlaylist}
              goToPlayPlaylist={goToPlayPlaylist}
              goToPlayedlist={goToPlayedlist}
              playlistCount={playlistCount}
              onDragEnd={onDragEnd}
              isError={false} 
              loading={false}
              errorMsg={''}
              snack={snack}
              msgType={msgType}
              alertMsg={alertMsg}
              handleClose={handleClose}
              playlist1={orderedPlaylist}
          />
}