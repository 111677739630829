import React from 'react'

import { makeStyles } from '@mui/styles'
import IframeResizer from 'iframe-resizer-react'

const useStyles = makeStyles(theme => ({
    articleFrame: { 
        width: '1px', 
        minWidth: '100%',
        height:'75vh',
        marginTop:20,
        marginBottom:20
      }
  }))

export default function ArticleFrame({
    link,
}){
    const classes = useStyles()
    // const artfram = window.document.getElementById("frame")
    // const sel = window.getSelection()
    // // var idoc= artfram.contentDocument || artfram.contentWindow.document; 
    return(
            <IframeResizer
                id="frame"
                scrolling
                autoResize
                src={link}
                heightCalculationMethod="lowestElement"
                className={classes.articleFrame}
            />
        )
    }