import React, { useEffect, useState, useMemo } from 'react'
import { useLiveQuery } from "dexie-react-hooks"

import { useParams } from "react-router-dom"

import {analytics} from '../App'

import { 
    logEvent, 
} from "firebase/analytics";

import {
  createQuiz,
  updateQuiz,
} from '../queries/quizQueries'

import { 
  langSwitch,
  getOffset,
  checkQuizCount,
} from '../utils'

import { dexdb } from "../App";

import VocabTestLangPage from '../components/Pages/VocabTestLangPage'

export default function VocabTestStartLang(){

    const { 
      lang, 
    } = useParams()

    const language = langSwitch(lang)

    const quiz1 = useLiveQuery(() => dexdb.quiz.where('lang').equals(lang).toArray(), []);

    const member1 = useLiveQuery(() => dexdb.member.toArray(), []);

    const time_zone = useMemo(() =>   member1 ? member1[0].time_zone : '',[member1])
    
    const [ quizActive, setQuizActive ] = useState(false)
    const [ startTime, setstartTime ] = useState(6)
    const [ endTime, setendTime ] = useState(23)
    const [ quizTimeZone, setQuizTimeZone ] = useState('')
    const [ quizType, setQuizType ] = useState('')
    const [ success, setSuccess ] = useState(false)
    const [ edit, setEdit ] = useState(false)
    const [ quizExists, setQuizExists ] = useState(false)
    const [ loading, setLoading] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)
    const toggleEdit = () => setEdit(!edit)
    const handleStartTime = e => setstartTime(e.target.value)
    const handleEndTime = e => setendTime(e.target.value)
    const handleTimeZone = e => setQuizTimeZone(e.target.value)
    const handleQuizType = e => setQuizType(e)
    const handleSuccess = () => setSuccess(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const toggleSwitch = async () => {
      const id = quiz1[0].id
      try {
        await updateQuiz({
          id,
          quiz_active: !quizActive
        })
        setQuizActive(!quizActive)
        await dexdb.quiz.update(id, {quiz_active: !quizActive})
        logEvent(analytics, 'toggle_quiz', {
          lang, 
          active: !quizActive,
          device: 'web',
        })
       } catch(error){
         showSnack('error', 'Error updating quiz!')
       }
    }

    const updateVocabQuiz = async () => {
      const id = quiz1[0].id
      setLoading(true)
      const offset =  getOffset(quizTimeZone)
      const quizUpdate = {
        id,
        quiz_time_zone: quizTimeZone,
        end_time: endTime,
        start_time: startTime,
        quiz_type: quizType,
        tz_offset: offset,
      }
      try {
        await updateQuiz(quizUpdate)
        await dexdb.quiz.update(id, quizUpdate)
        showSnack('success', 'Quiz created!')
      } catch(error){
        showSnack('error', 'Error updating quiz!')
      } finally {
        setLoading(false)
      }
    }

    const addVocabQuiz = async () => {
      if(quizTimeZone.length===0){
        showSnack('error', 'Please select timezone.')
      }
      setLoading(true)
      const offset =  getOffset(quizTimeZone)
      const newQuiz = {
        quiz_time_zone: quizTimeZone,
        end_time: endTime,
        start_time: startTime,
        quiz_type: quizType,
        tz_offset: offset,
        lang,
        member_id: member1.id,
      }
      try {
       const {id} = await createQuiz(newQuiz)
        await dexdb.quiz.put({...newQuiz, id})
         showSnack('success', 'Quiz created!')
       } catch(error){
         showSnack('error', 'Error updating quiz!')
       } finally {
         setLoading(false)
       }
    }
   
    useEffect(() => {
      const controller = new AbortController();

      if(quiz1 && quiz1.length>0){
        const quiz = quiz1[0]
        setQuizActive(quiz['quiz_active'])
        setstartTime(quiz['start_time'])
        setendTime(quiz['end_time'])
        setQuizTimeZone(quiz['quiz_time_zone'])
        setQuizType(quiz['quiz_type'])
        setQuizExists(true)
      } else {
        setQuizTimeZone(time_zone)
      }
      checkQuizCount()
      return () => {
        controller.abort();
      }

    }, [quiz1, time_zone])

    if(!member1) return <div></div>
    return  <VocabTestLangPage 
              user={member1[0]}
              quizExists={quizExists} 
              lang={lang}
              language={language}
              quizActive={quizActive}
              startTime={startTime}
              endTime={endTime}
              quizTimeZone={quizTimeZone}
              success={success}
              toggleSwitch={toggleSwitch}
              handleStartTime={handleStartTime}
              handleEndTime={handleEndTime}
              handleTimeZone={handleTimeZone}
              handleSuccess={handleSuccess}
              updateVocabQuiz={updateVocabQuiz}
              addVocabQuiz={addVocabQuiz}
              vocabstring={''}
              snack={snack}
              msgType={msgType}
              alertMsg={alertMsg}
              handleClose={handleClose}
              loading={loading}
              edit={edit}
              toggleEdit={toggleEdit}
              quizType={quizType}
              handleQuizType={handleQuizType}
            />
}