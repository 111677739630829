import React from 'react'

import { getTranslations } from '../../utils'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

import SnackAlert from '../Dialogs/SnackAlert'
import GetMobileApp from '../Profile/GetMobileApp'
import UpdateQuiz from '../VocabQuiz/UpdateQuiz'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import FlagRound from '../ImageElements/FlagRound'
import AdSidebar from "../Ads/AdSidebar"

export default function VocabTestLangPage({
  user,
  quizExists,
  lang,
  quizActive,
  startTime,
  endTime,
  quizTimeZone,
  success,
  toggleSwitch,
  handleStartTime,
  handleEndTime,
  handleTimeZone,
  handleSuccess,
  updateVocabQuiz,
  addVocabQuiz,
  snack,
  msgType,
  alertMsg,
  handleClose,
  edit,
  toggleEdit,
  quizType,
  handleQuizType,
}){

    const trans = getTranslations()
    const { 
      notify, 
      quizzes,
     } = trans 


    const language = trans[lang]
    const flagSize = 'medium'
    return <Grid container spacing={1} justifyContent='center' >
              <Grid item xs={12} lg={9}>
                <Container component="main" maxWidth="xl" >
                  <main >
                  <Card  > 
                    <CardContent>
                      <Grid container 
                            justifyContent='space-between' 
                            alignItems="center"
                            spacing={2}
                      >
                        <Grid item  >
                          <Grid container   
                                spacing={3} 
                                alignItems="center"
                          >
                            <Grid item >
                              <FlagRound 
                                flagSize={flagSize}
                                lang={lang}
                              />
                            </Grid>
                            <Grid item >
                              <Typography 
                                component="h4" 
                                variant="h4" 
                                sx={{ color: '#6415ff'}}
                              >
                                {language} Vocabulary Quiz
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item >
                            <BasicLinkIconButton 
                              to={`/user/vocabtest/${lang}`}
                              title='Start Quiz'
                              icon={<QuestionAnswerIcon />}
                              color='success'
                              size='large'
                            />
                        </Grid>
                      </Grid> 
                    </CardContent>
                    <Divider />
                    <CardContent>
                       
                      <Typography variant="h5" color='#6415ff'>
                        {notify}
                      </Typography>
                      <Typography >
                        {quizzes}
                      </Typography>
                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                        {user && user.fcm_token ? 
                          <UpdateQuiz 
                            quizExists={quizExists}
                            quizActive={quizActive}
                            toggleSwitch={toggleSwitch}
                            startTime={startTime}
                            handleStartTime={handleStartTime}
                            endTime={endTime}
                            handleEndTime={handleEndTime}
                            handleSuccess={handleSuccess}
                            success={success}
                            quizTimeZone={quizTimeZone}
                            handleTimeZone={handleTimeZone}
                            updateVocabQuiz={updateVocabQuiz}
                            addVocabQuiz={addVocabQuiz}
                            quizType={quizType}
                            handleQuizType={handleQuizType}
                            edit={edit}
                            toggleEdit={toggleEdit}
                          />
                          :
                          <GetMobileApp  />
                      }
                    </CardContent>
                  </Card>
                  <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                  />
                </main>
                </Container>
              </Grid>
              <Grid item>
                <AdSidebar />
              </Grid>
            </Grid>
    

}