import React , {useState} from 'react'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia';

import { CardActionArea } from '@mui/material';

import ImageModal from './ImageModal'

export default function ImgCard({
    vocab_image_library,
  }){
    const {url} = vocab_image_library

    const cardUrl = url.replace('private', '120')

    const [ confirm, setConfirm ] = useState(false)
  
    const toggleConfirm = () => setConfirm(!confirm)

    const handleImageError = (e) => {
      e.target.onerror = null;
      e.target.src = url
    }
  
    return <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="120"
                image={cardUrl}
                alt={url}
                onClick={toggleConfirm}
                onError={handleImageError}
              />
            </CardActionArea>
            <ImageModal
              fullUrl={url}
              confirm={confirm}
              closeConfirm={toggleConfirm}
            />
          </Card>
  }
  