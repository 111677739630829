import {supabase} from "../supabase"
import {formatDate1} from '../utils'
import { getAuthuser } from "./memberQueries.js"
import { dexdb } from '../App'

const table = 'recommendations'

// MUTATIONS

export const setDbArt = async id => {
    const art = await getArt({id})
    await dexdb.articles.put(art)
}

export const updateRecommendation = async update => {

    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)
        .select()

    if(error)  throw new Error(error.message)

    if(data.length===0)  throw new Error('No user data!')

    return data
}

export const updatePLRead = async update => {

    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('art_id', update.art_id)
        .select()

    if(error) throw new Error(error.message)

    return data
}

export const readArtUpdate = async ({
    rec_id,
    read,
    read_date,
})=> {
    const { data, error } = await supabase
        .from(table)
        .update({
            read,
            read_date,
        })
        .eq('id', rec_id)
        .select()

    if(error)  throw new Error(error.message)

    return data
}

export const deleteRecommendation = async ({id}) => {
    const { data, error } = await supabase
        .from(table)
        .delete({returning: 'minimal'})
        .eq('id', id)
        .select()

    if(error) throw new Error(error.message)

    return data
}

// QUERIES

const recSelectQuery = `id,
                        playlist,
                        rec_date,
                        lang,
                        cluster_id,
                        aid,
                        articles(
                            id,
                            title,
                            url,
                            date
                        )`

export const getRecommendation = async ({
    clusterId,
}) => {
    const { data, error } = await supabase
        .from(table)
        .select()
        .eq('id', clusterId)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")

    return data
}

export const getRecommendationArt = async ({
    id,
}) => {
    const recArtQuery = `id,
    playlist,
    rec_date,
    lang,
    cluster_id,
    read,
    aid,
    articles(
        id,
        title,
        url,
        date, 
        user_vocab_words(
            source_word,
            target_word
        )
    ),
    profile(
        id,
        native_lang,
        langs
    )
    user_vocab_words(
        id,
        source_word,
        target_word
    ),
    user_vocab_sentences(
        user_vocab_words(
            id,
            source_word,
            target_word,
            source_lang,
            target_lang
        )
    )
   `
    const { data, error } = await supabase
        .from(table)
        .select(recArtQuery)
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")

    return data
}

const artquery = `
    id,
    title,
    url,
    date, 
    lang,
    read(
        id,
        active
    ),
    playlist(
        id,
        active
    ),
    user_vocab_sentences(
        user_vocab_words(
            id,
            source_word,
            target_word,
            source_lang,
            target_lang,
            active
        )
    )
    `

// const playArtQuery = `
//     id,
//     title,
//     url,
//     date, 
//     lang,
//     text_art,
//     read(
//         id,
//         active
//     ),
//     playlist(
//         id,
//         active
//     )
//     `
const playArtQuery2 = `
    id,
    text_art,
    listen_time(
        sent_index
    )
    `

export const getArt = async ({
    id,
}) => {
    const user = await getAuthuser()
    if(!user){
       return
    }
    const { data, error } = await supabase
        .from('articles')
        .select(artquery)
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")
    return data
}

export const getPlayArt = async ({
    id,
}) => {
    const user = await getAuthuser()
    if(!user){
       return
    }
    const { data, error } = await supabase
        .from('articles')
        .select(playArtQuery2)
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")

    return data
}

export const getRecommendationArtRead = async ({
    id,
}) => {
    const recArtReadQuery = `id,
        playlist,
        rec_date,
        lang,
        cluster_id,
        articles(
            id,
            title,
            url,
            date,
            text_art
        )`
    const { data, error } = await supabase
        .from(table)
        .select(recArtReadQuery)
        .eq('id', id)
        .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")

    return data
  }

export const getRecommendationTextArt = async ({
    id,
}) => {
const { data, error } = await supabase
    .from(table)
    .select(`id,
             articles(
                text_art
            )`)
    .eq('id', id)
    .single()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Recommendation not found")

    return data
}

export const getCurrentRecommendations = async () => {
    const today = new Date()
  
    const date = formatDate1(today,'/')
    let { data, error } = await supabase
        .from(table)
        .select(recSelectQuery)
        .eq('rec_date', date)
    if(error) throw new Error(error.message)
    return data
}


export const getAndStoreCurRecs = async () => {
    const recommendations = await getCurrentRecommendations()
    await dexdb.recommendations.bulkPut(recommendations);
}

export const getHistoryRecommendations = async ({
    lang,
    dateStr,
}) => {

    let { data, error } = await supabase
        .from(table)
        .select(recSelectQuery)
        .eq('lang', lang)
        .eq('rec_date', dateStr)
        
    if(error) throw new Error(error.message)

    return data
}

export const getRecArt = async ({
    art_id,
}) => {
    let { data, error } = await supabase
        .from(table)
        .select('id')
        .eq('art_id', art_id)
        .order('inserted_at', {ascending: false})
        .limit(1)

    if(error) throw new Error(error.message)
    if(!data || data.length===0) throw new Error('No recommendation')
    return data[0]['id']
}
