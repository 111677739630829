import React from "react";

import { 
  getTranslations, 
} from '../../utils'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'

import DateRange from '@mui/icons-material/DateRange'
import RefreshIcon from '@mui/icons-material/Refresh'

import ArtLinkItem from '../ListItems/ArtLinkItem'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import BasicIconButton from "../Buttons/BasicIconButton";
import FlagRound from '../ImageElements/FlagRound'
import AdSidebar from "../Ads/AdSidebar"
import ClusterDropDown from '../DropDowns/ClusterDropDown'

export default function RecommendationsPage({
  recommendations,
  refreshRecs,
  handleCluster,
  selCluster,
  clusters,
  recCount,
  language,
  lang,
  isLoading,
}){

  const {articles} = getTranslations()
  const flagSize='small'
  
  return (
  <Grid container spacing={1} justifyContent='center' >
    <Grid item xs={12} lg={9}>
      <Container component="main" maxWidth="xl" >
        <Card sx={{minHeight:600}} >
          <CardContent>
          <Grid container 
                justifyContent="space-between"
                alignItems="center"
          > 
            <Grid item>

              <Grid container 
                    alignItems="center"
                    spacing={2}
                    sx={{minHeight: 80}}
              > 
                <Grid item >
                {!isLoading && language.length>0 && 
                  <FlagRound 
                    flagSize={flagSize}
                    lang={lang}
                  />
                }  
                </Grid> 
                <Grid item >
                {!isLoading && language.length>0 && 
                  <Typography 
                    variant={'h5'}
                    color='primary'
                  > 
                    {recCount} {language} {articles}
                  </Typography>
                  }
                </Grid> 
                  
            </Grid> 
   
          </Grid>
            <Grid item >
       
              <Grid container 
                    spacing={2}  
                    alignItems='center'
              >
                <Grid item>
                  {clusters && clusters.length &&
                    <ClusterDropDown
                      selCluster={selCluster}
                      clusters={clusters}
                      handleCluster={handleCluster}
                    />
                  }
                </Grid> 
                <Grid item >
                  <BasicIconButton 
                    title='Refresh'
                    actionFunc={refreshRecs}
                    icon={<RefreshIcon />}
                  />
                </Grid>
                <Grid item >
                  <BasicLinkIconButton 
                    title='History'
                    to='/user/history'
                    icon={<DateRange />}
                  />
                </Grid>
              </Grid>
            </Grid>  
            <Grid item xs={12}>
              <Divider sx={{mb:2, mt:1}} />
            </Grid>
   
              <Grid container 
                spacing={2}
                alignItems="center"
              >  
                <Grid item xs={12}>
                  {recommendations.length===0 && 
                      <Alert severity="error" sx={{m:2}}>
                        No Recommendations.
                      </Alert>
                  }
                  {isLoading ?
                    <div  />
                    :
                    <Grid container 
                      alignItems="center"
                      spacing={1}
                    > 
                      {recommendations && recommendations.length>0 && recommendations.map((r,i) => 
                        <ArtLinkItem 
                          key={i} 
                          lang={recommendations[0].lang} 
                          {...r}
                        />
                      )}
                    </Grid>
                  }
                  </Grid>
                </Grid>

          </Grid>
          </CardContent>
        </Card>
        
      </Container>
    </Grid>
    <Grid item>
      <AdSidebar />
    </Grid>
  </Grid>
    )
  }
