import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import {
  getNewTranslatedArt, 
  createTranslatedArt1,
  deleteNewTranslatedArt,
} from '../queries/translatedArtQueries'

import ClusterArtTextPage from '../components/Pages/ClusterArtTextPage'
import Loading from "../components/Dialogs/Loading";

const CHARACTER_LIMIT = 10000
const MIN_CHARACTER = 200

export default function ClusterArtExtText({history}){

    const { 
      link_id,
    } = useParams()

    const tooLongError = `Please add text that is less than ${CHARACTER_LIMIT} words.`

    const tooShortError = `Please enter text that is at least ${MIN_CHARACTER} characters`

    const instructions = ` If an article link you want to add requires a login or is otherwise not publicly available, you can add the link, article title and text (${MIN_CHARACTER} - ${CHARACTER_LIMIT} characters). `
  
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ text, setText ] = useState('Loading...')
    const [ title, setTitle ] = useState('')
    const [ url, setUrl ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ error1, setError ] = useState(false)
    const [ lang, setlang ] = useState(false)
    const [ orig_lang, setorig_lang ] = useState(false)
    const [ cluster_id, setcluster_id ] = useState(0)
    const [ clusterName, setclusterName ] = useState('')
    const [ artloading, setArtLoading ] = useState(false)

    const wordCount = text.length ?? 0

    const handleText = event => setText(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const setArticle = (art) => {
      setTitle(art['title'])
      setUrl(art['url'])
      setText(art['orig_text'])
      setlang(art['lang'])
      setorig_lang(art.orig_lang)
      setclusterName(art.clusters.cluster_name)
      setcluster_id(art.cluster_id)
      setLoading(false)
    }

    const checkItem = () => wordCount > CHARACTER_LIMIT || wordCount<MIN_CHARACTER ? false : true

    const goToCluster = () =>  history.push(`/user/cluster/${cluster_id}`)

  //   const createTranslatedArtMutation = useMutation(() => createTranslatedArt1({
  //     url,
  //     lang,
  //     title,
  //     orig_text: text,
  //     orig_lang,
  //     cluster_id,
  //     source: 'web',
  // }), {
  //   onSuccess: async (data) => {
  //     showSnack('success', 'Article added!')
  //     await deleteNewTranslatedArt(link_id)
  //     queryClient.invalidateQueries('clusters')
  //     queryClient.invalidateQueries('clusters1')
  //     goToCluster()
  //   },
  //   onError: (error) => {
  //     showSnack('error', 'Error adding article!')
  //   },
  //   onSettled: () => {
  //     setLoading(false)
  //   }
  // })

    const articleTextAdd = async () => {
      setLoading(true)

      if(wordCount>CHARACTER_LIMIT){
        setLoading(false)
        showSnack('error', tooLongError)
        return
      }

      if(wordCount<MIN_CHARACTER){
        setLoading(false)
        showSnack('error', tooShortError)
        return
      }
      
      try {
        await createTranslatedArt1({
          url,
          lang,
          title,
          orig_text: text,
          orig_lang,
          cluster_id,
          source: 'web',
        })

        await deleteNewTranslatedArt(link_id)
        showSnack('success', 'Article added!')
        goToCluster()
      } catch(error){
        showSnack('error', 'Error adding article!')
      } finally{
        setLoading(false)
      }
    }

    useEffect(()=> {
      const getArt = async (link_id) => {
        try {
          const translated_art = await getNewTranslatedArt({id: link_id})

          if(translated_art && translated_art.clusters){
            setArticle(translated_art)
          } else {
            setError(true)
          }
        } catch(error){
          setError(true)
        } finally {
          setArtLoading(false)
        }
      }
      setArtLoading(true)
      setLoading(true)
      getArt(link_id)
    }, [link_id])

    if(artloading) return <Loading />
    return <ClusterArtTextPage 
            clusterName={clusterName}
            isError={error1}
            clusterActive={false}
            lang={lang}
            count={-1}
            url={url}
            title={title}
            text={text}
            loading={loading }
            handleText={handleText}
            updateText={articleTextAdd}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            checkItem={checkItem}
            characterLimit={CHARACTER_LIMIT}
            characterMin={MIN_CHARACTER}
            wordCount={wordCount}
            buttonTitle='Add Article'
            instructions={instructions}
          />
}