import React , {useState} from 'react'

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function ImageCardSearch({
    url,
    id,
    selectImage,
    removeImage,
    selectedImages,
    sourceWord,
  }){
    
    const cardUrl = url.replace('private', '200')
  
    const [ confirm, setConfirm ] = useState(false)
  
    const toggleConfirm = () => setConfirm(!confirm)

    const selected = selectedImages.includes(id)

    const handleImageError = (e) => {
      e.target.onerror = null;
      e.target.src = url
    }
  
    const addImage = async () => {
      if(selected){
        removeImage(id)
      } else {
        selectImage(id)
      }
    }  
    
  
    return <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={selectImage}>
              <CardMedia
                component="img"
                height="200"
                image={cardUrl}
                alt={sourceWord}
                onClick={toggleConfirm}
                onError={handleImageError}
              />
            </CardActionArea>
            <CardActions>
              <Button 
                size="small" 
                fullWidth
                startIcon={selected ? 
                  <CheckIcon color='success' fontSize='small' /> 
                  : 
                  <AddIcon color='primary' fontSize='small' /> } 
                    color={selected ? 'success' : 'inherit' } 
                    onClick={addImage}
                  >
                {selected ? 'Selected' : 'Add'}
              </Button>
            </CardActions>
          </Card>
  }
  