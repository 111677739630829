import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLiveQuery } from "dexie-react-hooks";
import { dexdb } from '../App';
import { useParams } from "react-router-dom"
import {supabase} from '../supabase'

 import {analytics} from '../App'

 import { 
     logEvent, 
 } from "firebase/analytics";
 
import {
  getCurrentVocabWordsPaginated,
  getCurrentVocabWordsPaginatedSourceSort,
  addUserVocabWord,
} from '../queries/vocabWordQueries'

import { 
  langSwitch,
  incrementStorage,
  getStoredPage,
  checkQuizCount,
 } from '../utils'

import TranslationsPage from '../components/Pages/TranslationsPage'

export default function Translations(){

    const { 
      lang, 
    } = useParams()

    const member = useLiveQuery(() => dexdb.member.toArray(), []);

    const native_lang = useMemo(() => member ? member[0].native_lang : 'en', [member]);

    const language = langSwitch(lang)
    const nativeLanguage = langSwitch(native_lang)
    
    const [ origWord, setOrigWord ] = useState('')
    const [ transWord, setTransWord ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ buttonLoading, setButtonLoading ] = useState(false)
    const [ page, setPage ] = useState(0)
    const [ correctCount, setCorrectCount ] = useState(0)
    const [ answerCount, setAnswerCount ] = useState(0)
    const [ lastAnswered, setLastAnswered ] = useState()
    const [ vocabSearchTerm, setVocabSearchTerm ]  = useState('')
    const [ vocabSearchedSource, setVocabSearchedSource ]  = useState('')
    const [ vocabSearchedTarget, setVocabSearchedTarget ]  = useState('')
    const [ searchLang, setSearchLang ]  = useState('')
    const [ searchId, setSearchId ] = useState(0)
    const [ ascSource, setAscSource ] = useState(true)
    const [ ascTarget, setAscTarget ] = useState(true)
    const [ ascAnswered, setAscAnswered ] = useState(true)
    const [ wordType, setWordType ] = useState('source_word')
    const [ sortType, setSortType ]= useState('vocab')
    const [ search, setSearch ] = useState(false)
    const [ addWord, setAddWord ] = useState(false)
    const [ vocabList, setVocabList ] = useState([])
    const [ count, setCount ] = useState(0)

    const handleVocabSearch = event => setVocabSearchTerm(event.target.value)

    const toggleSearch = () => {
      setSearch(!search)
      setAddWord(false)
    }
    const toggleAddWord = () => {
      setAddWord(!addWord)
      setSearch(false)
    }

    const handleSortType = value => setSortType(value)

    const handleWordType = async value => setWordType(value)
  
    const toggleAscSource = () => setAscSource(!ascSource)
    const toggleAscTarget = () => setAscTarget(!ascTarget)
    const toggleAscAnsewred = () => setAscAnswered(!ascAnswered)

    const vws1 = vocabList
    const langVocabWords1 = vws1.filter(v => v.source_lang===lang)
    const itemCount1 = count

    const maxPage = Math.ceil(itemCount1/10) 

    const handleClose = () => setSnack(false)
    const handleOrigWord = event => setOrigWord(event.target.value)
    const handleTransWord = event => setTransWord(event.target.value)
   
    const showSnack = (type, message) => {
      setButtonLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const transCount = vocabList.length

    const pageName = 'user_vocab_page'
    
    const pageForward1 = () =>{ 

    if(page+1 < maxPage) {
      setPage(prevPage => prevPage + 1)
      incrementStorage(pageName, page, 1)
    }
     
    }
    const pageBack1 = () =>{ 
      if(page>0) {
        setPage(prevPage => prevPage - 1)
        incrementStorage(pageName, page,  -1)
      }
    }

    const clearInput = () => {
      setOrigWord('')
      setTransWord('')
    }

    const setSourceSort = useCallback(async () => {
      try {
      const words = await getCurrentVocabWordsPaginatedSourceSort(
        page,
        ascSource,
        'source_word',
      ) 
        setVocabList(words.userVocabsWords)
        setCount(words.count)
      } catch(error){
        setSnack('error','Error getting vocabulary!')
      }
    },[page, ascSource])

    const setTargetSort = async () => {
      try {
      const words = await getCurrentVocabWordsPaginatedSourceSort(
        page,
        ascTarget,
        'target_word',
      ) 
        setVocabList(words.userVocabsWords)
        setCount(words.count)
      } catch(error){
        setSnack('error','Error getting vocabulary!')
      }
    }

    const setAnsweredSort = async () => {
      try {
        const words = await getCurrentVocabWordsPaginated(
          page,
          ascAnswered,
        ) 
        setVocabList(words.userVocabsWords)
        setCount(words.count)
      } catch(error){
        setSnack('error','Error getting vocabulary!')
      }
    }

    const reloadVocab = () => {
      if(sortType==='vocab' && wordType==='source_word'){
        setSourceSort()
      }

      if(sortType==='vocab' && wordType==='target_word'){
        setTargetSort()
      }
      
      if(sortType==='lastanswer'){
        setAnsweredSort()
      }
    }

    const addTranslation = async () => {
    
      const alreadyAdded = langVocabWords1.filter(w => w.source_word === origWord.trim())
      if(origWord.length===0 || transWord.length===0){
        showSnack('error','Error! Please add both an original word and translation')
        return
      }
      if(alreadyAdded.length>0){
        showSnack('error','Error! This word is already been added to your list.')
        return
      }
      setButtonLoading(true)

      try {
        await addUserVocabWord({
          source_word: origWord,
          target_word: transWord,
          source_lang: lang,
          target_lang: native_lang,
        })
        showSnack('success', 'Word added!')
        clearInput()
        reloadVocab()
        logEvent(analytics, 'add_vocab', {
          source_lang: lang, 
          target_lang: native_lang, 
          number: 1,  
          type: 'manual',
          device: 'web',
        })
      } catch(error){
        showSnack('error', 'Error adding word!')
      } finally{
        setButtonLoading(false)
      }

    }

    const searchVocab  = async () => {
      setButtonLoading(true)
  
      const wordField = searchLang===lang ? 'source_word' : 'target_word'
      const searchTerm = vocabSearchTerm.trim()

      try {
      
            let { data: vocab, error } = await supabase
              .from('user_vocab_words')
              .select(`
                      id, 
                      source_word, 
                      target_word,
                      answer_count, 
                      correct_count, 
                      last_answered
                    `)
              .ilike(wordField, searchTerm)
              .eq('active', true)
      
            if (error) {
              showSnack('error', 'Error searching word!')
            }
     
            if(vocab && vocab.length>0){
              const cor = vocab[0]['correct_count']
              const answ = vocab[0]['answer_count']
              const last = vocab[0]['last_answered']
              const id = vocab[0]['id']
              const sourceWord = vocab[0]['source_word']
              const targetWord = vocab[0]['target_word']
              setVocabSearchedTarget(targetWord)
              setVocabSearchedSource(sourceWord)
              setCorrectCount(cor)
              setAnswerCount(answ)
              setLastAnswered(last)
              setSearchId(id)
           
            } else {
              setVocabSearchedSource('No results!')
              setVocabSearchedTarget('')
              setCorrectCount('')
              setAnswerCount('')
              setLastAnswered()
            }

      } catch(error){
        showSnack('error', 'Error searching word!')
       } finally {
        setButtonLoading(false)
      }
    }

    const clearSearch = async () => {
      setVocabSearchedSource('')
      setVocabSearchedTarget('')
      setVocabSearchTerm('')
    }

    useEffect(()=>{
      const setSourceSort = async () => {
        try {
        const words = await getCurrentVocabWordsPaginatedSourceSort(
          page,
          ascSource,
          'source_word',
        ) 
          setVocabList(words.userVocabsWords)
          setCount(words.count)
        } catch(error){
          setSnack('error','Error getting vocabulary!')
        }
      }
  
      const setTargetSort = async () => {
        try {
        const words = await getCurrentVocabWordsPaginatedSourceSort(
          page,
          ascTarget,
          'target_word',
        ) 
          setVocabList(words.userVocabsWords)
          setCount(words.count)
        } catch(error){
          setSnack('error','Error getting vocabulary!')
        }
      }
  
      const setAnsweredSort = async () => {
        try {
          const words = await getCurrentVocabWordsPaginated(
            page,
            ascAnswered,
          ) 
          setVocabList(words.userVocabsWords)
          setCount(words.count)
        } catch(error){
          setSnack('error','Error getting vocabulary!')
        }
      }


    const reloadVocab = () => {
      if(sortType==='vocab' && wordType==='source_word'){
        setSourceSort()
      }

      if(sortType==='vocab' && wordType==='target_word'){
        setTargetSort()
      }
      
      if(sortType==='lastanswer' && sortType!=='vocab'){
        setAnsweredSort()
      }
    }
  
      const curPageint = getStoredPage(pageName)
      setPage(curPageint)
      setSearchLang(lang)

      reloadVocab()
      checkQuizCount()
    },[ascAnswered, ascSource, ascTarget, lang, page, sortType, wordType])


    return  <TranslationsPage 
              sortType={sortType}
              wordType={wordType}
              lang={lang}
              language={language}
              native_lang={native_lang}
              nativeLanguage={nativeLanguage}
              translations={vws1}
              origWord={origWord}
              transWord={transWord}
              handleOrigWord={handleOrigWord}
              handleTransWord={handleTransWord}
              addTranslation={addTranslation}
              snack={snack}
              loading={false}
              msgType={msgType}
              alertMsg={alertMsg}
              buttonLoading={buttonLoading}
              handleClose={handleClose}
              transCount={transCount}
              page={page}
              pageBack={pageBack1}
              pageForward={pageForward1}
              maxPage={maxPage}
              isFetching={false}
              vocabSearchedSource={vocabSearchedSource}
              vocabSearchedTarget={vocabSearchedTarget}
              clearSearch={clearSearch}
              vocabSearchTerm={vocabSearchTerm}
              handleVocabSearch={handleVocabSearch}
              searchVocab={searchVocab}
              setSearchLang={setSearchLang}
              searchLang={searchLang}
              answerCount={answerCount}
              correctCount={correctCount}
              lastAnswered={lastAnswered}
              searchId={searchId}
              itemCount={itemCount1}
              handleSortType={handleSortType}
              ascAnswered={ascAnswered}
              ascSource={ascSource}
              ascTarget={ascTarget}
              toggleAscSource={toggleAscSource}
              toggleAscTarget={toggleAscTarget}
              toggleAscAnsewred={toggleAscAnsewred}
              handleWordType={handleWordType}
              toggleAddWord={toggleAddWord}
              toggleSearch={toggleSearch}
              addWord={addWord}
              search={search}
              setSourceSort={setSourceSort}
              setTargetSort={setTargetSort}
              setAnsweredSort={setAnsweredSort}
            />
}