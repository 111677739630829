import React from 'react'

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'

import ImgCard from './ImgCard';

export default function ImgList({
    vocabImages,
}) {

  return    <Grid container 
              spacing={2} 
            >           
                <Grid item xs={12} >
                    <Grid container 
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={2} 
                    >   
                        <Grid item >
                            <Typography variant='h6'>
                                Images
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>
                {vocabImages.map((item, i) => 
                    <Grid item key={i}>
                        <ImgCard {...item} key={i} />
                    </Grid>
                )}
            </Grid>
}
