import {supabase} from "../supabase"

import {incrementProgress} from './progressQueries'

import {
    getNowIso,
} from '../utils'

import { getAuthuser } from "./memberQueries.js"

const table = 'listen_time'

// MUTATIONS

export const createSentListen = async ({
    art_id,
    rate,
    listen_time_secs,
    sentIdx
}) => {
    const user = await getAuthuser()
    const listen_date = getNowIso()
    if(user){
        const aid = user.id 
        const { error } = await supabase
            .from(table)
            .upsert({
                article_id: art_id,
                aid,
                rate,
                sent_index: sentIdx,
                created_at: listen_date,
                listen_time_secs,
            }, { onConflict: 'article_id, aid, sent_index'})
        
        if(error) throw new Error(error.message)
        await incrementProgress('listen_count', listen_time_secs)
        return 
    } else {
        throw new Error('You are not authenticated!')
    }
}

// FETCH

export const getListenArtCount = async ({
    art_id,
}) => {
    let { error, count } = await supabase
        .from(table)
        .select('id', { count: 'exact' })
        .eq('article_id', art_id)

    if(error) throw new Error(error.message)

    return count
}