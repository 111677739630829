import {supabase} from "../supabase.js"
import { dexdb } from "../App.js"
import { getAuthuser } from "./memberQueries.js"

const table = 'library'

// MUTATIONS
export const createLibraryItem =  async ({
    url,
    title,
    lang,
}) => {
    const user = await getAuthuser()
    const aid = user.id
    const decocdedurl = decodeURIComponent(url)
    if(user){
        const newLibraryItem = {
            url: decocdedurl,
            title,
            lang,
            aid,
        }
        const { data, error } = await supabase
            .from(table)
            .insert(newLibraryItem)
            .select('id')
    if(error) throw new Error(error.message)

    const newLibrary = {
        id: data[0]['id'],
        url,
        title,
        lang,
    }
    await dexdb.library.put(newLibrary)
    return data[0]
    } else {
        throw new Error('Not logged in!')
    }
}

export const deleteLibraryItem = async ({url}) => {
    const { error } = await supabase
        .from(table)
        .delete()
        .eq('url', url)

    if(error) throw new Error(error.message)

    return
}

// QUERIES

export const getLibraryItems = async () => {
    const { data, error } = await supabase
        .from(table)
        .select(`
            id,
            url,
            title,
            lang
        `)

    if(error) throw new Error(error.message)

    if(!data)  throw new Error("Cluster not found")
   
    return data
}

// export const prefetchLibraryItems = async () => await queryClient.prefetchQuery('library', getLibraryItems, {staleTime: 86400000})