import React, { useEffect, useState } from 'react'
import {supabase} from "../supabase.js"

import {createSupportRequest} from '../queries/serviceDeskQueries'

import SupportPage from '../components/Pages/SupportPage'

export default function SupportLogged({history}){

    const [ supportType, setSupportType ] = useState('')
    const [ supportQuestion, setSupportQuestion ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ error, setError] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')

    const handleSupportType = event => setSupportType(event.target.value)
    const handleSupportQuestion = event => setSupportQuestion(event.target.value)
    const handleClose = () => setSnack(false)
    const handleError = () => setError(true)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const submitSupport = async () => {
      setLoading(true)

      if (supportType.length===0){
        setMsgType('error')
        return showSnack('error', 'Please choose a support type.')
      }
  
      if ( supportQuestion.length===0){
        return showSnack('error', 'Please choose a support question.')
      }

      const user = supabase.auth.user()
      const {email, id} = user

      try {
       const response = await createSupportRequest({
          supportQuestion, 
          supportType,
          email, 
          auth_id: id
        })
        if(response.error && response.error.length>0){
          showSnack('error', response.error)
          return
        }
        showSnack('success', 'Support Message Sent!')

      } catch(error){
        showSnack('error', error.message)
      } finally{
        setLoading(false)
      }
    }

    useEffect(() => {
      speechSynthesis.cancel()
    });

    const supportTypes = ['Technical','Other']

    return(
      <main sx={{flexGrow: 1}}>
        <SupportPage 
          submitSupport={submitSupport} 
          supportType={supportType}
          supportQuestion={supportQuestion}
          handleSupportType={handleSupportType}
          handleSupportQuestion={handleSupportQuestion}
          supportTypes={supportTypes}
          showSnack={showSnack}
          snack={snack}
          error={error}
          loading={loading}
          alertMsg={alertMsg}
          msgType={msgType}
          handleClose={handleClose}
          handleError={handleError}
          history={history}
        />
      </main>
    )
}