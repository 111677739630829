import React from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { Typography } from '@mui/material';

import FlagRound from '../ImageElements/FlagRound'

import {
    LinkBasic, 
    CaptionDate,
} from '../ListItems/BasicLinks'

import {
    getLocalTime,
} from '../../utils'

export default function PlayedlistCard({ 
    card, 
 }){

    const {
        title,
        lang,
        artDate,
        art_id,
        played_date
    } = card


    const link = `article/${art_id}/${lang}`

    const playedDate = getLocalTime(played_date).format('MMMM Do, YYYY, h:mm a')

    return  <Card sx={{mb: 1, pt: 1, pb: 1, pr: 2, pl: 2 }}>
                <Grid container 
                    alignItems="center"
                    justifyContent='space-between' 
                    spacing={1} 
                >
                     <Grid item xs={12}>
                        <Grid container 
                            alignItems="center"
                            spacing={1} 
                        >
                            <Grid item >
                                <FlagRound 
                                    flagSize='xsmall'
                                    lang={lang}
                                />
                            </Grid>
                            <Grid item >
                                <CaptionDate artDate={artDate} />
                            </Grid>
                            <Grid item xs={12} >
                                <LinkBasic 
                                    to={link} 
                                    title={title}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Typography variant='body2'>
                            Played: {playedDate}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
}