import React from 'react'

import Dialog from '@mui/material/Dialog';

export default function ImageModal({
    fullUrl,
    closeConfirm,
    confirm,
    origWord,
  }){

    const modalUrl = fullUrl.replace('private', '600')

    const handleImageError = (e) => {
      e.target.onerror = null;
      e.target.src = fullUrl
    }
  
    return  <Dialog onClose={closeConfirm} open={confirm}>
              <img
                style={{ width: 'auto', height: '100%' }}
                src={modalUrl}
                alt={origWord}
                onError={handleImageError}
              />
            </Dialog>
  }

