import React , {useState, useEffect} from 'react'

import {supabase} from "../../../supabase"

import {analytics} from '../../../App'

import { 
  logEvent, 
} from "firebase/analytics";

import {
    addVocabImagesFromLibrary,
} from '../../../queries/vocabMediaQueries'

import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ImageCardSearch from './ImageSearchCard'
import Button from '@mui/material/Button'

import { Alert, Divider, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import SnackAlert from '../../Dialogs/SnackAlert'

const query = `
    id,
    url
`

export default function ImageSearchDialog({
    confirm,
    closeConfirm,
    sourceWord,
    sourceLang, 
    targetLang,
    vocabId,
    pictures,
  }){

    const [ selectedImages, setselectedImages ] = useState([])
    const [ msgType, setMsgType ] = useState('info')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ snack, setSnack ] = useState(false)
    const [ curImages, setCurImages] = useState([])

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
    }
  
    const selectImage = (id) => setselectedImages([...selectedImages, id])
    
    const removeImage = (id) => {
        const imagerm = selectedImages.filter(a => a!==id)
        setselectedImages(imagerm)
    }

    const addSelected = async () => {
        const allSel = selectedImages.map(a => ({
            'user_vocab_id': vocabId, 
            'image_library_id':  a,
        }))
        const count = allSel.length
        try {
            await addVocabImagesFromLibrary(allSel)
            showSnack('success', 'Images added!')
            setselectedImages([])
           
            logEvent(analytics, 'add_vocab_image', { 
                source_lang: sourceLang,
                target_lang: targetLang, 
                count: count,
                device: 'web',
            })
        } catch(error){
            showSnack('error', 'Error adding images!')
        }
    }

    useEffect(() => {
        const controller = new AbortController();
  
        async function getImages(){
          const { data, error } = await supabase
            .from('vocab_image_library')
            .select(query)
            .eq('source_word', sourceWord)
            .eq('source_lang', sourceLang)
            .eq('target_lang', targetLang)
       
            if(error) {
                showSnack('error','Error getting images!')
            }
            
            const picIds = pictures.map(p => p.vocab_image_library.id)

            const curImages = data.filter(a => !picIds.includes(a.id))
      
            setCurImages(curImages)
        }

        getImages()
  
        return () => {
          controller.abort();
        }
    },[sourceWord, sourceLang, targetLang, pictures]);

    return  <Dialog onClose={closeConfirm} open={confirm}  >
                <DialogTitle sx={{color: '#6415ff' }}>
                    <Grid container spacing={2} justifyContent='space-between' >
                        <Grid item >
                            Audio Library - {sourceWord}
                        </Grid>
                        <Grid item >
                            <IconButton onClick={closeConfirm}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid> 
                </DialogTitle>
                {curImages.length>0 ?
                    <DialogContent >
                        <Grid container spacing={2} justifyContent='center' >
                            {curImages.map(a => 
                                <Grid item key={a.id} >
                                    <ImageCardSearch 
                                        {...a} 
                                        vocabId={vocabId}
                                        selectImage={selectImage}
                                        removeImage={removeImage}
                                        selectedImages={selectedImages}
                                        sourceWord={sourceWord}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                :
                    <DialogContent >
                        <Alert severity="error">
                            No images or all images have been added for this word!
                        </Alert>
                    </DialogContent>
                }
                <Divider />
                <DialogActions>
                     <Button  
                        disabled={selectedImages.length===0}
                        onClick={addSelected} 
                        color='primary' 
                        startIcon={<AddIcon />}
                    >
                    Add Selected
                  </Button>
                </DialogActions>
                <SnackAlert 
                    snack={snack}
                    handleClose={handleClose}
                    msgType={msgType}
                    alertMsg={alertMsg}
                />
            </Dialog>
}