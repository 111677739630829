import React, { useState, useEffect } from 'react'
import validator from 'validator'

import {supabase} from '../supabase'

import { getTranslations } from '../utils'

import SignUpAllPage from '../components/Pages/SignUpAllPage'
const message = 'Success! Check your email to confirm your address. Login to complete signup.'


export default function SignUpAll(){
  const {
    signuperr,
  } = getTranslations()

  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ errorMsg, setErrorMsg] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handleClose = () => setSnack(false)
  const handlePassword = event => setPassword(event.target.value)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const inputs = [
    {
        name:'email',
        label:'Email Address',
        handleFunc: handleEmail,
        value: email
    },
    {
        name:'password',
        label:'Password',
        handleFunc: handlePassword,
        value: password
    }
  ]

  const signUpGoogle = async () => { 
    setLoading(true)
      
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google'
    })

   if (error)  {
      showSnack('error', 'Error signing up..')
   }

  }

  const signUpGApple = async () => { 
    setLoading(true)
      
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'apple'
    })

   if (error)  {
      showSnack('error', 'Error signing up.')
   }

  }

  const signUpEmail = async () => {

    if (!validator.isEmail(email)) {
      setErrorMsg('Invalid email!')
      return
    } 

    setLoading(true)
    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
      })
      showSnack('success', message)
      if(error){
        showSnack('error', error)
        setErrorMsg(`${signuperr} ${error}`)
        throw error
      }
    } catch(error) {
      showSnack('error', error)
      setErrorMsg(`${signuperr} ${error}`)
      throw error
    } finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if(session){
        if(session.user!==null){
          showSnack('success', message)
        }
      }
    })
  
    const { subscription } = supabase.auth.onAuthStateChange((_event, session) => {

      if(session){
        if(session.user!==null){
          showSnack('success', message)
        }
      }
    })
    return () => {
      subscription?.unsubscribe();
    };
  }, [])

  return <SignUpAllPage  
          inputs={inputs} 
          signUpEmail={signUpEmail} 
          errorMsg={errorMsg} 
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          signUpGoogle={signUpGoogle}
          signUpGApple={signUpGApple}
        />
}